import React from 'react';
import * as emailjs from 'emailjs-com';
import { Container, Form, FormGroup, Label, Input, Row, Col, Card, CardTitle } from 'reactstrap'


class ContactComponent extends React.Component {
  state = {
    name: '',
    email: '',
    subject: '',
    message: '',
  };
  
    
  
  


  
  

handleSubmit(e) {
    e.preventDefault()
    const { name, email, subject, message } = this.state
    let templateParams = {
      email: email,
      name: name,
      subject: subject,
      message: message,
     }
     emailjs.send('service_wmh421i', 'template_if7tw59', templateParams, 'user_S43tLwP50AawjqOC9AJTY')
     alert("Thank you " + this.state.name + ", your message has been sent. We will get back to you as soon as possible."+
     "For immediate assistance, please use our live chat feature (blue widget in the bottom right corner of the screen)");
    this.resetForm()

     
 }
resetForm() {
    this.setState({
      name: '',
      email: '',
      subject: '',
      message: '',
    })
  }
handleChange = (param, e) => {
    this.setState({ [param]: e.target.value })
  }
  
render() {

  return (
<>
     

<div
      id="Contact-Us"
  className="contactBg2 cd-section"
style={{
  backgroundImage:
    "url(" + require("assets/img/nl/capetown.jpg") + ")",
}}
>
          <Container>
            <Row className="col-mob">
              <Col className="ml-auto" md="11" sm="12" xs="12">
                <Card className="card-contact no-transition">
                  <CardTitle className="text-center" tag="h3">
                  <h3 className="titleblack11 align-left">To learn how NEXTELEVEL Technology can transform your business, schedule a free consultation today! </h3>

                  </CardTitle>
                  <Row>
                    
                    <Col className="ml-auto" md="10" sm="12" xs="12">
          <Form onSubmit={this.handleSubmit.bind(this)}>
          <FormGroup controlId="formBasicName" className="align-left">
              <Label className="text-contact2">Name</Label>
              <Input
                type="text"
                name="name"
                value={this.state.name}
                className="headcontact2"
                onChange={this.handleChange.bind(this, 'name')}
                placeholder="Enter Name..."
              />
            </FormGroup>
            <FormGroup controlId="formBasicEmail" className="align-left">
              <Label className="text-contact2">Email Address</Label>
              <Input
                type="email"
                name="email"
                value={this.state.email}
                className="headcontact2"
                onChange={this.handleChange.bind(this, 'email')}
                placeholder="Email Address..."
              />
            </FormGroup>

<FormGroup controlId="formBasicSubject" className="align-left">
              <Label className="text-contact2">Telephone Number</Label>
              <Input
                type="text"
                name="subject"
                className="headcontact2"
                value={this.state.subject}
                onChange={this.handleChange.bind(this, 'subject')}
                placeholder="Contact Number..."
              />
            </FormGroup>
<FormGroup controlId="formBasicMessage" className="align-left">
              <Label className="text-contact2">What can we help you with?</Label>
              <Input
                type="textarea"
                name="message"
                className="headcontact2"
                value={this.state.message}
                onChange={this.handleChange.bind(this, 'message')}
                placeholder="Message..."

              />
              <br></br>
            <br></br>
            <br></br>

            </FormGroup>
            <button color="info" className="start-btn2" type="submit">
              Send 
              <i className="nc-icon nc-send" />
            </button>
            
            <br></br>
            <br></br>
            <br></br>
           

          </Form>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        </>
    )
  }
}
export default ContactComponent
  

  