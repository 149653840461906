/*eslint-disable*/
import React from "react";
// react plugin used to create datetimepicker
import ReactDatetime from "react-datetime";
// react plugin used to create switch buttons
import Switch from "react-bootstrap-switch";
// react plugin used to create an image gallery
import { PhotoSwipeGallery } from "react-photoswipe";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Collapse,
  
  Container,
  Row,
  Col,
  
} from "reactstrap";


function SeoLink() {
 
  // collapse states and functions
  const [collapses, setCollapses] = React.useState([]);
  const changeCollapse = (collapse) => {
    if (collapses.includes(collapse)) {
      setCollapses(collapses.filter((prop) => prop !== collapse));
    } else {
      setCollapses([...collapses, collapse]);
    }
  };
  
  return (
    <>
      <div className="section section-blue javascript-components shift-up-120px bottom-up-150px background-tp">
        <Container>
    
          
          <Row className="justify-center">
            <Col md="12">
              <div id="acordeon">
                <div aria-multiselectable={true} id="accordion" role="tablist">
                  <Card className="no-transition">
                    <CardHeader
                      className="card-collapse"
                      id="headingOne"
                      role="tab"
                    >
                      <h2 className="mb-0 custom-title">
                        <a
                          aria-expanded={collapses.includes(1)}
                          className="collapsed"
                          data-parent="#accordion"
                          href=""
                          id="collapseOne"
                          onClick={(e) => {
                            e.preventDefault();
                            changeCollapse(1);
                          }}
                        >
                         Link Building Breakdown{" "}
                          <i className="nc-icon nc-minimal-down" />
                        </a>
                      </h2>
                    </CardHeader>
                    <Collapse isOpen={collapses.includes(1)}>
                    <br></br>
                      <CardBody className="collapse-text">
                      <ol>
                          <li>
                              <strong>
                              “Natural” Editorial Links
                              </strong>
                              <br></br>
                              Links that are given naturally by sites and pages that want to link to your content or company. These links require no specific action from the SEO, other than the creation of worthy material (great content) and the ability to create awareness about it.
                          </li>
<br></br>
                          <li>
                              <strong>
                              Manual “Outreach” Link Building                              
                              </strong>
                              <br></br>
                              The SEO creates these links by emailing bloggers for links, submitting sites to directories, or paying for listings of any kind. The SEO often creates a value proposition by explaining to the link target why creating the link is in their best interest. Examples include filling out forms for submissions to a website award program or convincing a professor that your resource is worthy of inclusion on the public syllabus.
                          </li>
<br></br>
                          <li>
                              <strong>
                              Self-Created, Non-Editorial
                              </strong>
                              <br></br>
                              Hundreds of thousands of websites offer any visitor the opportunity to create links through guest book signings, forum signatures, blog comments, or user profiles. These links offer the lowest value, but can, in the aggregate, still have an impact for some sites. In general, search engines continue to devalue most of these types of links, and have been known to penalize sites that pursue these links aggressively. Today, these types of links are often considered spammy and should be pursued with caution.
                          </li>
                      </ol>
                      </CardBody>
                    </Collapse>
                 
                  </Card>
                </div>
                {/* end acordeon */}
              </div>
            </Col>
          </Row>
         
        </Container>
      </div>
    </>
  );
}

export default SeoLink;
