import React from "react";

// reactstrap components
import {
    Button,
   
    Container,
    Row,
    Col,
  } from "reactstrap";
  
  import { Link } from 'react-router-dom';

  
  function FooterNav() {
      return (
        <div>
              <footer className="footer footer-black footer-big mr--custom">
        
        <Container>
          <Row>
            <Col className="text-center ml-auto mr-auto border-right" md="2" sm="3" xs="12">
            
                 
            <div className="links">
                    <p className="footheader">
                      Company
                    </p>
                    <hr />
                    <ul className="stacked-links">
                    <Link to="/about-us">
                      <li>
                        <a href="#" className="footer-link">
                          About Us
                        </a>
                       </li>
                      </Link>
                      <Link to="/about-us#Compliance">
                      <li>
                        <a href="#" className="footer-link">
                          Compliance
                        </a>
                       </li>
                      </Link>
                      
                    </ul>
                  </div>
                  <br></br>
                  <br></br>
                 


                  <img
                className="ml--20"
                id="nav-logo"
                alt="NEXTLEVEL Logo"
                width="150px"
                src={require("assets/img/nl/300dpi_Logo_Dark.png")}
              />

              <div className="social-area ml--20">
                <a href="https://www.facebook.com/nextlevelpress">
                <Button
                  className="btn-just-icon btn-round mr-1"
                  color="facebook"
                >
                  <i className="fa fa-facebook" />
                </Button>
                </a>
                <a href="https://www.instagram.com/nextlevelgram/">
                <Button className="btn-just-icon btn-round mr-1" color="google">
                  <i className="fa fa-instagram" />
                </Button>
                </a>
                <a href="https://twitter.com/nextlevelpress">
                <Button
                  className="btn-just-icon btn-round"
                  color="twitter"
                >
                  <i className="fa fa-twitter" />
                </Button>
                </a>
 
              </div>
              <br></br>
              <br></br>

            </Col>
            <Col className="ml-auto mr-auto" md="9" sm="9" xs="12">
              <Row>
            
                <Col md="3" sm="3" xs="6">
                <p className="align-center footheader">
                      Services
                    </p>
                    <hr />
                  <div className="links">
                    
                    <ul className="stacked-links">
                      <Link to="/custom-software">
                      <li>
                        <a href="#" className="footer-link">
                          Custom Software Development
                        </a>
                      </li>
                      </Link>
                      
                      <Link to="/mobile-software">
                      <li>
                      <a href="#" className="footer-link">
                          Mobile Software Development
                        </a>
                       </li>
                      </Link>
                      
                      <Link to="/product-development">
                      <li>
                        <a href="#" className="footer-link">
                          Software Product Development
                        </a>
                       </li>
                      </Link>
                    
                       <Link to="/software-modernisation">
                      <li>
                        <a href="#" className="footer-link">
                        Legacy Software Replacement
                        </a>
                       </li>
                       </Link>
                      
                      

                      <Link to="/leveraging-resources">
                      <li>
                        <a href="#" className="footer-link">
                        Dedicated Development Team
                        </a>
                       </li>
                      </Link>
                      
                     </ul>
                     </div>
                </Col>
                <Col md="3" sm="3" xs="6" className="border-right">
                <p className="align-center footheader">
                    <br></br>
                    <hr/>
                    </p>                
                  <div className="links">
                    
                    <ul className="stacked-links">
                    <Link to="/web">
                      <li>
                        <a href="#" className="footer-link">
                          Website Design & Development
                        </a>
                       </li>
                      </Link>
                      <Link to="/digital-marketing">
                      <li>
                        <a href="#" className="footer-link">
                          Digital Marketing
                        </a>
                       </li>
                      </Link>
                      
                      <Link to="/seo-info">
                      <li>
                        <a href="#" className="footer-link">
                        Search Engine Optimisation
                        </a>
                       </li>
                       </Link>
                       <Link to="/business-analyis">
                      <li>
                        <a href="#" className="footer-link">
                          Business Analysis
                        </a>
                       </li>
                      </Link>
                      <Link to="/quality-assurance">
                      <li>
                        <a href="#" className="footer-link">
                          Quality Assurance
                        </a>
                       </li>
                      </Link>
                     
                      
                      <Link to="software-maintenance">
                      <li>
                        <a href="#" className="footer-link">
                        Software Maintenance 
                        </a>
                       </li>
                      </Link>
                     
                    </ul>
                  
                  </div>
                </Col>
                <Col md="3" sm="3" xs="6">
                <div className="links">
                    <p className="footheader">
                      Industries
                    </p>
                    <hr />
                    <ul className="stacked-links">
                    <Link to="/agriculture">
                      <li>
                        <a href="#" className="footer-link">
                          Agriculture
                        </a>
                       </li>
                      </Link>
                      <Link to="/finance&accounting">
                      <li>
                        <a href="#" className="footer-link">
                          Finance 
                        </a>
                       </li>
                      </Link>
                      <Link to="/healthcare">
                      <li>
                        <a href="#" className="footer-link">
                          Healthcare 
                        </a>
                       </li>
                      </Link>
                      <Link to="/smes">
                      <li>
                        <a href="#" className="footer-link">
                        Small & Medium-Sized Enterprises
                        </a>
                       </li>
                      </Link>
                     
                      <Link to="/wholesale&retail">
                      <li>
                        <a href="#" className="footer-link">
                          Wholesale & Retail
                        </a>
                       </li>
                      </Link>
                      
                    </ul>
                  </div>
                </Col>
                <Col md="3" sm="3" xs="6">
                <div className="links">
                    <p className="footheader">
                      <br></br>
                      <hr />
                    </p>
                    <ul className="stacked-links">
                    <Link to="/ecommerce">
                      <li>
                        <a href="#" className="footer-link">
                          Ecommerce
                        </a>
                       </li>
                      </Link>
                    <Link to="/education">
                      <li>
                        <a href="#" className="footer-link">
                          Education
                        </a>
                       </li>
                      </Link>
                      <Link to="/transport&logistics">
                      <li>
                        <a href="#" className="footer-link">
                          Transport & Logistics
                        </a>
                       </li>
                      </Link>
                      <Link to="/media&entertainment">
                      <li>
                        <a href="#" className="footer-link">
                          Media & Entertainment
                        </a>
                       </li>
                      </Link>
                      </ul>
                      </div>
                      
                      <br></br>
                      <br></br>

               
                </Col>
              </Row>
              <hr />

              <div className="copyright">
                    <p className="footheader">
                      <br></br>
                      Contacts
                    </p>
                    <hr />
                    <br></br>
                    <div className="pull-left">

                        <a href="mailto:info@nextleveltechnologies.co.za" className="footer-link color-grey">
                          <span>
                          <i className="nc-icon nc-email-85 icon icon-info" />&nbsp;&nbsp;
                          info@nextleveltechnology.org
                          </span>
                       
                        </a>
                      </div>
                      <div className="pull-right">
                      
                      
                    
                       <a href="tel:+27671647295" className="footer-link color-grey">
                       <i className="nc-icon nc-mobile icon icon-info" />&nbsp;&nbsp;
                          <span className="pr-2">
                          +27671647295
                          </span>
                       
                        </a>
                      </div>
                     
                    
                      

                  </div>
                  <br></br>
                  <hr />

              <div className="copyright">
                <div className="pull-right">
                  © {new Date().getFullYear()} NEXTLEVEL Technology
                </div>
                
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
          </div>
      )
  }
  
  export default FooterNav
  

