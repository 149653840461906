import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import ColorNavbar from "components/Navbars/ColorNavbar"; 
import ContactComponent from '../services/ContactComponent.js';

import WebHeaderTemplate from "./webHeaders/WebHeaderTemplate.js";
import FooterNav from "../services/FooterNav.js";
import {Link} from 'react-router-dom';
import Product from "../Product";


function WebCorporate() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
    };
  });
  return (
    <>
      <ColorNavbar />
      <WebHeaderTemplate navTitle="Corporate Identity Services" Title="Corporate Identity" />

      <div className="wrapper">
        {/* ********* PRICING 3 ********* */}
        <div
          className="pricing-3 section-bg-img"
          style={{
            backgroundImage:
            "url(" + require("assets/img/nl/sections/custom/red-blue.jpg") + ")",
          }}
        >
            <Container>
              <Row>
                <Col lg="12" md="12" sm="12" xs="12">
                  <h1 id="titlewhite3" className="mt--30">
                          Corporate Indentity Branding Packages
                          </h1>
                  </Col>
                </Row>
            <Row>
            <Col xl="4" lg="4" md="12" sm="12" xs="12" className="col1">
                <Card className="card-pricing w-150">
                <h6 className="card-category">Budget</h6>
                
                  <CardBody>
                  <div className="webcardicon my-5">
                        <img 
                          alt="security"
                          className="webcardimg"
                          src={require("assets/img/nlicons/web/corp/brand-identity.svg")}
                          />
                          <span>
                          <h5 className="webcardtitle">
                          Company Starter Package
                        </h5>
                          </span>
                    
                        </div>
                    <CardTitle tag="h2">R1799</CardTitle>
                    <ul className="align-left">
                    <li>
                      <img 
                          alt="website"
                          className="webcardimg"
                          src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                          />
                      2 Logo Design Options
                      </li>
                      <li>
                      <img 
                          alt="website"
                          className="webcardimg"
                          src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                          />
                      2 Business Card Design Options
                      </li>
                      <li>
                      <img 
                          alt="website"
                          className="webcardimg"
                          src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                          />
                      2 Letterhead or Email Signature Design Options
                      </li>
                      <li>
                      <img 
                          alt="website"
                          className="webcardimg"
                          src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                          />
                      3 Revision Rounds
                      </li>
                    
                      

                    </ul>
                    
                    <br></br>
                    <br></br>
                    <br></br>
                    <Product 
                  id="CompanyStarterPackage"
                  title="Company Starter Package"
                  price={1799.0}
                  rating={5}
                  image={require("assets/img/nlicons/web/corp/brand-identity.svg")}
                  />  
                     <Link to="/contact">
                  <Button
                    className="btn-round card-link web-card-btn"
                    color="success"
                    href="" 
                  >
                    Get Started
                  </Button>
                  </Link>
                  </CardBody>
                </Card>
              </Col>
              <Col xl="4" lg="4" md="12" sm="12" xs="12" className="col1">
                <Card className="card-pricing w-150">
                <h6 className="card-category">Standard</h6>
                
                  <CardBody>
                  <div className="webcardicon my-5">
                        <img 
                          alt="security"
                          className="webcardimg"
                          src={require("assets/img/nlicons/web/corp/brand-identity.svg")}
                          />
                          <span>
                          <h5 className="webcardtitle">
                          Company Starter Plus Package
                        </h5>
                          </span>
                    
                        </div>
                        <CardTitle tag="h2">R2999</CardTitle>
                        ,  
                    <ul className="align-left">
                    <li>
                      <img 
                          alt="website"
                          className="webcardimg"
                          src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                          />
                      2 Logo Design Options
                      </li>
                      <li>
                      <img 
                          alt="website"
                          className="webcardimg"
                          src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                          />
                      2 Business Card Design Options
                      </li>
                      <li>
                      <img 
                          alt="website"
                          className="webcardimg"
                          src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                          />
                      2 Letterhead Design Options
                      </li>
                      <li>
                      <img 
                          alt="website"
                          className="webcardimg"
                          src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                          />
                      2 Email Signature Design Options
                      </li>
                      <li>
                      <img 
                          alt="website"
                          className="webcardimg"
                          src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                          />
                      Company Profile Design
                      </li>
                      <li>
                      <img 
                          alt="website"
                          className="webcardimg"
                          src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                          />
                      3 Revision Rounds
                      </li>
                    
                      

                    </ul>
                    
                    <br></br>
                    <br></br>
                    <br></br>
                    <Product 
                  id="CompanyStarterPlusPackage"
                  title="Company Starter Plus Package"
                  price={2999.0}
                  rating={5}
                  image={require("assets/img/nlicons/web/corp/brand-identity.svg")}
                  />  
                     <Link to="/contact">
                  <Button
                    className="btn-round card-link web-card-btn"
                    color="success"
                    href="" 
                  >
                    Get Started
                  </Button>
                  </Link>
                  </CardBody>
                </Card>
              </Col>
              
              <Col xl="4" lg="4" md="12" sm="12" xs="12" className="col1">
                <Card className="card-pricing w-150">
                <h6 className="card-category">Premium</h6>
                  <CardBody>
                  <div className="webcardicon my-5">
                        <img 
                          alt="security"
                          className="webcardimg"
                          src={require("assets/img/nlicons/web/corp/brand-identity.svg")}
                          />
                          <span>
                          <h5 className="webcardtitle">
                        Company Starter Pro Package
                      </h5>
                        </span>
                  
                      </div>
                      <CardTitle tag="h2">R5999</CardTitle>
                   <ul className="align-left">
                   <li>
                    <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />
                    2 Logo Design Options
                    </li>
                    <li>
                    <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />
                    2 Business Card Design Options
                    </li>
                    <li>
                    <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />
                    2 Letterhead Design Options
                    </li>
                    <li>
                    <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />
                    2 Email Signature Design Options
                    </li>
                    <li>
                     <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />
                    Company Profile Design
                    </li>
                    <li>
                    <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />
                    Standard Website Design
                    </li>
                    <li>
                    <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />
                    1 Year Website Hosting 
                    </li>
                     <li>
                    <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />
                    3 Revision Rounds
                    </li>   

                  </ul>
                  
                  <br></br>
                  <br></br>
                  <br></br>
                  <Product 
                  id="CompanyStarterProPackage"
                  title="Company Starter Pro Package"
                  price={5999.0}
                  rating={5}
                  image={require("assets/img/nlicons/web/corp/brand-identity.svg")}
                  />  
                 <Link to="/contact">
                  <Button
                    className="btn-round card-link web-card-btn"
                    color="success"
                    href="" 
                  >
                    Get Started
                  </Button>
                  </Link>
                </CardBody>
              </Card>
            </Col>  
          </Row>
          </Container>
  <Container>
            <Row>
              <Col lg="12" md="12" sm="12" xs="12">
                <h1 id="titlewhite2">
                        Corporate Branding Elements
                        </h1>
                </Col>
              </Row>
          <Row>
            <Col xl="3" lg="3" md="6" sm="12" xs="12" className="col1">
              <Card className="card-pricing w-150">
              <h6 className="card-category">Standard</h6>
              
                <CardBody>
                <div className="webcardicon my-5">
                      <img 
                        alt="security"
                        className="webcardimg"
                        src={require("assets/img/nlicons/web/corp/image.svg")}
                        />
                        <span>
                        <h5 className="webcardtitle">
                        Logo Design
                      </h5>
                        </span>
                  
                      </div>
                  <CardTitle tag="h2">R1199</CardTitle>
                  <ul className="align-left">
                   <li>
                    <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />
                    2 Logo Design Options
                    </li>
                    
                     <li>
                    <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />
                    3 Revision Rounds
                    </li>
                   
                    

                  </ul>
                  
                  <br></br>
                  <br></br>
                  <br></br>
                  <Product 
                  id="LogoDesign"
                  title="Logo Design"
                  price={1199.0}
                  rating={5}
                  image={require("assets/img/nlicons/web/corp/image.svg")}
                  />  
                 <Link to="/contact">
                  <Button
                    className="btn-round card-link web-card-btn"
                    color="success"
                    href="" 
                  >
                    Get Started
                  </Button>
                  </Link>
                </CardBody>
              </Card>
            </Col>
            <Col xl="3" lg="3" md="6" sm="12" xs="12" className="col1">
              <Card className="card-pricing w-150">
              <h6 className="card-category">Standard</h6>
              
                <CardBody>
                <div className="webcardicon my-5">
                      <img 
                        alt="security"
                        className="webcardimg"
                        src={require("assets/img/nlicons/web/corp/document.svg")}
                        />
                        <span>
                        <h5 className="webcardtitle">
                        Letterhead / Email Signature Design
                      </h5>
                        </span>
                  
                      </div>
                      <CardTitle tag="h2">R499</CardTitle>
                      ,  
                  <ul className="align-left">
                   <li>
                    <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />
                    2 Design Options
                    </li>
                   
                    
                     <li>
                    <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />
                    3 Revision Rounds
                    </li>
                   
                    

                  </ul>
                  
                  <br></br>
                  <br></br>
                  <br></br>
                  <Product 
                  id="Letterhead/EmailSignatureDesign"
                  title="Letterhead / Email Signature Design"
                  price={499.0}
                  rating={5}
                  image={require("assets/img/nlicons/web/corp/document.svg")}
                  />  
                 <Link to="/contact">
                  <Button
                    className="btn-round card-link web-card-btn"
                    color="success"
                    href="" 
                  >
                    Get Started
                  </Button>
                  </Link>
                </CardBody>
              </Card>
            </Col>
            
            <Col xl="3" lg="3" md="6" sm="12" xs="12" className="col1">
              <Card className="card-pricing w-150">
              <h6 className="card-category">Standard</h6>
                <CardBody>
                <div className="webcardicon my-5">
                      <img 
                        alt="security"
                        className="webcardimg"
                        src={require("assets/img/nlicons/web/corp/business-card.svg")}
                        />
                        <span>
                        <h5 className="webcardtitle">
                        Business Card Design 
                      </h5>
                        </span>
                  
                      </div>
                      <CardTitle tag="h2">R599</CardTitle>
                   <ul className="align-left">
                   <li>
                    <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />
                    2 Design Layout Options
                    </li>
                    
                     <li>
                    <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />
                    3 Revision Rounds
                    </li>   

                  </ul>
                  
                  <br></br>
                  <br></br>
                  <br></br>
                  <Product 
                  id="BusinessCardDesign"
                  title="Business Card Design"
                  price={599.0}
                  rating={5}
                  image={require("assets/img/nlicons/web/corp/business-card.svg")}
                  />
                 <Link to="/contact">
                  <Button
                    className="btn-round card-link web-card-btn"
                    color="success"
                    href="" 
                  >
                    Get Started
                  </Button>
                  </Link>
                </CardBody>
              </Card>
            </Col>

             <Col xl="3" lg="3" md="6" sm="12" xs="12" className="col1">
              <Card className="card-pricing w-150">
              <h6 className="card-category">Premium</h6>
                <CardBody>
                <div className="webcardicon my-5">
                      <img 
                        alt="security"
                        className="webcardimg"
                        src={require("assets/img/nlicons/web/corp/branding.svg")}
                        />
                        <span>
                        <h5 className="webcardtitle">
                        Corporate Profile 
                      </h5>
                        </span>
                  
                      </div>
                      <CardTitle tag="h2">R1799</CardTitle>
                   <ul className="align-left">
                   <li>
                    <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />
                    1 Design Concept 
                    </li>
                    <li>
                    <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />
                    Profile up to 8 pages
                    </li>
                    <li>
                    <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />
                   Additional Pages Available At R149 each
                    </li>
                   
                     <li>
                    <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />
                    3 Revision Rounds
                    </li>   

                  </ul>
                  
                  <br></br>
                  <br></br>
                  <br></br>
                  <Product 
                  id="CorporateProfile"
                  title="Corporate Profile"
                  price={1799.0}
                  rating={5}
                  image={require("assets/img/nlicons/web/corp/branding.svg")}
                  />
                 <Link to="/contact">
                  <Button
                    className="btn-round card-link web-card-btn"
                    color="success"
                    href="" 
                  >
                    Get Started
                  </Button>
                  </Link>
                </CardBody>
              </Card>
            </Col>  
          </Row>
          </Container>
          </div>



        <ContactComponent />
      </div>
      <FooterNav />
    </>
  );
}

export default WebCorporate;
