import React from "react";

// reactstrap components
import { Row, Col, Button } from "reactstrap";
import FooterNav from "./FooterNav.js";
import QualityHeader from "components/Headers/Custom/QualityHeader.js";
import QAfaq from "./FAQ/QAfaq.js";

// core components
import ColorNavbar from "components/Navbars/ColorNavbar.js";
 
import ContactComponent from './ContactComponent.js';


function QualityAssurance() {
 
  document.documentElement.classList.remove("nav-open");
  // function that is being called on scroll of the page
  const checkScroll = () => {
    // it takes all the elements that have the .add-animation class on them
    const componentPosition = document.getElementsByClassName("add-animation");
    const scrollPosition = window.pageYOffset;
    for (var i = 0; i < componentPosition.length; i++) {
      var rec =
        componentPosition[i].getBoundingClientRect().top + window.scrollY + 100;
      // when the element with the .add-animation is in the scroll view,
      // the .animated class gets added to it, so it creates a nice fade in animation
      if (scrollPosition + window.innerHeight >= rec) {
        componentPosition[i].classList.add("animated");
        // when the element with the .add-animation is not in the scroll view,
        // the .animated class gets removed from it, so it creates a nice fade out animation
      } else if (scrollPosition + window.innerHeight * 0.8 < rec) {
        componentPosition[i].classList.remove("animated");
      }
    }
  };
  React.useEffect(() => {
    document.title = "Quality Assurance"
 });

  React.useEffect(() => {
    document.body.classList.add("presentation-page");
    window.addEventListener("scroll", checkScroll);
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("presentation-page");
      window.removeEventListener("scroll", checkScroll);
    };
  });
  return (
    <>
      <ColorNavbar />
      <QualityHeader />
      <div className="wrapper">
       
     
     
      <div>
           
      <Row>
      
                    <Col md="7">
                      <div className="topcard">
                        <h2 id="titleblack" className="align-left2">
                        Quality is the foundation of our service
                        </h2>
                       
                      </div>
                    
                    </Col>
                    <Col md="4">
                      
                  <p className="top-description mx-20">
                  With many years in the QA and testing business, NextLeval's helped our customers bring 
                  software products to market with superior quality. We can proudly say “Made by NEXTLEVEL” because we know 
                  that NEXTLEVEL means quality. The products we are creating for our customers are the true reflection of our 
                  culture, our great people who care of the result, and effective partnerships that we build with every client. 
                  </p>
                 
                    </Col> 
                </Row>

        </div>


        <div className="whitebg">
                <Row>
                    <Col md="7" sm="12">
                    <div
          className="pricing-3 custom-software-div3"
          style={{
            backgroundImage:
              "url(" + require("assets/img/nl/waterfront.jpg") + ")",
          }}
        >
                   
                    <h2 id="custom-titlewhite-2" className="mx-20"><span>Our Services</span></h2>
                    
                    <br></br>
                    <br></br>
                    <h5 className="whath4qa-nw mx-20"><span>NEXTLEVEL conducts independent QA services for third-party apps and solutions. From functionality to security, we've got you covered. With NEXTLEVEL as your QA service provider, you can sit back, relax, and focus on your business.</span></h5>
                </div>
                    </Col>
                    <Col md="5" className="minus-margin-left">
                    <img 
                    alt="cape town"
                    className="sectionqaimg shift-up-80px-sm"
                    src={require("assets/img/nl/qa.jpg")}
                    />
                    </Col> 
                </Row>

      

                <Row className="">
                    <Col md="6">
                    <div classname="card-qa">
                    <h2 id="qatitle-1">
                    Functionality<br></br>Testing
                    </h2>
                  <p className="description-black card-qa">
                  Functionality testing from NEXTLEVEL is designed so your application can run flawlessly. Whatever 
                  your goal, we'll figure out how to achieve it.

                  </p>
                  
              </div>
                    </Col>
                    <Col md="6">
                    <div classname="card-qa">
                    <h2 id="qatitle-2">
                    Usability<br></br>Testing
                    </h2>
                  <p className="description-black card-qa">
                  NEXTLEVEL thinks about your users and exactly how they'll interact with your application. 
                    Our exhaustive analysis identifies potential errors, confusing designs, and performance flaws.
                  </p>
                  
              </div>
                    </Col>
                </Row>
                
                <Row>
                <Col md="6">
                    <div classname="card-qa">
                    <h2 id="qatitle-3">
                    Security<br></br> Testing
                    </h2>
                  <p className="description-black card-qa">
                  NEXTLEVEL QA engineers are security experts. We'll complete a comprehensive assessment to identify 
                    system vulnerabilities, provide critical recommendations, and dramatically improve your application's security.
                  </p>
                  
              </div>
                    </Col>
                    <Col md="6" className="">
                    <div
          className="pricing-3 custom-software-div5 no-margin-right"
          style={{
            backgroundImage:
              "url(" + require("assets/img/nl/rocket2.jpg") + ")",
          }}
        >
                   
                    <h2 className="whath24 padding-x-20"><span>Talk to a NEXTLEVEL expert about how we can enhance your application's quality</span></h2>
                    
                    <div className="whatdiv">
                    <Button className="whatbutton btn-outline-danger"><span>Get Started</span></Button>
                    </div>
                    </div>
                    </Col> 
                </Row>
                <Row>
                <Col md="6">
                    <div classname="card-qa">
                      <br></br>
                    <h2 id="qatitle-4">
                    Performance<br></br> Testing
                    </h2>
                  <p className="description-black card-qa">
                  Can your application handle the stress of scaling? NEXTLEVEL experts will test how much use 
                    your application can take and determine whatever is slowing it down. We'll also provide a 
                    comprehensive report to help you make the best decisions for your business.
                  </p>
                  
              </div>
                    </Col>
                    <Col md="6">
                    <div classname="card-qa">
                    <h2 id="qatitle-5">
                    Compatibility<br></br> Testing
                    </h2>
                  <p className="description-black card-qa">
                  Our QA specialists will test your application's full compatibility with different browsers, 
                    databases, hardware, server infrastructures, resolution displays, and devices.
                  </p>
                  
              </div>
                    </Col>
                </Row>

        </div>


        <div
          className="pricing-3 custom-software-div"
          style={{
            backgroundImage:
              "url(" + require("assets/img/nlbg/bg3.jpg") + ")",
          }}
        >

        <Row>
          <Col md="6" sm="12" xs="12">
          

          </Col>
          <Col md="6" sm="12" xs="12">      
          <h1 className="whath2qa5"><span>Why Choose NEXTLEVEL</span></h1>      
          <ul className="qalist">
              <li><img 
                        alt="website"
                        className="webcardimg mx-4"
                        src={require("assets/img/nlicons/check-circle-white.svg")}
                        /><h5 className="whychoose">Expertise</h5>Our team has diverse technology stack experience and our customers come from diverse industries. Whatever the problem, we can solve it efficiently. </li>
              <br></br>
              <li><img 
                        alt="website"
                        className="webcardimg mx-4"
                        src={require("assets/img/nlicons/check-circle-white.svg")}
                        /><h5 className="whychoose">Value</h5>By ensuring your application's superior quality, NEXTLEVEL ensures its superior value in the market. </li>
              <br></br>
              <li><img 
                        alt="website"
                        className="webcardimg mx-4"
                        src={require("assets/img/nlicons/check-circle-white.svg")}
                        /><h5 className="whychoose">Transparency</h5>We believe that strong business relationships are built on trust and reliability. That's why NEXTLEVEL offers fair, transparent pricing at every stage of your project. </li>
              <br></br>
              <li><img 
                        alt="website"
                        className="webcardimg mx-4"
                        src={require("assets/img/nlicons/check-circle-white.svg")}
                        /><h5 className="whychoose">Scalability</h5>NEXTLEVEL's team of expert engineers can scale any project. Save overheads, time, and stress with our QA services. </li>

            </ul>
            
            </Col>
        </Row>
        </div>
        
       

        <QAfaq />

       
        <ContactComponent />
      </div>
      <FooterNav />
    </>
  );
}

export default QualityAssurance;
