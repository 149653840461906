import React from "react";
// reactstrap components
import {
  Row,
  Col,
} from "reactstrap";

// core components
import SMEHeader from "components/Headers/Custom/SMEHeader.js";
import ColorNavbar from "components/Navbars/ColorNavbar"; 
import ContactComponent from '../services/ContactComponent.js';

import FooterNav from "../services/FooterNav.js";


function SMEs() {
  
 

  document.documentElement.classList.remove("nav-open");
  // function that is being called on scroll of the page
  const checkScroll = () => {
    // it takes all the elements that have the .add-animation class on them
    const componentPosition = document.getElementsByClassName("add-animation");
    const scrollPosition = window.pageYOffset;
    for (var i = 0; i < componentPosition.length; i++) {
      var rec =
        componentPosition[i].getBoundingClientRect().top + window.scrollY + 100;
      // when the element with the .add-animation is in the scroll view,
      // the .animated class gets added to it, so it creates a nice fade in animation
      if (scrollPosition + window.innerHeight >= rec) {
        componentPosition[i].classList.add("animated");
        // when the element with the .add-animation is not in the scroll view,
        // the .animated class gets removed from it, so it creates a nice fade out animation
      } else if (scrollPosition + window.innerHeight * 0.8 < rec) {
        componentPosition[i].classList.remove("animated");
      }
    }
  };
  

  React.useEffect(() => {
    document.body.classList.add("presentation-page");
    window.addEventListener("scroll", checkScroll);
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("presentation-page");
      window.removeEventListener("scroll", checkScroll);
    };
  });
  return (
    <>
    <ColorNavbar />
      <SMEHeader />
      <div className="wrapper">
       
     
     
      <div>
           
      <Row>
      
                    <Col md="7">
                      <div className="topcard">
                        <h2 id="titleblack3" className="align-left2">
                        With NEXTLEVEL you can focus on what matters most: Growing your business                        
                        </h2>
                       
                      </div>
                    
                    </Col>
                    <Col md="4">
                      
                  <p className="top-description mx-20">
                  As businesses grow, so do their operations. NEXTLEVEL custom applications can automate processes, cut costs, and increase transparency. Our solutions are 100% tailored to your needs, so you can take your business to the next level.
                  </p>
                 <br></br>
                 <br></br>
                 <br></br>
                    </Col> 
                </Row>



                <Row>
                    <Col md="7">
                    <div
          className="pricing-3 custom-software-div4 pr-20"
          style={{
            backgroundImage:
            "url(" + require("assets/img/nlbg/bg5.jpg") + ")",
        }}
        >
                    
                    <h2 className="whath2fin2 mx-20"><span>Streamline your business processes with cutting-edge software</span></h2>
                    <br></br>
                    <h5 className="whath4ind mx-30"><span>A custom software application can generate serious ROI for your business. NEXTLEVEL customers report decreased overhead, better accountability, and increased efficiency. This translates to cost-savings of up to 250%.</span></h5>
                <br></br>
                </div>
                    </Col>
                    <Col md="5" className="">
                    <img 
                    alt="containers"
                    className="sectionimg-fin"
                    src={require("assets/img/nl/sections/containers.jpg")}
                    />
                    </Col> 
                </Row>

                <div className="small-border">
                      <div className="box">
                      <Row className="shift-down-80px">
        <Col md="4">
          <img 
                         alt="inventory"
                         className="customcardimg"
                         src={require("assets/img/nl/industries/sme/inventory.svg")}
                         />
          <h3 id="titleblack2">Inventory Solutions</h3>
          <br></br>
          <p className="top-description2">
          With a custom software application, you can integrate all your inventory management processes together, saving valuable time and resources.
          </p>
          </Col>
        <Col md="4">
        <img 
                         alt="contractors"
                         className="customcardimg"
                         src={require("assets/img/nl/industries/sme/team.svg")}
                         />
          <h3 id="titleblack2">Employees and Contractors</h3>
          <br></br>
          <p className="top-description2">
          Want to cut down on front-office operations like managing employees and contractors? With a single sign-on application, you can assign tasks and track their progress.
          </p>
          </Col>
        <Col md="4">
        <img 
                         alt="payments"
                         className="customcardimg"
                         src={require("assets/img/nl/industries/sme/card.svg")}
                         />
          <h3 id="titleblack2">One-Stop Payments</h3>
          <br></br>
          <p className="top-description2">
          Tired of using different payment platforms with different customers? Consolidate all of your third-party payment functions into one software application.
          </p>
          </Col>
        <Col md="4">
        <img 
                         alt="employee tools"
                         className="customcardimg"
                         src={require("assets/img/nl/industries/sme/schedule.svg")}
                         />
          <h3 id="titleblack2">Employee Tools</h3>
          <br></br>
          <p className="top-description2">
          Seamlessly connect your employees with a single sign-on system. A custom application can handle all of their communication, reporting, and work functions, all in one place.
          </p>
        </Col>
        <Col md="4">
        <img 
                         alt="customer feedback"
                         className="customcardimg"
                         src={require("assets/img/nl/industries/sme/popularity.svg")}
                         />
          <h3 id="titleblack2">Customer Feedback</h3>
          <br></br>
          <p className="top-description2">
          Listen to your customers with an integrated feedback application. Catch mistakes earlier, track your progress, and increase retention rates.
          </p>
        </Col>
        </Row>
                       
                      </div>
                  </div> 

                <Row className="shift-down-80px">
                    
                    <Col md="5">
                    <img 
                    alt="cape town"
                    className="sectionimg-sme2"
                    src={require("assets/img/nl/sections/truck-blur.jpg")}
                    />
                    </Col> 
                    <Col md="7">
                    <div
          className="pricing-3 custom-software-div3"
          style={{
            backgroundImage:
              "url(" + require("assets/img/nlbg/bg4.jpg") + ")",
          }}
        >
                   
                    <h2 className="whath2fin2 mx-10"><span>Know your business
inside-and-out with a custom NEXTLEVEL solution</span></h2>
                    <br></br>
                    <h5 className="whath4ind mx-20"><span>
                    All of the information you need can be just one click away. Make better business decisions with a NEXTLEVEL software application.
                    </span></h5>
                    <br></br>

                </div>
                    </Col>
                </Row>
                


                <div className="small-border">
                      <div className="box">
                      <Row className="shift-down-80px px-20">
        <Col md="4">
          <img 
                         alt="Web Application"
                         className="customcardimg"
                         src={require("assets/img/nl/industries/sme/clock.svg")}
                         />
          <h3 id="titleblack2">Employee Timekeeping</h3>
          <br></br>
          <p className="top-description2">
          Automate your employee timekeeping processes with a custom application. Save valuable resources, improve accountability, and increase transparency.
          </p>
          </Col>
        <Col md="4">
        <img 
                         alt="Mobile Application"
                         className="customcardimg"
                         src={require("assets/img/nl/industries/sme/delivery.svg")}
                         />
          <h3 id="titleblack2">Orders and Shipping</h3>
          <br></br>
          <p className="top-description2">
          Go paper-free with customer orders and shipping. Employees can manage processes in the field with a custom iPad application.
          </p>
        </Col>
        <Col md="4">
        <img 
                         alt="Marketing Support"
                         className="customcardimg"
                         src={require("assets/img/nl/industries/sme/speedometer.svg")}
                         />
          <h3 id="titleblack2">Dashboard Functionality</h3>
          <br></br>
          <p className="top-description2">
          Feel the pulse of your business at any moment by logging into a custom dashboard system, tailored specifically to your needs.
          </p>
        </Col>
        <Col md="4">
        <img 
                         alt="Cyber Security"
                         className="customcardimg"
                         src={require("assets/img/nl/industries/sme/startup.svg")}
                         />
          <h3 id="titleblack2">Proprietary Software</h3>
          <br></br>
          <p className="top-description2">
          A custom NEXTLEVEL system can boost company performance and be a valuable IP asset, two strong competitive advantages.
          </p>
        </Col>
        <Col md="4">
        <img 
                         alt="Cyber Security"
                         className="customcardimg"
                         src={require("assets/img/nl/industries/sme/lighthouse.svg")}
                         />
          <h3 id="titleblack2">Growth opportunities</h3>
          <br></br>
          <p className="top-description2">
          Are you opening new locations, hiring more employees, or expanding your customer base? A custom NEXTLEVEL system can expedite and enhance that growth, creating endless opportunities.
          </p>
        </Col>
        </Row>
                       
                      </div>
                  </div> 



                  <div className="shift-down-120px">
           
                  <Row className="">
                    <Col md="7">
                    <div
          className="pricing-3 custom-software-div4 pr-20"
          style={{
            backgroundImage:
            "url(" + require("assets/img/nlbg/bg5.jpg") + ")",
        }}
        >
                    
                    <h2 className="whath2fin2"><span>Let's Get Started!</span></h2>
                    <br></br>
                    <h5 className="whath4ind mx-20"><span>NEXTLEVEL experts will guide you through each stage of creating a custom software solution. Our team is trained to deliver projects on-time and on-budget, and our top priority is creating value for customers.
                        </span></h5>
                <br></br>
                <h6 className="whath4ind mx-10"><span>Creating custom software in a nutshell:
                        </span></h6>
                        <br></br>
                
                </div>
                    </Col>
                    <Col md="5" className="">
                    <img 
                    alt="online shopping"
                    className="sectionimg-sme3"
                    src={require("assets/img/nl/sections/team3.jpg")}
                    />
                    </Col> 
                </Row>
                <div className="small-border">


                     <Row className="shift-up-50px">
           
                     <Col md="4" sm="12" xs="12" className="col1">
             <div className="topcard2">
             <img 
                         alt="maintenance"
                         className="customcardimg3"
                         src={require("assets/img/nl/industries/sme/checklist.svg")}
                         />
              <img 
                         alt="maintenance"
                         className="customcardimg2"
                         id="flow-hori"
                         src={require("assets/img/nlicons/ic_arrow_forward_48px.svg")}
                         />
                         
               <h2 id="titleblacksmall" className="align-left2">
               Requirements
               </h2>
              <p className="tile-description">
              We'll start by learning about all of your business requirements, including current and future goals and challenges. This vital first step ensures that we know exactly what you want and need from design to release.
              </p>
             </div>
            
           
           </Col>
           <Col md="4" sm="12" xs="12" className="col1">
             <div className="topcard2">
             <img 
                         alt="maintenance"
                         className="customcardimg3"
                         src={require("assets/img/nlicons/sme/design-thinking.svg")}
                         />
                         <img 
                         alt="maintenance"
                         className="customcardimg2"
                         id="flow-hori"
                         src={require("assets/img/nlicons/ic_arrow_forward_48px.svg")}
                         />
               <h2 id="titleblacksmall" className="align-left2">
               Design
               </h2>
              <p className="tile-description">
              Building a custom system involves aligning a lot of moving parts, like architecture, UI/UX interfaces, and user scenarios. That's why we create a comprehensive design plan for each customer's unique business needs.
              </p>
              </div>
              
           
           </Col>
           <Col md="4" sm="12" xs="12" className="col1">
             <div className="topcard2">
             <img 
                         alt="maintenance"
                         className="customcardimg3"
                         src={require("assets/img/nlicons/agri/front-end.svg")}
                         />
                         <img 
                         alt="maintenance"
                         className="customcardimg2"
                         id="flow-hori"
                         src={require("assets/img/nlicons/ic_arrow_forward_48px.svg")}
                         />
               <h2 id="titleblacksmall" className="align-left2">
               Development
               </h2>
              <p className="tile-description">
              Once the requirements and design plan are a go, NEXTLEVEL starts building your system. This stage is managed by an expert team of software developers, a project manager, a business analyst, and QA engineers.
              </p>
             </div>
            
           
           </Col>
       
           
           <Col md="4" sm="12" xs="12" className="col1">
       
                         
             <div className="topcard2">
            
             <img 
                         alt="maintenance"
                         className="customcardimg3"
                         src={require("assets/img/nl/industries/sme/project-launch.svg")}
                         />
                        
                         
                         <img 
                         alt="maintenance"
                         className="customcardimg2"
                         id="flow-hori"
                         src={require("assets/img/nlicons/ic_arrow_forward_48px.svg")}
                         />
               <h2 id="titleblacksmall" className="align-left2">
               Deployment
               </h2>
              <p className="tile-description">

              After we've built your solution, NEXTLEVEL takes care of seamlessly integrating it with existing business processes, migrating data, and educating employees. Our support team is always available before, during, and after the solution is deployed.              </p>
             </div>
           
             
           </Col>
           <Col md="4" sm="12" xs="12" className="col1">
       
                         
       <div className="topcard2">
      
       <img 
                   alt="maintenance"
                   className="customcardimg3"
                   src={require("assets/img/nl/industries/sme/maintenance.svg")}
                   />
                  
                   
                  <img 
                         alt="maintenance"
                         className="customcardimg2"
                         src={require("assets/img/nlicons/ic_arrow_drop_down_circle_48px.svg")}
                         />
         <h2 id="titleblacksmall" className="align-left2">
         Support
         </h2>
        <p className="tile-description">
        NEXTLEVEL provides a guaranteed warranty with each product, along with customer support and system updates after the initial release.        </p>
       </div>
       
       
     </Col>
         
            
       </Row>
             </div>
               
</div>
                 

                  

                  <div
          className="pricing-3 custom-software-div3"
          style={{
            backgroundImage:
              "url(" + require("assets/img/nlbg/bg3.jpg") + ")",
          }}
        >

        <Row>
        <Col md="6">
          

          </Col>
          <Col md="6">      
          <div className="pr-40">    
          <h1 className="whath2qa5"><span>Why Choose NEXTLEVEL</span></h1>      
          <ul className="qalist">
              <li><img 
                        alt="website"
                        className="webcardimg mx-4"
                        src={require("assets/img/nlicons/check-circle-white.svg")}
                        /><h5 className="whychoose">Commitment to Transparency</h5>We believe that strong business relationships are built on trust and reliability. 
                        We work in tandem with your team to conceptualize and build the perfect platform, and we are committed to offering fair, transparent pricing at every stage of development. We give you all the information you need to make the right business decisions.</li>
              <br></br>
              <li><img 
                        alt="website"
                        className="webcardimg mx-4"
                        src={require("assets/img/nlicons/check-circle-white.svg")}
                        /><h5 className="whychoose">Comprehensive Experience</h5>Our team has diverse technology stack experience and deep expertise in cloud-based SaaS solutions. Whatever your business’s unique needs, we’re here to create the solutions.</li>
              <br></br>
              <li><img 
                        alt="website"
                        className="webcardimg mx-4"
                        src={require("assets/img/nlicons/check-circle-white.svg")}
                        /><h5 className="whychoose">Lean Business Model</h5>NEXTLEVEL uses a lean, global business model to create value for our customers. This model translates to better savings and higher-quality service than our competitors can offer.</li>
              <br></br>
              <li><img 
                        alt="website"
                        className="webcardimg mx-4"
                        src={require("assets/img/nlicons/check-circle-white.svg")}
                        /><h5 className="whychoose">Customer-Centric Culture</h5>NEXTLEVEL carefully selects each employee to align with our customer-centric culture. We're serious about providing the best quality software and service (at the best price) to add value for our customers.</li>
              <br></br>
<li><img 
                        alt="website"
                        className="webcardimg mx-4"
                        src={require("assets/img/nlicons/check-circle-white.svg")}
                        /><h5 className="whychoose">Capacity to Scale</h5>NEXTLEVEL's team of expert engineers can scale any project. Save overheads, time, and stress with our QA services. </li>
            </ul>
            </div>
            </Col>
        </Row>
        </div>

        </div>



       
        <ContactComponent />
      </div>
    
      <FooterNav />
    </>
    
  );
}

export default SMEs;
