/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container } from "reactstrap";
import Typist from "react-typist";
import styled, { createGlobalStyle } from "styled-components";
import { Link } from 'react-router-dom'

// core comments
const StyledTypist = styled(Typist)`
  color: white;
  font-size: 2.5em;
  padding-top: 50px;
  text-align: left;
`;

const WeAre = styled.span`
color: rgb(249, 181, 23);
font-weight: bold;
`;

const Newspan = styled.span`
  font-weight: bold;
`;

const H2mb = styled.h1`
  margin-bottom: 30px;
  color: turquoise;
  font-weight: bold;
  font-size: 2em;
`;

const H2mb2 = styled.h1`
  margin-bottom: 30px;
  color: mediumorchid;
  font-weight: bold;
  font-size: 2em;

`;


const Newspan2 = styled.span`
vertical-align: text-top;
font-size: 3vw !important;
`;

function MobileHeader() {
  return (
    <>
      <div className="wrapper" id="header">
        <div
          className="page-header section-dark"
          style={{
            backgroundImage:
              "url(" + require("assets/img/nl/sections/mobile/amber.jpg") + ")",
          }}
        >
            <div className="top-nav-small">
            <p className="top-nav-text">
                    <Link className="custom-link-custom" to="/">Home</Link> <span>&nbsp; { '>' } &nbsp; Mobile Software Development</span> 
                    </p>
            </div>
          <div className="content-center">
         
            <Container id="headercontainer">
                <h1 className="presentation-title-smaller-left3 pb-10">Application Development</h1>
                
                
              <div className="title-brand">

              <br></br>
              <br></br>
              </div>
              <div>
              <StyledTypist loop={true}>
        <WeAre>Delivering</WeAre>
        <Newspan> full-scale development services</Newspan>
        <Typist.Backspace count={31} delay={800} />

        <Newspan> bleeding-edge technologies</Newspan>
        <Typist.Backspace count={26} delay={800} />

        <Newspan> business-focused solutions</Newspan>
        <Typist.Backspace count={40} delay={800} />

        <Newspan> Creating next-generation products, from idea to implementation</Newspan>
        <Typist.Backspace count={62} delay={800} />

        

        <Newspan>
        Delivering your business straight into the hands of valuable customers
          </Newspan>
      </StyledTypist>
              </div>
            </Container>
          </div>
        </div>
        <div className="wrapper">
        <div className="divider__img__div">
        <img 
         alt="divider"
         className="divider__img2"
         src={require("assets/img/nlbg/triangleNegative3.svg")}
         />         
       
        <img 
         alt="divider"
         className="divider__img2"
         src={require("assets/img/nlbg/triangleNegative.svg")}
         />
           <img 
         alt="divider"
         className="divider__img2"
         src={require("assets/img/nlbg/triangleNegative2.svg")}
         />     
                           
        </div>
           
        </div>
       
      </div>
    </>
  );
}

export default MobileHeader;
