import React from "react";

// reactstrap components
import { Card, CardBody, Container, Row, Col, CardTitle } from "reactstrap";
import FooterNav from "./FooterNav.js";
import ProductHeader from "components/Headers/Custom/ProductHeader.js";
import Customfaq from "./CustomScript.js";

// core components
import ColorNavbar from "components/Navbars/ColorNavbar.js";
 
import ContactComponent from './ContactComponent.js';

function AppDevelopment() {
  
  document.documentElement.classList.remove("nav-open");
  // function that is being called on scroll of the page
  const checkScroll = () => {
    // it takes all the elements that have the .add-animation class on them
    const componentPosition = document.getElementsByClassName("add-animation");
    const scrollPosition = window.pageYOffset;
    for (var i = 0; i < componentPosition.length; i++) {
      var rec =
        componentPosition[i].getBoundingClientRect().top + window.scrollY + 100;
      // when the element with the .add-animation is in the scroll view,
      // the .animated class gets added to it, so it creates a nice fade in animation
      if (scrollPosition + window.innerHeight >= rec) {
        componentPosition[i].classList.add("animated");
        // when the element with the .add-animation is not in the scroll view,
        // the .animated class gets removed from it, so it creates a nice fade out animation
      } else if (scrollPosition + window.innerHeight * 0.8 < rec) {
        componentPosition[i].classList.remove("animated");
      }
    }
  };
  
  React.useEffect(() => {
    document.title = "Product Development"
 });

  React.useEffect(() => {
    document.body.classList.add("presentation-page");
    window.addEventListener("scroll", checkScroll);
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("presentation-page");
      window.removeEventListener("scroll", checkScroll);
    };
  });
  return (
    <>
      <ColorNavbar />
      <ProductHeader />
      <div className="wrapper">
       
     
     
       <div>
            
       <Row>
       
                     <Col md="7">
                       <div className="topcard">
                         <h2 id="titleblack" className="align-left2">
                         Have an Idea for application?<br></br>You're in the right place
                         </h2>
                        
                       </div>
                     
                     </Col>
                     <Col md="4">
                       
                   <p className="top-description mx-20">
                   At NEXTLEVEL, we help turn your ideas into reality. Our elite team of business analysts, 
                   project managers, and engineers will guide you through the entire process of building a 
                   software product. We have substantial experience building custom software solutions and 
                   deep understanding of how businesses work, making us your perfect development partner.
                   </p>
                  
                     </Col> 
                 </Row>
 
         </div>
          <div
           className="pricing-3 custom-software-div3"
           style={{
             backgroundImage:
               "url(" + require("assets/img/nl/sections/custom/red-blue.jpg") + ")",
           }}
         >
         
         <div className="my-20">
         <Row className="pl--20">
         <Col>
         <Row>
         <Col>
         <div className="custom-software-div">
 <h2 id="custom-titlewhite-2" className="align-left2">
                         Software that astounds
                         </h2>
                         <h5 className="custom-whitetext-custom align-left mx-20">
                         We create enterprise applications that streamline, automate and optimize 
                         processes at your company to unlock great business value.  
                         </h5>
         </div>
 <img 
                         alt="website"
                         className="main-layout-img ml--20"
                         src={require("assets/img/nl/sections/custom/team2.jpg")}
                         />
         </Col>
                 </Row>
 
         </Col>
         <Row>
         <Col>
          <div className="card-big-shadow ml-20">
                 <Card
                   className="customcard1"
                 >
                   <CardBody>
                     <hr className="h-line7" />
                   <div className="customcardicon">
                   <img 
                         alt="website"
                         className="customcardimg"
                         src={require("assets/img/nlicons/product/technical-support.svg")}
                         />
 
                     </div>
                     <br></br>
 
                       
                     <CardTitle tag="h5">
                       
                         <span>
                         <h5 className="webcardtitle">
                         Full-Scale Development
                         </h5>
                         </span>
                   
                       
                     </CardTitle>
                     <br></br>
                     <p className="card-description">
                     From idea to prototype to release, NEXTLEVEL can help your company through the entire life cycle of new 
                     product development. We've many applications at unbeatable values, and our expertise and passion 
                     for building creative and innovative solutions is unparalleled.
                     </p>
                     <br className="my-05"></br>
                   </CardBody>
                 </Card>
               </div>
                <div className="card-big-shadow ml-20">
                <Card
                   className="customcard1"
                 >
                   <CardBody>
                   <hr className="h-line7" />

                   <div className="customcardicon">
                   <img 
                         alt="website"
                         className="customcardimg"
                         src={require("assets/img/nlicons/product/device.svg")}
                         />
 
                     </div>
                     <br></br>
 
                       
                     <CardTitle tag="h5">
                       
                         <span>
                         <h5 className="webcardtitle">
                         Cutting-Edge Technologies
                       </h5>
                         </span>
                   
                       
                     </CardTitle>
                     <br></br>
                     <p className="card-description">
                     NEXTLEVEL is a technology-agnostic company. In other words, we don't sell or promote specific technologies, 
                     so we're 100% focused on our customers. We pick the right stack for your unique business needs and goals.
                     </p>
                   </CardBody>
                 </Card>
               </div>
         </Col>
         <Col className="custom-mr">
         <div className="card-big-shadow ml-20">
                 <Card
                   className="customcard1"
                 >
                   <CardBody>
                   <hr className="h-line7" />

                   <div className="customcardicon">
                   <img 
                         alt="website"
                         className="customcardimg"
                         src={require("assets/img/nlicons/product/target.svg")}
                         />
 
                     </div>
                     <br></br>
 
                       
                     <CardTitle tag="h5">
                       
                         <span>
                         <h5 className="webcardtitle">
                         Focused on Business
                       </h5>
                         </span>
                   
                       
                     </CardTitle>
                     <br></br>
                     <p className="card-description">
                     We work hard to understand your current business processes and determine how software could make them 
                     more efficient. Our team always wants to produce top-quality solutions and we will create a proprietary 
                     software product that creates real value for your business.
                     <br></br>
                     <br></br>
                     </p>
                   </CardBody>
                 </Card>
               </div>
                <div className="card-big-shadow ml-20">
                 <Card
                   className="customcard1"
                 >
                   <CardBody>
                   <hr className="h-line7" />

                   <div className="customcardicon">
                   <img 
                         alt="website"
                         className="customcardimg"
                         src={require("assets/img/nlicons/product/team.svg")}
                         />
 
                     </div>
                     <br></br>
 
                       
                     <CardTitle tag="h5">
                       
                         <span>
                         <h5 className="webcardtitle">
                         Continuous Partnership
                       </h5>
                         </span>
                   
                       
                     </CardTitle>
                     <br></br>
                     <p className="card-description">
                     NEXTLEVEL is your trusted development partner not only while creating a product, but well after 
                     it's released. We offer product support and enhancement at whatever level you need, and 99% of 
                     our customers become our continued partners.
                     </p>
                   </CardBody>
                 </Card>
               </div>
         </Col>
         </Row>
 
         </Row>
         </div>
         
         </div>  
    
 
         <div>
       <Row>
       
                     <Col md="7">
                       <div className="topcard">
                         <h2 id="titleblack" className="align-left2">
                         Product<br></br>Development Lifecycle
                         </h2>
                        
                       </div>
                     
                     </Col>
                     <Col md="4">
                       
                  
                  
                     </Col> 
                 </Row>
 
         </div>
         <img 
                         alt="Design Process"
                         id="processhorizontal"
                         className="process-img-h"
                         src={require("assets/img/nlscreenshots/app-process-2-horizontal.png")}
                         />
          <img 
                         alt="Design Process"
                         id="processvertical"
                         className="process-img-v"
                         src={require("assets/img/nlscreenshots/app-process-2-vertical.png")}
                         />
                   
         <div className="section text-center landing-section">
           <Container>
 
           
             <Row>
               <Col className="ml-auto mr-auto" md="12">
                 <h2 className="custom-title-main2">What We Promise</h2>
                 <h5 className="blacktext align-left mx-20">
                NEXTLEVEL Technology promises to offer the best quality custom web application development 
                services at the most competitive pricing. We use the latest web application frameworks 
                (both the frontend and backend) for our website design and custom website development. 
                <br></br><br></br>From small businesses to leading enterprises. We develop and provide clients a business goal and 
                strategic approach to driving qualified traffic, thereby leading to business growth and better ROI . 
                <br></br><br></br>Our professional website developers and project managers possess significant expertise to implement modern 
                programming languages for promising cloud-based web solutions. We assure secure, reliable and easy to maintain web enabled solutions delivery. 
                </h5>
                 <br />
                 
               </Col>
             </Row>
            
             
           </Container>
         </div>
 <div className="shift-up-30px" >
 <Customfaq />

 </div>

       
        <ContactComponent />
      </div>
     
      <FooterNav />
    </>
  );
}

export default AppDevelopment;
