/*eslint-disable*/
import React from "react";
// react plugin used to create datetimepicker
import ReactDatetime from "react-datetime";
// react plugin used to create switch buttons
import Switch from "react-bootstrap-switch";
// react plugin used to create an image gallery
import { PhotoSwipeGallery } from "react-photoswipe";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Collapse,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  PopoverBody,
  PopoverHeader,
  UncontrolledPopover,
  Modal,
  Input,
  Carousel,
  CarouselItem,
  CarouselIndicators,
  CarouselCaption,
} from "reactstrap";


function Customfaq() {
 
  // collapse states and functions
  const [collapses, setCollapses] = React.useState([]);
  const changeCollapse = (collapse) => {
    if (collapses.includes(collapse)) {
      setCollapses(collapses.filter((prop) => prop !== collapse));
    } else {
      setCollapses([...collapses, collapse]);
    }
  };
  
  return (
    <>
      <div className="section section-blue javascript-components">
        <Container>
    
          <div>
            <h2 className="custom-title-main"><strong>Frequently Asked Questions</strong></h2>
          </div>
          <Row className="justify-center">
            <Col md="10">
              <div id="acordeon">
                <div aria-multiselectable={true} id="accordion" role="tablist">
                  <Card className="no-transition">
                    <CardHeader
                      className="card-collapse"
                      id="headingOne"
                      role="tab"
                    >
                      <h2 className="mb-0 custom-title">
                        <a
                          aria-expanded={collapses.includes(1)}
                          className="collapsed"
                          data-parent="#accordion"
                          href="#pablo"
                          id="collapseOne"
                          onClick={(e) => {
                            e.preventDefault();
                            changeCollapse(1);
                          }}
                        >
                          What is custom software development?{" "}
                          <i className="nc-icon nc-minimal-down" />
                        </a>
                      </h2>
                    </CardHeader>
                    <Collapse isOpen={collapses.includes(1)}>
                      <CardBody className="collapse-text">
                      Custom software development is the process of creating a software product tailored to the specific needs 
                      of a business or activity. A distinctive feature of custom software is that, because it is designed to achieve 
                      unique goals, it is usually created from scratch and does not have analogous applications on the market. 
                      </CardBody>
                    </Collapse>
                    <CardHeader
                      className="card-collapse"
                      id="headingTwo"
                      role="tab"
                    >
                      <h2 className="mb-0 custom-title">
                        <a
                          aria-controls="collapseTwo"
                          aria-expanded={collapses.includes(2)}
                          className="collapsed"
                          data-parent="#accordion"
                          href="#pablo"
                          id="collapseTwo"
                          onClick={(e) => {
                            e.preventDefault();
                            changeCollapse(2);
                          }}
                        >
                          What is the difference between custom software solutions and off-the-shelf solutions?{" "}
                          <i className="nc-icon nc-minimal-down" />
                        </a>
                      </h2>
                    </CardHeader>
                    <Collapse isOpen={collapses.includes(2)}>
                    <CardBody className="collapse-text">
                    An off-the-shelf solution is usually created for a larger audience, to be used as a SaaS solution for example. 
                    While an off-the-shelf solution may solve similar problems or serve similar purposes to a custom solution, 
                    custom software is usually designed to meet specific business needs that off-the-shelf solutions do not address. 
                      </CardBody>
                    </Collapse>
                    <CardHeader
                      className="card-collapse"
                      id="headingThree"
                      role="tab"
                    >
                      <h2 className="mb-0 custom-title">
                        <a
                          aria-controls="collapseThree"
                          aria-expanded={collapses.includes(3)}
                          className="collapsed"
                          data-parent="#accordion"
                          href="#pablo"
                          id="collapseThree"
                          onClick={(e) => {
                            e.preventDefault();
                            changeCollapse(3);
                          }}
                        >
                          What are the benefits of a custom software solution?{" "}
                          <i className="nc-icon nc-minimal-down" />
                        </a>
                      </h2>
                    </CardHeader>
                    <Collapse isOpen={collapses.includes(3)}>
                    <CardBody className="collapse-text">
                    A custom software solution provides the utmost flexibility for its owner, 
                    as it can be tailored to almost every need a business may have. A custom 
                    software solution, if properly built, will provide a competitive advantage 
                    to its owner because of its unique features and functionality. And, finally, 
                    custom software doesn't come with ongoing licensing costs, as it is owned by its user. 
                      </CardBody>
                    </Collapse>
                  </Card>
                </div>
                {/* end acordeon */}
              </div>
            </Col>
          </Row>
         
        </Container>
      </div>
    </>
  );
}

export default Customfaq;
