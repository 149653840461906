import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import ColorNavbar from "components/Navbars/ColorNavbar"; 
import ContactComponent from '../services/ContactComponent.js';

import WebHeaderTemplate from "./webHeaders/WebHeaderTemplate.js";
import FooterNav from "../services/FooterNav.js";
import {Link} from 'react-router-dom';

function WebMaintenance() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
    };
  });
  return (
    <>
       <ColorNavbar />
      <WebHeaderTemplate navTitle="Website Maintenance Services" Title="Website Maintenance" />

      <div className="wrapper">
        {/* ********* PRICING 3 ********* */}
        <div
          className="pricing-3 section-bg-img"
          style={{
            backgroundImage:
            "url(" + require("assets/img/nl/sections/custom/red-blue.jpg") + ")",
          }}
        >
           <Container>
          <Row>
          <Col xl="6" lg="6" md="12" sm="12" xs="12" className="col1">
              <Card className="card-pricing w-150">
              <h6 className="card-category">Budget</h6>
              
                <CardBody>
                <div className="webcardicon my-5">
                      <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/web/dashboard-level.svg")}
                        />
                        <span>
                        <h5 className="webcardtitle">
                        Basic Maintenance Plan
                      </h5>
                        </span>
                  
                      </div>
                  <CardTitle tag="h2">R199</CardTitle>
                  <ul className="align-left">
                   
                    <li>
                    <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />
                    Software Updates
                    </li>
                    <li>
                    <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />
                    Monthly Backup's
                    </li>
                    
                    <br></br>

                  </ul>
                  
                  <br></br>
                  <br></br>
                  <br></br>
                  <div className="subscribe__div">
                  <a className="subscribe__btn" href="https://www.payfast.co.za/eng/process?cmd=_paynow&amp;receiver=17143900&amp;item_name=Basic+Maintenance+Plan&amp;amount=199.00&amp;return_url=https%3A%2F%2Fnextleveltechnology.org%2Fsuccess&amp;cancel_url=https%3A%2F%2Fnextleveltechnology.org%2Fwebsite-maintenance&amp;cycles=0&amp;frequency=3&amp;m_payment_id=pay_now_17143900&amp;subscription_type=1"><img src="https://www.payfast.co.za/images/buttons/dark-large-subscribe.png" width="210" height="59" alt="Subscribe" title="Subscribe Now with PayFast" /></a>
                  </div>
                 <Link to="/contact">
                  <Button
                    className="btn-round card-link web-card-btn"
                    color="success"
                    href="" 
                  >
                    Get Started
                  </Button>
                  </Link>
                </CardBody>
              </Card>
            </Col>
            <Col xl="6" lg="6" md="12" sm="12" xs="12" className="col1">
              <Card className="card-pricing w-150">
              <h6 className="card-category">Basic</h6>
              
                <CardBody>
                <div className="webcardicon my-5">
                      <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/web/dashboard-level.svg")}
                        />
                        <span>
                        <h5 className="webcardtitle">
                        Standard Maintenance Plan
                      </h5>
                        </span>
                  
                      </div>
                      <CardTitle tag="h2">R349</CardTitle>
                      ,  
                  <ul className="align-left">
                    <li>
                    <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />
                    Software Updates
                    </li>
                    <li>
                    <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />
                    Monthly Backup's
                    </li>
                    <li>
                    <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />
                    Content Changes
                    </li>
                    
                    <br></br>

                  </ul>
                  
                  <br></br>
                  <br></br>
                  <br></br>
                  <div className="subscribe__div">
                  <a className="subscribe__btn" href="https://www.payfast.co.za/eng/process?cmd=_paynow&amp;receiver=17143900&amp;item_name=Standard+Maintenance+Plan&amp;amount=349.00&amp;return_url=https%3A%2F%2Fnextleveltechnology.org%2Fsuccess&amp;cancel_url=https%3A%2F%2Fnextleveltechnology.org%2Fwebsite-maintenance&amp;cycles=0&amp;frequency=3&amp;m_payment_id=pay_now_17143900&amp;subscription_type=1"><img src="https://www.payfast.co.za/images/buttons/dark-large-subscribe.png" width="210" height="59" alt="Subscribe" title="Subscribe Now with PayFast" /></a>
                  </div>
                 <Link to="/contact">
                  <Button
                    className="btn-round card-link web-card-btn"
                    color="success"
                    href="" 
                  >
                    Get Started
                  </Button>
                  </Link>
                </CardBody>
              </Card>
            </Col>
            </Row>
            <Row>
            <Col xl="6" lg="6" md="12" sm="12" xs="12" className="col1">
              <Card className="card-pricing w-150">
              <h6 className="card-category">Standard</h6>
                <CardBody>
                <div className="webcardicon my-5">
                      <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/web/dashboard-level.svg")}
                        />
                        <span>
                        <h5 className="webcardtitle">
                        Business Maintenance Plan
                      </h5>
                        </span>
                  
                      </div>
                      <CardTitle tag="h2">R499</CardTitle>
                  <ul className="align-left">
                   <li>
                   <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />
                    Software Updates
                    </li>
                    <li>
                    <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />
                    Monthly Backup's
                    </li>
                    <li>
                    <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />
                    Content Changes
                    </li>
                    <li>
                    <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />
                    Content Additions
                    </li>
                    
                    <br></br>

                  </ul>
                  
                  <br></br>
                  <br></br>
                  <br></br>
                  <div className="subscribe__div">
                  <a className="subscribe__btn" href="https://www.payfast.co.za/eng/process?cmd=_paynow&amp;receiver=17143900&amp;item_name=Business+Maintenance+Plan&amp;amount=499.00&amp;return_url=https%3A%2F%2Fnextleveltechnology.org%2Fsuccess&amp;cancel_url=https%3A%2F%2Fnextleveltechnology.org%2Fwebsite-maintenance&amp;cycles=0&amp;frequency=3&amp;m_payment_id=pay_now_17143900&amp;subscription_type=1"><img src="https://www.payfast.co.za/images/buttons/dark-large-subscribe.png" width="210" height="59" alt="Subscribe" title="Subscribe Now with PayFast" /></a>
                  </div>
                 <Link to="/contact">
                  <Button
                    className="btn-round card-link web-card-btn"
                    color="success"
                    href="" 
                  >
                    Get Started
                  </Button>
                  </Link>
                </CardBody>
              </Card>
            </Col>
           
            <Col xl="6" lg="6" md="12" sm="12" xs="12" className="col1">
              <Card className="card-pricing w-150">
              <h6 className="card-category">Premium</h6>
                <CardBody>
                <div className="webcardicon my-5">
                      <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/web/dashboard-level.svg")}
                        />
                        <span>
                        <h5 className="webcardtitle">
                        Advanced Maintenance Plan
                      </h5>
                        </span>
                  
                      </div>
                      <CardTitle tag="h2">R799</CardTitle>
                  <ul className="align-left">
                    <li>
                    <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />
                    Software Updates
                    </li>
                    <li>
                    <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />
                    Monthly Backup's
                    </li>
                    <li>
                    <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />
                    Content Changes
                    </li>
                    <li>
                    <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />
                    Content Additions
                    </li>
                    <li>
                    <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />
                    Design Additions & Changes
                    </li>
                    <li>
                    <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />
                    Additional Pages
                    </li>
                    <br></br>

                  </ul>
                  
                  <br></br>
                  <br></br>
                  <br></br>
                  <div className="subscribe__div">
                  <a className="subscribe__btn" href="https://www.payfast.co.za/eng/process?cmd=_paynow&amp;receiver=17143900&amp;item_name=Advanced+Maintenance+Plan&amp;amount=799.00&amp;return_url=https%3A%2F%2Fnextleveltechnology.org%2Fsuccess&amp;cancel_url=https%3A%2F%2Fnextleveltechnology.org%2Fwebsite-maintenance&amp;cycles=0&amp;frequency=3&amp;m_payment_id=pay_now_17143900&amp;subscription_type=1"><img src="https://www.payfast.co.za/images/buttons/dark-large-subscribe.png" width="210" height="59" alt="Subscribe" title="Subscribe Now with PayFast" /></a>
                  </div>
                 <Link to="/contact">
                  <Button
                    className="btn-round card-link web-card-btn"
                    color="success"
                    href="" 
                  >
                    Get Started
                  </Button>
                  </Link>
                </CardBody>
              </Card>
            </Col>
            
          </Row>
          <Row>
          
  

          <Card className="card-pricing w-150">
  <CardBody>
  <h6 className="card-category align-center">Hosting Features</h6>

  <Row>

<Col className="cols2" md="6" sm="6" xs="12">

    <ul className="align-left">
<li>
<img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />
          <b>99.99%</b> Uptime Guarantee
        </li>
        <li>
        <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />
          <b>Unlimited</b> Bandwidth
        </li>
        <li>
        <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />          Managed Wordpress
        </li>
        <li>
        <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />          Wordpress Acceleration
        </li>
        <li>
        <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />         Cloudflare CDN with Railgun
        </li>
        <li>
        <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />
          Popular CMSs supported
        </li>
        <li>
<img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />
          Webmail
        </li>
        
</ul>
      <br></br>
      <br></br>
      
   
    </Col>

    <Col className="cols2" md="6" sm="6" xs="12">
    
    <ul className="align-left">
    <li>
        <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />          Malware and Spam, Scan & Removal
        </li>

        
        <li>
        <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />          DDoS protection & Mitigation
        </li>
        <li>
        <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />          Payment Options
        </li>
        <li>
        <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />         Backups
        </li>
        <li>
        <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />          Lets Encrypt SSL/TlS
        </li>
        <li>
        <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />          PHP 7, MySQL, PostgreSQL
        </li>
       
        
</ul>
</Col>

<Col className="cols1" md="12" sm="12" xs="12">
<ul className="align-left">
<li>
<img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />
          <b>99.99%</b> Uptime Guarantee
        </li>
        <li>
        <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />
          <b>Unlimited</b> Bandwidth
        </li>
        <li>
        <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />          Managed Wordpress
        </li>
        <li>
        <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />          Wordpress Acceleration
        </li>
        <li>
        <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />         Cloudflare CDN with Railgun
        </li>
        <li>
        <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />
          Popular CMSs supported
        </li>
        <li>
<img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />
          Webmail
        </li>
        <li>
        <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />          Malware and Spam, Scan & Removal
        </li>

        
        <li>
        <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />          DDoS protection & Mitigation
        </li>
        <li>
        <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />          Payment Options
        </li>
        <li>
        <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />         Backups
        </li>
        <li>
        <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />          Lets Encrypt SSL/TlS
        </li>
        <li>
        <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />          PHP 7, MySQL, PostgreSQL
        </li>
       
        
</ul>
</Col>
      <br></br>
      <br></br>
      <br></br>
      </Row>

    </CardBody>
    </Card>
          </Row>
  
  
          

           
          </Container>
        </div>
        <ContactComponent />
      </div>
      <FooterNav />
    </>
  );
}

export default WebMaintenance;
