import React from "react";
import { useStateValue } from "../StateProvider";
import {Button} from 'reactstrap';
import './CheckoutProduct.css';

function Product({ id, title, image, price, rating }) {
  const [{ basket }, dispatch] = useStateValue();

  const addToBasket = () => {
    // dispatch the item into the data layer
    dispatch({
      type: "ADD_TO_BASKET",
      item: {
        id: id,
        title: title,
        image: image,
        price: price,
        rating: rating,
      },
    });
  };

  return (
    
<div className="basket-div">      
  <Button className="btn btn-warning add__to__basket" onClick={addToBasket}>Add to Basket</Button>
</div>
  );
}

export default Product;
