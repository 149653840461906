import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col,
} from "reactstrap";
import {Link} from 'react-router-dom';

// core components
import ColorNavbar from "components/Navbars/ColorNavbar"; 
import ContactComponent from '../services/ContactComponent.js';

import WebHeaderTemplate from "./webHeaders/WebHeaderTemplate.js";
import FooterNav from "../services/FooterNav.js";
import Product from "../Product";

function WebContent() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
    };
  });
  return (
    <>
     <ColorNavbar />
      <WebHeaderTemplate navTitle="Content Creation Services" Title="Content Creation" />

      <div className="wrapper">
        {/* ********* PRICING 3 ********* */}
        <div
          className="pricing-3 section-bg-img"
          style={{
            backgroundImage:
            "url(" + require("assets/img/nl/sections/custom/red-blue.jpg") + ")",
          }}
        >
           <Container>
           <Row>
              <Col lg="12" md="12" sm="12" xs="12">
                <h1 id="titlewhite3">
                        Content Writing
                        </h1>
                </Col>
              </Row>
          <Row>
            <Col md="4" className="col1">
              <Card className="card-pricing w-150">
              <h6 className="card-category">Standard</h6>
              
                <CardBody>
                <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/web/pencil.svg")}
                        />
                <div className="webcardicon my-5">
                        <span>
                        <h5 className="webcardtitle">
                        750 Word Copyright Text 
                      </h5>
                        </span>
                  
                      </div>
                  <CardTitle tag="h2">R899</CardTitle>
                   <ul className="align-left">
                  <li>
                  <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                   Up to 750 words original content
                  </li>
                  <li>
                  <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                   1 Main Draft Concept
                  </li>
                   <li>
                   <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                   2 Reviews
                  </li>
                  </ul>
                  <br></br>
                  <br></br>
                  <br></br>
                  <Product 
                  id="750WordCopyrightText"
                  title="750 Word Copyright Text"
                  price={899.0}
                  rating={5}
                  image={require("assets/img/nlicons/web/pencil.svg")}
                  />   
                 <Link to="/contact">
                  <Button
                    className="btn-round card-link web-card-btn"
                    color="success"
                    href="" 
                  >
                    Get Started
                  </Button>
                  </Link>
                </CardBody>
              </Card>
            </Col>
            <Col md="4" className="col1">
              <Card className="card-pricing w-150">
              <h6 className="card-category">Standard</h6>
              
                <CardBody>
                <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/web/pencil.svg")}
                        />
                <div className="webcardicon my-5">
                      
                        <span>
                        <h5 className="webcardtitle">
                        1000 Word Copyright Text
                      </h5>
                        </span>
                  
                      </div>
                  <CardTitle tag="h2">R1199</CardTitle>
                  <ul className="align-left">
                  <li>
                  <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                   Up to 1000 words original content
                  </li>
                  <li>
                  <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                   1 Main Draft Concept
                  </li>
                   <li>
                   <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                   2 Reviews
                  </li>
                  </ul>
                  <br></br>
                  <br></br>
                  <Product 
                  id="1000WordCopyrightText"
                  title="1000 Word Copyright Text"
                  price={1199.0}
                  rating={5}
                  image={require("assets/img/nlicons/web/pencil.svg")}
                  />   
                 <Link to="/contact">
                  <Button
                    className="btn-round card-link web-card-btn"
                    color="success"
                    href="" 
                  >
                    Get Started
                  </Button>
                  </Link>
                </CardBody>
              </Card>
            </Col>
            <Col md="4" className="col1">
              <Card className="card-pricing w-150">
              <h6 className="card-category">Standard</h6>
                <CardBody>
                <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/web/pencil.svg")}
                        />
                <div className="webcardicon my-5">
                        <span>
                        <h5 className="webcardtitle">
                        1500 Word Copyright Text
                      </h5>
                        </span>
                  
                      </div>
                  <CardTitle tag="h2">R1399</CardTitle>
                  <ul className="align-left">
                  <li>
                  <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                   Up to 1500 words original content
                  </li>
                  <li>
                  <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                   1 Main Draft Concept
                  </li>
                   <li>
                   <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                   2 Reviews
                  </li>
                  </ul>
                   <br></br>
                  <br></br>
                  <Product 
                  id="1500WordCopyrightText"
                  title="1500 Word Copyright Text"
                  price={1399.0}
                  rating={5}
                  image={require("assets/img/nlicons/web/pencil.svg")}
                  />   
                 <Link to="/contact">
                  <Button
                    className="btn-round card-link web-card-btn"
                    color="success"
                    href="" 
                  >
                    Get Started
                  </Button>
                  </Link>
                </CardBody>
              </Card>
            </Col>
            
            
          </Row>
          </Container>

          <Container>
          <Row>
              <Col lg="12" md="12" sm="12" xs="12">
                <h1 id="titlewhite3">
                        Image Sourcing
                        </h1>
                </Col>
              </Row>
          <Row>
          <Col xl="6" lg="6" md="12" sm="12" xs="12" className="col1">
              <Card className="card-pricing w-150">
              <h6 className="card-category">Standard</h6>
              
                <CardBody>
                <div className="webcardicon my-5">
                      <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/web/image1.svg")}
                        />
                        <span>
                        <h5 className="webcardtitle">
                        Standard Image Collection
                      </h5>
                        </span>
                  
                      </div>
                  <CardTitle tag="h2">R999</CardTitle>
                  <ul className="align-left">
                  
                    <li>
                    <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      Collection of up to 12 Website Stock Images
                    </li>
                    
                    <li>
                    <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      Images Sourced From Suppliers (FreePik / AdobeStock / ShutterStock)
                    </li>
                    <li>
                    <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      Client Responsible for individually charged purchases from supplier
                    </li>
                    <li>
                    <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      Image Licensing
                    </li>
                    
                    <br></br>

                  </ul>
                  
                  <br></br>
                  <br></br>
                  <Product 
                  id="StandardImageCollection"
                  title="Standard Image Collection"
                  price={999.0}
                  rating={5}
                  image={require("assets/img/nlicons/web/image1.svg")}
                  />   
                 <Link to="/contact">
                  <Button
                    className="btn-round card-link web-card-btn"
                    color="success"
                    href="" 
                  >
                    Get Started
                  </Button>
                  </Link>
                </CardBody>
              </Card>
            </Col>
            <Col xl="6" lg="6" md="12" sm="12" xs="12" className="col1">
              <Card className="card-pricing w-150">
              <h6 className="card-category">Standard</h6>
              
                <CardBody>
                
                <div className="webcardicon my-5">
                <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/web/image1.svg")}
                        />
                        <span>
                        <h5 className="webcardtitle">
                        Large Image Collection
                      </h5>
                        </span>
                  
                      </div>
                      <CardTitle tag="h2">R2499</CardTitle>
                  <ul className="align-left">
                    
                    <li>
                    <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      Collection of up to 13-99 Website Stock Images
                    </li>
                    <li>
                    <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      Images Sourced From Suppliers (FreePik / AdobeStock / ShutterStock)
                    </li>
                    
                    <li>
                    <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      Client Responsible for individually charged purchases from supplier
                    </li>
                    <li>
                    <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      Image Licensing
                    </li>
                    <br></br>

                  </ul>
                  
                  <br></br>
                  <br></br>
                  <Product 
                  id="LargeImageCollection"
                  title="Large Image Collection"
                  price={2499.0}
                  rating={5}
                  image={require("assets/img/nlicons/web/image1.svg")}
                  />   
                 <Link to="/contact">
                  <Button
                    className="btn-round card-link web-card-btn"
                    color="success"
                    href="" 
                  >
                    Get Started
                  </Button>
                  </Link>
                </CardBody>
              </Card>
            </Col>
            </Row>
            <Row>
            <Col xl="6" lg="6" md="12" sm="12" xs="12" className="col1">
              <Card className="card-pricing w-150">
              <h6 className="card-category">Premium</h6>
                <CardBody>
                <div className="webcardicon my-5">
                      <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/web/image2.svg")}
                        />
                        <span>
                        <h5 className="webcardtitle">
                        Custom Freepik Collection
                      </h5>
                        </span>
                  
                      </div>
                      <CardTitle tag="h2">R2699</CardTitle>
                  <ul className="align-left">
                    
                    <li>
                    <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      3 Personalized & Edited Freepik Images 
                    </li>
                    
                    <li>
                    <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      Image Licensing
                    </li>
                    
                    <br></br>

                  </ul>
                  
                  <br></br>
                  <br></br>
                  <Product 
                  id="CustomFreepikCollection"
                  title="Custom Freepik Collection"
                  price={2699.0}
                  rating={5}
                  image={require("assets/img/nlicons/web/image2.svg")}
                  />   
                 <Link to="/contact">
                  <Button
                    className="btn-round card-link web-card-btn"
                    color="success"
                    href="" 
                  >
                    Get Started
                  </Button>
                  </Link>
                </CardBody>
              </Card>
            </Col>
           
            <Col xl="6" lg="6" md="12" sm="12" xs="12" className="col1">
              <Card className="card-pricing w-150">
              <h6 className="card-category">Premium</h6>
                <CardBody>
                <div className="webcardicon my-5">
                      <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/web/image2.svg")}
                        />
                        <span>
                        <h5 className="webcardtitle">
                        Custom ShutterStock Collection
                      </h5>
                        </span>
                  
                      </div>
                      <CardTitle tag="h2">R3499</CardTitle>
                   <ul className="align-left">
                    
                    <li>
                    <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      3 Personalized & Edited Shutterstock Images 
                    </li>
                    
                    <li>
                    <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      Image Licensing
                    </li>
                    
                    <br></br>

                    
                    <br></br>

                  </ul>
                  
                  <br></br>
                  <br></br>
                  <Product 
                  id="CustomShutterStockCollection"
                  title="Custom ShutterStock Collection"
                  price={3499.0}
                  rating={5}
                  image={require("assets/img/nlicons/web/image2.svg")}
                  />   
                 <Link to="/contact">
                  <Button
                    className="btn-round card-link web-card-btn"
                    color="success"
                    href="" 
                  >
                    Get Started
                  </Button>
                  </Link>
                </CardBody>
              </Card>
            </Col>
            
          </Row>
          </Container>


           <Container>
          <Row>
              <Col lg="12" md="12" sm="12" xs="12">
                <h1 id="titlewhite2">
                        Video Creation
                        </h1>
                </Col>
              </Row>
          <Row>
          <Col xl="6" lg="6" md="12" sm="12" xs="12" className="col1">
              <Card className="card-pricing w-150">
              <h6 className="card-category">Standard</h6>
              
                <CardBody>
                <div className="webcardicon my-5">
                      <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/web/video-66.svg")}
                        />
                        <span>
                        <h5 className="webcardtitle">
                        Image Video Base - Web Video
                      </h5>
                        </span>
                  
                      </div>
                  <CardTitle tag="h2">R1799</CardTitle>
                  <ul className="align-left">
                  
                    <li>
                    <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      60 Seconds Slide Image Video
                    </li>
                    
                    <li>
                    <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      Licenced Content Required
                    </li>
                    
                    
                    <br></br>

                  </ul>
                  
                  <br></br>
                  <br></br>
                  <Product 
                  id="ImageVideoBase-WebVideo"
                  title="Image Video Base - Web Video"
                  price={3299.0}
                  rating={5}
                  image={require("assets/img/nlicons/web/video-66.svg")}
                  />  
                 <Link to="/contact">
                  <Button
                    className="btn-round card-link web-card-btn"
                    color="success"
                    href="" 
                  >
                    Get Started
                  </Button>
                  </Link>
                </CardBody>
              </Card>
            </Col>
            <Col xl="6" lg="6" md="12" sm="12" xs="12" className="col1">
              <Card className="card-pricing w-150">
              <h6 className="card-category">Standard</h6>
              
                <CardBody>
                <div className="webcardicon my-5">
                      <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/web/video-66.svg")}
                        />
                        <span>
                        <h5 className="webcardtitle">
                        Image Video Base - Web Video Extended
                      </h5>
                        </span>
                  
                      </div>
                      <CardTitle tag="h2">R3299</CardTitle>
                 <ul className="align-left">
                  
                    <li>
                    <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      180 Seconds Slide Image Video
                    </li>
                    
                    <li>
                    <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      Licenced Content Required
                    </li>
                    
                    
                    <br></br>

                  </ul>
                  
                  <br></br>
                  <br></br>
                  <Product 
                  id="ImageVideoBase-WebVideoExtended"
                  title="Image Video Base - Web Video Extended"
                  price={2699.0}
                  rating={5}
                  image={require("assets/img/nlicons/web/video-66.svg")}
                  />  
                 <Link to="/contact">
                  <Button
                    className="btn-round card-link web-card-btn"
                    color="success"
                    href="" 
                  >
                    Get Started
                  </Button>
                  </Link>
                </CardBody>
              </Card>
            </Col>
            </Row>
            <Row>
            <Col xl="6" lg="6" md="12" sm="12" xs="12" className="col1">
              <Card className="card-pricing w-150">
              <h6 className="card-category">Premium</h6>
                <CardBody>
                <div className="webcardicon my-5">
                      <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/web/video-66.svg")}
                        />
                        <span>
                        <h5 className="webcardtitle">
                        Film Video Base - Web Video
                      </h5>
                        </span>
                  
                      </div>
                      <CardTitle tag="h2">R2699</CardTitle>
                  <ul className="align-left">
                    
                  
                    <li>
                    <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      60 Seconds Slide Film Video
                    </li>
                    
                    <li>
                    <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      Licenced Content Required
                    </li>
                    
                    
                    <br></br>

                  </ul>
                  
                  <br></br>
                  <br></br>
                  <Product 
                  id="FilmVideoBase-WebVideo"
                  title="Film Video Base - Web Video"
                  price={1799.0}
                  rating={5}
                  image={require("assets/img/nlicons/web/video-66.svg")}
                  />  
                 <Link to="/contact">
                  <Button
                    className="btn-round card-link web-card-btn"
                    color="success"
                    href="" 
                  >
                    Get Started
                  </Button>
                  </Link>
                </CardBody>
              </Card>
            </Col>
           
            <Col xl="6" lg="6" md="12" sm="12" xs="12" className="col1">
              <Card className="card-pricing w-150">
              <h6 className="card-category">Premium</h6>
                <CardBody>
                <div className="webcardicon my-5">
                      <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/web/video-66.svg")}
                        />
                        <span>
                        <h5 className="webcardtitle">
                        Film Video Base - Web Video Extended
                      </h5>
                        </span>
                  
                      </div>
                      <CardTitle tag="h2">R3499</CardTitle>
                   <ul className="align-left">
                  
                    <li>
                    <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      180 Seconds Slide Film Video
                    </li>
                    
                    <li>
                    <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      Licenced Content Required
                    </li>
                    
                    
                    <br></br>

                  </ul>
                  
                  <br></br>
                  <br></br>
                  <Product 
                  id="FilmVideoBase-WebVideoExtended"
                  title="Film Video Base - Web Video Extended"
                  price={1799.0}
                  rating={5}
                  image={require("assets/img/nlicons/web/video-66.svg")}
                  />  
                 <Link to="/contact">
                  <Button
                    className="btn-round card-link web-card-btn"
                    color="success"
                    href="" 
                  >
                    Get Started
                  </Button>
                  </Link>
                </CardBody>
              </Card>
            </Col>
            
          </Row>
          </Container>

        </div>



        <ContactComponent />
      </div>
      <FooterNav />
    </>
  );
}

export default WebContent;
