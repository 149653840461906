/*eslint-disable*/
import React from "react";
// react plugin used to create datetimepicker
import ReactDatetime from "react-datetime";
// react plugin used to create switch buttons
import Switch from "react-bootstrap-switch";
// react plugin used to create an image gallery
import { PhotoSwipeGallery } from "react-photoswipe";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Collapse,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  PopoverBody,
  PopoverHeader,
  UncontrolledPopover,
  Modal,
  Input,
  Carousel,
  CarouselItem,
  CarouselIndicators,
  CarouselCaption,
} from "reactstrap";


function Levfaq() {
 
  // collapse states and functions
  const [collapses, setCollapses] = React.useState([]);
  const changeCollapse = (collapse) => {
    if (collapses.includes(collapse)) {
      setCollapses(collapses.filter((prop) => prop !== collapse));
    } else {
      setCollapses([...collapses, collapse]);
    }
  };
  
  return (
    <>
      <div className="section section-blue javascript-components">
        <Container>
    
          <div>
            <h2 className="custom-title-main"><strong>Frequently Asked Questions</strong></h2>
          </div>
          <Row className="justify-center">
            <Col md="10">
              <div id="acordeon">
                <div aria-multiselectable={true} id="accordion" role="tablist">
                  <Card className="no-transition">
                    <CardHeader
                      className="card-collapse"
                      id="headingOne"
                      role="tab"
                    >
                      <h2 className="mb-0 custom-title">
                        <a
                          aria-expanded={collapses.includes(1)}
                          className="collapsed"
                          data-parent="#accordion"
                          href="#pablo"
                          id="collapseOne"
                          onClick={(e) => {
                            e.preventDefault();
                            changeCollapse(1);
                          }}
                        >
                          What is a dedicated development team?{" "}
                          <i className="nc-icon nc-minimal-down" />
                        </a>
                      </h2>
                    </CardHeader>
                    <Collapse isOpen={collapses.includes(1)}>
                      <CardBody className="collapse-text">
                      A dedicated development team is a team of people, provided by the software development vendor, with 
                      predefined qualifications and skillsets to handle ongoing and existing software development tasks for 
                      your organization. Usually a dedicated development team augments or supplements existing onsite software 
                      development capability.
                      </CardBody>
                    </Collapse>
                    <CardHeader
                      className="card-collapse"
                      id="headingTwo"
                      role="tab"
                    >
                      <h2 className="mb-0 custom-title">
                        <a
                          aria-controls="collapseTwo"
                          aria-expanded={collapses.includes(2)}
                          className="collapsed"
                          data-parent="#accordion"
                          href="#pablo"
                          id="collapseTwo"
                          onClick={(e) => {
                            e.preventDefault();
                            changeCollapse(2);
                          }}
                        >
                          How can I manage an external dedicated development team?{" "}
                          <i className="nc-icon nc-minimal-down" />
                        </a>
                      </h2>
                    </CardHeader>
                    <Collapse isOpen={collapses.includes(2)}>
                    <CardBody className="collapse-text">
                    Dedicated software developers typically adhere to an operational framework that provides visibility, 
                    transparency, and regular communication. This may include periodic reports, sprint- or increment-based 
                    work, pre-sprint planning, demos, daily and weekly meetings, and custom updates.
                      </CardBody>
                    </Collapse>
                    <CardHeader
                      className="card-collapse"
                      id="headingThree"
                      role="tab"
                    >
                      <h2 className="mb-0 custom-title">
                        <a
                          aria-controls="collapseThree"
                          aria-expanded={collapses.includes(3)}
                          className="collapsed"
                          data-parent="#accordion"
                          href="#pablo"
                          id="collapseThree"
                          onClick={(e) => {
                            e.preventDefault();
                            changeCollapse(3);
                          }}
                        >
                          Are dedicated teams more efficient that internal development teams?{" "}
                          <i className="nc-icon nc-minimal-down" />
                        </a>
                      </h2>
                    </CardHeader>
                    <Collapse isOpen={collapses.includes(3)}>
                    <CardBody className="collapse-text">
                    Only if a dedicated development team adheres to a strictly defined delivery processes and efficient communication 
                    framework, and commitment to transparency that builds trust between a client and a vendor. External teams do not 
                    require the resources that internal developers would, and they can adapt more easily to changing conditions or 
                    scope, so when these conditions for trust are met, they are more efficient.  
                      </CardBody>
                    </Collapse>
                  </Card>
                </div>
                {/* end acordeon */}
              </div>
            </Col>
          </Row>
         
        </Container>
      </div>
    </>
  );
}

export default Levfaq;
