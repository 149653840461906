import React from "react";
// reactstrap components
import {
  Row,
  Col,
} from "reactstrap";

// core components
import EntertainmentHeader from "components/Headers/Custom/EntertainmentHeader.js";
import ColorNavbar from "components/Navbars/ColorNavbar"; 
import ContactComponent from '../services/ContactComponent.js';

import FooterNav from "../services/FooterNav.js";


function Entertainment() {
  
 

  document.documentElement.classList.remove("nav-open");
  // function that is being called on scroll of the page
  const checkScroll = () => {
    // it takes all the elements that have the .add-animation class on them
    const componentPosition = document.getElementsByClassName("add-animation");
    const scrollPosition = window.pageYOffset;
    for (var i = 0; i < componentPosition.length; i++) {
      var rec =
        componentPosition[i].getBoundingClientRect().top + window.scrollY + 100;
      // when the element with the .add-animation is in the scroll view,
      // the .animated class gets added to it, so it creates a nice fade in animation
      if (scrollPosition + window.innerHeight >= rec) {
        componentPosition[i].classList.add("animated");
        // when the element with the .add-animation is not in the scroll view,
        // the .animated class gets removed from it, so it creates a nice fade out animation
      } else if (scrollPosition + window.innerHeight * 0.8 < rec) {
        componentPosition[i].classList.remove("animated");
      }
    }
  };
  

  React.useEffect(() => {
    document.body.classList.add("presentation-page");
    window.addEventListener("scroll", checkScroll);
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("presentation-page");
      window.removeEventListener("scroll", checkScroll);
    };
  });
  return (
    <>
    <ColorNavbar />
      <EntertainmentHeader />
      <div className="wrapper">
       
     
     
      <div>
           
      <Row>
      
                    <Col md="6">
                      <div className="topcard">
                        <h2 id="titleblack" className="align-left2">
                        Improve your business with NEXTLEVEL
                        </h2>
                       
                      </div>
                    
                    </Col>
                    <Col md="6">
                      
                  <p className="top-description">

                  Today’s audiences are interacting with media — written content, video, music, and gaming — in more ways than ever before. As consumer preferences change, the emergence of new technologies and channels is revolutionizing the way media and entertainment companies produce, manage, and distribute content.
                  <br></br>
                  And in this constantly changing landscape, businesses looking to grow and thrive require the support of software solutions that are both robust and flexible enough to support constant change and rapid scale.                  </p>
                 <br></br>
                 <br></br>
                 <br></br>
                    </Col> 
                </Row>

                <Row className="shift-down-20px">
                    
                    <Col md="5">
                    <img 
                    alt="cape town"
                    className="sectionimg-sme2"
                    src={require("assets/img/nl/industries/media/why-choose3.jpg")}
                    />
                    </Col> 
                    <Col md="7">
                    <div
          className="pricing-3 custom-software-div3"
          style={{
            backgroundImage:
              "url(" + require("assets/img/nlbg/bg4.jpg") + ")",
          }}
        >
                   <br></br>
                   <br></br>

                    <h2 className="whath2fin2 mx-10"><span>Why Choose Custom Software?</span></h2>
                    <br></br>
                    <h5 className="whath4ind ml-30 mr-30"><span>
                    While there are several off-the-shelf software options available on the market, their out-of-the-box features and clunky integration capabilities will make for a less-than-ideal experience — both for media and entertainment companies and their audiences. 
                    <br></br>
                    <br></br>
                    Custom software, on the other hand, is built specifically to meet your business’s unique needs, taking into consideration every nuance.
                    <br></br>
                    <br></br>
                    What’s more, you own the system, and you can make adjustments as you grow and evolve with the industry, scaling to support expanding teams, new distribution channels, and changing audience preferences. In short, with custom software, you’re always in control.
                    </span></h5>
                    <br></br>

                </div>
                    </Col>
                </Row>
                


                <Row className="">
                    <Col md="7">
                    <div
          className="pricing-3 custom-software-div4 pr-20"
          style={{
            backgroundImage:
            "url(" + require("assets/img/nlbg/bg5.jpg") + ")",
        }}
        >
                    <br></br>
                    <br></br>

                    <h2 className="whath2fin2 mx-10"><span>
                    Custom Software Solutions
                    for Media and Entertainment  
                    </span></h2>
                    <br></br>
                    <h5 className="whath4ind mr-30 ml-30"><span>A
                    From book publishing to video streaming, no corner of the industry has been untouched by the digital revolution. That’s why we offer all-in-one, custom solutions to help media and entertainment companies of all shapes and sizes stay ahead of the curve and keep customers engaged.  
                    </span></h5>
                <br></br>
                </div>
                    </Col>
                    <Col md="5" className="">
                    <img 
                    alt="containers"
                    className="sectionimg-fin ml-15 shift-up-40px"
                    src={require("assets/img/nl/industries/media/media-sols.jpg")}
                    />
                    </Col> 
                </Row>

                <div className="small-border">
                      <div className="box">
                      <Row className="shift-down-80px">
        <Col md="4">
        <hr className="h-line3"></hr>
          <img 
                         alt="video streaming"
                         className="customcardimg-ent"
                         src={require("assets/img/nl/industries/media/streaming.svg")}
                         />
          <h3 id="titleblack2">Video streaming</h3>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          
          </Col>
        <Col md="4">
        <hr className="h-line3"></hr>
        <img 
                         alt="music streaming"
                         className="customcardimg-ent"
                         src={require("assets/img/nl/industries/media/music2.svg")}
                         />
         <h3 id="titleblack2">Music streaming</h3>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          </Col>
        <Col md="4">
        <hr className="h-line3"></hr>
        <img 
                         alt="gaming"
                         className="customcardimg-ent"
                         src={require("assets/img/nl/industries/media/coding.svg")}
                         />
          <h3 id="titleblack2">Gaming</h3>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          </Col>
        <Col md="4">
        <hr className="h-line3"></hr>
        <img 
                         alt="social media"
                         className="customcardimg-ent"
                         src={require("assets/img/nl/industries/media/social-media.svg")}
                         />
          <h3 id="titleblack2">Social media</h3>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
        </Col>
        <Col md="4">
        <hr className="h-line3"></hr>
        <img 
                         alt="print and digital publishing"
                         className="customcardimg-ent"
                         src={require("assets/img/nl/industries/media/newspaper.svg")}
                         />
          <h3 id="titleblack2">Print and digital<br></br> publishing</h3>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
        </Col>
        <Col md="4">
        <hr className="h-line3"></hr>
        <img 
                         alt="augmented and virtual reality"
                         className="customcardimg-ent"
                         src={require("assets/img/nl/industries/media/virtual-reality.svg")}
                         />
          <h3 id="titleblack2">Augmented and<br></br> virtual reality</h3>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
        </Col>
        <Col md="4">
        <hr className="h-line3"></hr>
        <img 
                         alt="media and advertising"
                         className="customcardimg-ent"
                         src={require("assets/img/nl/industries/media/bullhorn.svg")}
                         />
          <h3 id="titleblack2">Media and<br></br> advertising</h3>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
        </Col>
        </Row>
                       
                      </div>
                  </div> 

    
              



                  <div className="shift-down-120px">
           
                  <Row className="">
                    <Col md="7">
                    <div
          className="pricing-3 custom-software-div4 pr-20"
          style={{
            backgroundImage:
            "url(" + require("assets/img/nlbg/bg5.jpg") + ")",
        }}
        >
                    
                    <h2 className="whath2fin2"><span>Features</span></h2>
                    <br></br>
                    <h5 className="whath4ind"><span>NEXTLEVEL's web and mobile applications support media streaming, gaming software, content distribution, and more, and our bleeding-edge technologies and cloud services allow you to rest assured that your custom media and entertainment solutions are always on.
                        </span></h5>
                <br></br>
               
                
                </div>
                    </Col>
                    <Col md="5" className="">
                    <img 
                    alt="online shopping"
                    className="sectionimg-sme3"
                    src={require("assets/img/nl/industries/media/media-friends.jpg")}
                    />
                    </Col> 
                </Row>
                <div className="small-border">


                     <Row className="shift-up-50px">
           
                     <Col lg="6" md="6" sm="12" xs="12" className="col1">
             <div className="topcard2">
             <hr className="h-line"></hr>
             <img 
                         alt="maintenance"
                         className="customcardimg3"
                         src={require("assets/img/nlicons/sme/checklist.svg")}
                         />
             
                         
               <h2 id="titleblacksmall" className="align-left2">
               Enhance the customer experience
               </h2>
              <p className="tile-description">
              NEXTLEVEL's high-performing solutions ensure customers keep coming back for more.
              </p>
             </div>
            
           
           </Col>
           <Col md="6" sm="12" xs="12" className="col1">
             <div className="topcard2">
             <hr className="h-line"></hr>

             <img 
                         alt="maintenance"
                         className="customcardimg3"
                         src={require("assets/img/nlicons/sme/design-thinking.svg")}
                         />
                        
               <h2 id="titleblacksmall" className="align-left2">
               Keep your content secure
               </h2>
              <p className="tile-description">
              From paywall and subscription management to anti-piracy measures, ensure your content is accessible and shareable — within the boundaries you define.              
              </p>
              </div>
              
           
           </Col>
           </Row>
           <Row>
           <Col md="6" sm="12" xs="12" className="col1">
             <div className="topcard2">
             <hr className="h-line"></hr>

             <img 
                         alt="maintenance"
                         className="customcardimg3"
                         src={require("assets/img/nlicons/agri/front-end.svg")}
                         />
                        
               <h2 id="titleblacksmall" className="align-left2">
               Monitor engagement
               </h2>
              <p className="tile-description">
              We build robust reporting engines so you can see at a glance which content is performing and how users are engaging, enabling you to make well-informed, data-driven marketing, production, and investment decisions.
              </p>
             </div>
            
           
           </Col>
       
           
           <Col md="6" sm="12" xs="12" className="col1">
       
                         
             <div className="topcard2">
             <hr className="h-line"></hr>

             <img 
                         alt="maintenance"
                         className="customcardimg3"
                         src={require("assets/img/nlicons/agri/knowledge.svg")}
                         />
                        
                         
                        
               <h2 id="titleblacksmall" className="align-left2">
               Meet your audience where they are
               </h2>
              <p className="tile-description">

              Leverage high-powered marketing capabilities and evolving distribution channels to engage users on demand, in their homes and on the go.              </p>
             </div>
           
             
           </Col>
          
            
       </Row>
             </div>
               
</div>
                 

                  

                  <div
          className="pricing-3 custom-software-div3"
          style={{
            backgroundImage:
              "url(" + require("assets/img/nlbg/bg3.jpg") + ")",
          }}
        >

        <Row>
        <Col md="6">
          

          </Col>
          <Col md="6">      
          <div className="pr-40">    
          <h1 className="whath2qa5"><span>Why Choose NEXTLEVEL</span></h1>      
          <ul className="qalist">
              <li><img 
                        alt="website"
                        className="webcardimg mx-4"
                        src={require("assets/img/nlicons/check-circle-white.svg")}
                        /><h5 className="whychoose">Commitment to Transparency</h5>We believe that strong business relationships are built on trust and reliability. 
                        We work in tandem with your team to conceptualize and build the perfect platform, and we are committed to offering fair, transparent pricing at every stage of development. We give you all the information you need to make the right business decisions.</li>
              <br></br>
              <li><img 
                        alt="website"
                        className="webcardimg mx-4"
                        src={require("assets/img/nlicons/check-circle-white.svg")}
                        /><h5 className="whychoose">Comprehensive Experience</h5>Our team has diverse technology stack experience and deep expertise in cloud-based SaaS solutions. Whatever your business’s unique needs, we’re here to create the solutions.</li>
              <br></br>
              <li><img 
                        alt="website"
                        className="webcardimg mx-4"
                        src={require("assets/img/nlicons/check-circle-white.svg")}
                        /><h5 className="whychoose">Lean Business Model</h5>NEXTLEVEL uses a lean, global business model to create value for our customers. This model translates to better savings and higher-quality service than our competitors can offer.</li>
              <br></br>
              <li><img 
                        alt="website"
                        className="webcardimg mx-4"
                        src={require("assets/img/nlicons/check-circle-white.svg")}
                        /><h5 className="whychoose">Customer-Centric Culture</h5>NEXTLEVEL carefully selects each employee to align with our customer-centric culture. We're serious about providing the best quality software and service (at the best price) to add value for our customers.</li>
              <br></br>
<li><img 
                        alt="website"
                        className="webcardimg mx-4"
                        src={require("assets/img/nlicons/check-circle-white.svg")}
                        /><h5 className="whychoose">Capacity to Scale</h5>NEXTLEVEL's team of expert engineers can scale any project. Save overheads, time, and stress with our QA services. </li>
            </ul>
            </div>
            </Col>
        </Row>
        </div>

        </div>



       
        <ContactComponent />
      </div>
     
      <FooterNav />
    </>
    
  );
}

export default Entertainment;
