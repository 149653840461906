import React from "react";

// reactstrap components
import { Row, Col } from "reactstrap";
import FooterNav from "./FooterNav.js";
import MaintenanceHeader from "components/Headers/Custom/MaintenanceHeader.js";
import QAfaq from "./FAQ/QAfaq.js";

// core components
import ColorNavbar from "components/Navbars/ColorNavbar.js";
import ContactComponent from './ContactComponent.js';

import { Link } from 'react-router-dom'

function Maintenance() {
 
  document.documentElement.classList.remove("nav-open");
  // function that is being called on scroll of the page
  const checkScroll = () => {
    // it takes all the elements that have the .add-animation class on them
    const componentPosition = document.getElementsByClassName("add-animation");
    const scrollPosition = window.pageYOffset;
    for (var i = 0; i < componentPosition.length; i++) {
      var rec =
        componentPosition[i].getBoundingClientRect().top + window.scrollY + 100;
      // when the element with the .add-animation is in the scroll view,
      // the .animated class gets added to it, so it creates a nice fade in animation
      if (scrollPosition + window.innerHeight >= rec) {
        componentPosition[i].classList.add("animated");
        // when the element with the .add-animation is not in the scroll view,
        // the .animated class gets removed from it, so it creates a nice fade out animation
      } else if (scrollPosition + window.innerHeight * 0.8 < rec) {
        componentPosition[i].classList.remove("animated");
      }
    }
  };
  React.useEffect(() => {
    document.title = "Software Maintenance"
 });

  React.useEffect(() => {
    document.body.classList.add("presentation-page");
    window.addEventListener("scroll", checkScroll);
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("presentation-page");
      window.removeEventListener("scroll", checkScroll);
    };
  });
  return (
    <>
      <ColorNavbar />
      <MaintenanceHeader />
      <div className="wrapper">
       
     
     
      <div>
           
      <Row>
      
                    <Col md="7">
                      <div className="topcard">
                        <h2 id="titleblack" className="align-left2">
                        Our support and maintenance services are a no-brainer
                        </h2>
                       
                      </div>
                    
                    </Col>
                    <Col md="4">
                      
                  <p className="top-description mx-20">
                  We design a unique program for each customer based on your support and maintenance needs. Whether you're rolling out a custom NEXTLEVEL solution or transitioning from a third-party system, we are here to help. Our support and maintenance experts are pros at solving problems on-time and on-budget.
                  </p>
                 
                    </Col> 
                </Row>

        </div>


        <div className="whitebg">
                <Row>
                    <Col md="6" sm="12">
                    <div
          className="pricing-3 custom-software-div3"
          style={{
            backgroundImage:
              "url(" + require("assets/img/nl/sections/maintenance/server2.jpg") + ")",
          }}
        >
                   
                    <h2 id="custom-titlewhite-2" className="mx-10"><span>Full-Cycle, 24/7 Services</span></h2>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <h5 className="whath4qa mx-30"><span>
                    NEXTLEVEL support services are designed to meet even the most rigorous needs of our customers. Whatever your schedule, we'll be there for you.
                      </span></h5>
                </div>
                    </Col>
                    <Col md="6" className="">
                    <div classname="card-qa">
                    <h2 id="qatitle-1">
                    Comprehensive
                  <br></br>L1/L2/L3 Support
                    </h2>
                  <p className="description-black card-qa">
                  Functionality testing from NEXTLEVEL is designed so your application can run flawlessly. Whatever 
                  your goal, we'll figure out how to achieve it.

                  </p>
                  
              </div>
                    </Col> 
                </Row>

      

                <Row>
                    <Col md="6">
                    <div classname="card-qa">
                    <h2 id="qatitle-4">
                    Proactive & Constant
                    <br></br>Monitoring
                    </h2>
                  <p className="description-black card-qa">
                  Functionality testing from NEXTLEVEL is designed so your application can run flawlessly. Whatever 
                  your goal, we'll figure out how to achieve it.

                  </p>
                  
              </div>
                    </Col>
                    <Col md="6">
                    <div classname="card-qa">
                    <h2 id="qatitle-5">
                    Security
                    <br></br>Audits & Updates
                    </h2>
                  <p className="description-black card-qa">
                  NEXTLEVEL thinks about your users and exactly how they'll interact with your application. 
                    Our exhaustive analysis identifies potential errors, confusing designs, and performance flaws.
                  </p>
                  
              </div>
                    </Col>
                </Row>
                
               
        </div>




        <div className="shift-down-50px">
        
        
                    
                    <div
          className="pricing-3 custom-software-div3"
          style={{
            backgroundImage:
            "url(" + require("assets/img/nlbg/bg1.jpg") + ")",
        }}
        >
            <Row className="">
               <Col md="7"> 
               <br></br>
               <br></br>
    
                    <h2 className="whath2fin2 pl-30"><span>Our Advantages<br></br>and Approach</span></h2>
                    <br></br>
                   
               
                
                
                    </Col>
                    <Col md="5" className="">
                    <img 
                    alt="team"
                    className="sectionimg-sme3"
                    src={require("assets/img/nl/sections/maintenance/check-server3.jpg")}
                    />
                    </Col> 
                    </Row>
                    
                
           
               
                     <div className="small-border">


                     <Row className="">
           
           <Col lg="6" md="6" sm="12" xs="12" className="col1">
   <div className="topcard2">
   <img 
               alt="maintenance"
               className="customcardimg3"
               src={require("assets/img/nlicons/maintenance/team-building.svg")}
               />
   
               
     <h2 id="titleblacksmall" className="align-left2 mx-20">
     We understand your business
     </h2>
    <p className="tile-description2 mx-10">
    We know how much the stability of a software solution impacts your business. Our goal is always to make your software as reliable as possible so that eventually, the only thing you'll need us for is<Link className="custom-link3" to="/product-development"> adding new features</Link>.
    </p>
   </div>
  
 
 </Col>
 <Col md="6" sm="12" xs="12" className="col1">
   <div className="topcard2">
   <img 
               alt="maintenance"
               className="customcardimg3"
               src={require("assets/img/nlicons/maintenance/processing(1).svg")}
               />
              
     <h2 id="titleblacksmall" className="align-left2 mx-20">
     Fast and Effective Responses
     </h2>
    <p className="tile-description2 mx-10">
    Thanks to our global business model and highly qualified personnel, we are here to support your business every day, at any hour. You can always rely on our fast and efficient response to whatever issue you're experiencing.
    </p>
    </div>
    
 
 </Col>
 </Row>
 <Row>
 <Col md="6" sm="12" xs="12" className="col1">
   <div className="topcard2">
   <img 
               alt="maintenance"
               className="customcardimg3"
               src={require("assets/img/nlicons/maintenance/data-security.svg")}
               />
              
     <h2 id="titleblacksmall" className="align-left2 mx-20">
     Established & Proven Procedures
     </h2>
    <p className="tile-description2 mx-10">
    NEXTLEVEL uses an established, effective procedure for reporting and responding to customer issues with our service ticket system. We have a strict prioritization and resolution processes based on our <a className="custom-link4" href="https://en.wikipedia.org/wiki/Service-level_agreement">Service Level Agreement</a>. You will be updated on the progress and outcomes of your service request in real-time.
    </p>
   </div>
  
 
 </Col>

 
 <Col md="6" sm="12" xs="12" className="col1">

               
   <div className="topcard2">
  
   <img 
               alt="maintenance"
               className="customcardimg3"
               src={require("assets/img/nlicons/maintenance/best-seller.svg")}
               />
              
               
              
     <h2 id="titleblacksmall" className="align-left2 mx-20">
     Customer-Friendly Expertise
     </h2>
    <p className="tile-description2 mx-10">
    We genuinely love helping our customers, and this is one thing that really differentiates us from our competitors. NEXTLEVEL specialists are not just committed to ensuring your application runs smoothly; we also want you to have a superior customer experience. </p>
   </div>
 
   
 </Col>

  
</Row>
             </div>
             </div>
</div>




<div className="shift-down-50px">
            
            <Row>
            
                          <Col md="7">
                            <div className="topcard">
                              <h2 id="titleblack" className="align-left2">
                              Taking Over a Third-Party Application
                              </h2>
                             
                            </div>
                          
                          </Col>
                          <Col md="4">
                            
                        <p className="top-description-left mx-20">
                        Sometimes things just don't work out and you need a new solution. NEXTLEVEL can transform projects from other developers and ensure a flawless transition to high-quality software.
                        </p>
                       
                          </Col> 
                      </Row>
      
              </div>
      
              
                     
              <div className="custom-industries">
      
                
              <div className="smaller-border">
                      <div className="box">
                  <Row className="px-20">
                   
                    <Col md="3">
                    <div className="info">
                        <div className="icon">
                        <img 
                               alt="maintenance"
                               className="customcardimg3"
                               src={require("assets/img/nlicons/maintenance/trade.svg")}
                               />
                        </div>
                        <div className="description">
                          <h4 className="info-title-black">Assessment</h4>
                          <p className="description-black">
                          We start each transition process by performing a 360-degree assessment of your application to find out exactly what's wrong. Then, we figure out exactly how to fix it.
                          </p>
                          
                        </div>
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="info">
                      <div className="icon">
                        <img 
                               alt="maintenance"
                               className="customcardimg3"
                               src={require("assets/img/nlicons/maintenance/schedule.svg")}
                               />
                        </div>
                        <div className="description">
                          <h4 className="info-title-black">Planning</h4>
                          <p className="description-black">
                          Next, NEXTLEVEL experts will create a detailed plan for tracking your application's performance bugs, prioritizing improvement goals, and meeting your timeline and budget.
                          </p>
                          
                        </div>
                      </div>
                    </Col>
                    <Col md="3">
                    <div className="info">
                    <div className="icon">
                        <img 
                               alt="maintenance"
                               className="customcardimg3"
                               src={require("assets/img/nlicons/maintenance/exec.svg")}
                               />
                        </div>
                        <div className="description">
                          <h4 className="info-title-black">Execution</h4>
                          <p className="description-black"> 
                          We understand that getting your project back on-track is vital to your business success. That's why NEXTLEVEL support specialists are trained to work as quickly as possible.                          
                        </p>
                        </div>
                      </div>
                    </Col>
                    <Col md="3">
                    <div className="info">
                    <div className="icon">
                        <img 
                               alt="maintenance"
                               className="customcardimg3"
                               src={require("assets/img/nlicons/maintenance/maintenance.svg")}
                               />
                        </div>
                        <div className="description">
                          <h4 className="info-title-black">Maintenance</h4>
                          <p className="description-black">
                          NEXTLEVEL always wants to move your system forward, not focus on the past. After we've found the root cause of your third-party issues, we move quickly towards ensuring the technology is solid. After that, most maintenance will focus on improving features as your business needs evolve.
                          </p>
                          
                        </div>
                      </div>
                    </Col>
                 
                   
                    
                  </Row>
              </div>

        </div>
        </div>
       

        <QAfaq />

       
        <ContactComponent />
      </div>
      <FooterNav />
    </>
  );
}

export default Maintenance;
