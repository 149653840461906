import React from "react";
import { Link } from "react-router-dom";
// nodejs library that concatenates strings
import classnames from "classnames";
// JavaScript plugin that hides or shows a component based on your scroll
import Headroom from "headroom.js";
// reactstrap components
import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  Nav,
  Container,
  UncontrolledTooltip,
} from "reactstrap";
// core components
import { useStateValue } from "../../StateProvider";
import { auth } from "../../firebase/firebaseConfig";

function ColorNavbar() {
  const [{ basket, user }, dispatch] = useStateValue();
  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  const [bodyClick, setBodyClick] = React.useState(false);
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const [logoDisplay, setLogo] = React.useState("hide__img");
  const [logoAltDisplay, setLogoAlt] = React.useState("");



  React.useEffect(() => {
    let headroom = new Headroom(document.getElementById("navbar-main"));
    // initialise
    headroom.init();
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 499 ||
        document.body.scrollTop > 499
      ) {
        setNavbarColor("");
        setLogo("")
        setLogoAlt("hide__img")

      } else if (
        document.documentElement.scrollTop < 500 ||
        document.body.scrollTop < 500
      ) {
        setNavbarColor("navbar-transparent");
        setLogo("hide__img")
        setLogoAlt("")
      }
    };
    window.addEventListener("scroll", updateNavbarColor);
    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });

  return (
    <>
      {bodyClick ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setBodyClick(false);
            setCollapseOpen(false);
          
          }}
          
        />
      ) : null}
      <Navbar
        className={classnames("fixed-top", navbarColor)}
        expand="lg"
        id="navbar-main"
      >
        <Container>
          <div className="navbar-translate">
            <NavbarBrand id="navbar-brand" to="/" tag={Link}>
            <img
                className={logoDisplay}
                id="nav-logo"
                alt="..."
                width="100px"
                src={require("assets/img/nl/300dpi_Logo_Colour.png")}
              />
              <img
                className={logoAltDisplay}
                alt="..."
                width="60px"
                src={require("assets/img/nl/300dpi_Icon_Dark.png")}
              />
            </NavbarBrand>
            <UncontrolledTooltip placement="bottom" target="navbar-brand">
              NEXTLEVEL Technology
            </UncontrolledTooltip>
            <button
              className="navbar-toggler"
              id="navigation"
              type="button"
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setBodyClick(true);
                setCollapseOpen(true);
              }}
              
            >
              <span className="navbar-toggler-bar bar1" />
              <span className="navbar-toggler-bar bar2" />
              <span className="navbar-toggler-bar bar3" />
            </button>
          </div>
          
          <Collapse navbar isOpen={collapseOpen}>
            <Nav className="ml-auto" navbar>
              <UncontrolledDropdown nav inNavbar
              >
                <DropdownToggle className="mr-2 menu-item" color="default" caret nav>
                  Services
                </DropdownToggle>
                <DropdownMenu className="dropdown-info" right>
                  <DropdownItem to="/custom-software" tag={Link}>
                  <i className="nc-icon nc-atom" />
                  Custom Software Development
                  </DropdownItem>
                  <DropdownItem to="/mobile-software" tag={Link}>
                  <i className="nc-icon nc-mobile" />
                  Mobile Software Development
                  </DropdownItem>
                  <DropdownItem to="/web" tag={Link}>
                  <i className="nc-icon nc-album-2" />
                  Website Design & Development
                  </DropdownItem>
                  <DropdownItem to="/digital-marketing" tag={Link}>
                  <i className="nc-icon nc-send" />
                  Digital Marketing
                  </DropdownItem>
                  <DropdownItem to="/seo-info" tag={Link}>
                  <i className="nc-icon nc-zoom-split" />
                  Search Engine Optimisation
                  </DropdownItem>
                  <DropdownItem to="/product-development" tag={Link}>
                  <i className="nc-icon nc-spaceship" />
                  Software Product Development
                  </DropdownItem>
                  <DropdownItem to="/business-analysis" tag={Link}>
                  <i className="nc-icon nc-chart-pie-36" />
                  Business Analysis
                  </DropdownItem>
                  <DropdownItem to="/software-modernisation" tag={Link}>
                  <i className="nc-icon nc-refresh-69" />
                  Software Modernisation
                  </DropdownItem>
                  <DropdownItem to="/quality-assurance" tag={Link}>
                  <i className="nc-icon nc-check-2" />
                  Quality Assurance
                  </DropdownItem>
                  <DropdownItem to="/leveraging-resources" tag={Link}>
                  <i className="nc-icon nc-single-02" />
                  Dedicated Development Team
                  </DropdownItem>
                  <DropdownItem to="/software-maintenance" tag={Link}>
                  <i className="nc-icon nc-settings" />
                  Software Maintenance 
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle className="mr-2 menu-item" color="default" caret nav>
                  Industries
                </DropdownToggle>
                <DropdownMenu className="dropdown-success" right>
                  <DropdownItem to="/agriculture" tag={Link}>
                    <i className="nc-icon nc-sun-fog-29" />
                    Agriculture
                  </DropdownItem>
                  <DropdownItem to="/finance&accounting" tag={Link}>
                    <i className="nc-icon nc-bank" />
                    Finance 
                  </DropdownItem>
                  <DropdownItem to="/healthcare" tag={Link}>
                    <i className="nc-icon nc-ambulance" />
                    Healthcare 
                  </DropdownItem>
                  <DropdownItem to="/smes" tag={Link}>
                    <i className="nc-icon nc-money-coins" />
                    Small & Medium-Sized Enterprises
                  </DropdownItem>
                  <DropdownItem to="/ecommerce" tag={Link}>
                    <i className="nc-icon nc-credit-card" />
                    Ecommerce
                  </DropdownItem>
                  <DropdownItem to="/wholesale&retail" tag={Link}>
                    <i className="nc-icon nc-cart-simple" />
                    Wholesale & Retail
                  </DropdownItem>
                  <DropdownItem to="/education" tag={Link}>
                    <i className="nc-icon nc-ruler-pencil" />
                    Education
                  </DropdownItem>
                  <DropdownItem to="/transport&logistics" tag={Link}>
                    <i className="nc-icon nc-delivery-fast" />
                    Transport & Logistics
                  </DropdownItem>
                  <DropdownItem to="/media&entertainment" tag={Link}>
                    <i className="nc-icon nc-button-play" />
                    Media & Entertainment
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle className="menu-item" color="default" caret nav>
                  Company
                </DropdownToggle>
                <DropdownMenu className="dropdown-danger" right>
                  <DropdownItem to="/about-us" tag={Link}>
                    <i className="nc-icon nc-bank" />
                    About-us
                  </DropdownItem>
                  <DropdownItem to="/about-us#Compliance" tag={Link}>
                    <i className="nc-icon nc-paper" />
                    Compliance Standards
                  </DropdownItem>
                 
                </DropdownMenu>
              </UncontrolledDropdown>
             
              <NavItem>
                <Button href="tel:+27671647295" className="btn-magnify btn-success">
                <i className="nc-icon nc-mobile success" color="success" /> 
                <a className="callbtn">Call</a>
                </Button>
                <Link to="/contact">
                <Button
                  className="btn-magnify btn"
                  color="info"
                  
                  target="_blank"
                >
                  <i className="nc-icon nc-email-85" /> Contact Us
                </Button>
                </Link>
              </NavItem>
            </Nav>
          </Collapse>
          <Link className="basket-icon-div" to="/shopping-basket">

            <div className="">
        <img 
          src={require("assets/img/nlicons/shopping-basket.png")}
          className="basket-icon"
          alt="basket-icon"
          id="basket-ico"
          />
          <span className="header__optionLineTwo header__basketCount">
              {basket?.length}
            </span>
          <UncontrolledTooltip placement="bottom" target="basket-ico">
              Shopping Basket
            </UncontrolledTooltip>
            </div>
            </Link>

        </Container>
       
      </Navbar>
    </>
  );
}

export default ColorNavbar;
