import React from "react";

// reactstrap components
import { Card, CardBody, Row, Col, CardTitle } from "reactstrap";
import FooterNav from "./FooterNav.js";
import LeveragingHeader from "components/Headers/Custom/LeveragingHeader.js";
import Carousel2 from "../sections-sections/Carousel.js";
import Levfaq from "./FAQ/Levfaq.js";
import ContactComponent from './ContactComponent.js';

// core components
import ColorNavbar from "components/Navbars/ColorNavbar.js";
 

function LeveragingResources() {
  
  document.documentElement.classList.remove("nav-open");
  // function that is being called on scroll of the page
  const checkScroll = () => {
    // it takes all the elements that have the .add-animation class on them
    const componentPosition = document.getElementsByClassName("add-animation");
    const scrollPosition = window.pageYOffset;
    for (var i = 0; i < componentPosition.length; i++) {
      var rec =
        componentPosition[i].getBoundingClientRect().top + window.scrollY + 100;
      // when the element with the .add-animation is in the scroll view,
      // the .animated class gets added to it, so it creates a nice fade in animation
      if (scrollPosition + window.innerHeight >= rec) {
        componentPosition[i].classList.add("animated");
        // when the element with the .add-animation is not in the scroll view,
        // the .animated class gets removed from it, so it creates a nice fade out animation
      } else if (scrollPosition + window.innerHeight * 0.8 < rec) {
        componentPosition[i].classList.remove("animated");
      }
    }
  };
  React.useEffect(() => {
    document.title = "Dedicated Development Team"
 });

  React.useEffect(() => {
    document.body.classList.add("presentation-page");
    window.addEventListener("scroll", checkScroll);
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("presentation-page");
      window.removeEventListener("scroll", checkScroll);
    };
  });
  return (
    <>
      <ColorNavbar />
      <LeveragingHeader />
      <div className="wrapper">
       
     
     
      <div>
           
      <Row>
      
                    <Col md="7">
                      <div className="topcard">
                        <h2 id="titleblack" className="align-left2">
                        Hire a dedicated team of NEXTLEVEL software experts
                        </h2>
                       
                      </div>
                    
                    </Col>
                    <Col md="4">
                      
                  <p className="top-description">
                  NEXTLEVEL has business analysts, project managers, QA experts, and software engineers 
                  trained to build software on-budget and on-time. When you hire a Dedicated Development team, you'll 
                  have the power to build a software solution tailored to your unique business needs.
                  </p>
                 
                    </Col> 
                </Row>

        </div>





        <div
          className="pricing-3 custom-software-div3"
          style={{
            backgroundImage:
              "url(" + require("assets/img/nl/sections/custom/red-blue.jpg") + ")",
          }}
        >
        
        <div className="my-20 padding-left-20px">
        
        <Row>
        <Col>
        <div className="custom-software-div">
<h2 id="custom-titlewhite" className="align-left2">
Why should you hire a Team of
Dedicated Software Developers?
                        </h2>
                        <br></br>
                        <h5 className="custom-whitetext-custom align-lefti mx-20">
                        For those of our customers desiring complete control of every aspect of the development process, NEXTLEVEL offers the Dedicated Software Development Team service. 
                        </h5>
        </div>

        </Col>
               
        <Row className="padding-health">
        <Col className="col1">
         <div className="card-big-shadow">
                <Card
                  className="customcard1"
                >
                  <CardBody>
                  <div className="customcardicon">
                  <img 
                         alt="developer team"
                         className="customcardimg"
                         src={require("assets/img/nlicons/leveraging/team-building.svg")}
                         />

                    </div>
                    <br></br>

                      
                    <CardTitle tag="h5">
                      
                    <span>
                        <h5 className="webcardtitle">
                        Get immediate
access to expert
developers
                      </h5>
                        </span>
                  
                      
                    </CardTitle>
                    <br></br>
                    <p className="card-description">
                    Instead of hiring a recruiting agency and spending extra time and money, cut out the middle man and get immediate access to expert developers with NEXTLEVEL.
                    </p>
                  </CardBody>
                </Card>
              </div>
              </Col>
              <Col className="custom-mr col1">
               <div className="card-big-shadow">
               <Card
                  className="customcard1"
                >
                  <CardBody>
                  <div className="customcardicon">
                  <img 
                         alt="streamline budget"
                         className="customcardimg"
                         src={require("assets/img/nlicons/leveraging/return.svg")}
                         />

                    </div>
                    <br></br>

                      
                    <CardTitle tag="h5">
                      
                    <span>
                        <h5 className="webcardtitle">
                        Streamline your
budget and save
money
                      </h5>
                        </span>
                  
                      
                    </CardTitle>
                    <br></br>
                    <p className="card-description">
                    When you hire a Dedicated Development team, you control the size of a team, the timeline for engagement, and the overall budget. Most importantly, the only thing you pay for is our service.                    
                    
                    </p>
                  </CardBody>
                </Card>
              </div>
             
        </Col>
        
        </Row>
       
        <Row>
         
        
        <Col className="custom-mr col1">
        <div className="card-big-shadow">
        <Card
                  className="customcard1"
                >
                  <CardBody>
                  <div className="customcardicon">
                  <img 
                         alt="maximise"
                         className="customcardimg"
                         src={require("assets/img/nlicons/leveraging/speedometer.svg")}
                         />

                    </div>
                    <br></br>

                      
                    <CardTitle tag="h5">
                      
                    <span>
                        <h5 className="webcardtitle">
                        Maximise
your in-house
resources                      </h5>
                        </span>
                  
                      
                    </CardTitle>
                    <br></br>
                    <p className="card-description">
                    NEXTLEVEL will leverage your in-house resources, including your existing technology and staff, to maximize the value of your software development project.                    
                    
                    </p>
                  </CardBody>
                </Card>
                
              </div>
              </Col>
              <Col className="custom-mr col1 col-md-offset-3">
               <div className="card-big-shadow">
                <Card
                  className="customcard1"
                >
                  <CardBody>
                  <div className="customcardicon">
                  <img 
                         alt="technologies"
                         className="customcardimg"
                         src={require("assets/img/nlicons/leveraging/biology.svg")}
                         />

                    </div>
                    <br></br>

                      
                    <CardTitle tag="h5">
                      
                    <span>
                        <h5 className="webcardtitle">
                        Gain access
to all major
technologies                      </h5>
                        </span>
                  
                      
                    </CardTitle>
                    <br></br>
                    <p className="card-description">
                    NEXTLEVEL developers are trained in all major technologies to give you the most flexibility. We match our engineers' skillsets with your software needs to ensure you're getting the best team possible for your project.
                    </p>
                  </CardBody>
                </Card>
              </div>
        </Col>
        </Row>
        <Col>
          <img 
                        alt="virtual reality"
                        className="main-layout-img2"
                        src={require("assets/img/nl/sections/vr-goggles.jpg")}
                        />
          </Col>
</Row>
<br></br>
<br></br>
<br></br>
<br></br>
<br></br>
<br></br>

        </div>
        
        </div>  



  
        
        <div>
           
      <Row>
      
                    <Col md="7">
                      <div className="topcard">
                        <h2 id="titleblack" className="align-left2">
                        Main Technologies that we work with
                        </h2>
                       
                      </div>
                    
                    </Col>
                   
                </Row>

        <Carousel2 />    

        </div>


        <div>
          <Row className="margin-botttom-30px">
      
      <Col md="7">
        <div className="topcard">
          <h2 id="titleblack" className="align-left2">
          Advantages of NEXTLEVEL Dedicated Development Teams 
          </h2>
         
        </div>
      
      </Col>
     <br></br>
  </Row>
          </div>
        <div>
        <div className="small-border">
                      <div className="box">
        <Row className="margin-5">
          <Col md="4">
          <img 
                         alt="top engineers"
                         className="customcardimg3"
                         src={require("assets/img/nlicons/leveraging/team.svg")}
                         />
          <h3 id="titleblack2">Top-Talent Engineers</h3>
          <br></br>
          <p className="top-description2">
          We use a rigorous hiring and training system to ensure that our engineers are some of the best in the industry. 
          Every NEXTLEVEL engineer has expertise with at least two different technology stacks and versatile development 
          experience to meet your unique business needs.
          </p>
          </Col>
          <Col md="4">
          <img 
                         alt="transparent pricing"
                         className="customcardimg3"
                         src={require("assets/img/nlicons/leveraging/transparency.svg")}
                         />
          <h3 id="titleblack2">Transparent Pricing and Timelines</h3>
          <br></br>
          <p className="top-description2">
          NEXTLEVEL offers fair, transparent pricing at every stage of your project. And if your business needs change, 
          our Dedicated Development teams are trained to adjust their performance.
          </p>
          </Col>
          <Col md="4">
          <img 
                         alt="rapid ramp up"
                         className="customcardimg3"
                         src={require("assets/img/nlicons/leveraging/link.svg")}
                         />
          <h3 id="titleblack2">Rapid Ramp-Up Times</h3>
          <br></br>
          <p className="top-description2">
          NEXTLEVEL has the capacity to ramp-up your project with unparalleled speed. We'll select your Dedicated 
          Development team of expert engineers quickly and effectively so you can focus on your business.
          </p>
          </Col>
        </Row>
        <Row className="margin-5">
        <Col md="4">
          <img 
                         alt="efficient service"
                         className="customcardimg3"
                         src={require("assets/img/nlicons/leveraging/justice-scale.svg")}
                         />
          <h3 id="titleblack2">Efficient and Accessible Service</h3>
          <br></br>
          <p className="top-description2">
          Our unique global business model gives you the highest value for the lowest cost. NEXTLEVEL is headquartered in Cape Town, South Africa with Asian development centers, so we're available whenever you need us and working around the clock to deliver your project.
          </p>
          </Col>
        <Col md="4">
        <img 
                         alt="customer focused"
                         className="customcardimg3"
                         src={require("assets/img/nlicons/leveraging/target.svg")}
                         />
          <h3 id="titleblack2">Customer-Focused Culture</h3>
          <br></br>
          <p className="top-description2">
          At NEXTLEVEL, we've cultivated a unique company culture that's focused on customers. All of our employees share common passion for helping your business thrive by 
          delivering high-quality software. When you hire a NEXTLEVEL team, you're gaining a true technology partner.
          </p>
        </Col>
        <Col md="4">
        <img 
                         alt="versatile team"
                         className="customcardimg3"
                         src={require("assets/img/nlicons/leveraging/network.svg")}
                         />
          <h3 id="titleblack2">Versatile Professional Teams</h3>
          <br></br>
          <p className="top-description2">
          Our teams leverage software development efforts in backend REST API in PHP, Python, NodeJS; Single-page apps in Javascript, React, 
          Enterprise apps in Java and Spring, .NET, AWS, GCP, Azure cloud automation, Docker, MongoDB, Heroku and DevOps using Python and Ansible.
          </p>
        </Col>
        </Row>
        </div>
      </div>
</div> 
        <Levfaq />

       
        <ContactComponent />
      </div>
     
      <FooterNav />
    </>
  );
}

export default LeveragingResources;
