import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import ColorNavbar from "components/Navbars/ColorNavbar"; 
import ContactComponent from '../services/ContactComponent.js';

import WebHeaderTemplate from "./webHeaders/WebHeaderTemplate.js";
import FooterNav from "../services/FooterNav.js";
import {Link} from 'react-router-dom';
import Product from "../Product";

function WebSocialMedia() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
    };
  });
  return (
    <>
     <ColorNavbar />
      <WebHeaderTemplate navTitle="Social Media & Email" Title="Social Media & Email Marketing" />

      <div className="wrapper">
        {/* ********* PRICING 3 ********* */}
        <div
          className="pricing-3 section-bg-img"
          style={{
            backgroundImage:
            "url(" + require("assets/img/nl/sections/custom/red-blue.jpg") + ")",
          }}
        >
           <Container>
            <Row>
              <Col lg="12" md="12" sm="12" xs="12">
                <h1 id="titlewhite3" className="mt--30">
                        Facebook Marketing
                        </h1>
                </Col>
                <Card className="card-pricing w-150">
                <CardBody>
                <h5 className="blacktext-fb">


                Facebook Ads Campaigns are great alternative to Google Ads an excellent drive additional targeted paid traffic to your website. We begin by performing keyword research in your industry and product and then develop an optimized Facebook campaign to maximise conversions while minimising expenditure. After the initial setup fee, a small monthly maintenance fee is applied in addition to 10% of the monthly allocated budget, this fee covers the upkeep and reporting of your ads to keep them running at optimal performance. Our Facebook Ad consultants will advise on your monthly budget expenditure.

</h5>
  
                </CardBody>
              </Card>
               
              </Row>
          <Row>
            <Col md="4" className="col1">
              <Card className="card-pricing w-150">
              <h6 className="card-category">Budget</h6>
              
                <CardBody>
                <img 
                        alt="website"
                        className="webcardimg mx-4"
                        src={require("assets/img/nlicons/web/multiple-11.svg")}
                        />
                <div className="webcardicon my-5">
                      
                        <span>
                        <h5 className="webcardtitle">
                        Starter - Facebook Ads Campaign
                      </h5>
                        </span>
                  
                      </div>
                  
                  <CardTitle tag="h4"><strong>R2499 Setup Fee</strong></CardTitle>
                  <CardTitle tag="h5"><strong>+R199 Per Hour</strong></CardTitle>
                  <CardTitle tag="h5"><strong>+10%<span> Monthly Budget</span></strong></CardTitle>
 
                  <p className="servicep">
                  Our most affordable campaign.
                  This campaign is ideal for a basic campaign targeting a niche industry with a single product or landing page.
                  Basic keyword research, up to 15 keywords.
                  
                  <br></br>

                  </p>
                  <br></br>

                  <Product 
                  id="Starter-FacebookAdsCampaign"
                  title="Starter - Facebook Ads Campaign"
                  price={2499.0}
                  rating={5}
                  image={require("assets/img/nlicons/web/multiple-11.svg")}
                  />   
                 <Link to="/contact">
                  <Button
                    className="btn-round card-link web-card-btn"
                    color="success"
                    href="" 
                  >
                    Get Started
                  </Button>
                  </Link>
                </CardBody>
              </Card>
            </Col>
            <Col md="4" className="col1">
              <Card className="card-pricing w-150">
              <h6 className="card-category">Standard</h6>
              
                <CardBody>
                <img 
                        alt="website"
                        className="webcardimg mx-4"
                        src={require("assets/img/nlicons/web/multiple-11.svg")}
                        />
                <div className="webcardicon my-5">
                        <span>
                        <h5 className="webcardtitle">
                        Business - Facebook Ads Campaign
                      </h5>
                        </span>
                  
                      </div>
                  <CardTitle tag="h4"><strong>R3999 Setup Fee</strong></CardTitle>
                  <CardTitle tag="h5"><strong>+R299 Per Hour</strong></CardTitle>
                  <CardTitle tag="h5"><strong>+10%<span> Monthly Budget</span></strong></CardTitle>

                  <p className="servicep">
                  Our most popular campaign.
                  Best suited for moderately sized campaigns targeting a common industry with a couple products or landing pages.
                  Moderate keyword research, up to 25 keywords.

 

                  </p>
                  <Product 
                  id="Business-FacebookAdsCampaign"
                  title="Business - Facebook Ads Campaign"
                  price={3999.0}
                  rating={5}
                  image={require("assets/img/nlicons/web/multiple-11.svg")}
                  />   
                 <Link to="/contact">
                  <Button
                    className="btn-round card-link web-card-btn"
                    color="success"
                    href="" 
                  >
                    Get Started
                  </Button>
                  </Link>
                </CardBody>
              </Card>
            </Col>
            <Col md="4" className="col1">
              <Card className="card-pricing w-150">
              <h6 className="card-category">Premium</h6>
                <CardBody>
                <img 
                        alt="website"
                        className="webcardimg mx-4"
                        src={require("assets/img/nlicons/web/multiple-11.svg")}
                        />
                <div className="webcardicon my-5">
                        <span>
                        <h5 className="webcardtitle">
                        Professional - Facebook Ads Campaign
                      </h5>
                        </span>
                  
                      </div>

                      <CardTitle tag="h4"><strong>R9999 Setup Fee</strong></CardTitle>
                  <CardTitle tag="h5"><strong>+R499 Per Hour</strong></CardTitle>
                  <CardTitle tag="h5"><strong>+10%<span> Monthly Budget</span></strong></CardTitle>

                  <p className="servicep">
                  Comprehensive Campaign.
                  Advanced campaign management targeting a highly competitive industry with multiple products or landing pages.
                  Advanced keyword research, up to 75 keywords.

 
  
                  </p>
                  <Product 
                  id="Professional-FacebookAdsCampaign"
                  title="Professional - Facebook Ads Campaign"
                  price={9999.0}
                  rating={5}
                  image={require("assets/img/nlicons/web/multiple-11.svg")}
                  />   
                 <Link to="/contact">
                  <Button
                    className="btn-round card-link web-card-btn"
                    color="success"
                    href="" 
                  >
                    Get Started
                  </Button>
                  </Link>
                </CardBody>
              </Card>
            </Col>
            
            
          </Row>



        
          <Row>
              <Col lg="12" md="12" sm="12" xs="12">
                <h1 id="titlewhite2">
                        Email & Social Media 
                        </h1>
                </Col>
                <Card className="card-pricing w-150">
                <CardBody>
                <h5 className="blacktext-fb">


Email marketing may be old school, but it’s still an extremely effective marketing tool. We primarily use Mailchimp for email marketing as it provides powerful analytical tools and is cost effective. We charge and initial setup fee and then per hour for email template design.
<br></br><br></br>
Our Social Media Management services include both the initial setup of social media accounts and continuous account management.
</h5>
  
                </CardBody>
              </Card>
               
                <br />
                
              </Row>
              <Row>
            <Col md="4" className="col1">
              <Card className="card-pricing w-150">
              <h6 className="card-category">Email Marketing</h6>
              <br></br>
                <CardBody>
                <img 
                        alt="website"
                        className="webcardimg mx-4"
                        src={require("assets/img/nlicons/web/multiple-11.svg")}
                        />
                <div className="webcardicon my-5">
                        <span>
                        <h5 className="webcardtitle">
                        Mailchimp
                      </h5>
                        </span>
                        <br></br>

                      </div>
                  <CardTitle tag="h3"><strong>R799 Setup Fee</strong></CardTitle>
                  <CardTitle tag="h4"><strong>R399 Per Hour<br></br>For Template Design</strong>+</CardTitle>
                  <ul className="align-left">
                    <li>
   <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
Account Creation                    </li>
                    <li>
   <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
Profile Setup                    </li>
                    <li>
   <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
Website Integration                    </li>
                    <li>
   <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
Database Import                    </li>
                    <li>
   <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
Domain Linking                    </li>
                  
                    <br></br>

                  </ul>
                  <br></br>
                  <br></br>
                  <br></br>
                  <Product 
                  id="Mailchimp"
                  title="Mailchimp"
                  price={799.0}
                  rating={5}
                  image={require("assets/img/nlicons/web/multiple-11.svg")}
                  />   
                 <Link to="/contact">
                  <Button
                    className="btn-round card-link web-card-btn"
                    color="success"
                    href="" 
                  >
                    Get Started
                  </Button>
                  </Link>
                </CardBody>
              </Card>
            </Col>
            <Col md="4" className="col1">
              <Card className="card-pricing w-150">
              <h6 className="card-category">Social Media Management</h6>
              
                <CardBody>
                <img 
                        alt="website"
                        className="webcardimg mx-4"
                        src={require("assets/img/nlicons/web/multiple-11.svg")}
                        />
                <div className="webcardicon my-5">
                        <span>
                        <h5 className="webcardtitle">
                        Account Setup
                      </h5>
                        </span>
                  
                      </div>
                  <CardTitle tag="h3"><strong>R399 Per Platform</strong></CardTitle>
                  <ul className="align-left">
                    <li>
   <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
Facebook                    </li>
                    <li>
   <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
Twitter                    </li>
                    <li>
   <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
LinkedIn                    </li>
                    <li>
   <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
Instagram                    </li>
                    <li>
   <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
Youtube                    </li>
                    <li>
   <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
Google + / My Business                    </li>
                   
                    <br></br>
                    <li>
  
<strong>Includes:  </strong>                  </li>
                    <li>
   <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
Account Creation & Verification              </li>
                    
                    <li>
 <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
Profile Population                    </li>
                    <li>
 <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
Profile Picture & Banner Design                    </li>
                    
                    
                  </ul>
                  <br></br>
                  <br></br>
                  <br></br>
                  <Product 
                  id="AccountSetup"
                  title="Account Setup"
                  price={399.0}
                  rating={5}
                  image={require("assets/img/nlicons/web/multiple-11.svg")}
                  />   
                 <Link to="/contact">
                  <Button
                    className="btn-round card-link web-card-btn"
                    color="success"
                    href="" 
                  >
                    Get Started
                  </Button>
                  </Link>
                </CardBody>
              </Card>
            </Col>
            <Col md="4" className="col1">
              <Card className="card-pricing w-150">
              <h6 className="card-category">Social Media Management</h6>
                <CardBody>
                <img 
                        alt="website"
                        className="webcardimg mx-4"
                        src={require("assets/img/nlicons/web/multiple-11.svg")}
                        />
                <div className="webcardicon my-5">
                        <span>
                        <h5 className="webcardtitle">
                        Account Management
                      </h5>
                        </span>
                  
                      </div>
                  <CardTitle tag="h3"><strong>R599 PM</strong></CardTitle>
                  <CardTitle tag="h4"><strong>+R79 Per Platform</strong></CardTitle>
                  <ul className="align-left">
                  <li>
   <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
Facebook                    </li>
                    <li>
   <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
Twitter                    </li>
                    <li>
   <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
LinkedIn                    </li>
                    <li>
   <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
Instagram                    </li>
                    <li>
   <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
Youtube                    </li>
                    <li>
   <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
Google + / My Business                    </li>
<br></br>

<li>
  
<strong>Includes:  </strong>                  </li>
                    <li>
   <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
Weekly Posting              </li>
                    
                    <li>
 <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
Hootsuite Integration                    </li>
                    <li>
 <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
Community Management                    </li>
                  </ul>
                  
                  <br></br>
                  <br></br>
                  <br></br>
                  <div className="subscribe__div">
                  <a className="subscribe__btn" href="https://www.payfast.co.za/eng/process?cmd=_paynow&amp;receiver=17143900&amp;item_name=Account+Management&amp;item_description=Social+Media+Account+Management&amp;amount=599.00&amp;return_url=https%3A%2F%2Fnextleveltechnology.org%2Fsuccess&amp;cancel_url=https%3A%2F%2Fnextleveltechnology.org%2Fsocial-media&amp;cycles=0&amp;frequency=3&amp;m_payment_id=pay_now_17143900&amp;subscription_type=1"><img src="https://www.payfast.co.za/images/buttons/dark-large-subscribe.png" width="210" height="59" alt="Subscribe" title="Subscribe Now with PayFast" /></a>
                  </div>
                 <Link to="/contact">
                  <Button
                    className="btn-round card-link web-card-btn"
                    color="success"
                    href="" 
                  >
                    Get Started
                  </Button>
                  </Link>
                </CardBody>
              </Card>
            </Col>
            
            
          </Row>


          </Container>
        </div>
        {/* ********* END PRICING 3 ********* */}
        </div>







      <div className="wrapper">
        
        <ContactComponent />
      </div>
      <FooterNav />
    </>
  );
}

export default WebSocialMedia;
