import React from "react";

// reactstrap components
import { Row, Col} from "reactstrap";
import FooterNav from "./FooterNav.js";
import ModernisationHeader from "components/Headers/Custom/ModernisationHeader.js";
import { Link } from 'react-router-dom';
import Legacyfaq from "./FAQ/Legacyfaq.js";

// core components
import ColorNavbar from "components/Navbars/ColorNavbar.js";
import ContactComponent from './ContactComponent.js';


function LegacySoftware() {
  
  document.documentElement.classList.remove("nav-open");
  // function that is being called on scroll of the page
  const checkScroll = () => {
    // it takes all the elements that have the .add-animation class on them
    const componentPosition = document.getElementsByClassName("add-animation");
    const scrollPosition = window.pageYOffset;
    for (var i = 0; i < componentPosition.length; i++) {
      var rec =
        componentPosition[i].getBoundingClientRect().top + window.scrollY + 100;
      // when the element with the .add-animation is in the scroll view,
      // the .animated class gets added to it, so it creates a nice fade in animation
      if (scrollPosition + window.innerHeight >= rec) {
        componentPosition[i].classList.add("animated");
        // when the element with the .add-animation is not in the scroll view,
        // the .animated class gets removed from it, so it creates a nice fade out animation
      } else if (scrollPosition + window.innerHeight * 0.8 < rec) {
        componentPosition[i].classList.remove("animated");
      }
    }
  };
  React.useEffect(() => {
    document.title = "Legacy Software Replacement"
 });

  React.useEffect(() => {
    document.body.classList.add("presentation-page");
    window.addEventListener("scroll", checkScroll);
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("presentation-page");
      window.removeEventListener("scroll", checkScroll);
    };
  });
  return (
    <>
      <ColorNavbar />
      <ModernisationHeader />
      <div className="wrapper">
       
     
     
      <div>
           
      <Row>
      
                    <Col md="7">
                      <div className="topcard">
                        <h2 id="titleblack" className="align-left2">
                        Optimizing your business with the most current technology
                        </h2>
                       
                      </div>
                    
                    </Col>
                    <Col md="4">
                      
                  <p className="top-description">
                  At NEXTLEVEL, our world-class engineers are passionate about solving complex 
                  problems quickly and effectively. Empowering our customers’ business growth 
                  is our number-one priority, and we use bleeding-edge technology to develop 
                  world-class software and provide fantastic customer experiences. 
                  </p>
                 
                    </Col> 
                </Row>

        </div>
        <Row className="margin-top-80px">
        
        <Col md="7">
                    <div
          className="pricing-3 custom-software-div3"
          style={{
            backgroundImage:
              "url(" + require("assets/img/nlbg/bg1.jpg") + ")",
          }}
        >
          <br></br>
                   
                    <h2 id="custom-titlewhite-2" className="align-left mx-20 shift-down-30px shift-up-100pxmin padding-top-20"><span>Drawbacks<br></br> of Legacy Software</span></h2>
                    <br></br>
                    
                    <br></br>

                </div>
                    </Col>
          
                   
                    <Col md="5" className="minus-margin-left legacyimg shift-down-10px">
                    <img 
                    alt="cape town"
                    className="sectionqaimg"
                    src={require("assets/img/nl/crashed-rocket2.jpg")}
                    />
                    </Col> 
                </Row>
        <div className="shift-down-50px">
        <Row className="mx-20">
          <Col md="4">
          <img 
                         alt="maintenance"
                         className="customcardimg3"
                         src={require("assets/img/nlicons/modernisation/money-bag.svg")}
                         />
          <h3 id="titleblack2">Cost of Maintenance</h3>
          <br></br>
          <p className="top-description2">
          Like a beat-up old car that lands in the shop every other month, legacy software systems require 
          significant maintenance just to keep them running. At a certain point, the cost (in both money and time) 
          of maintaining the old system starts to outweigh the costs of starting over.
          </p>
          </Col>
          <Col md="4">
          <img 
                         alt="maintenance"
                         className="customcardimg3"
                         src={require("assets/img/nlicons/modernisation/caution.svg")}
                         />
          <h3 id="titleblack2">Incompatibility</h3>
          <br></br>
          <p className="top-description2">
          Many older software systems are built on technology that is no longer widely used today — 
          and by developers who are no longer in business. As such, businesses often find that, when 
          they do want to integrate their legacy systems with newer components and systems, the old 
          technology is incompatible with the new. The workarounds required to achieve the desired 
          capabilities tend to result in fragile patchwork jobs that aren’t very efficient or resilient.
          </p>
          </Col>
          <Col md="4">
          <img 
                         alt="maintenance"
                         className="customcardimg3"
                         src={require("assets/img/nlicons/modernisation/brick-wall.svg")}
                         />
          <h3 id="titleblack2">Inflexibility</h3>
          <br></br>
          <p className="top-description2">
          While your old system may have suited your organization’s needs perfectly when it was first installed, 
          as your company has grown, it’s struggled to keep up. Legacy software is seldom built with enough 
          flexibility and configurability to keep up with shifting needs — at least not without heavy investments 
          and plenty of custom coding.
          </p>
          </Col>
          
        </Row>
        <Row>
        
        <Col md="4">
        <img 
                         alt="maintenance"
                         className="customcardimg3"
                         src={require("assets/img/nlicons/modernisation/padlock.svg")}
                         />
          <h3 id="titleblack2">Security and<br></br> Compliance Gaps </h3>
          <br></br>
          <p className="top-description2">
          As data security needs have evolved and compliance measures have increased, legacy systems have become more 
          and more risky — increasingly vulnerable to data leaks and security slipups.
          </p>
        </Col>
        <Col md="4">
        <img 
                         alt="maintenance"
                         className="customcardimg3"
                         src={require("assets/img/nlicons/modernisation/personality.svg")}
                         />
          <h3 id="titleblack2">Customer & Employee Dissatisfaction </h3>
          <br></br>
          <p className="top-description2">
          When legacy systems slow down internal processes, customers start to see delays in front-end interactions — and maybe 
          even errors in fulfillment, communication, or billing. The result is weakened loyalty and negative buzz around your 
          business. And internally, when employees have to spend significant time coaxing the software, they have less time and 
          energy to spend on their “real” work, and they’re less likely to be satisfied in their jobs.
          </p>
        </Col>
        </Row>
        </div>

        <div className="shift-down-50px">
      <Row>
      
                    <Col md="7">
                      <div className="topcard">
                        <h2 id="titleblack" className="align-left2">
                        Legacy<br></br>Software Replacement 
                        </h2>
                       
                      </div>
                    
                    </Col>
                    <Col md="4">
                      
                  <p className="top-description">
                  NEXTLEVEL's dedicated team of software engineers is well-versed in helping our clients bring their systems up to 
                  date by replacing them in a thoughtful, methodical way that’s oriented toward business outcomes, preserves existing 
                  data and information, and minimizes blackout time. 
                  </p>
                 
                    </Col> 
                </Row>

        </div>

        
        <div className="custom-industries">

          
            
            <Row>
              <Col md="3">
                <div className="info">
                  <div className="icon icon-danger">
                  <img 
                         alt="maintenance"
                         className="customcardimg3"
                         src={require("assets/img/nlicons/modernisation/update.svg")}
                         />                 
                         </div>
                  <div className="description">
                    <h4 className="info-title-black">Evolutionary Approach</h4>
                    <p className="description-black">
                    While pulling the plug on the old system entirely and replacing it with a new one right away is certainly an 
                    option, we prefer an evolutionary approach — replacing one component at a time (starting with the most problematic) 
                    in order to prevent disruption by minimizing the time your organization spends offline.
                    </p>
                    
                  </div>
                </div>
              </Col>
              <Col md="3">
              <div className="info">
                  <div className="icon icon-danger">
                  <img 
                         alt="maintenance"
                         className="customcardimg3"
                         src={require("assets/img/nlicons/modernisation/solution.svg")}
                         />                   
                         </div>
                  <div className="description">
                    <h4 className="info-title-black">Data & Logic Preservation</h4>
                    <p className="description-black">
                    Replacing legacy systems with new software is a delicate process, as so much data and process information are embedded 
                    in those systems. Our careful, systematic approach preserves your business processes and leaves your information intact.
                    </p>
                    
                  </div>
                </div>
              </Col>
              <Col md="3">
              <div className="info">
                  <div className="icon icon-danger">
                  <img 
                         alt="maintenance"
                         className="customcardimg3"
                         src={require("assets/img/nlicons/modernisation/chip.svg")}
                         />                  
                          </div>
                  <div className="description">
                    <h4 className="info-title-black">Cutting-Edge Technology</h4>
                    <p className="description-black">
                    While your legacy software may have struggled to integrate with modern systems, your new systems will be built using 
                    current technologies that are designed to play well with others and flexible enough to evolve with your organization.
                    </p>
                    
                  </div>
                </div>
              </Col>
              <Col md="3">
              <div className="info">
                  <div className="icon icon-danger">
                  <img 
                         alt="maintenance"
                         className="customcardimg3"
                         src={require("assets/img/nlicons/modernisation/ui-design.svg")}
                         />                   
                         </div>
                  <div className="description">
                    <h4 className="info-title-black">Sleek, Intuitive User Experience</h4>
                    <p className="description-black">
                    A key component of customer and employee satisfaction, as well as general productivity, is a system’s user 
                    experience. Our thoughtful designers create attractive interfaces that allow our clients to be much more 
                    productive, with much less headache.
                    </p>
                    
                  </div>
                </div>
              </Col>
            </Row>

            <Row>
              <Col md="3">
                <div className="info">
                  <div className="icon icon-danger">
                  <img 
                         alt="maintenance"
                         className="customcardimg3"
                         src={require("assets/img/nlicons/modernisation/integration.svg")}
                         /> 
                  </div>
                  <div className="description">
                    <h4 className="info-title-black">Full System Integration</h4>
                    <p className="description-black">
                    Redundancy is a key issue with many legacy systems — especially those that include many different components 
                    all stitched together. As we work to replace your systems, we also work to eliminate that redundancy, running 
                    every component off of one database to eliminate the frustration (and risk of error) that comes with employees 
                    having to enter and manage data on multiple platforms.
                    </p>
                    
                  </div>
                </div>
              </Col>
              <Col md="3">
              <div className="info">
                  <div className="icon icon-danger">
                  <img 
                         alt="maintenance"
                         className="customcardimg3"
                         src={require("assets/img/nlicons/modernisation/automation.svg")}
                         /> 
                  </div>
                  <div className="description">
                    <h4 className="info-title-black">Operations Consolidation</h4>
                    <p className="description-black">
                    Beyond data management, our state-of-the-art replacements for your legacy systems will automate workflows, 
                    optimize processes, and consolidate tasks, decreasing the time spent on busywork and systems management and 
                    increasing the time spent focusing on the core of the business.
                    </p>
                    
                  </div>
                </div>
              </Col>
              <Col md="3">
              <div className="info">
                  <div className="icon icon-danger">
                  <img 
                         alt="maintenance"
                         className="customcardimg3"
                         src={require("assets/img/nlicons/modernisation/solution(1).svg")}
                         /> 
                  </div>
                  <div className="description">
                    <h4 className="info-title-black">Focus on Business Outcomes</h4>
                    <p className="description-black">
                    Speaking of the core of the business, when we design the systems to replace your outdated software, our expert 
                    business advisors work together with your team and our developers to understand the business outcomes you’re 
                    looking for and create a solution that will empower your organization to achieve its most pressing goals.
                    </p>
                    
                  </div>
                </div>
              </Col>
              
            </Row>
        </div>
        <br></br>


        <div
          className="pricing-3 custom-software-div"
          style={{
            backgroundImage:
              "url(" + require("assets/img/nlbg/bg3.jpg") + ")",
          }}
        >

        <Row>
          <Col md="6">

          </Col>
          <Col md="6">
          <h2 className="whath2qa3"><span>Which systems can<br></br>we replace?</span></h2>
          
          <h5 className="whath4qa3"><span>NEXTLEVEL's expansive team of engineers includes experts in a wide range of technologies and applications. We’ve planned, built, 
            and implemented custom software solutions many corporate clients, from ERP systems to mobile apps to Ecommerce solutions, 
            and more. So regardless of what your legacy systems look like, rest assured that we can bring them up to date with brand new 
            solutions tailored to your organization’s needs. Learn more about our <Link to="/custom-software"><strong className="custom-link">custom software services.</strong></Link></span></h5>
            <br></br>
          <br></br>
            
            </Col>
        </Row>
        </div>

        <div>
           
           <Row>
           
                         <Col md="7">
                           <div className="topcard">
                             <h2 id="titleblack" className="align-left2">
                             Key steps while replacing a legacy system 
                             </h2>
                            
                           </div>
                         
                         </Col>
                         <Col md="4">
                           
                       <p className="top-description">
                       We’re a talented team of software designers, developers and engineers, specializing in making businesses more efficient 
                       with game-changing custom-made software solutions. 
                       </p>
                      
                         </Col> 
                     </Row>


                     <Row className="mx-10">
           
                     <Col md="4" sm="12" xs="12" className="col1">
             <div className="topcard2">
             <img 
                         alt="maintenance"
                         className="customcardimg3"
                         src={require("assets/img/nlicons/modernisation/problem.svg")}
                         />
              <img 
                         alt="maintenance"
                         className="customcardimg2"
                         id="flow-hori"
                         src={require("assets/img/nlicons/ic_arrow_forward_48px.svg")}
                         />
                         
               <h2 id="titleblacksmall" className="align-left2">
               Identify Gaps
               </h2>
              <p className="tile-description">
              You first need to look at how big the gap is between the tasks your system can complete vs what you need to do now and in the future.</p>
             </div>
             <img 
                         alt="maintenance"
                         className="customcardimg2"
                         id="flow-vert"
                         src={require("assets/img/nlicons/ic_arrow_downward_48px.svg")}
                         />
           
           </Col>
           <Col md="4" sm="12" xs="12" className="col1">
             <div className="topcard2">
             <img 
                         alt="maintenance"
                         className="customcardimg3"
                         src={require("assets/img/nlicons/modernisation/speedometer.svg")}
                         />
                         <img 
                         alt="maintenance"
                         className="customcardimg2"
                         id="flow-hori"
                         src={require("assets/img/nlicons/ic_arrow_forward_48px.svg")}
                         />
               <h2 id="titleblacksmall" className="align-left2">
               Current Capacity
               </h2>
              <p className="tile-description">
              You need to consider the software’s real-time capabilities, processing speed, and ability such as mobile devices, or application integration.</p>
              </div>
              <img 
                         alt="maintenance"
                         className="customcardimg2"
                         id="flow-vert"
                         src={require("assets/img/nlicons/ic_arrow_downward_48px.svg")}
                         />
           
           </Col>
           <Col md="4" sm="12" xs="12" className="col1">
             <div className="topcard2">
             <img 
                         alt="maintenance"
                         className="customcardimg3"
                         src={require("assets/img/nlicons/modernisation/project.svg")}
                         />
                         <img 
                         alt="maintenance"
                         className="customcardimg2"
                         id="flow-hori"
                         src={require("assets/img/nlicons/ic_arrow_forward_48px.svg")}
                         />
               <h2 id="titleblacksmall" className="align-left2">
               Current Problems
               </h2>
              <p className="tile-description">
              Legacy system often have non-existent or insubstantial documentation, no testing, application depending on outdated frameworks, libraries, or technologies and confusing, inconsistent code.</p>
             </div>
             <img 
                         alt="maintenance"
                         className="customcardimg2"
                         id="flow-vert"
                         src={require("assets/img/nlicons/ic_arrow_downward_48px.svg")}
                         />
           
           </Col>
       
           
           <Col md="4" sm="12" xs="12" className="col1">
       
                         
             <div className="topcard2">
            
             <img 
                         alt="maintenance"
                         className="customcardimg3"
                         src={require("assets/img/nlicons/modernisation/benefit.svg")}
                         />
                        
                         
                         <img 
                         alt="maintenance"
                         className="customcardimg2"
                         id="flow-hori"
                         src={require("assets/img/nlicons/ic_arrow_forward_48px.svg")}
                         />
               <h2 id="titleblacksmall" className="align-left2">
               Your Needs
               </h2>
              <p className="tile-description">
              It can be tempting to rebuild the system from the ground up, and while this can be hugely beneficial and result in great gains in ROI, it will also require a substantial upfront investment.</p>
             </div>
             <img 
                         alt="maintenance"
                         className="customcardimg2"
                         id="flow-vert"
                         src={require("assets/img/nlicons/ic_arrow_downward_48px.svg")}
                         />
             
           </Col>
           <Col md="4" sm="12" xs="12" className="col1">
             <div className="topcard2">
             <img 
                         alt="maintenance"
                         className="customcardimg3"
                         src={require("assets/img/nlicons/modernisation/internet.svg")}
                         />
                          <img 
                         alt="maintenance"
                         className="customcardimg2"
                         id="flow-hori"
                         src={require("assets/img/nlicons/ic_arrow_drop_down_circle_48px.svg")}
                         />
                         
                         
               <h2 id="titleblacksmall" className="align-left2">
               Make a Decision
               </h2>
              <p className="tile-description">
              So how do you decide between a partial and complete rebuild? We can take a look at your system and software.</p>
             </div>
           
           </Col>
           
       </Row>
             </div>
             


       

        <Legacyfaq />

       
        <ContactComponent />
      </div>
     
      <FooterNav />
    </>
  );
}

export default LegacySoftware;
