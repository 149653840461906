import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col,
} from "reactstrap";
import {Link} from 'react-router-dom';
// core components
import WebHeaderTemplate from "./webHeaders/WebHeaderTemplate.js";
import ColorNavbar from "components/Navbars/ColorNavbar.js";
 
import FooterNav from "../services/FooterNav.js";
import ContactComponent from '../services/ContactComponent.js';
import Product from "../Product";


function WebBusiness() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
    };
  });
  return (
    <>
      <ColorNavbar />
      <WebHeaderTemplate navTitle="Business Website Packages" Title="Business Websites" />
      <div className="wrapper">
        <div
          className="pricing-3 section-bg-img"
          style={{
            backgroundImage:
            "url(" + require("assets/img/nl/sections/custom/red-blue.jpg") + ")",
          }}
        >
           <Container>
          <Row>
            <Col md="4" className="col1">
              <Card className="card-pricing w-150">
              <h6 className="card-category">Budget</h6>
              
                <CardBody>
                <img 
                        alt="website"
                        className="webcardimg mx-4"
                        src={require("assets/img/nlicons/web/widget2.svg")}
                        />
                <div className="webcardicon my-5">
                     
                        <span>
                        <h5 className="webcardtitle">
                        Starter Business Website
                      </h5>
                        </span>
                  
                      </div>
                  <CardTitle tag="h2">R4999</CardTitle>
                  <ul className="align-left">
                    <li>
   <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      Responsive (Mobile Friendly)
                    </li>
                    <li>
   <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      Content Management System
                    </li>
                    <li>
   <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      No-CMS Option Available
                    </li>
                    <li>
   <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      Maximum 6 Pages
                    </li>
                    <li>
   <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      Additional Pages At R990 per page
                    </li>
                    <li>
   <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      Dynamic Pages At R1200 per page
                    </li>
                    <li>
 <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      <b>FREE</b> Domain Name
                    </li>
                    <li>
 <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      <b>FREE</b> SSL Certificate
                    </li>
                    <li>
 <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      <b>1 year FREE</b> Website Hosting
                    </li>
                    <li>
 <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      <b>10</b> Custom Email Addresses
                    </li>
                    <li>
   <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      On-Page SEO
                    </li>
                    <br></br>

                  </ul>
                 
                  <br></br>
                  <br></br>
                  <br></br>
                  <Product 
                  id="StarterBusinessWebsite"
                  title="Starter Business Website"
                  price={4999.0}
                  rating={5}
                  image={require("assets/img/nlicons/web/widget2.svg")}
                  />   
                 <Link to="/contact">
                  <Button
                    className="btn-round card-link web-card-btn"
                    color="success"
                    href="" 
                  >
                    Get Started
                  </Button>
                  </Link>
                </CardBody>
              </Card>
            </Col>
            <Col md="4" className="col1">
              <Card className="card-pricing w-150">
              <h6 className="card-category">Standard</h6>
              
                <CardBody>
                <img 
                        alt="website"
                        className="webcardimg mx-4"
                        src={require("assets/img/nlicons/web/widget2.svg")}
                        />
                <div className="webcardicon my-5">
                     
                        <span>
                        <h5 className="webcardtitle">
                        Standard Business Website
                      </h5>
                        </span>
                  
                      </div>
                  <CardTitle tag="h2">R6999</CardTitle>
                  <ul className="align-left">
                    <li>
   <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      Responsive (Mobile Friendly)
                    </li>
                    <li>
   <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      Content Management System
                    </li>
                    <li>
   <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      No-CMS Option Available
                    </li>
                    <li>
   <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      Online Shopping System
                    </li>
                    <li>
   <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      10 Products Pre-Loaded
                    </li>
                    <li>
   <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      Additional Products At R120 each
                    </li>
                    <li>
   <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      Payment Gateway Integration
                    </li>
                    <li>
   <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      Maximum 10 Pages
                    </li>
                    <li>
   <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      Additional Pages At R990 per page
                    </li>
                    <li>
   <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      Dynamic Pages At R1200 per page
                    </li>
                    <li>
 <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      <b>FREE</b> Domain Name
                    </li>
                    <li>
 <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      <b>FREE</b> SSL Certificate
                    </li>
                    <li>
 <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      <b>1 year FREE</b> Website Hosting
                    </li>
                    <li>
 <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      <b>20</b> Custom Email Addresses
                    </li>
                    <li>
   <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      On-Page SEO
                    </li>
                    
                  </ul>
                  <br></br>
                  <br></br>
                  <Product 
                  id="StandardBusinessWebsite"
                  title="Standard Business Website"
                  price={6999.0}
                  rating={5}
                  image={require("assets/img/nlicons/web/widget2.svg")}
                  />   
                 <Link to="/contact">
                  <Button
                    className="btn-round card-link web-card-btn"
                    color="success"
                    href="" 
                  >
                    Get Started
                  </Button>
                  </Link>
                </CardBody>
              </Card>
            </Col>
            <Col md="4" className="col1">
              <Card className="card-pricing w-150">
              <h6 className="card-category">Premium</h6>
                <CardBody>
                <img 
                        alt="website"
                        className="webcardimg mx-4"
                        src={require("assets/img/nlicons/web/widget2.svg")}
                        />
                <div className="webcardicon my-5">
                     
                        <span>
                        <h5 className="webcardtitle">
                        Professional Business Website
                      </h5>
                        </span>
                  
                      </div>
                  <CardTitle tag="h2">R9999</CardTitle>
                  <ul className="align-left">
                    <li>
   <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      Responsive (Mobile Friendly)
                    </li>
                    <li>
   <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      Content Management System
                    </li>
                    <li>
   <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      No-CMS Option Available
                    </li>
                    <li>
   <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      Online Shopping System
                    </li>
                    <li>
   <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      30 Products Pre-Loaded
                    </li>
                    <li>
   <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      Additional Products At R120 each
                    </li>
                    <li>
   <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      Payment Gateway Integration
                    </li>
                    <li>
   <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      Full POS System
                    </li>
                    <li>
   <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      Maximum 14 Pages
                    </li>
                    <li>
   <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      Additional Pages At R990 per page
                    </li>
                    <li>
   <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      Dynamic Pages At R1200 per page
                    </li>
                    <li>
 <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      <b>FREE</b> Domain Name
                    </li>
                    <li>
   <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      <b>FREE</b> SSL Certificate
                    </li>
                    <li>
   <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      <b>1 YEAR FREE</b> Website Hosting
                    </li>
                    <li>
 <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      <b>Unlimited</b> Custom Email Addresses
                    </li>
                    <li>
   <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      On-Page SEO
                    </li>
                  </ul>
                  <br></br>
                  <br></br>
                  <br></br>
                  <Product 
                  id="ProfessionalBusinessWebsite"
                  title="Professional Business Website"
                  price={9999.0}
                  rating={5}
                  image={require("assets/img/nlicons/web/widget2.svg")}
                  />   
                 <Link to="/contact">
                  <Button
                    className="btn-round card-link web-card-btn"
                    color="success"
                    href="" 
                  >
                    Get Started
                  </Button>
                  </Link>
                </CardBody>
              </Card>
            </Col>
            
            
          </Row>
          </Container>
        </div>
        {/* ********* END PRICING 3 ********* */}
       
        <ContactComponent />
      </div>
      <FooterNav />
    </>
  );
}

export default WebBusiness;
