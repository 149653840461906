import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";
import { Button } from "reactstrap";
import { Link } from 'react-router-dom'
// core components

function SectionComponents() {
  return (
    <>
    
      <div id="nopadding__section"className="section section-components cd-section mr--custom">
      
        <Row className="">
          <Col lg="6" md="10" className="ml-40 mr--20">
            <div className="image-container sec-components">
              <img
                alt="..."
                className="components-macbook max-width575"
                src={require("assets/img/nlmockup/transport.png")}
              />
              <img
                alt="..."
                className="table-img"
                src={require("assets/img/nlmockup/quote2.png")}
              />
              <img
                alt="..."
                className="share-btn-img"
                src={require("assets/img/presentation-page/share-btn.png")}
              />
              <img
                alt="..."
                className="coloured-card-btn-img"
                src={require("assets/img/nlmockup/quote.jpeg")}
              />
              <img
                alt="..."
                className="coloured-card-img"
                src={require("assets/img/nlmockup/cart.jpg")}
              />
              <img
                alt="..."
                className="social-img"
                src={require("assets/img/nlmockup/socialbar-nobg.png")}
              />
              <img
                alt="..."
                className="pin-btn-img"
                src={require("assets/img/presentation-page/pin-btn.png")}
              />
            </div>
          </Col>
          <Col className="mr-20 ml-30" lg="4" md="6">
            <Container className="basic-container" id="Introduction">
            <Link to="/custom-software">
              <h1 className="title align-left">Technology Solutions For Business</h1>
              </Link>
              <h6 className="category" id="topcategory">Bringing your ideas to life</h6>
              <h5 className="description align-left">
              NEXTLEVEL offers our customers a wealth of technical and business expertise. 
              We create diverse, complex, <strong>web</strong>, <strong>mobile</strong> and <strong>desktop</strong> software as well as, <strong>cloud</strong>, <strong>server</strong>, <strong>network</strong>, <strong>IoT</strong>, <strong>cybersecurity</strong> and <strong>digital marketing</strong> solutions for any business need. 
              Our knowledge and experience translate to added value and peace of mind for our customers. 
              With NEXTLEVEL Technology you get quality software and perfect service every time.
              </h5>
              <div>
              <Link to="/#WhatWeDo">
              <Button className="btn-move-right btn-outline-info margin-t15" id="topbtn">
                  What We Do<i className="nc-icon nc-minimal-right" />
                </Button>
                </Link>
                <Link to="/#IndustriesWeServe">
              <Button className="btn-move-right btn-outline-info margin-t15 right-aligned" id="topbtn2">
                  Who We Serve<i className="nc-icon nc-minimal-right" />
              </Button>
              </Link>
              <br></br>
              <br></br>
              <br></br>
              <br></br>

              </div>
            </Container>
            
          </Col>
        </Row>
      </div>
    </>
  );
}

export default SectionComponents;
