import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import ColorNavbar from "components/Navbars/ColorNavbar"; 
import ContactComponent from '../services/ContactComponent.js';

import WebHeaderTemplate from "./webHeaders/WebHeaderTemplate.js";
import FooterNav from "../services/FooterNav.js";
import {Link} from 'react-router-dom';
import Product from "../Product";

function WebHosting() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
    };
  });
  return (
    <>
       <ColorNavbar />
      <WebHeaderTemplate navTitle="Website Hosting Services" Title="Website Hosting" />

      <div className="wrapper">
        {/* ********* PRICING 3 ********* */}
        <div
          className="pricing-3 section-bg-img"
          style={{
            backgroundImage:
            "url(" + require("assets/img/nl/sections/custom/red-blue.jpg") + ")",
          }}
        >
           <Container>
          <Row>
          <Col xl="6" lg="6" md="6" sm="12" xs="12" className="colhs">
              <Card className="card-pricing w-150">
              <h6 className="card-category">Budget</h6>
              
                <CardBody>
                <div className="webcardicon my-5">
                       <img 
                        alt="basic hosting"
                        className="webcardimg"
                        src={require("assets/img/nlicons/web/world.svg")}
                        />
                        <span>
                        <h5 className="webcardtitle">
                        Basic Hosting
                      </h5>
                        </span>
                  
                      </div>
                  <CardTitle tag="h2">R99</CardTitle>
                  <ul>
                    <li>
                      <b>5GB</b> SSD Storage
                    </li>
                    <li>
                      <b>5GB</b> SSD Databases
                    </li>
                    <li>
                      <b>1</b> Domain
                    </li>
                    <li>
                      <b>50</b> Subdomains
                    </li>
                    <li>
                      <b>50</b> Email Accounts
                    </li>
                    <li>
                      <b>1</b> FTP Account
                    </li>
                    
                    <br></br>

                  </ul>
                  
                  <br></br>
                  <br></br>
                  <br></br>
                  <div className="subscribe__div">
                  <a className="subscribe__btn" href="https://www.payfast.co.za/eng/process?cmd=_paynow&amp;receiver=17143900&amp;item_name=Basic+Hosting&amp;amount=99.00&amp;return_url=https%3A%2F%2Fnextleveltechnology.org%2Fsuccess&amp;cancel_url=https%3A%2F%2Fnextleveltechnology.org%2Fweb-hosting&amp;cycles=0&amp;frequency=3&amp;m_payment_id=pay_now_17143900&amp;subscription_type=1"><img src="https://www.payfast.co.za/images/buttons/dark-large-subscribe.png" width="210" height="59" alt="Subscribe" title="Subscribe Now with PayFast" /></a>
                  </div>
                 <Link to="/contact">
                  <Button
                    className="btn-round card-link web-card-btn"
                    color="success"
                    href="" 
                  >
                    Get Started
                  </Button>
                  </Link>
                </CardBody>
              </Card>
            </Col>
            <Col xl="6" lg="6" md="6" sm="12" xs="12" className="colhs">
              <Card className="card-pricing w-150">
              <h6 className="card-category">Standard</h6>
              
                <CardBody>
                <div className="webcardicon my-5">
                       <img 
                        alt="standard hosting"
                        className="webcardimg"
                        src={require("assets/img/nlicons/web/world.svg")}
                        />
                        <span>
                        <h5 className="webcardtitle">
                        Standard Hosting
                      </h5>
                        </span>
                  
                      </div>
                      <CardTitle tag="h2">R149</CardTitle>
                  <ul>
                    <li>
                      <b>20GB</b> SSD Storage
                    </li>
                    <li>
                      <b>20GB</b> SSD Databases
                    </li>
                    <li>
                      <b>10</b> Domains
                    </li>
                    <li>
                      <b>Unlimited</b> Subdomains
                    </li>
                    
                    <li>
                      <b>250</b> Email Accounts
                    </li>
                    <li>
                      <b>2</b> FTP Accounts
                    </li>
                    <li>
                      <b>1</b> cPanel Account
                    </li>
                    <br></br>

                  </ul>
                  
                  <br></br>
                  <br></br>
                  <br></br>
                  <div className="subscribe__div">
                  <a className="subscribe__btn" href="https://www.payfast.co.za/eng/process?cmd=_paynow&amp;receiver=17143900&amp;item_name=Standard+Hosting&amp;amount=149.00&amp;return_url=https%3A%2F%2Fnextleveltechnology.org%2Fsuccess&amp;cancel_url=https%3A%2F%2Fnextleveltechnology.org%2Fweb-hosting&amp;cycles=0&amp;frequency=3&amp;m_payment_id=pay_now_17143900&amp;subscription_type=1"><img src="https://www.payfast.co.za/images/buttons/dark-large-subscribe.png" width="210" height="59" alt="Subscribe" title="Subscribe Now with PayFast" /></a>
                  </div>
                 <Link to="/contact">
                  <Button
                    className="btn-round card-link web-card-btn"
                    color="success"
                    href="" 
                  >
                    Get Started
                  </Button>
                  </Link>
                </CardBody>
              </Card>
            </Col>
            </Row>
            <Row>
            <Col xl="6" lg="6" md="6" sm="12" xs="12" className="colhs">
              <Card className="card-pricing w-150">
              <h6 className="card-category">Premium</h6>
                <CardBody>
                <div className="webcardicon my-5">
                       <img 
                        alt="unlimited hosting"
                        className="webcardimg"
                        src={require("assets/img/nlicons/web/world.svg")}
                        />
                        <span>
                        <h5 className="webcardtitle">
                        Unlimited Hosting
                      </h5>
                        </span>
                  
                      </div>
                      <CardTitle tag="h2">R249</CardTitle>
                  <ul>
                    <li>
                      <b>Unlimited</b> SSD Storage
                    </li>
                    <li>
                      <b>Unlimited</b> SSD Databases
                    </li>
                    <li>
                      <b>Unlimited</b> Domains
                   </li>
                    <li>
                      <b>Unlimited</b> Email Accounts
                    </li>
                    <li>
                      <b>Unlimited</b> FTP Accounts
                    </li>
                    
                    <br></br>

                  </ul>
                  
                  <br></br>
                  <br></br>
                  <br></br>
                  <div className="subscribe__div">
                  <a className="subscribe__btn" href="https://www.payfast.co.za/eng/process?cmd=_paynow&amp;receiver=17143900&amp;item_name=Unlimited+Hosting&amp;amount=249.00&amp;return_url=https%3A%2F%2Fnextleveltechnology.org%2Fsuccess&amp;cancel_url=https%3A%2F%2Fnextleveltechnology.org%2Fweb-hosting&amp;cycles=0&amp;frequency=3&amp;m_payment_id=pay_now_17143900&amp;subscription_type=1"><img src="https://www.payfast.co.za/images/buttons/dark-large-subscribe.png" width="210" height="59" alt="Subscribe" title="Subscribe Now with PayFast" /></a>
                  </div>
                 <Link to="/contact">
                  <Button
                    className="btn-round card-link web-card-btn"
                    color="success"
                    href="" 
                  >
                    Get Started
                  </Button>
                  </Link>
                </CardBody>
              </Card>
            </Col>
           
            <Col xl="6" lg="6" md="6" sm="12" xs="12" className="colhs">
              <Card className="card-pricing w-150">
              <h6 className="card-category">Master</h6>
                <CardBody>
                <div className="webcardicon my-5">
                       <img 
                        alt="pro hosting"
                        className="webcardimg pl-30"
                        src={require("assets/img/nlicons/web/world.svg")}
                        />
                        <span>
                        <h5 className="webcardtitle">
                        Unlimited Professional Hosting
                      </h5>
                        </span>
                  
                      </div>
                      <CardTitle tag="h2">R399</CardTitle>
                  <ul>
                    <li>
                      <b>Unlimited</b> NVMe SSD Storage
                    </li>
                    <li>
                      <b>Unlimited</b> NVMe SSD Databases
                    </li>
                    <li>
                      <b>Unlimited</b> Mailboxes
                    </li>
                    <li>
                      <b>Unlimited</b> Email Aliases
                    </li>
                    <li>
                      <b>Unlimited</b> FTP Accounts
                    </li>
                    <li>
                      Dedicated Server Resources
                    </li>
                    <li>
                      Ultra-Fast Speeds
                    </li>
                    
                    <br></br>

                  </ul>
                  
                  <br></br>
                  <br></br>
                  <br></br>
                  <div className="subscribe__div">
                  <a className="subscribe__btn" href="https://www.payfast.co.za/eng/process?cmd=_paynow&amp;receiver=17143900&amp;item_name=Unlimited+Hosting&amp;amount=249.00&amp;return_url=https%3A%2F%2Fnextleveltechnology.org%2Fsuccess&amp;cancel_url=https%3A%2F%2Fnextleveltechnology.org%2Fweb-hosting&amp;cycles=0&amp;frequency=3&amp;m_payment_id=pay_now_17143900&amp;subscription_type=1"><img src="https://www.payfast.co.za/images/buttons/dark-large-subscribe.png" width="210" height="59" alt="Subscribe" title="Subscribe Now with PayFast" /></a>
                  </div>
                 <Link to="/contact">
                  <Button
                    className="btn-round card-link web-card-btn"
                    color="success"
                    href="" 
                  >
                    Get Started
                  </Button>
                  </Link>
                </CardBody>
              </Card>
            </Col>
            
          </Row>

          <Row>
          <Col xl="6" lg="6" md="12" sm="12" xs="12" className="colhs">
              <Card className="card-pricing w-150">
              <h6 className="card-category">Option</h6>
              
                <CardBody>
                <div className="webcardicon my-5">
                       <img 
                        alt="domain renewal"
                        className="webcardimg"
                        src={require("assets/img/nlicons/web/key-25.svg")}
                        />
                        <span>
                        <h5 className="webcardtitle">
                        Domain Renewal
                      </h5>
                        </span>
                  
                      </div>
                  <CardTitle tag="h3"><strong>R29-R99 PM</strong></CardTitle>
                  <ul>
                    <li>
                      <b>5GB</b> SSD Storage
                    </li>
                    <li>
                      <b>5GB</b> SSD Databases
                    </li>
                    <li>
                      <b>1</b> Domain
                    </li>
                    <li>
                      <b>50</b> Subdomains
                    </li>
                    <li>
                      <b>50</b> Email Accounts
                    </li>
                    <li>
                      <b>1</b> FTP Account
                    </li>
                    
                    <br></br>

                  </ul>
                  
                  <br></br>
                  <br></br>
                  <br></br>
                  <div className="subscribe__div">
                  <a className="subscribe__btn" href="https://www.payfast.co.za/eng/process?cmd=_paynow&amp;receiver=17143900&amp;item_name=Domain+Renewal&amp;amount=99.00&amp;return_url=https%3A%2F%2Fnextleveltechnology.org%2Fsuccess&amp;cancel_url=https%3A%2F%2Fnextleveltechnology.org%2Fweb-hosting&amp;cycles=0&amp;frequency=3&amp;m_payment_id=pay_now_17143900&amp;subscription_type=1"><img src="https://www.payfast.co.za/images/buttons/dark-large-subscribe.png" width="210" height="59" alt="Subscribe" title="Subscribe Now with PayFast" /></a>
                  </div>
                 <Link to="/contact">
                  <Button
                    className="btn-round card-link web-card-btn"
                    color="success"
                    href="" 
                  >
                    Get Started
                  </Button>
                  </Link>
                </CardBody>
              </Card>
            </Col>
            <Col xl="6" lg="6" md="12" sm="12" xs="12" className="colhs">
              <Card className="card-pricing w-150">
              <h6 className="card-category">Option</h6>
              
                <CardBody>
                <div className="webcardicon my-5">
                       <img 
                        alt="ssl renewal"
                        className="webcardimg"
                        src={require("assets/img/nlicons/web/lock2.svg")}
                        />
                        <span>
                        <h5 className="webcardtitle">
                        SSL Certificate Renewal
                      </h5>
                        </span>
                  
                      </div>
                      <CardTitle tag="h3"><strong>R159-R249 PM</strong></CardTitle>
                  <ul>
                  <li>
                    <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />
                    Domain Validation
                    </li>
                    <li>
                    <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />
                    Registration & Setup
                    </li>
                    <li>
                    <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />
                    Certificate Valid for 12 Months
                    </li>
                     <li>
                    <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />
                    Certificate Renewal Option Available after 12 Months
                    </li>
                     <li>
                    <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />
                    SHA256 Signature Algorithm 
                    </li>
                    

                  </ul>
                  <br></br>
                  <br></br>
                  <br></br>
                  <div className="subscribe__div">
                  <a className="subscribe__btn" href="https://www.payfast.co.za/eng/process?cmd=_paynow&amp;receiver=17143900&amp;item_name=SSL+Certificate+Renewal&amp;amount=249.00&amp;return_url=https%3A%2F%2Fnextleveltechnology.org%2Fsuccess&amp;cancel_url=https%3A%2F%2Fnextleveltechnology.org%2Fweb-hosting&amp;cycles=0&amp;frequency=3&amp;m_payment_id=pay_now_17143900&amp;subscription_type=1"><img src="https://www.payfast.co.za/images/buttons/dark-large-subscribe.png" width="210" height="59" alt="Subscribe" title="Subscribe Now with PayFast" /></a>
                  </div>
                 <Link to="/contact">
                  <Button
                    className="btn-round card-link web-card-btn"
                    color="success"
                    href="" 
                  >
                    Get Started
                  </Button>
                  </Link>
                </CardBody>
              </Card>
            </Col>
            </Row>

          <Row>
          
  

<Card className="card-pricing w-150">
  <CardBody>
  <h6 className="card-category align-center">Features - All Hosting Packages</h6>

  <Row>

<Col className="cols2" md="6" sm="6" xs="12">

    <ul className="align-left">
<li>
<img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />
          <b>99.99%</b> Uptime Guarantee
        </li>
        <li>
        <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />
          <b>Unlimited</b> Bandwidth
        </li>
        <li>
        <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />          Managed Wordpress
        </li>
        <li>
        <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />          Wordpress Acceleration
        </li>
        <li>
        <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />         Cloudflare CDN with Railgun
        </li>
        <li>
        <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />
          Popular CMSs supported
        </li>
        <li>
<img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />
          Webmail
        </li>
        
</ul>
      <br></br>
      <br></br>
      
   
    </Col>

    <Col className="cols2" md="6" sm="6" xs="12">
    
    <ul className="align-left">
    <li>
        <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />          Malware and Spam, Scan & Removal
        </li>

        
        <li>
        <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />          DDoS protection & Mitigation
        </li>
        <li>
        <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />          Payment Options
        </li>
        <li>
        <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />         Backups
        </li>
        <li>
        <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />          Lets Encrypt SSL/TlS
        </li>
        <li>
        <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />          PHP 7, MySQL, PostgreSQL
        </li>
       
        
</ul>
</Col>

<Col className="cols1" md="12" sm="12" xs="12">
<ul className="align-left">
<li>
<img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />
          <b>99.99%</b> Uptime Guarantee
        </li>
        <li>
        <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />
          <b>Unlimited</b> Bandwidth
        </li>
        <li>
        <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />          Managed Wordpress
        </li>
        <li>
        <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />          Wordpress Acceleration
        </li>
        <li>
        <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />         Cloudflare CDN with Railgun
        </li>
        <li>
        <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />
          Popular CMSs supported
        </li>
        <li>
<img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />
          Webmail
        </li>
        <li>
        <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />          Malware and Spam, Scan & Removal
        </li>

        
        <li>
        <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />          DDoS protection & Mitigation
        </li>
        <li>
        <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />          Payment Options
        </li>
        <li>
        <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />         Backups
        </li>
        <li>
        <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />          Lets Encrypt SSL/TlS
        </li>
        <li>
        <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />          PHP 7, MySQL, PostgreSQL
        </li>
       
        
</ul>
</Col>
      <br></br>
      <br></br>
      <br></br>
      </Row>

    </CardBody>
    </Card>
          </Row>
  
  
          

           
          </Container>
        </div>
        {/* ********* END PRICING 3 ********* */}
       
        <ContactComponent />
      </div>
      <FooterNav />
    </>
  );
}

export default WebHosting;
