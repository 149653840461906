import React from 'react';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

const responsive = {
    0: { items: 1 },
    568: { items: 3 },
    1024: { items: 3 },
};

const items = [
        <img 
        alt="adobe"
        className="carouselimg"
        src={require("assets/img/nltech/adobe.png")}
        />, 
        <img 
        alt="android"
        className="carouselimg"
        src={require("assets/img/nltech/android.png")}
        />,
        <img 
        alt="apache"
        className="carouselimg"
        src={require("assets/img/nltech/apache.png")}
        />,
        <img 
        alt="apple"
        className="carouselimg"
        src={require("assets/img/nltech/apple.png")}
        />,                   
        <img 
        alt="aws"
        className="carouselimg"
        src={require("assets/img/nltech/aws.png")}
        />, 
        <img 
        alt="azure"
        className="carouselimg"
        src={require("assets/img/nltech/azure.png")}
        />,
        <img 
        alt="c++"
        className="carouselimg"
        src={require("assets/img/nltech/gcloud.jpg")}
        />,
        <img 
        alt="c++"
        className="carouselimg"
        src={require("assets/img/nltech/cpp.png")}
        />,
        <img 
        alt="css"
        className="carouselimg"
        src={require("assets/img/nltech/css.png")}
        />,
        <img 
        alt="django"
        className="carouselimg"
        src={require("assets/img/nltech/django.png")}
        />,
        <img                   
        alt="docker"
        className="carouselimg"
        src={require("assets/img/nltech/docker.svg")}
        />, 
        <img 
        alt="firebase"
        className="carouselimg"
        src={require("assets/img/nltech/firebase.png")}
        />,
        <img 
        alt="git"
        className="carouselimg"
        src={require("assets/img/nltech/git.png")}
        />,
        <img 
        alt="google"
        className="carouselimg"
        src={require("assets/img/nltech/google.png")}
        />,
        <img 
        alt="graphql"
        className="carouselimg"
        src={require("assets/img/nltech/graphql.png")}
        />,                   
        <img 
        alt="html"
        className="carouselimg"
        src={require("assets/img/nltech/html.png")}
        />, 
        <img 
        alt="java"
        className="carouselimg"
        src={require("assets/img/nltech/java.png")}
        />,
        <img 
        alt="jira"
        className="carouselimg"
        src={require("assets/img/nltech/jira.png")}
        />,
        <img 
        alt="javascript"
        className="carouselimg"
        src={require("assets/img/nltech/js.png")}
        />,
        <img 
        alt="linux"
        className="carouselimg"
        src={require("assets/img/nltech/linux.jpg")}
        />, 
        <img                   
        alt="microsoft"
        className="carouselimg"
        src={require("assets/img/nltech/microsoft.jpg")}
        />, 
        <img 
        alt="nginx"
        className="carouselimg"
        src={require("assets/img/nltech/nginx.png")}
        />,
        <img 
        alt="nodejs"
        className="carouselimg"
        src={require("assets/img/nltech/nodejs.png")}
        />,
        <img 
        alt="php"
        className="carouselimg"
        src={require("assets/img/nltech/php.svg")}
        />,
        <img 
        alt="psql"
        className="carouselimg"
        src={require("assets/img/nltech/psql.png")}
        />,                   
        <img 
        alt="python"
        className="carouselimg"
        src={require("assets/img/nltech/python.png")}
        />, 
        <img 
        alt="react"
        className="carouselimg"
        src={require("assets/img/nltech/react.png")}
        />,
        <img 
        alt="angular"
        className="carouselimg"
        src={require("assets/img/nltech/angular.svg")}
        />,
        <img 
        alt="sql"
        className="carouselimg"
        src={require("assets/img/nltech/sql.webp")}
        />,
        <img 
        alt="swift"
        className="carouselimg"
        src={require("assets/img/nltech/swift.svg")}
        />,
        <img 
        alt="ts"
        className="carouselimg"
        src={require("assets/img/nltech/ts.png")}
        />,
        <img 
        alt="windows"
        className="carouselimg"
        src={require("assets/img/nltech/windows.jpg")}
        />                          
];

const mystyle = {

fontSize: "48px/48px",
  fontWeight: "700",
  marginTop: "70px",
  marginBottom: "50px",
  color: "navy",
  fontFamily: "Tahoma, sans-serif",
  };

const Carousel2 = () => (
    <div className="carouseldiv shift-down-80px">
<AliceCarousel
        autoPlay
        autoPlayStrategy="none"
        autoPlayInterval={0}
        animationDuration={2000}
        animationType="fadeout"
        infinite
        touchTracking={false}
        disableDotsControls
        disableButtonsControls
        items={items}

        paddingLeft={50}
        paddingRight={50}
        responsive={responsive}

    />
    </div>
    
);

export default Carousel2