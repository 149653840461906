import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import ColorNavbar from "components/Navbars/ColorNavbar"; 
import ContactComponent from '../services/ContactComponent.js';

import WebHeaderTemplate from "./webHeaders/WebHeaderTemplate.js";
import FooterNav from "../services/FooterNav.js";
import {Link} from 'react-router-dom';
import Product from "../Product";


function WebDevTraining() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
    };
  });
  return (
    <>
      <ColorNavbar />
      <WebHeaderTemplate navTitle="Website Development Training Services" Title="Web Development Training" />

      <div className="wrapper">
        {/* ********* PRICING 3 ********* */}
        <div
          className="pricing-3 section-bg-img"
          style={{
            backgroundImage:
            "url(" + require("assets/img/nl/sections/custom/red-blue.jpg") + ")",
          }}
        >
            <Container>
              
            <Row>
            <Col xl="4" lg="4" md="12" sm="12" xs="12" className="col1">
                <Card className="card-pricing w-150">
                <h6 className="card-category">Budget</h6>
                
                  <CardBody>
                  <div className="webcardicon my-5">
                        <img 
                          alt="development"
                          className="webcardimg"
                          src={require("assets/img/nlicons/web/barcode-qr.svg")}
                          />
                          <span>
                          <h5 className="webcardtitle">
                          Starter Virtual Training - Development
                        </h5>
                          </span>
                    
                        </div>
                    <CardTitle tag="h2">R799</CardTitle>
                    <ul className="align-left">
                    
                      <li>
                      <img 
                          alt="website"
                          className="webcardimg"
                          src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                          />
                      1 Hour Virtual Training 
                      </li>
                      <li>
                      <img 
                          alt="website"
                          className="webcardimg"
                          src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                          />
                      Video Conference Screen Share
                      </li>
                  
                    </ul>
                    
                    <br></br>
                    <br></br>
                    <br></br>
                    <Product 
                  id="StarterVirtualTraining-Development"
                  title="Starter Virtual Training - Development"
                  price={799.0}
                  rating={5}
                  image={require("assets/img/nlicons/web/barcode-qr.svg")}
                  />
                    <Button
                      className="btn-round card-link web-card-btn"
                      color="success"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      Get Started
                    </Button>
                  </CardBody>
                </Card>
              </Col>
              <Col xl="4" lg="4" md="12" sm="12" xs="12" className="col1">
                <Card className="card-pricing w-150">
                <h6 className="card-category">Standard</h6>
                
                  <CardBody>
                  <div className="webcardicon my-5">
                        <img 
                          alt="development"
                          className="webcardimg"
                          src={require("assets/img/nlicons/web/barcode-qr.svg")}
                          />
                          <span>
                          <h5 className="webcardtitle">
                          Comprehensive Virtual Training - Development
                        </h5>
                          </span>
                    
                        </div>
                        <CardTitle tag="h2">R1199</CardTitle>
                        ,  
                    <ul className="align-left">
                    
                      <li>
                      <img 
                          alt="website"
                          className="webcardimg"
                          src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                          />
                      2 Hour Training Session
                      </li>
                      <li>
                      <img 
                          alt="website"
                          className="webcardimg"
                          src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                          />
                      Video Conference Screen Share
                      </li>
                  
                    </ul>
                    
                    <br></br>
                    <br></br>
                    <br></br>
                    <Product 
                  id="ComprehensiveVirtualTraining-Development"
                  title="Comprehensive Virtual Training - Development"
                  price={1199.0}
                  rating={5}
                  image={require("assets/img/nlicons/web/barcode-qr.svg")}
                  />
                    <Button
                      className="btn-round card-link web-card-btn"
                      color="success"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      Get Started
                    </Button>
                  </CardBody>
                </Card>
              </Col>
              
              <Col xl="4" lg="4" md="12" sm="12" xs="12" className="col1">
                <Card className="card-pricing w-150">
                <h6 className="card-category">Premium</h6>
                <CardBody>

                 <div className="webcardicon my-5">
                        <img 
                          alt="development"
                          className="webcardimg"
                          src={require("assets/img/nlicons/web/barcode-qr.svg")}
                          />
                          <span>
                          <h5 className="webcardtitle">
                          Extended Virtual Training - Development
                        </h5>
                          </span>
                    
                        </div>
                        <CardTitle tag="h2">R1599</CardTitle>
                        ,  
                    <ul className="align-left">
                    
                      <li>
                      <img 
                          alt="website"
                          className="webcardimg"
                          src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                          />
                      3 Hour Training Session
                      </li>
                      <li>
                      <img 
                          alt="website"
                          className="webcardimg"
                          src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                          />
                      Video Conference Screen Share
                      </li>
                  
                    </ul>
                  
                  <br></br>
                  <br></br>
                  <br></br>
                  <Product 
                  id="ExtendedVirtualTraining-Development"
                  title="Extended Virtual Training - Development"
                  price={1599.0}
                  rating={5}
                  image={require("assets/img/nlicons/web/barcode-qr.svg")}
                  />
                 <Link to="/contact">
                  <Button
                    className="btn-round card-link web-card-btn"
                    color="success"
                    href="" 
                  >
                    Get Started
                  </Button>
                  </Link>
                </CardBody>
              </Card>
            </Col>  
          </Row>
          </Container>
 
          </div>
        <ContactComponent />
      </div>
      <FooterNav />
    </>
  );
}

export default WebDevTraining;
