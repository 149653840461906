/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container } from "reactstrap";
import Typist from "react-typist";
import styled, { createGlobalStyle } from "styled-components";
import { Link } from 'react-router-dom'

// core comments
const StyledTypist = styled(Typist)`
  color: white;
  font-size: 2.5em;
  padding-top: 40px;
  text-align: left;
  line-height: 35px;
`;

const WeAre = styled.span`
color: rgb(47, 184, 252);
font-weight: bold;
`;

const Newspan = styled.span`
  font-weight: bold;
`;

const H2mb = styled.h1`
  margin-bottom: 30px;
  color: turquoise;
  font-weight: bold;
  font-size: 2em;
`;

const H2mb2 = styled.h1`
  margin-bottom: 30px;
  color: mediumorchid;
  font-weight: bold;
  font-size: 2em;

`;


const Newspan2 = styled.span`
vertical-align: text-top;
font-size: 3vw !important;
`;

function SMEHeader() {
  return (
    <>
      <div className="wrapper" id="header">
        <div
          className="page-header section-dark"
          style={{
            backgroundImage:
              "url(" + require("assets/img/nl/industries/sme/sa-team.jpg") + ")",
          }}
        >
            <div className="top-nav-small">
            <p className="top-nav-text">
                    <Link className="custom-link-custom" to="/">Home</Link> <span>&nbsp; { '>' } &nbsp; SME Solutions</span> 
                    </p>
            </div>
          <div className="content-center">
         
            <Container id="headercontainer">
                <h1 className="presentation-title-smaller-left2">Small and medium sized enterprise solutions</h1>
                
                
              <div className="title-brand">

              
              </div>
              <div>
              <StyledTypist loop={true}>
        <WeAre>Delivering</WeAre>
        <Newspan> a significant competitive advantage to your business</Newspan>
        <Typist.Backspace count={63} delay={800} />

        <Newspan>Streamline your budget and save money</Newspan>
        <Typist.Backspace count={37} delay={800} />

        <Newspan>Maximise your in-house resources</Newspan>
        <Typist.Backspace count={32} delay={800} />

        <Newspan>Gain access to all major technologies</Newspan>
        <Typist.Backspace count={37} delay={800} />

        

        <Newspan>
        We help businesses nationwide with top-shelf software solutions</Newspan>
      </StyledTypist>
              </div>
            </Container>
          </div>
        </div>
        <div className="wrapper">
        <div className="divider__img__div">
        <img 
         alt="divider"
         className="divider__img2"
         src={require("assets/img/nlbg/triangleNegative3.svg")}
         />         
       
        <img 
         alt="divider"
         className="divider__img2"
         src={require("assets/img/nlbg/triangleNegative.svg")}
         />
           <img 
         alt="divider"
         className="divider__img2"
         src={require("assets/img/nlbg/triangleNegative2.svg")}
         />     
                           
        </div>
           
        </div>
       
      </div>
    </>
  );
}

export default SMEHeader;
