import React from 'react';
import ContactComponent from './ContactComponent.js';
import ContactHeader from "components/Headers/Custom/ContactHeader.js";
import ColorNavbar from "components/Navbars/ColorNavbar";
import FooterNav from "./FooterNav.js";
import { Row, Col, Button } from 'reactstrap'

function getCurrentDate(separator='/'){

    let newDate = new Date()
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    
    return `${date}${separator}${month<10?`0${month}`:`${month}`}${separator}${year}`
    }
function ContactPage() {

  React.useEffect(() => {
    document.title = "Contact Us"
 });

  const { hash } = window.location;
    if (hash !== '') {
      // Push onto callback queue so it runs after the DOM is updated,
      // this is required when navigating from a different page so that
      // the element is rendered on the page before trying to getElementById.
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) element.scrollIntoView();
      }, 0);
    }
    
    document.documentElement.classList.remove("nav-open");
  // function that is being called on scroll of the page
  const checkScroll = () => {
    // it takes all the elements that have the .add-animation class on them
    const componentPosition = document.getElementsByClassName("add-animation");
    const scrollPosition = window.pageYOffset;
    for (var i = 0; i < componentPosition.length; i++) {
      var rec =
        componentPosition[i].getBoundingClientRect().top + window.scrollY + 100;
      // when the element with the .add-animation is in the scroll view,
      // the .animated class gets added to it, so it creates a nice fade in animation
      if (scrollPosition + window.innerHeight >= rec) {
        componentPosition[i].classList.add("animated");
        // when the element with the .add-animation is not in the scroll view,
        // the .animated class gets removed from it, so it creates a nice fade out animation
      } else if (scrollPosition + window.innerHeight * 0.8 < rec) {
        componentPosition[i].classList.remove("animated");
      }
    }
  };
  

  React.useEffect(() => {
    document.body.classList.add("presentation-page");
    window.addEventListener("scroll", checkScroll);
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("presentation-page");
      window.removeEventListener("scroll", checkScroll);
    };
  });
    return (
        <div>
        <ColorNavbar />
        <ContactHeader />
        <Row className="shift-up-50px px-20">
           
           <Col lg="4" md="4" sm="12" xs="12" className="col1">
   <div className="topcard2 z-index-2i">
   <hr className="h-line"></hr>
   <img 
               alt="maintenance"
               className="customcardimg-cont"
               src={require("assets/img/nlicons/contact/email.svg")}
               />
   
               
     <h2 id="titleblacksmall" className="align-left2">
     Submit a request
     </h2>
    <p className="tile-description">


    Send a request directly to us!
<br></br>
<br></br>

Typical response time: <strong>within 1 business day</strong>
    </p>
    <a href="#Contact-Section">
    <Button className="btn-move-right btn-outline-info margin-t15" id="topbtn2s">
                  Submit<i className="nc-icon nc-minimal-right" />
              </Button>
              </a>
   </div>
  
 
 </Col>
 <Col lg="4" md="4" sm="12" xs="12" className="col1">
 <div className="topcard2 z-index-2i">
 <hr className="h-line"></hr>

   <img 
               alt="maintenance"
               className="customcardimg-cont"
               src={require("assets/img/nlicons/contact/schedule.svg")}
               />
              
     <h2 id="titleblacksmall" className="align-left2">
     Schedule a meeting 
     </h2>
    <p className="tile-description">
    Set up a meeting with us:  
    <br></br>  
    <br></br>
    Earliest available date: <strong>{getCurrentDate()}</strong>
    </p>
    <a href="#Contact-Section">
    <Button className="btn-move-right btn-outline-info margin-t15" id="topbtn2c">
                  Schedule<i className="nc-icon nc-minimal-right" />
              </Button>
              </a>
    </div>
    
 
 </Col>
 <Col lg="4" md="4" sm="12" xs="12" className="col1">
 <div className="topcard2 z-index-2i">
 <hr className="h-line"></hr>

   <img 
               alt="maintenance"
               className="customcardimg-cont"
               src={require("assets/img/nlicons/contact/chat.svg")}
               />
              
     <h2 id="titleblacksmall" className="align-left2">
     Live Chat
     </h2>
    <p className="tile-description">
For an immediate response, make use of our live chat widget
<br></br>
<br></br>
(Located in the bottom right hand corner of the screen)
    </p>
    </div>
    
 
 </Col>
 </Row>
 <div id="Contact-Section">
         <ContactComponent /> 
         </div>
         <FooterNav />  
        </div>
    )
}
export default ContactPage