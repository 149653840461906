import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import ColorNavbar from "components/Navbars/ColorNavbar"; 
import ContactComponent from '../services/ContactComponent.js';

import WebHeaderTemplate from "./webHeaders/WebHeaderTemplate.js";
import FooterNav from "../services/FooterNav.js";
import {Link} from 'react-router-dom';

function WebAdWords() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
    };
  });
  return (
    <>
      <ColorNavbar />
      <WebHeaderTemplate navTitle="Google AdWords" Title="Google AdWords Campaigns" />
      <div className="wrapper">
        {/* ********* PRICING 3 ********* */}
        <div
          className="pricing-3 section-bg-img"
          style={{
            backgroundImage:
            "url(" + require("assets/img/nl/sections/custom/red-blue.jpg") + ")",
          }}
        >
           <Container>
           <Row>
              <Col lg="12" md="12" sm="12" xs="12">
                <h1 id="titlewhite3">
                        Campaigns
                        </h1>
                </Col>
                </Row>
          <Row>
            <Col md="4" className="col1">
              <Card className="card-pricing w-150">
              <h6 className="card-category">Budget</h6>
              
                <CardBody>
                <img 
                        alt="website"
                        className="webcardimg mx-4"
                        src={require("assets/img/nlicons/web/zoom-2.svg")}
                        />
                <div className="webcardicon my-5">
                    
                        <span>
                        <h5 className="webcardtitle">
                        Starter - Google AdWords Campaign
                      </h5>
                        </span>

                      </div>
                      <h6 className="card-category">2 MONTHS</h6>

                  <CardTitle tag="h2">R1199 PM</CardTitle>

                  <p className="servicep">
                  Our most affordable campaign.
                  This campaign is ideal for a basic campaign targeting a niche industry with a single product or landing page.
                  Basic keyword research, up to 15 keywords.

                  
                  
                  </p>
                  <div className="subscribe__div">
                  <a className="subscribe__btn" href="https://www.payfast.co.za/eng/process?cmd=_paynow&amp;receiver=17143900&amp;item_name=Starter+-+Google+AdWords+Campaign&amp;item_description=Our+most+affordable+campaign.+This+campaign+is+ideal+for+a+basic+campaign+targeting+a+niche+industry+with+a+single+product+or+landing+page.+Basic+keyword+research%2C+up+to+15+keywords.&amp;amount=1199.00&amp;return_url=https%3A%2F%2Fnextleveltechnology.org%2Fsuccess&amp;cancel_url=https%3A%2F%2Fnextleveltechnology.org%2FAdWords&amp;cycles=2&amp;frequency=3&amp;m_payment_id=pay_now_17143900&amp;subscription_type=1"><img src="https://www.payfast.co.za/images/buttons/dark-large-subscribe.png" width="210" height="59" alt="Subscribe" title="Subscribe Now with PayFast" /></a>
                  </div>

                 <Link to="/contact">
                  <Button
                    className="btn-round card-link web-card-btn"
                    color="success"
                    href="" 
                  >
                    Get Started
                  </Button>
                  </Link>
                </CardBody>
              </Card>
            </Col>
            <Col md="4" className="col1">
              <Card className="card-pricing w-150">
              <h6 className="card-category">Standard</h6>
              
                <CardBody>
                <img 
                        alt="website"
                        className="webcardimg mx-4"
                        src={require("assets/img/nlicons/web/zoom-2.svg")}
                        />
                <div className="webcardicon my-5">
                      
                        <span>
                        <h5 className="webcardtitle">
                        Business - Google AdWords Campaign
                      </h5>
                        </span>
                  
                      </div>
                      <h6 className="card-category">3 MONTHS</h6>
                  <CardTitle tag="h2">R1299 PM</CardTitle>
                  <p className="servicep">
                  Our most popular campaign.
Best suited for moderately sized campaigns targeting a common industry with a couple products or landing pages.
Moderate keyword research, up to 25 keywords.

 

                  </p>
                  <div className="subscribe__div">
                  <a className="subscribe__btn" href="https://www.payfast.co.za/eng/process?cmd=_paynow&amp;receiver=17143900&amp;item_name=Business+-+Google+AdWords+Campaign&amp;item_description=Our+most+popular+campaign.+Best+suited+for+moderately+sized+campaigns+targeting+a+common+industry+with+a+couple+products+or+landing+pages.+Moderate+keyword+research%2C+up+to+25+keywords.&amp;amount=1299.00&amp;return_url=https%3A%2F%2Fnextleveltechnology.org%2Fsuccess&amp;cancel_url=https%3A%2F%2Fnextleveltechnology.org%2FAdWords&amp;cycles=3&amp;frequency=3&amp;m_payment_id=pay_now_17143900&amp;subscription_type=1"><img src="https://www.payfast.co.za/images/buttons/dark-large-subscribe.png" width="210" height="59" alt="Subscribe" title="Subscribe Now with PayFast" /></a>                </div>
                 <Link to="/contact">
                  <Button
                    className="btn-round card-link web-card-btn"
                    color="success"
                    href="" 
                  >
                    Get Started
                  </Button>
                  </Link>
                </CardBody>
              </Card>
            </Col>
            <Col md="4" className="col1">
              <Card className="card-pricing w-150">
              <h6 className="card-category">Premium</h6>
                <CardBody>
                <img 
                        alt="website"
                        className="webcardimg mx-4"
                        src={require("assets/img/nlicons/web/zoom-2.svg")}
                        />
                <div className="webcardicon my-5">
                     
                        <span>
                        <h5 className="webcardtitle">
                        Professional - Google AdWords Campaign
                      </h5>
                        </span>
                  
                      </div>
                      <h6 className="card-category">5 MONTHS</h6>
                  <CardTitle tag="h2">R1999 PM</CardTitle>
                  <p className="servicep">
                  Comprehensive Campaign.
Advanced campaign management targeting a highly competitive industry with multiple products or landing pages.
Advanced keyword research, up to 75 keywords.

 
  
                  </p>
                  <br></br>
                  <br></br>
                  <br></br>
                  <div className="subscribe__div">
                  <a className="subscribe__btn" href="https://www.payfast.co.za/eng/process?cmd=_paynow&amp;receiver=17143900&amp;item_name=Professional+-+Google+AdWords+Campaign&amp;item_description=Comprehensive+Campaign.+Advanced+campaign+management+targeting+a+highly+competitive+industry+with+multiple+products+or+landing+pages.+Advanced+keyword+research%2C+up+to+75+keywords.&amp;amount=1999.00&amp;return_url=https%3A%2F%2Fnextleveltechnology.org%2Fsuccess&amp;cancel_url=https%3A%2F%2Fnextleveltechnology.org%2FAdWords&amp;cycles=5&amp;frequency=3&amp;m_payment_id=pay_now_17143900&amp;subscription_type=1"><img src="https://www.payfast.co.za/images/buttons/dark-large-subscribe.png" width="210" height="59" alt="Subscribe" title="Subscribe Now with PayFast" /></a>
                 </div>
                 <Link to="/contact">
                  <Button
                    className="btn-round card-link web-card-btn"
                    color="success"
                    href="" 
                  >
                    Get Started
                  </Button>
                  </Link>
                </CardBody>
              </Card>
            </Col>
            
            
          </Row>



        
          <Row>
              <Col lg="12" md="12" sm="12" xs="12">
                <h1 id="titlewhite4">
                        Weekly Management
                        </h1>
                </Col>
              </Row>
              <Row>
            <Col md="4" className="col1">
              <Card className="card-pricing w-150">
              <h6 className="card-category">Budget</h6>
              
                <CardBody>
                <img 
                        alt="website"
                        className="webcardimg mx-4"
                        src={require("assets/img/nlicons/web/zoom-2.svg")}
                        />
                <div className="webcardicon my-5">
                     
                        <span>
                        <h5 className="webcardtitle">
                        Starter Weekly Management 
                      </h5>
                        </span>
                  
                      </div>
                  <CardTitle tag="h2">R999 PM</CardTitle>
                  
                  <br></br>
                  <br></br>
                  <br></br>
                  1 Session per week
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <div className="subscribe__div">
                  <a className="subscribe__btn" href="https://www.payfast.co.za/eng/process?cmd=_paynow&amp;receiver=17143900&amp;item_name=Starter+Weekly+Management&amp;item_description=1+Session+per+week&amp;amount=999.00&amp;return_url=https%3A%2F%2Fnextleveltechnology.org%2Fsuccess&amp;cancel_url=https%3A%2F%2Fnextleveltechnology.org%2FAdWords&amp;cycles=0&amp;frequency=3&amp;m_payment_id=pay_now_17143900&amp;subscription_type=1"><img src="https://www.payfast.co.za/images/buttons/dark-large-subscribe.png" width="210" height="59" alt="Subscribe" title="Subscribe Now with PayFast" /></a>                 
                  </div>
                 <Link to="/contact">
                  <Button
                    className="btn-round card-link web-card-btn"
                    color="success"
                    href="" 
                  >
                    Get Started
                  </Button>
                  </Link>
                </CardBody>
              </Card>
            </Col>
            <Col md="4" className="col1">
              <Card className="card-pricing w-150">
              <h6 className="card-category">Standard</h6>
              
                <CardBody>
                <img 
                        alt="website"
                        className="webcardimg mx-4"
                        src={require("assets/img/nlicons/web/zoom-2.svg")}
                        />
                <div className="webcardicon my-5">
                      
                        <span>
                        <h5 className="webcardtitle">
                        Business Weekly Management
                      </h5>
                        </span>
                  
                      </div>
                  <CardTitle tag="h2">R1599 PM</CardTitle>
                  
                  <br></br>
                  <br></br>
                  <br></br>
                  3 Session per week
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <div className="subscribe__div">
                  <a className="subscribe__btn" href="https://www.payfast.co.za/eng/process?cmd=_paynow&amp;receiver=17143900&amp;item_name=Business+Weekly+Management&amp;item_description=3+Session+per+week&amp;amount=1599.00&amp;return_url=https%3A%2F%2Fnextleveltechnology.org%2Fsuccess&amp;cancel_url=https%3A%2F%2Fnextleveltechnology.org%2FAdWords&amp;cycles=0&amp;frequency=3&amp;m_payment_id=pay_now_17143900&amp;subscription_type=1"><img src="https://www.payfast.co.za/images/buttons/dark-large-subscribe.png" width="210" height="59" alt="Subscribe" title="Subscribe Now with PayFast" /></a>
                  </div>
                 <Link to="/contact">
                  <Button
                    className="btn-round card-link web-card-btn"
                    color="success"
                    href="" 
                  >
                    Get Started
                  </Button>
                  </Link>
                </CardBody>
              </Card>
            </Col>
            <Col md="4" className="col1">
              <Card className="card-pricing w-150">
              <h6 className="card-category">Premium</h6>
                <CardBody>
                <img 
                        alt="website"
                        className="webcardimg mx-4"
                        src={require("assets/img/nlicons/web/zoom-2.svg")}
                        />
                <div className="webcardicon my-5">
                     
                        <span>
                        <h5 className="webcardtitle">
                        Advanced Weekly Management
                      </h5>
                        </span>
                  
                      </div>
                  <CardTitle tag="h2">R2999 PM</CardTitle>
                 
                  <br></br>
                  <br></br>
                  <br></br>
                  5 Sessions per week
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <div className="subscribe__div">
                  <a className="subscribe__btn" href="https://www.payfast.co.za/eng/process?cmd=_paynow&amp;receiver=17143900&amp;item_name=Advanced+Weekly+Management&amp;item_description=5+Sessions+per+week&amp;amount=2999.00&amp;return_url=https%3A%2F%2Fnextleveltechnology.org%2Fsuccess&amp;cancel_url=https%3A%2F%2Fnextleveltechnology.org%2FAdWords&amp;cycles=0&amp;frequency=3&amp;m_payment_id=pay_now_17143900&amp;subscription_type=1"><img src="https://www.payfast.co.za/images/buttons/dark-large-subscribe.png" width="210" height="59" alt="Subscribe" title="Subscribe Now with PayFast" /></a>
                  </div>
                 <Link to="/contact">
                  <Button
                    className="btn-round card-link web-card-btn"
                    color="success"
                    href="" 
                  >
                    Get Started
                  </Button>
                  </Link>
                </CardBody>
              </Card>
            </Col>
            
            
          </Row>


          </Container>
        </div>
        {/* ********* END PRICING 3 ********* */}
        </div>

<ContactComponent />

        <FooterNav />



    </>
  );
}

export default WebAdWords;
