/*eslint-disable*/
import React from "react";
// react plugin used to create datetimepicker
import ReactDatetime from "react-datetime";
// react plugin used to create switch buttons
import Switch from "react-bootstrap-switch";
// react plugin used to create an image gallery
import { PhotoSwipeGallery } from "react-photoswipe";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Collapse,
  
  Container,
  Row,
  Col,
  
} from "reactstrap";


function Legacyfaq() {
 
  // collapse states and functions
  const [collapses, setCollapses] = React.useState([]);
  const changeCollapse = (collapse) => {
    if (collapses.includes(collapse)) {
      setCollapses(collapses.filter((prop) => prop !== collapse));
    } else {
      setCollapses([...collapses, collapse]);
    }
  };
  
  return (
    <>
      <div className="section section-blue javascript-components">
        <Container>
    
          <div>
            <h2 className="custom-title-main"><strong>Frequently Asked Questions</strong></h2>
          </div>
          <Row className="justify-center">
            <Col md="10">
              <div id="acordeon">
                <div aria-multiselectable={true} id="accordion" role="tablist">
                  <Card className="no-transition">
                    <CardHeader
                      className="card-collapse"
                      id="headingOne"
                      role="tab"
                    >
                      <h2 className="mb-0 custom-title">
                        <a
                          aria-expanded={collapses.includes(1)}
                          className="collapsed"
                          data-parent="#accordion"
                          href="#pablo"
                          id="collapseOne"
                          onClick={(e) => {
                            e.preventDefault();
                            changeCollapse(1);
                          }}
                        >
                         When does a software system need modernization?{" "}
                          <i className="nc-icon nc-minimal-down" />
                        </a>
                      </h2>
                    </CardHeader>
                    <Collapse isOpen={collapses.includes(1)}>
                      <CardBody className="collapse-text">
                      There is no strict rule, but generally, a system needs to be modernized or replaced when the 
                      technology that it was built on has been discontinued, the performance or functionality of 
                      the system is no longer up to par, or further enhancement would require more investment than 
                      building a new one from scratch. 
                      </CardBody>
                    </Collapse>
                    <CardHeader
                      className="card-collapse"
                      id="headingTwo"
                      role="tab"
                    >
                      <h2 className="mb-0 custom-title">
                        <a
                          aria-controls="collapseTwo"
                          aria-expanded={collapses.includes(2)}
                          className="collapsed"
                          data-parent="#accordion"
                          href="#pablo"
                          id="collapseTwo"
                          onClick={(e) => {
                            e.preventDefault();
                            changeCollapse(2);
                          }}
                        >
                          Can we reuse something from my legacy system?{" "}
                          <i className="nc-icon nc-minimal-down" />
                        </a>
                      </h2>
                    </CardHeader>
                    <Collapse isOpen={collapses.includes(2)}>
                    <CardBody className="collapse-text">
                    The main value of a good legacy system is its business logic, which can and should be maintained in 
                    the new system, though process automation capabilities will be upgraded. In some cases, a database 
                    can also be reused. Usually, however, the code cannot, especially if it was created on a different technology. 
                      </CardBody>
                    </Collapse>
                    <CardHeader
                      className="card-collapse"
                      id="headingThree"
                      role="tab"
                    >
                      <h2 className="mb-0 custom-title">
                        <a
                          aria-controls="collapseThree"
                          aria-expanded={collapses.includes(3)}
                          className="collapsed"
                          data-parent="#accordion"
                          href="#pablo"
                          id="collapseThree"
                          onClick={(e) => {
                            e.preventDefault();
                            changeCollapse(3);
                          }}
                        >
                          What are the benefits of modernizing a software system?{" "}
                          <i className="nc-icon nc-minimal-down" />
                        </a>
                      </h2>
                    </CardHeader>
                    <Collapse isOpen={collapses.includes(3)}>
                    <CardBody className="collapse-text">
                    Replacing a legacy system with a new one allows the owner to choose any technology with a sufficiently 
                    long lifespan. New technologies will provide speed, security, and usability that legacy systems built 
                    on old technology cannot achieve. Additionally, creating a modern replacement for a legacy system allows 
                    owners to consider any functionality changes and upgrades that weren’t on the table at the time the legacy 
                    system was created.   
                      </CardBody>
                    </Collapse>
                  </Card>
                </div>
                {/* end acordeon */}
              </div>
            </Col>
          </Row>
         
        </Container>
      </div>
    </>
  );
}

export default Legacyfaq;
