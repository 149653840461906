import React from "react";

// reactstrap components
import {Row, Col} from "reactstrap";

// core components
import ColorNavbar from "components/Navbars/ColorNavbar.js";
import ContactComponent from '../services/ContactComponent.js';

import RetailHeader from "components/Headers/Custom/RetailHeader.js";
import FooterNav from "../services/FooterNav.js";
import { Link } from 'react-router-dom'

function Retail() {
  document.documentElement.classList.remove("nav-open");
  // function that is being called on scroll of the page
  const checkScroll = () => {
    // it takes all the elements that have the .add-animation class on them
    const componentPosition = document.getElementsByClassName("add-animation");
    const scrollPosition = window.pageYOffset;
    for (var i = 0; i < componentPosition.length; i++) {
      var rec =
        componentPosition[i].getBoundingClientRect().top + window.scrollY + 100;
      // when the element with the .add-animation is in the scroll view,
      // the .animated class gets added to it, so it creates a nice fade in animation
      if (scrollPosition + window.innerHeight >= rec) {
        componentPosition[i].classList.add("animated");
        // when the element with the .add-animation is not in the scroll view,
        // the .animated class gets removed from it, so it creates a nice fade out animation
      } else if (scrollPosition + window.innerHeight * 0.8 < rec) {
        componentPosition[i].classList.remove("animated");
      }
    }
  };
  

  React.useEffect(() => {
    document.body.classList.add("presentation-page");
    window.addEventListener("scroll", checkScroll);
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("presentation-page");
      window.removeEventListener("scroll", checkScroll);
    };
  });
  return (
    <>
      <ColorNavbar />
      <RetailHeader />
      <div className="wrapper">
       
     
     
      <div>
           
      <Row>
      
                    <Col md="7">
                      <div className="topcard">
                        <h2 id="titleblack" className="align-left2">
                        Learn how NEXTLEVEL Is Bringing Wholesale and Retail into the Digital Age
                        </h2>
                       
                      </div>
                    
                    </Col>
                    <Col md="4">
                      
                  <p className="top-description">
                  Our software solutions help streamline operations and optimize processes for wholesalers and retailers, increasing margins, enhancing customer relationships, and improving the bottom line.
                  </p>
                 <br></br>
                 <br></br>
                 <br></br>
                 <br></br>
                 <br></br>
                 
                    </Col> 
                </Row>

                <Row>
                <Col md="6">
                    <div
          className="pricing-3 custom-software-div3"
          style={{
            backgroundImage:
              "url(" + require("assets/img/nlbg/bg4.jpg") + ")",
          }}
        >
                   
                    <h2 className="whath2fin padding-top-20"><span>Updating and Replacing Retailers’ & Wholesalers' Legacy ERP Systems</span></h2>
                    <br></br>
                    
                    <br></br>

                </div>
                    </Col>
                    
                    <Col md="6" className="minus-margin-left">
                    <img 
                    alt="warehouse"
                    className="sectionimg-retail"
                    src={require("assets/img/nl/industries/retail/warehouse3.jpg")}
                    />
                    </Col> 
                    
                </Row>
                <Row>
        <p className="top-description-center shift-down-50px">
        Too many wholesale and retail operators rely on obsolete legacy systems, built in the 1980s and ‘90s, to manage processes and logistics. NEXTLEVEL's software engineers can replace those outdate ERPs with custom software solutions designed to keep your business ahead of the competition.          </p>
        </Row>
              



                <div className="small-border">
                      <div className="box">
                      <Row className="shift-down-80px mx-10">
        <Col md="4">
          <img 
                         alt="Payments"
                         className="customcardimg"
                         src={require("assets/img/nlicons/retail/cashless-payment.svg")}
                         />
          <h3 id="titleblack2">Billing and Payments</h3>
          <br></br>
          <p className="top-description2">
          Billing may be the last step in the customer experience journey, but it’s also the most important. NEXTLEVEL's software developers are adept at creating custom solutions that streamline the billing process and keep customers satisfied through every step of the transaction.         
           </p>
          </Col>
        <Col md="4">
        <img 
                         alt="Inventory Management"
                         className="customcardimg"
                         src={require("assets/img/nlicons/retail/inventory.svg")}
                         />
          <h3 id="titleblack2">Inventory Management</h3>
          <br></br>
          <p className="top-description2">
          Keep your inventory up to date with our custom inventory management software solutions. With real-time stock reports and multi-warehouse inventory tracking, you can see what’s coming in and out and avoid the costly errors of both overstock and backorders.          </p>
        </Col>
        <Col md="4">
        <img 
                         alt="Order Management"
                         className="customcardimg"
                         src={require("assets/img/nlicons/retail/checklist.svg")}
                         />
          <h3 id="titleblack2">Order Management</h3>
          <br></br>
          <p className="top-description2">
          Streamline the sales and fulfillment process with the power to efficiently create and revise retail client orders, leverage buyer history to upsell and cross-sell relevant products to existing clients, and quickly process and track orders.
          </p>
        </Col>
        <Col md="4">
        <img 
                         alt="Fleet Management"
                         className="customcardimg"
                         src={require("assets/img/nlicons/retail/express-delivery.svg")}
                         />
          <h3 id="titleblack2">Fleet Management</h3>
          <br></br>
          <p className="top-description2">
          NEXTLEVEL's developers work with you to build fleet-management solutions that will optimize the performance of every vehicle in your fleet, ensuring the utmost safety, productivity, and efficiency throughout the lifecycle of each vehicle.           
          </p>
        </Col>
        <Col md="4">
        <img 
                         alt="Employee Management"
                         className="customcardimg"
                         src={require("assets/img/nlicons/retail/team.svg")}
                         />
          <h3 id="titleblack2">Employee Management</h3>
          <br></br>
          <p className="top-description2">
          From recruiting to hiring to training, NEXTLEVEL's custom software solutions optimize the people processes, as well, ensuring efficient and effective onboarding and coordination across the board.          </p>
        </Col>
        <Col md="4">
        <img 
                         alt="Vendor and Client Management"
                         className="customcardimg"
                         src={require("assets/img/nlicons/retail/team(1).svg")}
                         />
          <h3 id="titleblack2">Vendor and Client Management</h3>
          <br></br>
          <p className="top-description2">
          A custom CRM for wholesale distributors is a game changer, allowing your organization to keep every vendor’s and client’s data, history, and contact information in one place, and integrating with other systems to ensure the information is consistent, accurate, and up to date.          </p>
        </Col>
        <Col md="4">
        <img 
                         alt="Internet of Things"
                         className="customcardimg"
                         src={require("assets/img/nlicons/retail/insight.svg")}
                         />
          <h3 id="titleblack2">IoT Integration</h3>
          <br></br>
          <p className="top-description2">
          As IoT becomes more commonplace in the wholesale and retail industries, distributors can use sensors to improve operations in warehousing, transportation, equipment monitoring, and inventory management. But if the IoT components don’t communicate with the ERP or warehouse management software, they can cause more problems than they solve. NEXTLEVEL's team is experienced with IoT integrations, and can ensure all your tech is connected and working in sync.          </p>
        </Col>
        <Col md="4">
        <img 
                         alt="3rd Party Integration"
                         className="customcardimg"
                         src={require("assets/img/nlicons/retail/integration.svg")}
                         />
          <h3 id="titleblack2">Integrations with third-party systems </h3>
          <br></br>
          <p className="top-description2">
          Sometimes, too many systems can lead to extra work due to redundancies or data disparities between applications. But NEXTLEVEL's custom-built whole distribution software is designed to integrate with the third-party systems your organization already has in place, including ERPs, supply chain management systems, ecommerce platforms, and others.          </p>
        </Col>
        </Row>
                       
                      </div>
                  </div> 
                  <Row className="shift-down-100px">
                  <Col md="5" className="retail-img">
                    <img 
                    alt="Finance Applications"
                    className="sectionimg-retail"
                    src={require("assets/img/nl/industries/retail/truck2.jpg")}
                    />
                    </Col> 
                    <Col md="7">
                    <div
          className="pricing-3 custom-software-div3 px-20"
          style={{
            backgroundImage:
              "url(" + require("assets/img/nlbg/bg5.jpg") + ")",
          }}
        >
                    
                    <h2 className="whath2fin2 pr-10"><span>Building Software Solutions for Wholesale, Retail & Ecommerce</span></h2>
                    <br></br>
                    <h5 className="whath4ind mx-30"><span>ERP solutions based on flexible, modern technology stacks are key to success in the ever-shifting, digitally focused wholesale and retail market.</span></h5>
                <br></br>
                </div>
                    </Col>
                   
                </Row>


                

                <div className="small-border">
                      <div className="box">
                      <Row className="shift-down-80px px-20">
        <Col md="4">
          <img 
                         alt="Logistics"
                         className="customcardimg"
                         src={require("assets/img/nlicons/retail/cargo-ship.svg")}
                         />
          <h3 id="titleblack2">Wholesale Logistics<br></br>Management </h3>
          <br></br>
          <p className="top-description2">
          From supply chain management to order fulfillment, NEXTLEVEL's custom enterprise resource management solutions ensure a smooth journey from order to cash in every transaction.          </p>
          </Col>
        <Col md="4">
        <img 
                         alt="Commerce Software"
                         className="customcardimg"
                         src={require("assets/img/nlicons/retail/transfer.svg")}
                         />
          <h3 id="titleblack2">Retail Software</h3>
          <br></br>
          <p className="top-description2">
          We develop retail applications designed to support personalized shopping across a variety of platforms, infrastructures, and connected channels.</p>
          </Col>
        <Col md="4">
        <img 
                         alt="Ecommerce"
                         className="customcardimg"
                         src={require("assets/img/nlicons/retail/online-shopping.svg")}
                         />
          <Link to="/ecommerce">
          <h3 id="titleblack2" className="unl-onhover">Ecommerce Solutions</h3>
          </Link>
          <br></br>
          <p className="top-description2">
          With ever-evolving regulations and technical requirements, keeping aging systems up to date is no easy task. NEXTLEVEL's flexible solutions meet today’s requirements and anticipate tomorrow’s.        
          </p>
          </Col>
       
        </Row>
                       
                      </div>
                  </div> 

                  <div className="shift-down-100px">
      <Row>
      
                    <Col md="7">
                      <div className="topcard">
                        <h2 id="titleblack" className="align-left2">
                        The NEXTLEVEL Process
                        </h2>
                       
                      </div>
                    
                    </Col>
                    <Col md="4">
                      
                  
                    </Col> 
                </Row>

        </div>

        
               
        <div className="custom-industries">

          
            
            <Row>
              <Col md="4">
                <div className="info">
                  <div className="icon">
                  <img 
                         alt="Business Analysis"
                         className="customcardimg3"
                         src={require("assets/img/nlicons/retail/dashboard.svg")}
                         />
                  </div>
                  <div className="description">
                    <h4 className="info-title-black">Business Analysis</h4>
                    <p className="description-black">
                    Along with world-class software engineers, the NEXTLEVEL team includes business analysts who will work with your 
                    team to understand your organization’s unique needs, and then work with our engineers to design a platform that 
                    will fulfill those needs.
                    </p>
                    
                  </div>
                </div>
              </Col>
              <Col md="4">
              <div className="info">
                  <div className="icon icon-danger">
                  <img 
                         alt="Migration Plan"
                         className="customcardimg3"
                         src={require("assets/img/nlicons/retail/preparation.svg")}
                         />
                  </div>
                  <div className="description">
                    <h4 className="info-title-black">Migration Plan</h4>
                    <p className="description-black">
                    The last thing your organization needs when migrating from the old system to the new is to suspend operations due 
                    to technical issues. From the start, we devise a plan to ensure business continuity every step of the way.
                    </p>
                    
                  </div>
                </div>
              </Col>
              <Col md="4">
              <div className="info">
                  <div className="icon icon-danger">
                  <img 
                         alt="Scope"
                         className="customcardimg3"
                         src={require("assets/img/nlicons/retail/project.svg")}
                         />                  </div>
                  <div className="description">
                    <h4 className="info-title-black">Scope and Modules</h4>
                    <p className="description-black">
                    After we work up the scope of the full project, we split it into modules in order to get each piece up and 
                    running faster and more efficiently.
                    </p>
                    
                  </div>
                </div>
              </Col>
              <Col md="4">
              <div className="info">
                  <div className="icon icon-danger">
                  <img 
                         alt="Game Plan"
                         className="customcardimg3"
                         src={require("assets/img/nlicons/retail/project(1).svg")}
                         />                  </div>
                  <div className="description">
                    <h4 className="info-title-black">Software Development Game Plan</h4>
                    <p className="description-black">
                    Once we have our scope divided into individual modules, we work with you to outline detailed requirements for each 
                    module, then we create the development work plan.
                    </p>
                    
                  </div>
                </div>
              </Col>
            
            <Col md="4">
                <div className="info">
                  <div className="icon icon-danger">
                  <img 
                         alt="Project Implementation"
                         className="customcardimg3"
                         src={require("assets/img/nlicons/retail/project-launch.svg")}
                         />                  </div>
                  <div className="description">
                    <h4 className="info-title-black">Implementation</h4>
                    <p className="description-black">
                    We implement each module as it’s finished merging with and replacing the old system piece by piece, which means your 
                    new system is fully functional much more quickly.
                    </p>
                    
                  </div>
                </div>
              </Col>
              <Col md="4">
              <div className="info">
                  <div className="icon icon-danger">
                  <img 
                         alt="Training"
                         className="customcardimg3"
                         src={require("assets/img/nlicons/retail/training.svg")}
                         />                  </div>
                  <div className="description">
                    <h4 className="info-title-black">Personnel Training</h4>
                    <p className="description-black">
                    Any new system comes with a learning curve, and our team of developers and business analysts works with your 
                    back-office teams to ensure they’re confident and ready to use the new system.
                    </p>
                    
                  </div>
                </div>
              </Col>
              <Col md="4">
              <div className="info">
                  <div className="icon icon-danger">
                  <img 
                         alt="Data Migration"
                         className="customcardimg3"
                         src={require("assets/img/nlicons/retail/data.svg")}
                         />                  </div>
                  <div className="description">
                    <h4 className="info-title-black">Data Migration</h4>
                    <p className="description-black">
                    Your data is the core of your business, and it’s our job to ensure everything is successfully migrated to the 
                    new system without introducing any gaps, inconsistencies, or redundancies.
                    </p>
                    
                  </div>
                </div>
              </Col>

              <Col md="4">
              <div className="info">
                  <div className="icon icon-danger">
                  <img 
                         alt="System Sunset"
                         className="customcardimg3"
                         src={require("assets/img/nlicons/retail/processing(1).svg")}
                         />                  </div>
                  <div className="description">
                    <h4 className="info-title-black">Old System Sunset</h4>
                    <p className="description-black">
                    Once the new system is fully in place, it’s time to eliminate the old system altogether. Our team 
                    will orchestrate that process to ensure there are no surprises or gaps in operations.
                    </p>
                    
                  </div>
                </div>
              </Col>

              <Col md="4">
              <div className="info">
                  <div className="icon icon-danger">
                  <img 
                         alt="Support"
                         className="customcardimg3"
                         src={require("assets/img/nlicons/retail/engineering.svg")}
                         />                  </div>
                  <div className="description">
                    <h4 className="info-title-black">New System Support</h4>
                    <p className="description-black">
                    Our relationship with our clients doesn’t end at implementation. We’ll continue to work with you to 
                    maintain your new system, adding any enhancements, fixes, or adjustments needed.
                    </p>
                    
                  </div>
                </div>
              </Col>
              
            </Row>
        </div>
        <br></br>
             

        </div>



        <div
          className="pricing-3 custom-software-div3"
          style={{
            backgroundImage:
              "url(" + require("assets/img/nlbg/bg3.jpg") + ")",
          }}
        >

        <Row>
        <Col md="6" sm="12" xs="12">      


          </Col>
          <Col md="6" sm="12" xs="12">      
          <h1 className="whath2qa5"><span>Why Choose NEXTLEVEL</span></h1>      
            <ul className="qalist">
              <li><img 
                        alt="website"
                        className="webcardimg mx-4"
                        src={require("assets/img/nlicons/check-circle-white.svg")}
                        /><h5 className="whychoose">Comprehensive Experience</h5>Our team has diverse technology stack experience and our customers come from diverse industries. Whatever the problem, we can solve it efficiently. </li>
              <br></br>
              <li><img 
                        alt="website"
                        className="webcardimg mx-4"
                        src={require("assets/img/nlicons/check-circle-white.svg")}
                        /><h5 className="whychoose">Lean Business Model</h5>NEXTLEVEL uses a lean, global business model to create value for our 
                        customers. This model translates to better savings and higher-quality service than our competitors can offer. </li>
              <br></br>
              <li><img 
                        alt="website"
                        className="webcardimg mx-4"
                        src={require("assets/img/nlicons/check-circle-white.svg")}
                        /><h5 className="whychoose">Transparenct Pricing</h5>We believe that strong business relationships are built on trust and reliability. 
                        That's why we offer fair, transparent pricing at every stage of your project. We give you all the information 
                        you need to make the right business decisions. </li>
              <br></br>
              <li><img 
                        alt="website"
                        className="webcardimg mx-4"
                        src={require("assets/img/nlicons/check-circle-white.svg")}
                        /><h5 className="whychoose">Customer-Centric Culture</h5>NEXTLEVEL carefully selects each team member to align with our customer-centric culture. 
                        We're serious about providing the best quality software and service (at the best price) to add value for our customers. </li>
              <br></br>
              <li><img 
                        alt="website"
                        className="webcardimg mx-4"
                        src={require("assets/img/nlicons/check-circle-white.svg")}
                        /><h5 className="whychoose">Capacity to Scale</h5>NEXTLEVEL's team of expert engineers can scale any project. That means your software can grow with your business. </li>

            </ul>
            
            </Col>
        </Row>
        </div>

        <ContactComponent />
      </div>
     
      <FooterNav />
    </>
  );
}

export default Retail;
