import React, {useState} from "react";
import "./Checkout.css";
import SubtotalPay from "./SubtotalPay";
import { useStateValue } from "../StateProvider";
import CheckoutProduct from "./CheckoutProduct";
import ColorNavbar from "components/Navbars/ColorNavbar";
import {Row, Col} from 'reactstrap';
import FooterNav from "views/services/FooterNav.js";
import { validate, res } from 'validator';
import { useHistory } from "react-router-dom";
import { Form, FormGroup, Label, Input} from 'reactstrap'
import PaymentForm from "./PaymentForm";
import { getBasketTotal } from "../reducer";



function Payments() {
const history = useHistory();
const [{ basket, user }, dispatch] = useStateValue();




  const registerEmail = (e) => {
    let { email } = this.state.email;
    let res=validate.isEmail(email);
    if (res) {
      fetch('https://sandbox.payfast.co.za/eng/process', {
        method: 'post',
        body : JSON.stringify({
          EMAIL: email
        }),
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      })
      .then(JSON.parse(JSON)) // It parses the output
      .catch(function(error) {
        console.log("error---", error)
      });
    } else {
      e.preventDefault();
      alert("invalid email")
    }
  }
  const handleChange = (param, e) => {
    this.setState({ [param]: e.target.value })
  }
  
  
  

const items = 
  document.documentElement.classList.remove("nav-open");
  // function that is being called on scroll of the page
  const checkScroll = () => {
    // it takes all the elements that have the .add-animation class on them
    const componentPosition = document.getElementsByClassName("add-animation");
    const scrollPosition = window.pageYOffset;
    for (var i = 0; i < componentPosition.length; i++) {
      var rec =
        componentPosition[i].getBoundingClientRect().top + window.scrollY + 100;
      // when the element with the .add-animation is in the scroll view,
      // the .animated class gets added to it, so it creates a nice fade in animation
      if (scrollPosition + window.innerHeight >= rec) {
        componentPosition[i].classList.add("animated");
        // when the element with the .add-animation is not in the scroll view,
        // the .animated class gets removed from it, so it creates a nice fade out animation
      } else if (scrollPosition + window.innerHeight * 0.8 < rec) {
        componentPosition[i].classList.remove("animated");
      }
    }
  };
  React.useEffect(() => {
    document.body.classList.add("presentation-page");
    window.addEventListener("scroll", checkScroll);
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("presentation-page");
      window.removeEventListener("scroll", checkScroll);
    };
  });

  return (
    <>
    <div className="checkout">
    <ColorNavbar/>

      <div className="checkout__div">
        
        <Row>
        <Col md="8">
        <div className="white__bg">
          <br></br>
        
         <PaymentForm total={getBasketTotal(basket)} basket={basket}/>
           

        </div>
        </Col>
        <Col className="margin__subtotal" md="4">
        <div className="checkout__right">
        <SubtotalPay />
      </div>
        </Col>
        </Row>
        
      </div>

      
    </div>
    </>
  );
}

export default Payments;
