/*eslint-disable*/
import React from "react";
// react plugin used to create datetimepicker
import ReactDatetime from "react-datetime";
// react plugin used to create switch buttons
import Switch from "react-bootstrap-switch";
// react plugin used to create an image gallery
import { PhotoSwipeGallery } from "react-photoswipe";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Collapse,
  
  Container,
  Row,
  Col,
  
} from "reactstrap";


function SeoOnpage() {
 
  // collapse states and functions
  const [collapses, setCollapses] = React.useState([]);
  const changeCollapse = (collapse) => {
    if (collapses.includes(collapse)) {
      setCollapses(collapses.filter((prop) => prop !== collapse));
    } else {
      setCollapses([...collapses, collapse]);
    }
  };
  
  return (
    <>
      <div className="section section-blue javascript-components shift-up-150px bottom-up-150px background-tp">
        <Container>
    
          
          <Row className="justify-center">
            <Col md="12">
              <div id="acordeon">
                <div aria-multiselectable={true} id="accordion" role="tablist">
                  <Card className="no-transition">
                    <CardHeader
                      className="card-collapse"
                      id="headingOne"
                      role="tab"
                    >
                      <h2 className="mb-0 custom-title">
                        <a
                          aria-expanded={collapses.includes(1)}
                          className="collapsed"
                          data-parent="#accordion"
                          href=""
                          id="collapseOne"
                          onClick={(e) => {
                            e.preventDefault();
                            changeCollapse(1);
                          }}
                        >
                         On Page SEO Breakdown{" "}
                          <i className="nc-icon nc-minimal-down" />
                        </a>
                      </h2>
                    </CardHeader>
                    <Collapse isOpen={collapses.includes(1)}>
                    <br></br>
                      <CardBody className="collapse-text align-left">
                      <a>
                        <a className="mb-20">
                        <strong>
                        KEYWORD IN THE TITLE TAG  
                    </strong>
                        </a>
                        <br></br>
                      <br></br>
                      The title meta tag is one of the strongest relevancy signals for a search engine. The tag itself is meant to give the accurate description of the pages content. Search engines use it to display the main title of a search result. Including a keyword in it will indicate to search engine what to rank the page for.
Ideally, the keyword should be placed at the start of the title tag. Pages optimized this way will rank better than those with keyword closer to the title’s tag end.
                      </a>
<br></br>
<br></br>
                      <a>
                      <strong>
                      KEYWORD IN META DESCRIPTION TAG   
                    </strong>
                      <br></br>
                      <br></br>
                      The importance of the meta description tag today is often discussed in SEO circles. It is nonetheless still a relevancy signal. It is also crucial for gaining user clicks from search results pages. Including the keyword in it makes it more relevant to a search engine and a searcher.
                      </a>
                      <br></br>
<br></br>
                      <a>
                      <strong>
                      KEYWORD IN H1 TAG 
                    </strong>
                      <br></br>
                      <br></br>
                      H1 tag is yet another relevance factor, serving as a description of the pages content. In spite of an
                      <a className="custom-link6" href="https://backlinko.com/how-to-get-backlinks" target="_blank"> ongoing discussion </a>about its importance, it is still a good practice to include your keyword in a unique H1 tag on a page.
                      </a>
                      <br></br>
<br></br>
                      <a>
                      <strong>
                      USING KEYWORDS IN THE PAGES COPY
                    </strong>
                      <br></br>
                      <br></br>
Up until recently, stuffing your page with keywords was a surefire way to increase its rankings for a particular keyword. That’s not the case anymore. Using the keyword in the copy still sends a relevancy signal of what the content is about. How you place it, however, has changed drastically.
                      </a>
                      <br></br>
<br></br>
                      <a>
                      <strong>
                      THE LENGTH OF THE CONTENT
                    </strong>
                      <br></br>
                      <br></br>
These days searchers want to be educated and won’t satisfy with basic information. Google, therefore, looks for authoritative and informative content to rank first. And it’s common sense that the longer your content is, the greater the chance that you can cover more aspects of your topic. Don’t be shy of writing long but highly useful copy then.
</a>
<br></br>
<br></br>
                      <a>
                      <strong>
                      DUPLICATE CONTENT
                    </strong>
                      <br></br>
                      <br></br>
Not all factors can influence your rankings in a positive way. Having similar content across various pages of your site can actually hurt your rankings. Avoid duplicating content and write original copy for each page.
                      </a>
                      <br></br>
                      <br></br>

                      <a>
                      <strong>
                      CANONICAL TAG 
                    </strong>
                      <br></br>
                      <br></br>
                      Sometimes, however, having two URLs with similar content is unavoidable. One of the ways from preventing this from becoming a duplicate content issue is by using a <a className="custom-link6" href="https://positionly.com/blog/seo/canonical-url-tag" target="_blank">canonical tag</a> on your site. This tag does one simple thing; it tells Google that one URL is equivalent of another, clearly stating that in spite of two pages having the same content, they are in fact one.
                      </a>
                      <br></br>
                      <br></br>
                       
                      <a>
                      <strong>
                      IMAGE OPTIMIZATION  
                    </strong>
                      <br></br>
                      <br></br>
                      It’s not only text that can be optimized on a page but other media too. Images, for instance, can send the search engine relevancy signals <a className="custom-link6" href="https://unamo.com/university/search-engine-optimization" target="_blank">through their alt text</a>, caption, and description for example.
                      </a>
                      <br></br>
                      <br></br>
                       
                      <a>
                      <strong>
                      CONTENT UPDATES
                    </strong>
                      <br></br>
                      <br></br>
                    Google algorithm prefers freshly updated content. It does not mean that you have to edit your pages all the time. 
                    I believe that for commercial pages, such as product descriptions Google recognizes the fact that they are not as 
                    time sensitive as blog posts covering recent events. It is wise however to include some strategy to update certain 
                    types of content once every 12 months or so.
                      </a>
                      <br></br>
                      <br></br>

                      <a>
                      <strong>
                      OUTBOUND LINKS
                    </strong>
                      <br></br>
                      <br></br>
                    Linking to authoritative pages sends trust signals to the search engine. Think of it this way, the only reason why you would 
                    send a user to another site is if you wanted them to learn more of the subject. This can be a huge trust factor for Google. 
                    Too many outbound links, however, can significantly diminish the page’s PageRank, hurting its search visibility. 
                    Outbound links can affect your rankings but use them in moderation.
                      </a>
                      <br></br>
                      <br></br>

                      <a>
                      <strong>
                      INTERNAL LINKS
                    </strong>
                      <br></br>
                      <br></br>
Interlinking pages on your site can pass their strength between them.
                      </a>
                      <br></br>
                      <br></br>

                      <a>
                      <strong>
                      KEYWORD IN URL
                    </strong>
                      <br></br>
                      <br></br>
Including the keyword in the URL slug (that’s the bit that appears after the “.com/“part of the URL) is said to send another relevancy signal to Google.
                      </a>
                      <br></br>
                      <br></br>

                      <a>
                    <h4>
                    <strong>
                      On Site factors:
                    </strong>
                    </h4>
                     <strong>
                     There are certain site-wide factors that can affect your site’s search visibility as well:                      
                     </strong>
</a>
                      <br></br>
                      <br></br>

                      <a>
                      <strong>
                      SITEMAP
                    </strong>
                      <br></br>
                      <br></br>
A sitemap helps search engine to index all pages on your site. It is the simplest and most efficient way to tell Google what pages your website includes.
                      </a>
                      <br></br>
                      <br></br>

                      <a>
                      <strong>
                      DOMAIN TRUST
                    </strong>
                      <br></br>
                      <br></br>
Trust matters. It’s hard no to think that sites Google trusts should rank higher. But how do you build that trust? Backlinko has a full list of trust factors <a className="custom-link6" href="https://backlinko.com/how-to-get-backlinks" target="_blank">here</a>. Needless to say, building trust factors of your domain will certainly pay off.
                      </a>
                      <br></br>
                      <br></br>

                      <a>
                      <strong>
                      SERVER LOCATION
                    </strong>
                      <br></br>
                      <br></br>
Some SEOs believe that a server’s location helps to boost rankings for that particular country or region.
                      </a>
                      <br></br>
                      <br></br>

                      <a>
                      <strong>
                      MOBILE OPTIMIZED SITE
                    </strong>
                      <br></br>
                      <br></br>
Only a year ago, 46% of searchers used mobile exclusively to research. I believe this number increased exponentially in the last 12 months. It would be no surprise then that having a mobile optimized site would affect rankings in some way.
                      </a>
                      <br></br>
                      <br></br>
                      </CardBody>
                    </Collapse>
                 
                  </Card>
                </div>
                {/* end acordeon */}
              </div>
            </Col>
          </Row>
         
        </Container>
      </div>
    </>
  );
}

export default SeoOnpage;
