import React from 'react';
import * as emailjs from 'emailjs-com';
import { Container, Form, FormGroup, Label, Input, Row, Col, Card, CardTitle } from 'reactstrap'
import "./Checkout.css";
import { getBasketTotal } from "../reducer";
import { useHistory } from "react-router-dom";
import { useStateValue } from "../StateProvider";


  

class PaymentForm extends React.Component {

  state = {
    name: '',
    email: '',
    subject: '',
    message: '',
  };

submitForms(e){
    var md5 = require('md5');
    const MD5Signature = md5(params.toString());

    const params = new URLSearchParams({
        merchant_id: "10023189",
        merchant_key: "896xb3quv52iv",
        return_url: "https://nextleveltechnology.org/contact",
        cancel_url: "https://nextleveltechnology.org/payments",
        notify_url: "https://nextleveltechnology.org/contact",
        name_first: this.state.name,
        email_address: this.state.email,
        m_payment_id: this.state.email,
        amount: this.props.total,
        item_name: "NEXTLEVEL Technology Product",
        item_description: this.props.basket.map(item => ([item.name,])),
        custom_int1: 1,
        custom_str1: "custom_str1",
        passphrase: "NEXTLEVEL 234 layer of security"
      });

    e.preventDefault()
    document.getElementById("signature").value=MD5Signature;
    document.getElementById("form1").submit();
    const { name, email, subject, message } = this.state
    let templateParams = {
      email: email,
      name: name,
      subject: subject,
      message: message,
     }
     alert(this.props.total)
    this.resetForm()

}
 
  

handleSubmit = (e)=> {
    e.preventDefault()
    document.getElementById("form1").submit();
    const { name, email, subject, message } = this.state
    let templateParams = {
      email: email,
      name: name,
      subject: subject,
      message: message,
     }
     alert("You are now being directed to NEXTLEVEL Technoglogy Payment Gateway - Powered by PayFast")
    this.resetForm()

     
 }
resetForm() {
    this.setState({
      name: '',
      email: '',
      subject: '',
      message: '',
    })
  }
handleChange = (param, e) => {
    this.setState({ [param]: e.target.value })
  }
  
render() {
    
  return (
<>
    

<div
      id="payment__form"
  className="contactBg2 cd-section"
style={{
  backgroundImage:
    "url(" + require("assets/img/nl/capetown.jpg") + ")",
}}
>
<form id="form1" action="https://www.payfast.co.za/eng/process" method="POST" >
          <input type="hidden" name="merchant_id" value="17143900" />
          <input type="hidden" name="merchant_key" value="6adaf4tw76gx4" />
          <input type="hidden" name="return_url" value="https://nextleveltechnology.org/contact" />
          <input type="hidden" name="cancel_url" value="https://nextleveltechnology.org/payments" />
          <input type="hidden" name="notify_url" value="https://nextleveltechnology.org/contact" />
          <input type="hidden" name="name_first" value={this.props.name} />
          <input type="hidden" name="email_address" value={this.props.email} />
          <input type="hidden" name="m_payment_id" value={this.props.email} />
          <input type="hidden" name="amount" value={this.props.total} />
          <input type="hidden" name="item_name" value="NEXTLEVEL Technology" />
          <input type="hidden" name="item_description" value={this.props.basket.map(item => ([item.name,]))} />
          <input type="hidden" name="custom_int1" value="1" />
          <input type="hidden" name="custom_str1" value="custom_str1" />
          <input type="hidden" name="passphrase" value="NEXTLEVEL 234 layer of security" />
          <input type="hidden" name="signature" value="" />
          
        </form>
          <Container>
            <Row className="col-mob">
              <Col className="ml-auto" md="11" sm="12" xs="12">
                <Card className="card-contact no-transition">
                  <CardTitle className="text-center" tag="h3">
                  <h3 className="titleblack11 align-left">Payment Details</h3>

                  </CardTitle>
                  <Row>
                    
                    <Col className="ml-auto" md="10" sm="12" xs="12">
          <Form id="form2" onSubmit={this.handleSubmit.bind(this)}>
          <FormGroup controlId="formBasicName" className="align-left">
              <Label className="text-contact2">Name</Label>
              <Input
                type="text"
                name="name"
                value={this.state.name}
                className="headcontact2"
                onChange={this.handleChange.bind(this, 'name')}
                placeholder="Enter Name..."
              />
            </FormGroup>
            <FormGroup controlId="formBasicEmail" className="align-left">
              <Label className="text-contact2">Email Address</Label>
              <Input
                type="email"
                name="email"
                value={this.state.email}
                className="headcontact2"
                onChange={this.handleChange.bind(this, 'email')}
                placeholder="Email Address..."
              />
            </FormGroup>

<FormGroup controlId="formBasicSubject" className="align-left">
              <Label className="text-contact2">Telephone Number</Label>
              <Input
                type="text"
                name="subject"
                className="headcontact2"
                value={this.state.subject}
                onChange={this.handleChange.bind(this, 'subject')}
                placeholder="Contact Number..."
              />
            </FormGroup>

            <button color="info" className="checkout__details__button" type="submit">
           
            <img 
            alt="secure_gateway"
            src={require("assets/img/nlicons/button/lock.png")}
            className="button-icon" />
            <img 
            alt="secure_payment"
            src={require("assets/img/nlicons/button/shield.png")} 
            className="button-icon" />

              Proceed To Payment 
            </button>
            
            <br></br>
            <br></br>
            <br></br>
           

          </Form>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        </>
    )
  }
}
export default PaymentForm
  

  