import React from "react";
// reactstrap components
import {
  Row,
  Col,
} from "reactstrap";

// core components
import FinanceHeader from "components/Headers/Custom/FinanceHeader.js";
import ColorNavbar from "components/Navbars/ColorNavbar"; 
import ContactComponent from '../services/ContactComponent.js';

import FooterNav from "../services/FooterNav.js";


function Finance() {
  
 

  document.documentElement.classList.remove("nav-open");
  // function that is being called on scroll of the page
  const checkScroll = () => {
    // it takes all the elements that have the .add-animation class on them
    const componentPosition = document.getElementsByClassName("add-animation");
    const scrollPosition = window.pageYOffset;
    for (var i = 0; i < componentPosition.length; i++) {
      var rec =
        componentPosition[i].getBoundingClientRect().top + window.scrollY + 100;
      // when the element with the .add-animation is in the scroll view,
      // the .animated class gets added to it, so it creates a nice fade in animation
      if (scrollPosition + window.innerHeight >= rec) {
        componentPosition[i].classList.add("animated");
        // when the element with the .add-animation is not in the scroll view,
        // the .animated class gets removed from it, so it creates a nice fade out animation
      } else if (scrollPosition + window.innerHeight * 0.8 < rec) {
        componentPosition[i].classList.remove("animated");
      }
    }
  };
  

  React.useEffect(() => {
    document.body.classList.add("presentation-page");
    window.addEventListener("scroll", checkScroll);
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("presentation-page");
      window.removeEventListener("scroll", checkScroll);
    };
  });
  return (
    <>
    
      <ColorNavbar />
      <FinanceHeader />
      <div className="wrapper">
       
     
     
      <div>
           
      <Row>
      
                    <Col md="7">
                      <div className="topcard">
                        <h2 id="titleblack3" className="align-left2">
                        Let NEXTLEVEL bring your financial services organization into the digital era with software that’s as sharp as you are
                        </h2>
                       
                      </div>
                    
                    </Col>
                    <Col md="4">
                      
                  <p className="top-description mx-20">
                  In today’s world of digital transformation, financial services companies are juggling heightened regulatory and data security 
                  requirements, increased risk of attack, and growing customer expectations for connectivity, transparency, flexibility, and 
                  a seamless user experience. At NEXTLEVEL, our expert business analysts and developers partner with financial services companies 
                  to develop the software solutions they need to achieve that frictionless experience with world-class security.
                  </p>
                 <br></br>
                 <br></br>
                 <br></br>
                    </Col> 
                </Row>

                <Row>
                    
                    <Col md="5" className="z-index-5">
                    <img 
                    alt="SARB"
                    className="sectionimg-sarb"
                    src={require("assets/img/nl/industries/fin/sarb.jpg")}
                    />
                    </Col> 
                    <Col md="7">
                    <div
          className="pricing-3 custom-software-div3 pl-20 pr-20"
          style={{
            backgroundImage:
              "url(" + require("assets/img/nl/industries/fin/geometric.jpg") + ")",
          }}
        >
                   
                    <h2 className="whath2fin2"><span>Your customers’ security is our priority</span></h2>
                    <br></br>
                    <h5 className="whath4ind"><span>Work side by side with NEXTLEVEL to hone your risk management strategies, and our engineers will develop the software you need to provide a flawless customer experience.
                    </span></h5>
                    <br></br>

                </div>
                    </Col>
                </Row>
                


                <div className="small-border">
                      <div className="box">
                      <Row className="shift-down-80px">
        <Col md="4">
          <img 
                         alt="Customer Experience"
                         className="customcardimg"
                         src={require("assets/img/nlicons/fin/user-experience.svg")}
                         />
          <h3 id="titleblack2">Enhance Customer Experience</h3>
          <br></br>
          <p className="top-description2">
          The digital transformation has led customers to expect increased connectivity, but these demands bring extra security challenges. With NEXTLEVEL's custom software, you can mitigate risk and give customers the experience they want.</p>
          </Col>
        <Col md="4">
        <img 
                         alt="Business Processes"
                         className="customcardimg"
                         src={require("assets/img/nlicons/fin/processing.svg")}
                         />
          <h3 id="titleblack2">Streamline Your Business Processes</h3>
          <br></br>
          <p className="top-description2">
          Strict regulatory requirements can encumber process and prevent the kind of growth businesses want to achieve. NEXTLEVEL can build the software solutions you need to remain agile and compliant.          </p>
        </Col>
        <Col md="4">
        <img 
                         alt="Talent"
                         className="customcardimg"
                         src={require("assets/img/nlicons/fin/personality.svg")}
                         />
          <h3 id="titleblack2"> Attract and Retain Top Talent</h3>
          <br></br>
          <p className="top-description2">
          Today’s employees want to work from anywhere, on any device. But in the financial services world, that kind of leeway can cause major headaches. Our engineers can help give your employees the flexibility they want without compromising data security.</p>
        </Col>
        <Col md="4">
        <img 
                         alt="Cyber Security"
                         className="customcardimg"
                         src={require("assets/img/nlicons/fin/data-security.svg")}
                         />
          <h3 id="titleblack2">Protect Against Cyber Attacks</h3>
          <br></br>
          <p className="top-description2">
          A security breach is every financial services firm’s worst nightmare, and digitization only increases the risks. NEXTLEVEL's dedicated team can deliver secure, compliant software — and monitor it consistently to prevent attacks.          </p>
        </Col>
        </Row>
                       
                      </div>
                  </div> 


                  <Row className="shift-down-130px">
                    <Col md="7">
                    <div
          className="pricing-3 custom-software-div4"
          style={{
            backgroundImage:
              "url(" + require("assets/img/nl/industries/fin/geometric.jpg") + ")",
          }}
        >
                    
                    <h2 className="whath2fin2"><span>Specific Applications</span></h2>
                    <br></br>
                    <h5 className="whath4ind mx-30"><span>From database management to mobile applications, NEXTLEVEL team of software engineers are ready to create custom solutions to streamline every area of your financial services business.</span></h5>
                <br></br>
                </div>
                    </Col>
                    <Col md="5" className="">
                    <img 
                    alt="Finance Applications"
                    className="sectionimg-sme2"
                    src={require("assets/img/nl/industries/fin/finapp.jpg")}
                    />
                    </Col> 
                </Row>

                <div className="small-border">
                      <div className="box">
                      <Row className="shift-down-80px">
        <Col md="4">
          <img 
                         alt="Data Management"
                         className="customcardimg"
                         src={require("assets/img/nlicons/fin/database-storage.svg")}
                         />
          <h3 id="titleblack2">Data Management</h3>
          <br></br>
          <p className="top-description2">
          Data is the heart of every business, but the stakes are highest in the financial industry. NEXTLEVEL's software solutions help our clients manage and organize data while paying close attention to security and regulatory compliance.          
          </p>
          </Col>
        <Col md="4">
        <img 
                         alt="Banking Digitisation"
                         className="customcardimg"
                         src={require("assets/img/nlicons/fin/bank-online.svg")}
                         />
          <h3 id="titleblack2">Banking Digitisation</h3>
          <br></br>
          <p className="top-description2">
          We create software solutions that bring financial services into the digital world where customers live. From cloud services to mobile apps, we help our customers stay ahead of the curve.       
          </p>
          </Col>
        <Col md="4">
        <img 
                         alt="System Modernisation"
                         className="customcardimg"
                         src={require("assets/img/nlicons/fin/rocket.svg")}
                         />
          <h3 id="titleblack2">System Modernisation</h3>
          <br></br>
          <p className="top-description2">
          With ever-evolving regulations and technical requirements, keeping aging systems up to date is no easy task. NEXTLEVEL's flexible solutions meet today’s requirements and anticipate tomorrow’s.        
          </p>
          </Col>
        <Col md="4">
        <img 
                         alt="Process Management"
                         className="customcardimg"
                         src={require("assets/img/nlicons/fin/money.svg")}
                         />
          <h3 id="titleblack2">Process Management</h3>
          <br></br>
          <p className="top-description2">
          NEXTLEVEL's applications help our financial customers streamline internal and external processes to meet consumers’ demands for efficiency, transparency and personalized service.          
          </p>
        </Col>
        <Col md="4">
        <img 
                         alt="Blockchain"
                         className="customcardimg"
                         src={require("assets/img/nlicons/fin/blockchain.svg")}
                         />
          <h3 id="titleblack2">Blockchain</h3>
          <br></br>
          <p className="top-description2">
          Blockchain is revolutionizing the tech landscape. From strategy to implementation, the NEXTLEVEL team is ready to help clients apply the latest innovations to their systems in practical, beneficial ways.          
          </p>
        </Col>
        </Row>
                       
                      </div>
                  </div> 

                  <div className="shift-down-100px">
                  <img 
                        alt="Design Process 1"
                        id="processhorizontal"
                        className="process-img-h"
                        src={require("assets/img/nlscreenshots/financewide.png")}
                        />
         <img 
                        alt="Design Process 2"
                        id="processvertical"
                        className="process-img-fin"
                        src={require("assets/img/nlscreenshots/financesmall1.png")}
                        />
        <img 
                        alt="Design Process 3"
                        id="processvertical"
                        className="process-img-fin"
                        src={require("assets/img/nlscreenshots/financesmall2.png")}
                        />
        <img 
                        alt="Design Process 4"
                        id="processvertical"
                        className="process-img-fin"
                        src={require("assets/img/nlscreenshots/financesmall3.png")}
                        />                         
                  </div>


                  

                <Row className="shift-down-130px">
                    
                    <Col md="5" className="">
                    <img 
                    alt="summit"
                    className="sectionimg-sme2"
                    src={require("assets/img/nl/industries/fin/summit2.jpg")}
                    />
                    </Col> 
                    <Col md="7">
                    <div
          className="pricing-3 custom-software-div3"
          style={{
            backgroundImage:
              "url(" + require("assets/img/nl/industries/fin/geometric.jpg") + ")",
          }}
        >
                   
                    <h2 className="whath2fin2"><span>Why Choose NEXTLEVEL</span></h2>
                    <br></br>
                    <h5 className="whath4ind"><span>NEXTLEVEL is more than a vendor. Our dedicated team of developers and business analysts are ready to partner with you to implement the custom solutions that streamline your operations and empower you to focus on the core of your business.
                    </span></h5>
                    <br></br>

                </div>
                    </Col>
                </Row>

                <div className="small-border">
                      <div className="box">
                      <Row className="shift-down-80px">
        <Col md="4">
          <img 
                         alt="Customer Approach"
                         className="customcardimg"
                         src={require("assets/img/nlicons/fin/target.svg")}
                         />
          <h3 id="titleblack2">Customer-First Approach</h3>
          <br></br>
          <p className="top-description2">
          NEXTLEVEL provides a full professional development team with a customer-oriented approach.          
          </p>
          </Col>
        <Col md="4">
        <img 
                         alt="Technology Experience"
                         className="customcardimg"
                         src={require("assets/img/nlicons/fin/skills.svg")}
                         />
          <h3 id="titleblack2">Broad Technology Expertise</h3>
          <br></br>
          <p className="top-description2">
          Our diverse technology stack experience and expertise in the financial services sphere mean we can partner with clients to tailor effective solutions to any need.          </p>
          </Col>
        <Col md="4">
        <img 
                         alt="User Interface"
                         className="customcardimg"
                         src={require("assets/img/nlicons/fin/online-community.svg")}
                         />
          <h3 id="titleblack2">Sleek, User-Friendly Design</h3>
          <br></br>
          <p className="top-description2">
          Our UI and UX experts can give financial institutions a “digital makeover,” with visually appealing apps that promote a better customer experience.          </p>
          </Col>
        <Col md="4">
        <img 
                         alt="Security"
                         className="customcardimg"
                         src={require("assets/img/nlicons/fin/shield.svg")}
                         />
          <h3 id="titleblack2">Locked-Down Security </h3>
          <br></br>
          <p className="top-description2">
          We understand the need for bulletproof security, and we pair preventative strategies with airtight incident response plans to keep your customers’ financial information safe.          </p>
        </Col>
       
        </Row>
                       
                      </div>
                  </div> 

        </div>



       
        <ContactComponent />
      </div>
     
      <FooterNav />
    </>
    
  );
}

export default Finance;
