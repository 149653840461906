import React from "react";

// reactstrap components
import { Row, Col,} from "reactstrap";
import FooterNav from "../services/FooterNav.js";
import BAHeader from "components/Headers/Custom/BAHeader.js";

// core components
import ColorNavbar from "components/Navbars/ColorNavbar.js";
import ContactComponent from './ContactComponent.js';


function BusinessAnalysis() {
  
  document.documentElement.classList.remove("nav-open");
  // function that is being called on scroll of the page
  const checkScroll = () => {
    // it takes all the elements that have the .add-animation class on them
    const componentPosition = document.getElementsByClassName("add-animation");
    const scrollPosition = window.pageYOffset;
    for (var i = 0; i < componentPosition.length; i++) {
      var rec =
        componentPosition[i].getBoundingClientRect().top + window.scrollY + 100;
      // when the element with the .add-animation is in the scroll view,
      // the .animated class gets added to it, so it creates a nice fade in animation
      if (scrollPosition + window.innerHeight >= rec) {
        componentPosition[i].classList.add("animated");
        // when the element with the .add-animation is not in the scroll view,
        // the .animated class gets removed from it, so it creates a nice fade out animation
      } else if (scrollPosition + window.innerHeight * 0.8 < rec) {
        componentPosition[i].classList.remove("animated");
      }
    }
  };
  React.useEffect(() => {
    document.title = "Business Analysis"
 });

  React.useEffect(() => {
    document.body.classList.add("presentation-page");
    window.addEventListener("scroll", checkScroll);
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("presentation-page");
      window.removeEventListener("scroll", checkScroll);
    };
  });
  return (
    <>
      <ColorNavbar />
      <BAHeader />
      <div className="wrapper">
       
     
     
      <div>
           
      <Row>
      
                    <Col md="7">
                      <div className="topcard">
                        <h2 id="titleblack" className="align-left2">
                        NEXTLEVEL provides specialised insight into business requirements and functional design analysis
                        </h2>
                       
                      </div>
                    
                    </Col>
                    <Col md="4">
                      
                  <p className="top-description-left mx-20">
                  If your business needs a piece of software or an application developed, business analysis fulfills
                  several crucial roles in the software development process. Such as helping to identify the problem, 
                  reviewing your competitors’ efforts, and analyzing the market in which you operate. 
                  We record the entire process and create a formal specification detailing the needs of your company,
                  to be used as a blueprint for the concept creation.

</p>
                 
                    </Col> 
                </Row>

        </div>
        


        <div className="shift-down-50px">
        
        
                    
                    <div
          className="pricing-3 custom-software-div3"
          style={{
            backgroundImage:
            "url(" + require("assets/img/nlbg/bg5.jpg") + ")",
        }}
        >
            <Row className="">
               <Col md="7">  
               <br></br>
               <br></br>
   
                    <h2 className="whath2fin2 mx-20"><span>The bridge between your business and software</span></h2>
                    <br></br>
                    <h5 className="whath4ind mx-30"><span>
                    We ensure that effective planning and communication steers the project to meet the specific goals of your business and ensures maximum efficiency of the process and the end results.
                    </span></h5>
                <br></br>
               
                
                
                    </Col>
                    <Col md="5" className="">
                    <img 
                    alt="team"
                    className="sectionimg-sme3"
                    src={require("assets/img/nl/sections/team3.jpg")}
                    />
                    </Col> 
                    </Row>
                    
                
           
               
                     <div className="small-border">

                     <Row>
           
           <Col lg="6" md="6" sm="12" xs="12" className="col1">
   <div className="topcard2">
   <hr className="h-line5" />
   <img 
               alt="Business Needs Analysis"
               className="customcardimg-mob"
               src={require("assets/img/nlicons/business/innovation.svg")}
               />
   
               
     <h2 id="titleblacksmall" className="align-left2">
     Business Needs Analysis
     </h2>
    <p className="tile-description2">
    We gain specific insight into the needs of your business. We then utilise this vital information to strategically inform on any software and application development choices that your business will make.    </p>
   </div>
  
 
 </Col>
 <Col md="6" sm="12" xs="12" className="col1">
   <div className="topcard2">
   <hr className="h-line5" />
   <img 
               alt="maintenance"
               className="customcardimg-mob"
               src={require("assets/img/nlicons/business/target.svg")}
               />
              
     <h2 id="titleblacksmall" className="align-left2">
     Competition Analysis
     </h2>
    <p className="tile-description2">
We take the actions of your competitors into consideration. Intelligence gathered from competitors is used to inform on market trends
and to construct specific counter-strategies that will give your company the edge in that market.
    </p>
    </div>
    
 
 </Col>
 </Row>
 <Row>
 <Col md="6" sm="12" xs="12" className="col1">
   <div className="topcard2">
   <hr className="h-line5" />
   <img 
               alt="maintenance"
               className="customcardimg-mob"
               src={require("assets/img/nl/sections/mobile/time.svg")}
               />
              
     <h2 id="titleblacksmall" className="align-left2">
     Market Analysis
     </h2>
    <p className="tile-description2">
By effectively defining and selecting your market share and target market, we maximise the use of time and resources spent on marketing.
We use various marketing techniques to ensure your success and that you maintain relevance in your respective market.
    </p>
   </div>
  
 
 </Col>

 
 <Col md="6" sm="12" xs="12" className="col1">

               
   <div className="topcard2">
   <hr className="h-line5" />
   <img 
               alt="maintenance"
               className="customcardimg-mob"
               src={require("assets/img/nlicons/business/checklist.svg")}
               />
              
               
              
     <h2 id="titleblacksmall" className="align-left2">
     Requirements Analysis
     </h2>
    <p className="tile-description2">
We use blueprints detailing your business requirements and specifications to create software concepts. 
This ensures you get the most effective and efficient product at the end of the process.
    </p>
   </div>
 
   
 </Col>

  
</Row>
<Row>
 <Col md="6" sm="12" xs="12" className="col1">
   <div className="topcard2">
   <hr className="h-line5" />

   <img 
               alt="maintenance"
               className="customcardimg-mob"
               src={require("assets/img/nlicons/business/solution.svg")}
               />
              
     <h2 id="titleblacksmall" className="align-left2">
     Customised Solution
     </h2>
    <p className="tile-description2">
NEXTLEVEL facilitates the design and development of the best software solution to your issue as it presents itself. 
We make sure that the project explicitly meets the requirements laid out in the specification blueprint.
    </p>
   </div>
  
 
 </Col>

 
 <Col md="6" sm="12" xs="12" className="col1">

               
   <div className="topcard2">
   <hr className="h-line5" />

   <img 
               alt="maintenance"
               className="customcardimg-mob"
               src={require("assets/img/nlicons/business/rocket.svg")}
               />
              
               
              
     <h2 id="titleblacksmall" className="align-left2">
     Testing and Implementation
     </h2>
    <p className="tile-description2">
We make sure that the outcome of the project aligns with the clients’ specifications and goals.
    </p>
   </div>
 
   
 </Col>

  
</Row>
             </div>
             </div>
</div>
        

        <div className="shift-down-50px">
            
      <Row>
      
                    <Col md="5">
                      <div className="topcard">
                        <h2 id="titleblack" className="align-left2">
                        How we do it
                        </h2>
                       
                      </div>
                    
                    </Col>
                    <Col md="7">
                      
                  <p className="top-description-left mx-20">
                    <br></br>
                    We have a unique approach to developing solutions for clients. Firstly, we listen. Carefully. Then using our industry expertise, analytical abilities and blend of consultancy skills, we devise creative, cost effective, future proof solutions.
<br></br>
<br></br>

From working across different industries and helping clients with a range of challenges, we are constantly developing our skills and as a result are well equipped to understand how best to approach the transformation challenge our clients might be facing.
<br></br>
<br></br>

We use our NEXTLEVEL methodology to develop best in class delivery solutions for any transformation our clients are going through.                  </p>
                 
                    </Col> 
                </Row>

        </div>

        
               
       


        



        <div className="mt-20">
      <Row>
      
                    <Col md="7">
                      <div className="topcard">
                        <h2 id="titleblack" className="align-left2">
                        NEXTLEVEL Development Process 
                        </h2>
                       
                      </div>
                    
                    </Col>
                    <Col md="4">
                      
                  <p className="top-description mx-20">
                  Software development, like any other manufacturing process stands on logic, clear structure, and 
                  accountability of each component. This is the only way to create quality software. We have all of 
                  those at NEXTLEVEL, we know what we do on each stage of creating a successful application. 
                  </p>
                 
                    </Col> 
                </Row>

        </div>
        <img 
                        alt="Design Process"
                        id="processhorizontal"
                        className="process-img-h"
                        src={require("assets/img/nlscreenshots/app-process-horizontal.png")}
                        />
         <img 
                        alt="Design Process"
                        id="processvertical"
                        className="process-img-v"
                        src={require("assets/img/nlscreenshots/app-process-vertical.png")}
                        />


<div
          className="pricing-3 custom-software-div3 mt-60"
          style={{
            backgroundImage:
              "url(" + require("assets/img/nl/sections/mobile/mobile-why.jpeg") + ")",
          }}
        >

        <Row>
          <Col md="6" sm="12" xs="12">
          

          </Col>
          <Col md="6" sm="12" xs="12">      
          <h1 className="whath2qa5"><span>Why Choose NEXTLEVEL</span></h1>      
          <ul className="qalist">
              <li><img 
                        alt="website"
                        className="webcardimg mx-4"
                        src={require("assets/img/nlicons/check-circle-white.svg")}
                        /><h5 className="whychoose">Expertise</h5>Our team has diverse technology stack experience and our customers come from diverse industries. Whatever the problem, we can solve it efficiently. </li>
              <br></br>
              <li><img 
                        alt="website"
                        className="webcardimg mx-4"
                        src={require("assets/img/nlicons/check-circle-white.svg")}
                        /><h5 className="whychoose">Value</h5>By ensuring your application's superior quality, NEXTLEVEL ensures its superior value in the market. </li>
              <br></br>
              <li><img 
                        alt="website"
                        className="webcardimg mx-4"
                        src={require("assets/img/nlicons/check-circle-white.svg")}
                        /><h5 className="whychoose">Transparency</h5>We believe that strong business relationships are built on trust and reliability. That's why NEXTLEVEL offers fair, transparent pricing at every stage of your project. </li>
              <br></br>
              <li><img 
                        alt="website"
                        className="webcardimg mx-4"
                        src={require("assets/img/nlicons/check-circle-white.svg")}
                        /><h5 className="whychoose">Scalability</h5>NEXTLEVEL's team of expert engineers can scale any project. Save overheads, time, and stress with our QA services. </li>

            </ul>
            
            </Col>
        </Row>
        </div>



       
        <ContactComponent />
        
      </div>
      <FooterNav />
    </>
  );
}

export default BusinessAnalysis;
