/*eslint-disable*/
import React from "react";
// react plugin used to create datetimepicker
import ReactDatetime from "react-datetime";
// react plugin used to create switch buttons
import Switch from "react-bootstrap-switch";
// react plugin used to create an image gallery
import { PhotoSwipeGallery } from "react-photoswipe";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Collapse,
  
  Container,
  Row,
  Col,
  
} from "reactstrap";


function SeoContent() {
 
  // collapse states and functions
  const [collapses, setCollapses] = React.useState([]);
  const changeCollapse = (collapse) => {
    if (collapses.includes(collapse)) {
      setCollapses(collapses.filter((prop) => prop !== collapse));
    } else {
      setCollapses([...collapses, collapse]);
    }
  };
  
  return (
    <>
      <div className="section section-blue javascript-components shift-up-150px bottom-up-150px background-tp">
        <Container>
    
          
          <Row className="justify-center">
            <Col md="12">
              <div id="acordeon">
                <div aria-multiselectable={true} id="accordion" role="tablist">
                  <Card className="no-transition">
                    <CardHeader
                      className="card-collapse"
                      id="headingOne"
                      role="tab"
                    >
                      <h2 className="mb-0 custom-title">
                        <a
                          aria-expanded={collapses.includes(1)}
                          className="collapsed"
                          data-parent="#accordion"
                          href="#pablo"
                          id="collapseOne"
                          onClick={(e) => {
                            e.preventDefault();
                            changeCollapse(1);
                          }}
                        >
                         Types of SEO Content{" "}
                          <i className="nc-icon nc-minimal-down" />
                        </a>
                      </h2>
                    </CardHeader>
                    <Collapse isOpen={collapses.includes(1)}>
                    <h4>SEO content comes in a variety of mediums:</h4>
                    <br></br>
                      <CardBody className="collapse-text align-left">
                      <a>
                        <a className="mb-20">
                        <strong>
                      BLOG POSTS   
                    </strong>
                        </a>
                        <br></br>
                      <br></br>
A blog is one of the easiest ways to create a regular stream of SEO content. In general, 
blog posts are more engaging and more likely to attract backlinks than product pages, so 
they can be a great way to build some authority for your site.
                      </a>
<br></br>
<br></br>
                      <a>
                      <strong>
                      ARTICLES   
                    </strong>
                      <br></br>
                      <br></br>
                      Think news article, interview, or feature piece. This is the main kind of content you’ll find on most newspaper or magazine-style websites.
                      </a>
                      <br></br>
<br></br>
                      <a>
                      <strong>
                      LISTS  
                    </strong>
                      <br></br>
                      <br></br>
                      A list is really just a kind of article, but framing it as a list (such as “5 Top SEO Tips for Ecommerce Websites” or “10 Ways To Rank Higher”) makes it easier to digest. These types of titles also seem to be more clickable when found in search results or in social media feeds.
                      </a>
                      <br></br>
<br></br>
                      <a>
                      <strong>
                      GUIDES   
                    </strong>
                      <br></br>
                      <br></br>
                      A guide is a longer piece of content that explains in detail how to do something. You can post a full guide on your website, 
                      or you can post a summary or excerpt, requiring visitors to fill out a registration form to read the full guide. This can be a 
                      good way to generate leads, but keep in mind that putting up a registration wall will likely reduce the amount of SEO traffic you can drive to that guide.
                      <br></br>
                      Consider creating video tutorials of how to use your products. Or illustrate a process that is related to your business – for example, a mechanic could make a 
                      video showing how to change a sparkplug. (A note on SEO: You might consider including a text transcript of your video)
                      </a>
                      <br></br>
<br></br>
                      <a>
                      <strong>
                      INFOGRAPHICS  
                    </strong>
                      <br></br>
                      <br></br>
                      Infographics, or large-format images that contain a lot of data (often in the form of graphs or charts) on a single subject, can rack up a lot of page views and links. <a className="custom-link6" href="https://backlinko.com/how-to-get-backlinks" target="_blank">See Proof</a>. However, because so much of the content is embedded in the image and therefore not readable as text by search engines, it’s important to carefully optimize the rest of the page.
</a>
<br></br>
<br></br>
                      <a>
                      <strong>
                      SLIDESHOWS  
                    </strong>
                      <br></br>
                      <br></br>
                      A slideshow is a way to display a series of related images. Sometimes pictures are more important than text – say you’re trying to show what all the stars wore to the Oscars. Here again, SEO of your title, captions, image alt tags, image file names and so on is important because there is less for the search engines to “read.”
                      </a>
                      <br></br>
                      <br></br>

                      <a>
                      <strong>
                      GLOSSARIES  
                    </strong>
                      <br></br>
                      <br></br>
                      If you work in a specialized industry, a well built-out glossary can be a good way to capture search traffic. Think cooking terms, medical terms, fashion terms, architectural terms …
                      </a>
                      <br></br>
                      <br></br>
                       
                      <a>
                      <strong>
                      DIRECTORIES  
                    </strong>
                      <br></br>
                      <br></br>
                      A directory is a useful taxonomy of links to sites or resources around a given topic. For example, we have a directory of all the best hosting services websites.                      
                      </a>
                      <br></br>
                      <br></br>
                       
                      <a>
                      <strong>
                      PRODUCT PAGES 
                    </strong>
                      <br></br>
                      <br></br>
                      A product page is an excellent tool if optimized for search engines. You can even use Rich Snippets to appear in Google searches. A good product page can serve as both SEO content and a PPC landing page.                      
                      </a>
                      <br></br>
                      <br></br>
                      </CardBody>
                    </Collapse>
                 
                  </Card>
                </div>
                {/* end acordeon */}
              </div>
            </Col>
          </Row>
         
        </Container>
      </div>
    </>
  );
}

export default SeoContent;
