import React from "react";
// reactstrap components
import {
  Row,
  Col
} from "reactstrap";

// core components
import EcommerceHeader from "components/Headers/Custom/EcommerceHeader.js";
import ColorNavbar from "components/Navbars/ColorNavbar"; 
import ContactComponent from '../services/ContactComponent.js';

import FooterNav from "../services/FooterNav.js";


function Ecommerce() {
  
 

  document.documentElement.classList.remove("nav-open");
  // function that is being called on scroll of the page
  const checkScroll = () => {
    // it takes all the elements that have the .add-animation class on them
    const componentPosition = document.getElementsByClassName("add-animation");
    const scrollPosition = window.pageYOffset;
    for (var i = 0; i < componentPosition.length; i++) {
      var rec =
        componentPosition[i].getBoundingClientRect().top + window.scrollY + 100;
      // when the element with the .add-animation is in the scroll view,
      // the .animated class gets added to it, so it creates a nice fade in animation
      if (scrollPosition + window.innerHeight >= rec) {
        componentPosition[i].classList.add("animated");
        // when the element with the .add-animation is not in the scroll view,
        // the .animated class gets removed from it, so it creates a nice fade out animation
      } else if (scrollPosition + window.innerHeight * 0.8 < rec) {
        componentPosition[i].classList.remove("animated");
      }
    }
  };
  

  React.useEffect(() => {
    document.body.classList.add("presentation-page");
    window.addEventListener("scroll", checkScroll);
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("presentation-page");
      window.removeEventListener("scroll", checkScroll);
    };
  });
  return (
    <>
    <ColorNavbar />
      <EcommerceHeader />
      <div className="wrapper">
       
     
     
      <div>
           
      <Row>
      
                    <Col md="7">
                      <div className="topcard">
                        <h2 id="titleblack" className="align-left2">
                        Ecommerce Is Always Changing                        
                        </h2>
                       
                      </div>
                    
                    </Col>
                    <Col md="4">
                      
                  <p className="top-description mx-20">
                  Between new business models, marketing trends, and UX best practices and the ever-changing winds of consumer demand, it’s more important today than ever before that ecommerce organizations are built on agile, flexible, resilient software platforms that empower them to meet customers wherever they are, whenever they want, however they want.
                  </p>
                 <br></br>
                 <br></br>
                 <br></br>
                    </Col> 
                </Row>

                <Row className="full-width">
                    
                   
                    <Col md="7">
                    <div
          className="pricing-3 custom-software-div4 pr-30 mr-5"
          style={{
            backgroundImage:
            "url(" + require("assets/img/nlbg/bg4.jpg") + ")",
        }}
        >
                   <br></br>
                   <br></br>
                   <br></br>
                    <h2 className="whath2fin2 px-30"><span>Why Choose Custom Software?</span></h2>
                    <br></br>
                    <h5 className="whath4ind ml-20"><span>
                    While there are several off-the-shelf software options available on the market, their out-of-the-box features and clunky integration capabilities will make for a less-than-ideal experience. Custom software, on the other hand, is built specifically to meet your business’s unique needs, taking into consideration every nuance.

What’s more, you own the system — and the value it adds to your marketplace — and you can make adjustments as you grow and evolve, scaling to support expanding teams and seamlessly integrating it with other third-party solutions. In short, with custom software, you’re always in control.
                    </span></h5>
                    <br></br>

                </div>
                    </Col>
                    <Col md="5" className="">
                    <img 
                    alt="developer"
                    className="sectionimg-sme211"
                    src={require("assets/img/nl/sections/dev-work.jpg")}
                    />
                    </Col> 
                </Row>



                <Row className="shift-down-130px">
                <Col md="5" className="minus-margin-left">
                    <img 
                    alt="online shopping"
                    className="sectionimg-sme2"
                    src={require("assets/img/nl/sections/woman-shopping.jpg")}
                    />
                    </Col> 
                    <Col md="7">
                    <div
          className="pricing-3 custom-software-div311"
          style={{
            backgroundImage:
            "url(" + require("assets/img/nlbg/bg1.jpg") + ")",
        }}
        >
                    
                    <h2 className="whath2fin2 px-30"><span>Ecommerce Solutions for Shops of All Shapes and Sizes</span></h2>
                    <br></br>
                    <h5 className="whath4ind mx-30"><span>From simple online shops to more complex marketplaces, the system that powers customer relationships and sales is critical to an ecommerce business’s success. That’s why we offer all-in-one, custom ecommerce solutions to help you engage with customers at every turn.</span></h5>
                <br></br>
                </div>
                    </Col>
                   
                </Row>

                <div className="small-border">
                      <div className="box">
                      <Row className="shift-down-80px">
        <Col md="4">
          <img 
                         alt="fashion"
                         className="customcardimg"
                         src={require("assets/img/nlicons/fin/database-storage.svg")}
                         />
          <h3 id="titleblack2">Fashion & Apparel</h3>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          </Col>
        <Col md="4">
        <img 
                         alt="food"
                         className="customcardimg"
                         src={require("assets/img/nlicons/fin/bank-online.svg")}
                         />
          <h3 id="titleblack2">Food & Grocery</h3>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          </Col>
        <Col md="4">
        <img 
                         alt="supplies"
                         className="customcardimg"
                         src={require("assets/img/nlicons/fin/rocket.svg")}
                         />
          <h3 id="titleblack2">Supplies</h3>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          </Col>
        <Col md="4">
        <img 
                         alt="b2b"
                         className="customcardimg"
                         src={require("assets/img/nlicons/fin/money.svg")}
                         />
          <h3 id="titleblack2">B2B Businesses</h3>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
        </Col>
        
        </Row>
                       
                      </div>
                  </div> 

                <Row className="shift-down-100px">
                    
                   
                    <Col md="7">
                    <div
          className="pricing-3 custom-software-div4 pr-40"
          style={{
            backgroundImage:
              "url(" + require("assets/img/nlbg/bg5.jpg") + ")",
          }}
        >
                   <br></br>
                    <h2 className="whath2fin212 mr-20 ml-20"><span>NEXTLEVEL Custom Ecommerce Software Lets You Control Every Piece of the Project</span></h2>
                    <br></br>
                    <h5 className="whath4ind mx-30"><span>
                    From the finances to the finish outs, your custom ecommerce management system lets you customize the way you track and manage each of the many facets of your business.
                    </span></h5>
                    <br></br>

                </div>
                    </Col>
                    <Col md="5" className="">
                    <img 
                    alt="cape town"
                    className="sectionimg-sme211 ml-10 mr-20"
                    src={require("assets/img/nl/sections/control.jpg")}
                    />
                    </Col> 
                </Row>
                


                <div className="small-border">
                      <div className="box">
                      <Row className="shift-down-80px">
        <Col md="4">
          <img 
                         alt="Web Application"
                         className="customcardimg"
                         src={require("assets/img/nlicons/fin/processing.svg")}
                         />
          <h3 id="titleblack2">Web Application</h3>
          <br></br>
          <p className="top-description2">
          NEXTLEVEL's team designs sleek, user-friendly web applications that make it easy — and enjoyable — 
          for customers to find and purchase the products they love. We pride ourselves on creating fully 
          responsive online stores that are simple to navigate and tailored to your brand. They support multiple 
          currencies and multiple languages, and the smart search and streamlined checkout features make for a frictionless customer experience every time.
          </p>
          </Col>
        <Col md="4">
        <img 
                         alt="Mobile Application"
                         className="customcardimg"
                         src={require("assets/img/nlicons/fin/processing.svg")}
                         />
          <h3 id="titleblack2">Mobile Application</h3>
          <br></br>
          <p className="top-description2">
          We know your customers aren’t always shopping from behind their computers. Often, they’re engaging on the go, and NEXTLEVEL's branded mobile apps pair perfectly with our web applications to ensure you’re there for your customers wherever they are.
          </p>
        </Col>
        <Col md="4">
        <img 
                         alt="Marketing Support"
                         className="customcardimg"
                         src={require("assets/img/nlicons/fin/personality.svg")}
                         />
          <h3 id="titleblack2">Marketing Support</h3>
          <br></br>
          <p className="top-description2">
          NEXTLEVEL understands that much of an ecommerce business’s power lies in its ability to communicate with customers across a variety of channels, upselling and cross-selling based on products they might like, nudging them to return to abandoned shopping carts, and offering them coupons to entice them to come back to the shop. Our custom ecommerce applications are SEO-friendly and equipped to support email marketing, Facebook advertising, and more to keep your organization in constant contact with customers.
          </p>
        </Col>
        <Col md="4">
        <img 
                         alt="Cyber Security"
                         className="customcardimg"
                         src={require("assets/img/nlicons/fin/data-security.svg")}
                         />
          <h3 id="titleblack2">Business Management</h3>
          <br></br>
          <p className="top-description2">
          The front-end shopping experience is only as strong as the back-office management system that supports it. NEXTLEVEL's custom ecommerce applications are designed with admin panels that enable your team to manage orders, shipments, and inventory, publish and update marketing content, and rotate products and descriptions seamlessly for an always-fresh, always-easy shopping experience.
          </p>
        </Col>
        </Row>
                       
                      </div>
                  </div> 


               

                 

                  

                  <div
          className="pricing-3 custom-software-div3"
          style={{
            backgroundImage:
              "url(" + require("assets/img/nlbg/bg3.jpg") + ")",
          }}
        >

        <Row>
          <Col md="6">
          

          </Col>
          <Col md="6">      
          <div className="pr-40 pl-20">
          <h1 className="whath2qa5"><span>Why Choose NEXTLEVEL</span></h1>      
          <ul className="qalist">
              <li><img 
                        alt="website"
                        className="webcardimg mx-4"
                        src={require("assets/img/nlicons/check-circle-white.svg")}
                        /><h5 className="whychoose">Commitment to Transparency</h5>We believe that strong business relationships are built on trust and reliability. 
                        We work in tandem with your team to conceptualize and build the perfect platform, and we are committed to offering fair, transparent pricing at every stage of development. We give you all the information you need to make the right business decisions.</li>
              <br></br>
              <li><img 
                        alt="website"
                        className="webcardimg mx-4"
                        src={require("assets/img/nlicons/check-circle-white.svg")}
                        /><h5 className="whychoose">Comprehensive Experience</h5>Our team has diverse technology stack experience and deep expertise in cloud-based SaaS solutions. Whatever your business’s unique needs, we’re here to create the solutions.</li>
              <br></br>
              <li><img 
                        alt="website"
                        className="webcardimg mx-4"
                        src={require("assets/img/nlicons/check-circle-white.svg")}
                        /><h5 className="whychoose">Lean Business Model</h5>NEXTLEVEL uses a lean, global business model to create value for our customers. This model translates to better savings and higher-quality service than our competitors can offer.</li>
              <br></br>
              <li><img 
                        alt="website"
                        className="webcardimg mx-4"
                        src={require("assets/img/nlicons/check-circle-white.svg")}
                        /><h5 className="whychoose">Customer-Centric Culture</h5>NEXTLEVEL carefully selects each employee to align with our customer-centric culture. We're serious about providing the best quality software and service (at the best price) to add value for our customers.</li>
              <br></br>
<li><img 
                        alt="website"
                        className="webcardimg mx-4"
                        src={require("assets/img/nlicons/check-circle-white.svg")}
                        /><h5 className="whychoose">Capacity to Scale</h5>NEXTLEVEL's team of expert engineers can scale any project. Save overheads, time, and stress with our QA services. </li>
            </ul>
            </div>
            </Col>
        </Row>
        </div>

        </div>



       
        <ContactComponent />
      </div>
     
      <FooterNav />
    </>
    
  );
}

export default Ecommerce;
