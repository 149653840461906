/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container } from "reactstrap";
import Typist from "react-typist";
import { Link } from 'react-router-dom'

// core comments


function WebHeaderTemplate({navTitle, Title}) {
  
  return (
    <>
      <div className="wrapper" id="header">
        <div
          className="page-header section-dark"
          style={{
            backgroundImage:
              "url(" + require("assets/img/nl/header/daynight.gif") + ")",
          }}
        >
            <div className="top-nav-small">
            <p className="top-nav-text">
            <Link className="custom-link-custom" to="/">Home</Link> <span>&nbsp; { '>' } &nbsp; </span> <Link className="custom-link-custom" to="/web">Web Design & Development</Link>  <span>&nbsp; { '>' } &nbsp; {navTitle}</span> 

                    </p>
            </div>
          <div className="content-center">
         
            <Container id="headercontainer">
                <h1 className="presentation-title-new">{Title}</h1>
                
                
              <div className="title-brand">

              <br></br>
              <br></br>
              </div>
              <div>
           
              </div>
            </Container>
          </div>
        </div>
        <div className="wrapper">
        <div className="divider__img__div">
        <img 
         alt="divider"
         className="divider__img2"
         src={require("assets/img/nlbg/triangleNegative3.svg")}
         />         
       
        <img 
         alt="divider"
         className="divider__img2"
         src={require("assets/img/nlbg/triangleNegative.svg")}
         />
           <img 
         alt="divider"
         className="divider__img2"
         src={require("assets/img/nlbg/triangleNegative2.svg")}
         />     
                           
        </div>
           
        </div>
       
      </div>
    </>
  );
}

export default WebHeaderTemplate;
