import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Container,
  Row,
  Col,
} from "reactstrap";

import { Link } from 'react-router-dom'


// core components
import WebHeader from "components/Headers/Custom/WebHeader.js";
import ColorNavbar from "components/Navbars/ColorNavbar"; import ContactComponent from './ContactComponent.js'

import FooterNav from "./FooterNav.js";
import Divider from "../sections-sections/Divider.js";


function WebDevelopment() {
  document.documentElement.classList.remove("nav-open");
  // function that is being called on scroll of the page
  const checkScroll = () => {
    // it takes all the elements that have the .add-animation class on them
    const componentPosition = document.getElementsByClassName("add-animation");
    const scrollPosition = window.pageYOffset;
    for (var i = 0; i < componentPosition.length; i++) {
      var rec =
        componentPosition[i].getBoundingClientRect().top + window.scrollY + 100;
      // when the element with the .add-animation is in the scroll view,
      // the .animated class gets added to it, so it creates a nice fade in animation
      if (scrollPosition + window.innerHeight >= rec) {
        componentPosition[i].classList.add("animated");
        // when the element with the .add-animation is not in the scroll view,
        // the .animated class gets removed from it, so it creates a nice fade out animation
      } else if (scrollPosition + window.innerHeight * 0.8 < rec) {
        componentPosition[i].classList.remove("animated");
      }
    }
  };
  React.useEffect(() => {
    document.title = "Web Design and Development"
 });
  React.useEffect(() => {
    document.body.classList.add("presentation-page");
    window.addEventListener("scroll", checkScroll);
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("presentation-page");
      window.removeEventListener("scroll", checkScroll);
    };
  });
  return (
    <>
      <ColorNavbar />
      <WebHeader />
      <div className="wrapper">
       
      
      <Row>
      
                    <Col md="7">
                    <Link to="/custom-software">
                      <div className="topcard">
                        <h1 id="titleblack">
                        What We Offer
                        </h1>
                       
                      </div>
                    
              </Link>
                    </Col>
                    <Col md="4">
                      
                  <p className="top-description">
                  Striking a delicate balance between the intuitive and the analytical, world-class product and 
                  service design is about science as much as beauty. Using human-centered design tools, we create 
                  digital products that help organizations enhance the value they provide to people.
                  </p>
                 
                    </Col> 
                </Row>
               
                <div className="webdiv shift-up-200px">
                  
                  

                <Row className="col-container">
                  
                <Col lg="8" md="8" sm="12" className="">

                <Divider dividerHeading="divider__heading1" divTitle="Websites" />

                  </Col>
                
                  <Col lg="4" md="4" sm="6" xs="12" className="col3">
              <div className="card-big-shadow">
              <Link to="/basic-website-packages">
                <Card
                  className="webcard"
                >
                  <CardBody>
                  <hr className="h-line4" />
                    <CardTitle tag="h5">
                      <div className="webcardicon">
                      <img 
                        alt="basic website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/web/widget1.svg")}
                        />
                        <span>
                        <h5 className="webcardtitle">
                        Basic Website Packages
                      </h5>
                        </span>
                  
                      </div>
                      
                    </CardTitle>
                    <br></br>
                    <h6 className="card-category">From</h6><h5><strong>R2499</strong></h5>
                    <br></br>
                    <p className="card-description">
                    We offer smart, responsive and easy to navigate, basic websites for individuals or businesses at affordable 
                    rates. We include various content management system options.
                    </p>
                  </CardBody>
                  <CardFooter>
                  <Link to="/basic-website-packages">
                  <Button
                      className="btn-link" id="colorviolet2"
                      
                    >
                      See more
                    </Button>
                    </Link>
                  </CardFooter>
                </Card>
                </Link>
              </div>
            </Col>

          </Row>

          <Row>


            <Col lg="4" md="4" sm="6" xs="12" className="col3">
              <div className="card-big-shadow">
              <Link to="/business-website-packages">
                <Card
                  className="webcard h-100"
                >
                  <CardBody>
                  <hr className="h-line4" />
                    <CardTitle tag="h5">
                    <div className="webcardicon">
                      <img 
                        alt="business website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/web/widget2.svg")}
                        />
                        <span>
                        <h5 className="webcardtitle">
                        Business Website Packages
                      </h5>
                        </span>
                  
                      </div>
                    </CardTitle>
                    <br></br>
                    <h6 className="card-category">From</h6><h5><strong>R4999</strong></h5>
                    <br></br>
                    <p className="card-description">
                    We offer smart, intuitive, responsive websites for business, at affordable rates. From functional business web design to dynamic ecommerce systems.
                    </p>
                    
                  </CardBody>
                  <CardFooter>
                  <Link to="/business-website-packages">
                  <Button
                      className="btn-link" id="colorviolet2"
                      
                    >
                      See more
                    </Button>
                    </Link>
                  </CardFooter>
                </Card>
                </Link>
              </div>
            </Col>
            <Col lg="4" md="4" sm="6" xs="12" className="col3">
              <div className="card-big-shadow">
              <Link to="/ecommerce-packages">
                <Card
                  className="webcard h-100"
                  
                >
                  <CardBody>
                  <hr className="h-line4" />
                    <CardTitle tag="h5">
                    <div className="webcardicon">
                      <img 
                        alt="ecommerce website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/web/cart-simple1.svg")}
                        />
                        <span>
                        <h5 className="webcardtitle">
                        Advanced Ecommerce Packages
                      </h5>
                        </span>
                  
                      </div>
                    </CardTitle>
                    <br></br>
                    <h6 className="card-category">From</h6><h5><strong>R6999</strong></h5>
                    <br></br>
                    <p className="card-description">
                    Our Ecommerce Website options will allow you to sell your products online, with inventory management and POS options available through an Ecommerce platform.
                    </p>
                    
                 </CardBody>
                  <CardFooter>
                  <Link to="/ecommerce-packages">
                  <Button
                      className="btn-link" id="colorviolet2"
                      
                    >
                      See more
                    </Button>
                    </Link>
                  </CardFooter>
                </Card>
                </Link>
              </div>
            </Col>

            <Col lg="4" md="4" sm="6" xs="12" className="col3">
              <div className="card-big-shadow">
              <Link to="/custom-website-packages">
                <Card
                  className="webcard h-100"
                >
                  <CardBody>
                  <hr className="h-line4" />
                    <CardTitle tag="h5">
                    <div className="webcardicon">
                      <img 
                        alt="custom website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/web/widget3.svg")}
                        />
                        <span>
                        <h5 className="webcardtitle">
                        Custom Website Packages
                      </h5>
                        </span>
                  
                      </div>
                      
                    </CardTitle>
                    <br></br>
                    <h6 className="card-category">From</h6><h5><strong>R14999</strong></h5>
                    <br></br>
                    <p className="card-description">
                      Advanced, large, interactive sites with programmatic user input and data processing.
                    </p>
                    
                 </CardBody>
                  <CardFooter>
                  <Link to="/custom-website-packages">
                  <Button
                      className="btn-link" id="colorviolet2"
                      
                    >
                      See more
                    </Button>
                    </Link>
                  </CardFooter>
                </Card>
                </Link>
              </div>
            </Col>
                  </Row>

             
                  <Row className="shift-up-100px-0px">
                  <Col lg="12" md="12" sm="12">
              <Divider dividerHeading="divider__heading" divTitle="Digital Marketing" />
                  </Col>
                  </Row>
                  <Row>
                  
           
                
                  
            <Col lg="4" md="4" sm="6" xs="12" className="col3">
              <div className="card-big-shadow">
              <Link to="/AdWords">
                <Card
                  className="webcard"
                  >
                 <CardBody>
                  <hr className="h-line4" />
                    <CardTitle tag="h5">
                    <div className="webcardicon">
                      <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/web/zoom-2.svg")}
                        />
                        <span>
                        <h5 className="webcardtitle">
                        Google AdWords
                      </h5>
                        </span>
                  
                      </div>
                      
                    </CardTitle>
                    <br></br>
                    <h6 className="card-category">From</h6><h5><strong>R2499</strong></h5>
                    <br></br>
                    <br></br>
                    <p className="card-description">
                      We utilise Google's pay-per-click service, combined with SEO strategy, to capture your target audience and drive traffic to your site. 
                      It allows for your site to be seen by customers at the moment that they're searching Google for terms related to your website and products.
                    </p>
                 </CardBody>
                  <CardFooter>
                  <Link to="/AdWords">
                  <Button
                      className="btn-link" id="colorviolet2"
                      
                    >
                      See more
                    </Button>
                    </Link>
                  </CardFooter>
                </Card>
                </Link>
              </div>
            </Col>

            <Col lg="4" md="4" sm="6" xs="12" className="col3 ipad-col">
              <div className="card-big-shadow">
              <Link to="/seo">
                <Card
                  className="webcard"
                  >
                  <CardBody>
                  <hr className="h-line4" />
                    <CardTitle tag="h5">
                      
                    <div className="webcardicon">
                    <img 
                        alt="website"
                        className="webcardimg-seo"
                        src={require("assets/img/nlicons/web/link-72.svg")}
                        />
                        <span>
                        <h5 className="webcardtitle">
                        Search Engine Optimisation
                      </h5>
                        </span>
                  
                      </div>
                      
                    </CardTitle>
                    <br></br>
                    <h6 className="card-category">From</h6><h5><strong>R2999</strong></h5>
                    <br></br>
                    <p className="card-description">
                    We optimise your website and create quality backlinks to grow your online presence and compete 
                    for a natural top position on search engines such as Google. SEO 
                    plans are available with regular updates, required to stay on top and beat your competition.
                    </p>
                   
                 </CardBody>
                  <CardFooter>
                  <Link to="/seo">
                  <Button
                      className="btn-link" id="colorviolet2"
                      
                    >
                      See more
                    </Button>
                    </Link>
                  </CardFooter>
                </Card>
                </Link>
              </div>
            </Col>

            <Col lg="4" md="4" sm="6" xs="12" className="col3">
              <div className="card-big-shadow">
              <Link to="/social-media">
                <Card
                  className="webcard"
                  >
                 <CardBody>
                  <hr className="h-line4" />
                    <CardTitle tag="h5">
                    <div className="webcardicon">
                      <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/web/multiple-11.svg")}
                        />
                        <span>
                        <h5 className="webcardtitle">
                        Social Media Marketing 
                      </h5>
                        </span>
                  
                      </div>
                      
                    </CardTitle>
                    <br></br>
                    <h6 className="card-category">From</h6><h5><strong>R2499</strong></h5>
                    <br></br>
                    <p className="card-description">
                      We leverage various services and user-bases of Facebook, Instagram, Youtube, Twitter, Influencers and more, to 
                      increase exposure and brand awareness of a specifically targeted audience, thus maximising the cost-benefit ratio of your advertising budget. 
                    </p>
                 </CardBody>
                  <CardFooter>
                  <Link to="/social-media">
                  <Button
                      className="btn-link" id="colorviolet2"
                      
                    >
                      See more
                    </Button>
                    </Link>
                  </CardFooter>
                </Card>
                </Link>
              </div>
            </Col>
            </Row>
            
           


                  <Row className="">
                <Col lg="12" md="12" sm="12">
                <Divider dividerHeading="divider__heading2" divTitle="Hosting & Maintenance" />

               
                </Col>
              </Row>


                  <Row>
                  
           
                
                  <Col lg="4" md="4" sm="6" xs="12" className="col3">
              <div className="card-big-shadow">
              <Link to="/web-hosting">
                <Card
                  className="webcard"
                  >
                 <CardBody>
                  <hr className="h-line4" />
                    <CardTitle tag="h5">
                    <div className="webcardicon">
                      <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/web/world.svg")}
                        />
                        <span>
                        <h5 className="webcardtitle">
                        Web Hosting Services
                      </h5>
                        </span>
                  
                      </div>
                      
                    </CardTitle>
                    <br></br>
                    <h6 className="card-category">From</h6><h5><strong>R99</strong></h5>
                    <br></br>
                    <p className="card-description">
                    Looking to take your site online? We provide the most affordable business hosting and email services in South Africa. 
                    We have various package options available to cater to your business' specific needs.
                    </p>
                    
                 </CardBody>
                  <CardFooter>
                  <Link to="/web-hosting">
                  <Button
                      className="btn-link" id="colorviolet2"
                      
                    >
                      See more
                    </Button>
                    </Link>
                  </CardFooter>
                </Card>
                </Link>
              </div>
            </Col>
            <Col lg="4" md="4" sm="6" xs="12" className="col3 ipad-col">
              <div className="card-big-shadow">
              <Link to="/website-maintenance">
                <Card
                  className="webcard"
                  >
                 <CardBody>
                  <hr className="h-line4" />
                    <CardTitle tag="h5">
                    <div className="webcardicon">
                      <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/web/dashboard-level.svg")}
                        />
                        <span>
                        <h5 className="webcardtitle">
                        Website Maintenance Plans
                      </h5>
                        </span>
                  
                      </div>
                     
                    </CardTitle>
                    <br></br>
                    <h6 className="card-category">From</h6><h5><strong>R199</strong></h5>
                    <br></br>
                    <p className="card-description">
                      Our website maintenance services keep your website safe and current and your plugins secure with updates. 
                      We also offer services to contstantly backup and update your website content and design.
                    </p>
                    
                 </CardBody>
                  <CardFooter>
                  <Link to="/website-maintenance">
                  <Button
                      className="btn-link" id="colorviolet2"
                      
                    >
                      See more
                    </Button>
                    </Link>
                  </CardFooter>
                </Card>
                </Link>
              </div>
            </Col>
            <Col lg="4" md="4" sm="6" xs="12" className="col3">
              <div className="card-big-shadow">
              <Link to="/web-security">
                <Card
                  className="webcard"
                >
                 <CardBody>
                  <hr className="h-line4" />
                    <CardTitle tag="h5">
                    <div className="webcardicon">
                      <img 
                        alt="security"
                        className="webcardimg"
                        src={require("assets/img/nlicons/web/lock.svg")}
                        />
                        <span>
                        <h5 className="webcardtitle">
                        Security & SSL
                      </h5>
                        </span>
                  
                      </div>
                    </CardTitle>
                    <br></br>
                    <h6 className="card-category">From</h6><h5><strong>R1099</strong></h5>
                    <br></br>
                    <br></br>
                    <p className="card-description">
                      We ensure the integrity of your website security, with full website security audits and solutions along with 
                      encryption of traffic between your sites visitors' browsers and your web server.
                    </p>
                    <br></br>
                    
                 </CardBody>
                  <CardFooter>
                  <Link to="/web-security">
                  <Button
                      className="btn-link" id="colorviolet2"
                      
                    >
                      See more
                    </Button>
                    </Link>
                  </CardFooter>
                </Card>
                </Link>
              </div>
            </Col>
           
            
                  </Row>

                  <Row className="shift-up-100px-0px">
                <Col lg="12" md="12" sm="12">
                <Divider dividerHeading="divider__heading3" divTitle="Digital Design" />

                </Col>
              </Row>


                  <Row>
                   
                  <Col lg="4" md="4" sm="6" xs="12" className="col3">
              <div className="card-big-shadow">
              <Link to="/content-creation">
                <Card
                  className="webcard"
                  >
                 <CardBody>
                  <hr className="h-line4" />
                    <CardTitle tag="h5">
                    <div className="webcardicon">
                      <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/web/pencil.svg")}
                        />
                        <span>
                        <h5 className="webcardtitle">
                        Content Creation
                      </h5>
                        </span>
                  
                      </div>
                      
                    </CardTitle>
                    <br></br>
                    <h6 className="card-category">From</h6><h5><strong>R899</strong></h5>
                    <br></br>
                    <p className="card-description">
                    Our content writers will create well-researched, relevant content as per your briefing. Our process includes 
                    quality control, copyright and plagiarism checks.
                    </p>
                    <br></br>
                    <br></br>
                 </CardBody>
                  <CardFooter>
                  <Link to="/content-creation">
                  <Button
                      className="btn-link" id="colorviolet2"
                      
                    >
                      See more
                    </Button>
                    </Link>
                  </CardFooter>
                </Card>
                </Link>
              </div>
            </Col>
            <Col lg="4" md="4" sm="6" xs="12" className="col3">
              <div className="card-big-shadow">
              <Link to="/graphic-design">
                <Card
                  className="webcard"
                  >
                 <CardBody>
                  <hr className="h-line4" />
                    <CardTitle tag="h5">
                    <div className="webcardicon">
                      <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/web/vector.svg")}
                        />
                        <span>
                        <h5 className="webcardtitle">
                        Graphic Design
                      </h5>
                        </span>
                  
                      </div>
                      
                    </CardTitle>
                    <br></br>
                    <h6 className="card-category">From</h6><h5><strong>R1299</strong></h5>
                    <br></br>
                    <p className="card-description">
                      Our designers will create unique graphic content for your business, such as a logo, 
                      letterhead, stationary and merchandise to capture the imagination of your target audience. 
                    </p>
                    <br></br>
                 </CardBody>
                  <CardFooter>
                  <Link to="/graphic-design">
                  <Button
                      className="btn-link" id="colorviolet2"
                      
                    >
                      See more
                    </Button>
                    </Link>
                  </CardFooter>
                </Card>
                </Link>
              </div>
            </Col>
            <Col lg="4" md="4" sm="6" xs="12" className="col3">
              <div className="card-big-shadow">
              <Link to="/corporate-identity">
                <Card
                  className="webcard"
                  >
                 <CardBody>
                  <hr className="h-line4" />
                    <CardTitle tag="h5">
                    <div className="webcardicon">
                      <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/web/check-circle-07.svg")}
                        />
                        <span>
                        <h5 className="webcardtitle">
                        Corporate Identity
                      </h5>
                        </span>
                  
                      </div>
                      
                    </CardTitle>
                    <br></br>
                    <h6 className="card-category">From</h6><h5><strong>R1799</strong></h5>
                    <br></br>
                    <p className="card-description">
                      Our team works collaboratively to create professionallly branded content, consistent 
                      throughout your profile and communications. This creates an impressive look and feel of your corporate identity, increasing 
                      client confidence.
                    </p>
                    
                 </CardBody>
                  <CardFooter>
                  <Link to="/corporate-identity">
                  <Button
                      className="btn-link" id="colorviolet2"
                      
                    >
                      See more
                    </Button>
                    </Link>
                  </CardFooter>
                </Card>
                </Link>
              </div>
            </Col>
            <Col sm="12" md="12" lg="12">
            
            </Col>
            
                  </Row>

                  <Row className="shift-up-100px-0px">
                <Col lg="12" md="12" sm="12">
                <Divider dividerHeading="divider__heading4" divTitle="Training" />

                </Col>
              </Row>


                  <Row>
                   
              <Col lg="4" md="4" sm="6" xs="12" className="col3">
              <div className="card-big-shadow">
              <Link to="/website-maintenance-training">
                <Card
                  className="webcard"
                  >
                 <CardBody>
                  <hr className="h-line4" />
                    <CardTitle tag="h5">
                    <img 
                        alt="website"
                        className="webcardimg mb-5"
                        src={require("assets/img/nlicons/web/dashboard-level2.svg")}
                        />
                    <div className="webcardicon">
                      
                        <span>
                        <h5 className="webcardtitle">
                        Website Maintenance Training
                      </h5>
                        </span>
                  
                      </div>
                      
                    </CardTitle>
                    <br></br>
                    <h6 className="card-category">From</h6><h5><strong>R499</strong></h5>
                    <br></br>
                    <p className="card-description">
                    We provide you with all the skills needed to edit and update your website content yourself.
                    </p>
                    
                 </CardBody>
                  <CardFooter>
                  <Link to="/website-maintenance-training">
                  <Button
                      className="btn-link" id="colorviolet2"
                      
                    >
                      See more
                    </Button>
                    </Link>
                  </CardFooter>
                </Card>
                </Link>
              </div>
            </Col>
            <Col lg="4" md="4" sm="6" xs="12" className="col3">
              <div className="card-big-shadow">
              <Link to="/development-training">
                <Card
                  className="webcard"
                  >
                 <CardBody>
                  <hr className="h-line4" />
                    <CardTitle tag="h5">
                    <img 
                        alt="website"
                        className="webcardimg mb-5"
                        src={require("assets/img/nlicons/web/barcode-qr.svg")}
                        />
                    <div className="webcardicon">
                     
                        <span>
                        <h5 className="webcardtitle">
                        Web Development Training
                      </h5>
                        </span>
                  
                      </div>
                      
                    </CardTitle>
                    <br></br>
                    <h6 className="card-category">From</h6><h5><strong>R799</strong></h5>
                    <br></br>
                    <br></br>
                    <br></br>
                    <p className="card-description">
                      We equip you with all of the skills needed to build your own websites from scratch.
                    </p>
                    
                 </CardBody>
                  <CardFooter>
                  <Link to="/development-training">
                  <Button
                      className="btn-link" id="colorviolet2"
                      
                    >
                      See more
                    </Button>
                    </Link>
                  </CardFooter>
                </Card>
                </Link>
              </div>
            </Col>
            <Col lg="4" md="4" sm="6" xs="12" className="col3">
              <div className="card-big-shadow">
              <Link to="/software-training">
                <Card
                  className="webcard"
                  >
                 <CardBody>
                  <hr className="h-line4" />
                    <CardTitle tag="h5">
                    <img 
                        alt="website"
                        className="webcardimg mb-5"
                        src={require("assets/img/nlicons/web/edit-78.svg")}
                        />
                    <div className="webcardicon">
                      
                        <span>
                        <h5 className="webcardtitle">
                        Software Development Workshops
                      </h5>
                        </span>
                  
                      </div>
                      
                    </CardTitle>
                    <br></br>
                    <h6 className="card-category">From</h6><h5><strong>R2499</strong></h5>
                    <br></br>
                    <p className="card-description">
                      We provide training on various software systems and products.
                    </p>
                    <br></br>
                 </CardBody>
                  <CardFooter>
                  <Link to="/software-training">
                  <Button
                      className="btn-link" id="colorviolet2"
                      
                    >
                      See more
                    </Button>
                    </Link>
                  </CardFooter>
                </Card>
                </Link>
              </div>
            </Col>
            <Col sm="12" md="12" lg="12">
           
            </Col>
            
                  </Row>
                  
                </div>
                <div
          className="pricing-3 custom-software-div3"
          style={{
            backgroundImage:
              "url(" + require("assets/img/nlbg/bg3.jpg") + ")",
          }}
        >
          <Container>
          
            <Row>
              <Col className="ml-auto mr-auto" md="12">
                <h2 id="custom-titlewhite">What We Promise</h2>
                <h5 className="custom-whitetext-custom align-left mx-20">
                NEXTLEVEL Technology promises to offer the best quality custom web application development 
                services at the most competitive pricing. We use the latest web application frameworks 
                (both the frontend and backend) for our website design and custom website development. 
                <br></br><br></br>From small businesses to leading enterprises. We develop and provide clients a business goal and 
                strategic approach to driving qualified traffic, thereby leading to business growth and better ROI. 
                <br></br><br></br>Our professional website developers and project managers possess significant expertise to implement modern 
                programming languages for promising cloud-based web solutions. We assure secure, reliable and easy to maintain web enabled solutions delivery. 
                </h5>
                <br />
                
              </Col>
            </Row>
            <br />
            <br />
            
          </Container>
        </div>
       
        <ContactComponent />
      </div>
     
      <FooterNav />
    </>
    
  );
}

export default WebDevelopment;
