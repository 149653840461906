import React from 'react'

function Divider({dividerHeading, divTitle}) {
    return (
        <div>
<div className="divider__centertext">
            <h1 class={dividerHeading}>{divTitle}</h1>
        </div>
        <div className="divider__body">
            

<span> 
<div className="custom01"></div>
<div></div> 
  <div></div> 
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
</span>


            
        </div>

        </div>
        
    )
}

export default Divider



