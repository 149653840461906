import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col,
} from "reactstrap";
import {Link} from 'react-router-dom';
// core components
import ColorNavbar from "components/Navbars/ColorNavbar"; 
import ContactComponent from '../services/ContactComponent.js';

import WebHeaderTemplate from "./webHeaders/WebHeaderTemplate.js";
import FooterNav from "../services/FooterNav.js";
import Product from "../Product";

function WebSecurity() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
    };
  });
  return (
    <>
      <ColorNavbar />
      <WebHeaderTemplate navTitle="Website Security Services" Title="Website Security" />

      <div className="wrapper">
        {/* ********* PRICING 3 ********* */}
        <div
          className="pricing-3 section-bg-img"
          style={{
            backgroundImage:
            "url(" + require("assets/img/nl/sections/custom/red-blue.jpg") + ")",
          }}
        >
           <Container>
            <Row>
              <Col lg="12" md="12" sm="12" xs="12">
                <h1 id="titlewhite3">
                        SSL Certificates
                        </h1>
                </Col>
              </Row>
          <Row>
          <Col xl="4" lg="4" md="12" sm="12" xs="12" className="col1">
              <Card className="card-pricing w-150">
              <h6 className="card-category">Budget</h6>
              
                <CardBody>
                <div className="webcardicon my-5">
                      <img 
                        alt="security"
                        className="webcardimg"
                        src={require("assets/img/nlicons/web/lock.svg")}
                        />
                        <span>
                        <h5 className="webcardtitle">
                        Standard SSL Certificate
                      </h5>
                        </span>
                  
                      </div>
                  <CardTitle tag="h2">R1099</CardTitle>
                  <ul className="align-left">
                   <li>
                    <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />
                    Domain Validation
                    </li>
                    <li>
                    <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />
                    Registration & Setup
                    </li>
                    <li>
                    <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />
                    Certificate Valid for 12 Months
                    </li>
                     <li>
                    <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />
                    Certificate Renewal Option Available after 12 Months
                    </li>
                     <li>
                    <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />
                    SHA256 Signature Algorithm 
                    </li>
                    

                  </ul>
                  
                  <br></br>
                  <br></br>
                  <br></br>
                  <Product 
                  id="StandardSSLCertificate"
                  title="Standard SSL Certificate"
                  price={1099.0}
                  rating={5}
                  image={require("assets/img/nlicons/web/lock.svg")}
                  />   
                 <Link to="/contact">
                  <Button
                    className="btn-round card-link web-card-btn"
                    color="success"
                    href="" 
                  >
                    Get Started
                  </Button>
                  </Link>
                </CardBody>
              </Card>
            </Col>
            <Col xl="4" lg="4" md="12" sm="12" xs="12" className="col1">
              <Card className="card-pricing w-150">
              <h6 className="card-category">Standard</h6>
              
                <CardBody>
                <div className="webcardicon my-5">
                      <img 
                        alt="security"
                        className="webcardimg"
                        src={require("assets/img/nlicons/web/lock.svg")}
                        />
                        <span>
                        <h5 className="webcardtitle">
                        Advanced SSL Certificate
                      </h5>
                        </span>
                  
                      </div>
                      <CardTitle tag="h2">R1899</CardTitle>
                      ,  
                  <ul className="align-left">
                     <li>
                    <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />
                    Extended Validation Certificate
                    </li>
                    <li>
                    <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />
                    Registration & Setup
                    </li>
                    <li>
                    <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />
                    Certificate Valid for 12 Months
                    </li>
                     <li>
                    <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />
                    Certificate Renewal Option Available after 12 Months
                    </li>
                     <li>
                    <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />
                    SHA256 Signature Algorithm 
                    </li>

                  </ul>
                  
                  <br></br>
                  <br></br>
                  <br></br>
                  <Product 
                  id="AdvancedSSLCertificate"
                  title="Advanced SSL Certificate"
                  price={1899.0}
                  rating={5}
                  image={require("assets/img/nlicons/web/lock.svg")}
                  />   
                 <Link to="/contact">
                  <Button
                    className="btn-round card-link web-card-btn"
                    color="success"
                    href="" 
                  >
                    Get Started
                  </Button>
                  </Link>
                </CardBody>
              </Card>
            </Col>
            
            <Col xl="4" lg="4" md="12" sm="12" xs="12" className="col1">
              <Card className="card-pricing w-150">
              <h6 className="card-category">Premium</h6>
                <CardBody>
                <div className="webcardicon my-5">
                      <img 
                        alt="security"
                        className="webcardimg"
                        src={require("assets/img/nlicons/web/lock.svg")}
                        />
                        <span>
                        <h5 className="webcardtitle">
                        Multi-Domain SSL Certificate
                      </h5>
                        </span>
                  
                      </div>
                      <CardTitle tag="h2">R2899</CardTitle>
                  <ul className="align-left">
                    <li>
                    <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />
                    Extended Validation Certificate
                    </li>
                    <li>
                    <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />
                    Multiple Domains & Subdomains
                    </li>
                    <li>
                    <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />
                    Registration & Setup
                    </li>
                    <li>
                    <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />
                    Certificate Valid for 12 Months
                    </li>
                     <li>
                    <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />
                    Certificate Renewal Option Available after 12 Months
                    </li>
                     <li>
                    <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />
                    SHA256 Signature Algorithm 
                    </li>
                  </ul>
                  
                  <br></br>
                  <br></br>
                  <br></br>
                  <Product 
                  id="Multi-DomainSSLCertificate"
                  title="Multi-Domain SSL Certificate"
                  price={2899.0}
                  rating={5}
                  image={require("assets/img/nlicons/web/lock.svg")}
                  />
                 <Link to="/contact">
                  <Button
                    className="btn-round card-link web-card-btn"
                    color="success"
                    href="" 
                  >
                    Get Started
                  </Button>
                  </Link>
                </CardBody>
              </Card>
            </Col>
           
          
            
          </Row>
         
           <Row>
              <Col lg="12" md="12" sm="12" xs="12">
                <h1 id="titlewhite3">
                        Security Audits
                        </h1>
                </Col>
              </Row>

        <Row>
          <Col xl="4" lg="4" md="12" sm="12" xs="12" className="col1">
              <Card className="card-pricing w-150">
              <h6 className="card-category">Standard</h6>
              
                <CardBody>
                <div className="webcardicon my-5">
                      <img 
                        alt="security"
                        className="webcardimg"
                        src={require("assets/img/nlicons/web/lock.svg")}
                        />
                        <span>
                        <h5 className="webcardtitle">
                        Website Vulnerability Assessment
                      </h5>
                        </span>
                  
                      </div>
                  <CardTitle tag="h2">R649</CardTitle>
                  <ul className="align-left">
                   <li>
                    <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />
                    Vulnerability Test Setup
                    </li>
                    <li>
                    <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />
                    Vulnerability Test Execution
                    </li>
                    <li>
                    <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />
                    Vulnerability Analysis
                    </li>
                     <li>
                    <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />
                    Vulnerability Report
                    </li>
                     
                    

                  </ul>
                  
                  <br></br>
                  <br></br>
                  <br></br>
                  <Product 
                  id="WebsiteVulnerabilityAssessment"
                  title="Website Vulnerability Assessment"
                  price={649.0}
                  rating={5}
                  image={require("assets/img/nlicons/web/lock.svg")}
                  />   
                 <Link to="/contact">
                  <Button
                    className="btn-round card-link web-card-btn"
                    color="success"
                    href="" 
                  >
                    Get Started
                  </Button>
                  </Link>
                </CardBody>
              </Card>
            </Col>
            <Col xl="4" lg="4" md="12" sm="12" xs="12" className="col1">
              <Card className="card-pricing w-150">
              <h6 className="card-category">Standard</h6>
              
                <CardBody>
                <div className="webcardicon my-5">
                      <img 
                        alt="security"
                        className="webcardimg"
                        src={require("assets/img/nlicons/web/lock.svg")}
                        />
                        <span>
                        <h5 className="webcardtitle">
                        Hacked Website Assessment & Recovery
                      </h5>
                        </span>
                  
                      </div>
                      <CardTitle tag="h2">R2499</CardTitle>
                      ,  
                  <ul className="align-left">
                     <li>
                    <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />
                    Hacked Website Audit
                    </li>
                    <li>
                    <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />
                    Domain Recovery
                    </li>
                    <li>
                    <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />
                    Data Recovery
                    </li>
                     <li>
                    <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />
                    Security Analysis & Report 
                    </li>
                    

                  </ul>
                  
                  <br></br>
                  <br></br>
                  <br></br>
                  <Product 
                  id="HackedWebsiteAssessment&Recovery"
                  title="Hacked Website Assessment & Recovery"
                  price={649.0}
                  rating={5}
                  image={require("assets/img/nlicons/web/lock.svg")}
                  />   
                 <Link to="/contact">
                  <Button
                    className="btn-round card-link web-card-btn"
                    color="success"
                    href="" 
                  >
                    Get Started
                  </Button>
                  </Link>
                </CardBody>
              </Card>
            </Col>
            
            <Col xl="4" lg="4" md="12" sm="12" xs="12" className="col1">
              <Card className="card-pricing w-150">
              <h6 className="card-category">Premium</h6>
                <CardBody>
                <div className="webcardicon my-5">
                      <img 
                        alt="security"
                        className="webcardimg"
                        src={require("assets/img/nlicons/web/lock.svg")}
                        />
                        <span>
                        <h5 className="webcardtitle">
                        Full Penetration Testing Assessment
                      </h5>
                        </span>
                  
                      </div>
                      <CardTitle tag="h2">R4999</CardTitle>
                  <ul className="align-left">
                   <li>
                    <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />
                    Penetration Test Setup
                    </li>
                    <li>
                    <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />
                    Penetration Test Execution
                    </li>
                    <li>
                    <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />
                    Penetration Test Analysis
                    </li>
                     <li>
                    <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />
                    Penetration Test Report
                    </li>
                     
                  </ul>
                  
                  <br></br>
                  <br></br>
                  <br></br>
                  <Product 
                  id="FullPenetrationTestingAssessment"
                  title="Full Penetration Testing Assessment"
                  price={4999.0}
                  rating={5}
                  image={require("assets/img/nlicons/web/lock.svg")}
                  />   
                 <Link to="/contact">
                  <Button
                    className="btn-round card-link web-card-btn"
                    color="success"
                    href="" 
                  >
                    Get Started
                  </Button>
                  </Link>
                </CardBody>
              </Card>
            </Col>
          
          </Row>

           <Row>
              <Col lg="12" md="12" sm="12" xs="12">
                <h1 id="titlewhite2">
                        Web Security Solutions
                        </h1>
                </Col>
              </Row>
          <Row>
          <Col xl="4" lg="4" md="12" sm="12" xs="12" className="col1">
              <Card className="card-pricing w-150">
              <h6 className="card-category">Basic</h6>
              
                <CardBody>
                <div className="webcardicon my-5">
                      <img 
                        alt="security"
                        className="webcardimg"
                        src={require("assets/img/nlicons/web/lock.svg")}
                        />
                        <span>
                        <h5 className="webcardtitle">
                        WP Security & Firewall - Wordpress
                      </h5>
                        </span>
                  
                      </div>
                  <CardTitle tag="h2">R749</CardTitle>
                  <ul className="align-left">
                   <li>
                    <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />
                    Web Security Suite For Wordpress
                    </li>
                    <li>
                    <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />
                    Login Protection
                    </li>
                    <li>
                    <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />
                    Malware Scanner
                    </li>
                     <li>
                    <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />
                    Brute Force Attacks Prevention
                    </li>
                     <li>
                    <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />
                    Plugin Integration
                    </li>
                     <li>
                    <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />
                    Realtime Global IP Blocking
                    </li>
                    <li>
                    <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />
                    Rate Limiting Of Requests
                    </li>
                    

                  </ul>
                  
                  <br></br>
                  <br></br>
                  <br></br>
                  <Product 
                  id="WPSecurity&Firewall-Wordpress"
                  title="WP Security & Firewall - Wordpress"
                  price={749.0}
                  rating={5}
                  image={require("assets/img/nlicons/web/lock.svg")}
                  />   
                 <Link to="/contact">
                  <Button
                    className="btn-round card-link web-card-btn"
                    color="success"
                    href="" 
                  >
                    Get Started
                  </Button>
                  </Link>
                </CardBody>
              </Card>
            </Col>
            <Col xl="4" lg="4" md="12" sm="12" xs="12" className="col1">
              <Card className="card-pricing w-150">
              <h6 className="card-category">Standard</h6>
              
                <CardBody>
                <div className="webcardicon my-5">
                      <img 
                        alt="security"
                        className="webcardimg"
                        src={require("assets/img/nlicons/web/lock.svg")}
                        />
                        <span>
                        <h5 className="webcardtitle">
                        Cloudflare - Content Delivery Network
                      </h5>
                        </span>
                  
                      </div>
                      <CardTitle tag="h2">R799</CardTitle>
                      ,  
                  <ul className="align-left">
                     <li>
                    <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />
                    DDos Attack Mitigation
                    </li>
                     <li>
                    <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />
                    Static Content Caching
                    </li>
                    <li>
                    <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />
                    Global Content Delivery Network
                    </li>
                    <li>
                    <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />
                    Accelerated Mobile Links
                    </li>
                     <li>
                    <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />
                    Mobile Optimisation
                    </li>
                    <li>
                    <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />
                    Lossless Image Optimisation
                    </li>
                    <li>
                    <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />
                    Optimised JavaScipt Loading
                    </li>
                     <li>
                    <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />
                    Spam Protection
                    </li>
                     <li>
                    <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />
                    Web Traffic Monitoring
                    </li>

                  </ul>
                  
                  <br></br>
                  <br></br>
                  <br></br>
                  <Product 
                  id="Cloudflare-ContentDeliveryNetwork"
                  title="Cloudflare - Content Delivery Network"
                  price={799.0}
                  rating={5}
                  image={require("assets/img/nlicons/web/lock.svg")}
                  />   
                 <Link to="/contact">
                  <Button
                    className="btn-round card-link web-card-btn"
                    color="success"
                    href="" 
                  >
                    Get Started
                  </Button>
                  </Link>
                </CardBody>
              </Card>
            </Col>
            
            <Col xl="4" lg="4" md="12" sm="12" xs="12" className="col1">
              <Card className="card-pricing w-150">
              <h6 className="card-category">Premium</h6>
                <CardBody>
                <div className="webcardicon my-5">
                      <img 
                        alt="security"
                        className="webcardimg"
                        src={require("assets/img/nlicons/web/lock.svg")}
                        />
                        <span>
                        <h5 className="webcardtitle">
                        Wordfence - Wordpress
                      </h5>
                        </span>
                  
                      </div>
                      <CardTitle tag="h2">R2499</CardTitle>
                  <ul className="align-left">
                    <li>
                    <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />
                    Complete Web Security Suite For Wordpress
                    </li>
                    <li>
                    <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />
                    DNS Level Web Application Firewall
                    </li>
                     <li>
                    <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />
                    Realtime Threat & Spam Protection
                    </li>
                    <li>
                    <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />
                    Malware Scanner
                    </li>
                    <li>
                    <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />
                    Security Incident Recovery Tools
                    </li>
                    <li>
                    <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />
                    Login Protection & 2FA
                    </li>
                    <li>
                    <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />
                    Malware Scanner
                    </li>
                     <li>
                    <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />
                    Brute Force Attacks Prevention
                    </li>
                     <li>
                    <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />
                    Plugin Integration
                    </li>
                    
                    <li>
                    <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />
                    Rate Limiting Of Requests
                    </li>
                    <li>
                    <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />
                      Live Traffic Monitoring
                    </li>
                    <li>
                    <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />
                    File Change Detection
                    </li>
                    <li>
                    <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        />
                    Content Safety Checks
                    </li>
                  </ul>
                  
                  <br></br>
                  <br></br>
                  <br></br>
                  <Product 
                  id="Wordfence-Wordpress"
                  title="Wordfence - Wordpress"
                  price={2499.0}
                  rating={5}
                  image={require("assets/img/nlicons/web/lock.svg")}
                  />   
                 <Link to="/contact">
                  <Button
                    className="btn-round card-link web-card-btn"
                    color="success"
                    href="" 
                  >
                    Get Started
                  </Button>
                  </Link>
                </CardBody>
              </Card>
            </Col>
           
          
            
          </Row>   
          </Container>
        </div>
        <ContactComponent />
      </div>
      <FooterNav />
    </>
  );
}

export default WebSecurity;
