import React from "react";

// reactstrap components
import { Card, CardBody, Row, Col, CardTitle } from "reactstrap";
import ContactComponent from '../services/ContactComponent.js';

// core components
import ColorNavbar from "components/Navbars/ColorNavbar.js";
 
import HealthcareHeader from "components/Headers/Custom/HealthcareHeader.js";
import FooterNav from "../services/FooterNav.js";

function Healthcare() {
  document.documentElement.classList.remove("nav-open");
  // function that is being called on scroll of the page
  const checkScroll = () => {
    // it takes all the elements that have the .add-animation class on them
    const componentPosition = document.getElementsByClassName("add-animation");
    const scrollPosition = window.pageYOffset;
    for (var i = 0; i < componentPosition.length; i++) {
      var rec =
        componentPosition[i].getBoundingClientRect().top + window.scrollY + 100;
      // when the element with the .add-animation is in the scroll view,
      // the .animated class gets added to it, so it creates a nice fade in animation
      if (scrollPosition + window.innerHeight >= rec) {
        componentPosition[i].classList.add("animated");
        // when the element with the .add-animation is not in the scroll view,
        // the .animated class gets removed from it, so it creates a nice fade out animation
      } else if (scrollPosition + window.innerHeight * 0.8 < rec) {
        componentPosition[i].classList.remove("animated");
      }
    }
  };
  

  React.useEffect(() => {
    document.body.classList.add("presentation-page");
    window.addEventListener("scroll", checkScroll);
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("presentation-page");
      window.removeEventListener("scroll", checkScroll);
    };
  });
  return (
    <>
      <ColorNavbar />
      <HealthcareHeader />
      <div className="wrapper">
       
     
     
      <div>
           
      <Row>
      
                    <Col md="6">
                      <div className="topcard">
                        <h2 id="titleblack" className="align-left2">
                        Why choose NEXTLEVEL? So glad you asked!
                        </h2>
                       
                      </div>
                    
                    </Col>
                    <Col md="6">
                      
                  <p className="top-description">
                  In today’s digital world, healthcare companies are juggling rigorous internal processes, strict regulatory 
                  requirements, and growing patient expectations for convenient, transparent, and personal interactions with 
                  medical professionals. At NEXTLEVEL, we partner with healthcare companies to develop the software solutions 
                  they need to improve every aspect of patient care.
                  </p>
                 
                    </Col> 
                </Row>

        </div>












         <div
          className="pricing-3 custom-software-div3"
          style={{
            backgroundImage:
              "url(" + require("assets/img/nl/industries/health/dna.jpg") + ")",
          }}
        >
        
        <div className="my-20 padding-left-20px">
        
        <Row>
        <Col>
        <div className="custom-software-div3">
<h2 id="titleblack" className="align-left2">
                        Your priorities are our priorities
                        </h2>
                        <br></br>
                        <h5 className="custom-blacktext">
                        Work alongside NEXTLEVEL's expert business analysts, engineers, and developers to design and build 
                        the software applications you need to streamline operations, automate tedious manual processes, 
                        and provide flawless patient experiences. 

                        </h5>
        </div>

        </Col>
               
        <Row className="padding-health">
        <Col className="col1">
         <div className="card-big-shadow">
                <Card
                  className="customcard1"
                >
                  <CardBody>
                  <div className="customcardicon">
                  <img 
                         alt="maintenance"
                         className="customcardimg"
                         src={require("assets/img/nlicons/health/healthcare.svg")}
                         />

                    </div>
                    <br></br>

                      
                    <CardTitle tag="h5">
                      
                        <span>
                        <h5 className="webcardtitle">
                        Personalise Patient Experience
                      </h5>
                        </span>
                  
                      
                    </CardTitle>
                    <br></br>
                    <p className="card-description">
                    From streamlined booking systems to mobile healthcare applications, our custom software solutions 
                    empower patients to take their health into their own hands.
                    </p>
                  </CardBody>
                </Card>
              </div>
              </Col>
              <Col className="custom-mr col1">
               <div className="card-big-shadow">
               <Card
                  className="customcard1"
                >
                  <CardBody>
                  <div className="customcardicon">
                  <img 
                         alt="maintenance"
                         className="customcardimg"
                         src={require("assets/img/nlicons/health/health-check.svg")}
                         />

                    </div>
                    <br></br>

                      
                    <CardTitle tag="h5">
                      
                        <span>
                        <h5 className="webcardtitle">
                        Simplify Operations & Processes
                      </h5>
                        </span>
                  
                      
                    </CardTitle>
                    <br></br>
                    <p className="card-description">
                    NEXTLEVEL helps healthcare clients streamline care and equipment management to give healthcare providers more time to focus on patient care.
                    </p>
                  </CardBody>
                </Card>
              </div>
             
        </Col>
        
        </Row>
       
        <Row>
         
        
        <Col className="custom-mr col1">
        <div className="card-big-shadow">
        <Card
                  className="customcard1"
                >
                  <CardBody>
                  <div className="customcardicon">
                  <img 
                         alt="maintenance"
                         className="customcardimg"
                         src={require("assets/img/nlicons/health/collaboration.svg")}
                         />

                    </div>
                    <br></br>

                      
                    <CardTitle tag="h5">
                      
                        <span>
                        <h5 className="webcardtitle">
                        Enhance Collaboration
                      </h5>
                        </span>
                  
                      
                    </CardTitle>
                    <br></br>
                    <p className="card-description">
                    Our custom software applications enable medical professionals to collaborate effectively from across the hall, across town, and across the globe.
                    </p>
                  </CardBody>
                </Card>
                
              </div>
              </Col>
              <Col className="custom-mr col1 col-md-offset-3">
               <div className="card-big-shadow">
                <Card
                  className="customcard1"
                >
                  <CardBody>
                  <div className="customcardicon">
                  <img 
                         alt="maintenance"
                         className="customcardimg"
                         src={require("assets/img/nlicons/health/hospital.svg")}
                         />

                    </div>
                    <br></br>

                      
                    <CardTitle tag="h5">
                      
                        <span>
                        <h5 className="webcardtitle">
                        Ensure Security & Compliance
                      </h5>
                        </span>
                  
                      
                    </CardTitle>
                    <br></br>
                    <p className="card-description">
                    NEXTLEVEL's engineers are well versed in NHA & POPI Act compliance and can build custom applications 
                    that are designed to keep your patients’ information secure and confidential.
                    </p>
                  </CardBody>
                </Card>
              </div>
        </Col>
        </Row>
        <Col>
          <img 
                        alt="website"
                        className="main-layout-img2"
                        src={require("assets/img/nl/industries/health/doctor.jpg")}
                        />
          </Col>
</Row>
<br></br>
<br></br>
<br></br>
<br></br>
<br></br>
<br></br>

        </div>
        
        </div>  


<Row className="shiftup">
  <Col>
  <div className="custom-software-div">
    <br></br>
    <br></br>

<h2 className="align-left2 ml-30" id="titleblack">
Specific Applications
                        </h2>
                        <h5 className="custom-blacktext ml-20">
                        Bring your healthcare company into the digital age with a NEXTLEVEL custom software application. 

                        </h5>
        </div>
  </Col>
  <Col>
  <img 
                        alt="healthcare application"
                        className="main-layout-img"
                        src={require("assets/img/nl/industries/medical-record.jpg")}
                        />
  </Col>
</Row>







      
                    <div className="small-border">
                      <div className="box">
                      <Row className="shift-down-80px pr-20">
        <Col md="4">
        <img 
                         alt="maintenance"
                         className="customcardimg"
                         src={require("assets/img/nlicons/health/heartbeat.svg")}
                         />
          <h3 id="titleblack2">Patient Care</h3>
          <br></br>
          <p className="top-description2">
          From making critical information accessible with the swipe of a finger to enhancing access to medical professionals, NEXTLEVEL's clients use our software applications to improve every aspect of the patient experience.
          </p>
          </Col>
        <Col md="4">
        <img 
                         alt="maintenance"
                         className="customcardimg"
                         src={require("assets/img/nlicons/health/group-users.svg")}
                         />
          <h3 id="titleblack2">Data Management</h3>
          <br></br>
          <p className="top-description2">
          Data is the heart of every business, but the stakes are highest when your patients’ health is on the line. NEXTLEVEL's software solutions help our clients manage data while paying close attention to security and compliance.
          </p>
        </Col>
        <Col md="4">
        <img 
                         alt="maintenance"
                         className="customcardimg"
                         src={require("assets/img/nlicons/health/human-resources.svg")}
                         />
          <h3 id="titleblack2">Process Management</h3>
          <br></br>
          <p className="top-description2">
          NEXTLEVEL's applications help our healthcare customers streamline internal and external processes to meet patients’ demands for efficiency, transparency, and personalized service.
          </p>
        </Col>
        <Col md="4">
        <img 
                         alt="maintenance"
                         className="customcardimg"
                         src={require("assets/img/nlicons/health/phone.svg")}
                         />
          <h3 id="titleblack2">System Modernisation</h3>
          <br></br>
          <p className="top-description2">
          Legacy systems managed on paper and in spreadsheets can no longer efficient meet today’s healthcare needs. NEXTLEVEL's software solutions help healthcare companies find their place in the new, digital landscape.
          </p>
        </Col>
        </Row>
                       
                      </div>
                    
                    
                  </div> 

                  <div
          className="pricing-3 custom-software-div3 shift-down-50px"
          style={{
            backgroundImage:
              "url(" + require("assets/img/nl/industries/health/dnabg.jpg") + ")",
          }}
        >

        <Row>
          <Col md="6">

          </Col>
          <Col md="6">
          <h1 className="whath2qa5"><span>Why Choose NEXTLEVEL</span></h1>

            <ul className="qalist">
              <li><img 
                        alt="website"
                        className="webcardimg mx-4"
                        src={require("assets/img/nlicons/check-circle-white.svg")}
                        /><h5 className="whychoose">Professional Team</h5><br></br>NEXTLEVEL provides a full professional development team with a customer-oriented approach.</li>
              <br></br>
              <li><img 
                        alt="website"
                        className="webcardimg mx-4"
                        src={require("assets/img/nlicons/check-circle-white.svg")}
                        /><h5 className="whychoose">Expertise</h5><br></br>Our developers’ diverse technology stack experience and our expertise in the healthcare sphere mean we can partner with clients to tailor effective solutions to any need. </li>
              <br></br>
              <li><img 
                        alt="website"
                        className="webcardimg mx-4"
                        src={require("assets/img/nlicons/check-circle-white.svg")}
                        /><h5 className="whychoose">Experience</h5><br></br>Our expert UI and UX developers and engineers can give healthcare companies a “digital makeover,” with visually appealing apps that promote a better patient experience. </li>
              <br></br>
              <li><img 
                        alt="website"
                        className="webcardimg mx-4"
                        src={require("assets/img/nlicons/check-circle-white.svg")}
                        /><h5 className="whychoose">Privacy & Security</h5><br></br>We understand the need for utmost data privacy, and we employ best practices to ensure your systems are secure and fully compliant to applicable regulations.  </li>

            </ul>
            
            </Col>
        </Row>
        </div>
        
               
        
        


       
        <ContactComponent />
      </div>
      <div className="separator" />
      <FooterNav />
    </>
  );
}

export default Healthcare;
