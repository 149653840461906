import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import ColorNavbar from "components/Navbars/ColorNavbar"; 
import ContactComponent from '../services/ContactComponent.js';

import WebHeaderTemplate from "./webHeaders/WebHeaderTemplate.js";
import FooterNav from "../services/FooterNav.js";
import {Link} from 'react-router-dom';
import Product from "../Product";

function WebSEO() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
    };
  });
  return (
    <>
      <ColorNavbar />
      <WebHeaderTemplate navTitle="SEO Packages" Title="Search Engine Optimisation" />
      <div className="wrapper">
        {/* ********* PRICING 3 ********* */}
        <div
          className="pricing-3 section-bg-img"
          style={{
            backgroundImage:
            "url(" + require("assets/img/nl/sections/custom/red-blue.jpg") + ")",
          }}
        >
           <Container>
           <Row>
              <Col lg="12" md="12" sm="12" xs="12">
                <h1 id="titlewhite3">
                        SEO Audits
                        </h1>
                </Col>
              </Row>
          <Row>
            <Col md="4" className="col1">
              <Card className="card-pricing w-120">
              <h6 className="card-category">Technical Audit</h6>
              
                <CardBody>
                <img 
                        alt="website"
                        className="webcardimg mx-4"
                        src={require("assets/img/nlicons/web/link-72.svg")}
                        />
                <div className="webcardicon my-5">
                     
                        <span>
                        <h5 className="webcardtitle">
                        Indexability Checks
                      </h5>
                        </span>
                  
                      </div>
                  <CardTitle tag="h2">R8999</CardTitle>
                  <p className="servicep">
                  A full health check of your site and its technical build with recommendations on what issues are and exactly how to fix them.
                  </p>
                  <br></br>
                  <Product 
                  id="IndexabilityChecks"
                  title="Indexability Checks"
                  price={8999.0}
                  rating={5}
                  image={require("assets/img/nlicons/web/link-72.svg")}
                  />   
                 <Link to="/contact">
                  <Button
                    className="btn-round card-link web-card-btn"
                    color="success"
                    href="" 
                  >
                    Get Started
                  </Button>
                  </Link>
                </CardBody>
              </Card>
            </Col>
            <Col md="4" className="col1">
              <Card className="card-pricing w-120">
              <h6 className="card-category">Content Audit</h6>
              
                <CardBody>
                <img 
                        alt="website"
                        className="webcardimg mx-4"
                        src={require("assets/img/nlicons/web/link-72.svg")}
                        />
                <div className="webcardicon my-5">
                      
                        <span>
                        <h5 className="webcardtitle">
                        Relevancy Check
                      </h5>
                        </span>
                  
                      </div>
                  <CardTitle tag="h2">R12999</CardTitle>
                  <p className="servicep">
                  A full analysis of your website’s current content and a strategy moving forward, with step by step content guides.
                  </p>
                  <Product 
                  id="RelevancyCheck"
                  title="Relevancy Check"
                  price={12999.0}
                  rating={5}
                  image={require("assets/img/nlicons/web/link-72.svg")}
                  />   
                 <Link to="/contact">
                  <Button
                    className="btn-round card-link web-card-btn"
                    color="success"
                    href="" 
                  >
                    Get Started
                  </Button>
                  </Link>
                </CardBody>
              </Card>
            </Col>
            <Col md="4" className="col1">
              <Card className="card-pricing w-120">
              <h6 className="card-category">Competitor Audit</h6>
                <CardBody>
                <img 
                        alt="website"
                        className="webcardimg mx-4"
                        src={require("assets/img/nlicons/web/link-72.svg")}
                        />
                <div className="webcardicon my-5">
                      
                        <span>
                        <h5 className="webcardtitle">
                        Authority Check
                      </h5>
                        </span>
                  
                      </div>
                  <CardTitle tag="h2">R7999</CardTitle>
                  <p className="servicep">
                  A detailed analysis of your positions online, the gap between you and your competitors as well as how difficult it will be to overtake them

                  </p>
                  <Product 
                  id="AuthorityCheck"
                  title="Authority Check"
                  price={7999.0}
                  rating={5}
                  image={require("assets/img/nlicons/web/link-72.svg")}
                  />   
                 <Link to="/contact">
                  <Button
                    className="btn-round card-link web-card-btn"
                    color="success"
                    href="" 
                  >
                    Get Started
                  </Button>
                  </Link>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
              <Col lg="12" md="12" sm="12" xs="12">
                <h1 id="titlewhite2">
                        Monthly Packages
                        </h1>
                </Col>
              </Row>
              <Row>
            <Col md="6" className="col1">
              <Card className="card-pricing w-120">
              <h6 className="card-category">Full Audit</h6>
              
                <CardBody>
                
                <div className="webcardicon my-5">
                <img 
                        alt="website"
                        className="webcardimg mx-4"
                        src={require("assets/img/nlicons/web/link-72.svg")}
                        />
                        <span>
                        <h5 className="webcardtitle">
                        Premium Audit
                      </h5>
                        </span>
                  
                      </div>
                  <CardTitle tag="h2">R19999</CardTitle>
                  <br></br>
                  <br></br>
                  <br></br>
                  For the ultimate SEO advantage, combine all 3 audits for a comprehensive SEO audit package and Save.
                  <br></br>
                  <br></br>
                  <br></br>
The Premium Audit takes 4 weeks to complete.
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <Product 
                  id="PremiumAudit"
                  title="Premium Audit"
                  price={19999.0}
                  rating={5}
                  image={require("assets/img/nlicons/web/link-72.svg")}
                  />   
                 <Link to="/contact">
                  <Button
                    className="btn-round card-link web-card-btn"
                    color="success"
                    href="" 
                  >
                    Get Started
                  </Button>
                  </Link>
                </CardBody>
              </Card>
            </Col>
            <Col md="6" className="col1">
              <Card className="card-pricing w-120">
              <h6 className="card-category">Retainer</h6>
              
                <CardBody>
                <div className="webcardicon my-5">
                <img 
                        alt="website"
                        className="webcardimg mx-4"
                        src={require("assets/img/nlicons/web/link-72.svg")}
                        />
                        <span>
                        <h5 className="webcardtitle">
                        SEO Retainer
                      </h5>
                        </span>
                  
                      </div>
                  <CardTitle tag="h2">R9999 PM</CardTitle>
                  <br></br>
                  <br></br>
                  <br></br>
                  Our final audit report can be used as either a basis for a retainer, or as a blueprint for your internal development team.
                  <br></br>
                  <br></br>
                  <br></br>
SEO can take time to implement, and even more time to reflect. We recommend clients wait between 3-6 months to see tangible results.
<br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <div className="subscribe__div">
                  <a className="subscribe__btn" href="https://www.payfast.co.za/eng/process?cmd=_paynow&amp;receiver=17143900&amp;item_name=SEO+Retainer&amp;amount=9999.00&amp;return_url=https%3A%2F%2Fnextleveltechnology.org%2Fsuccess&amp;cancel_url=https%3A%2F%2Fnextleveltechnology.org%2Fseo&amp;cycles=0&amp;frequency=3&amp;m_payment_id=pay_now_17143900&amp;subscription_type=1"><img src="https://www.payfast.co.za/images/buttons/dark-large-subscribe.png" width="210" height="59" alt="Subscribe" title="Subscribe Now with PayFast" /></a>
                  </div>
                 <Link to="/contact">
                  <Button
                    className="btn-round card-link web-card-btn"
                    color="success"
                    href="" 
                  >
                    Get Started
                  </Button>
                  </Link>
                </CardBody>
              </Card>
            </Col>
           
            
            
          </Row>
          </Container>
        </div>
        
        <ContactComponent />
      </div>
      <FooterNav />
    </>
  );
}

export default WebSEO;
