import React from "react";
import {Link} from 'react-router-dom';


function DigitalMarketing() {
    return (
        <div className="digital__marketingbase cd-section mr--custom" id="DigitalMarketing">
<div className="digital__marketing ml-20 shift-up-50px">
<Link to="/digital-marketing">
            <h1 className="marketing__title1">Digital</h1>
            </Link>
            <Link to="/digital-marketing">
            <h1 className="marketing__title2">Marketing</h1>
            </Link>
            <Link to="/digital-marketing">
            <h1 className="marketing__title3">That Drives</h1>
            </Link>
            <Link to="/digital-marketing">
            <h1 className="marketing__title4">Results<span>!</span></h1>
            </Link>


<Link to="/digital-marketing">
            <button href="#" class="animated-button">
  <span></span>
  <span></span>
  <span></span>
  <span></span>
  Get Started
</button>
</Link>

            
        </div>
        </div>
        
    )
}

export default DigitalMarketing
