import React from "react";
import "./Subtotal.css";
import CurrencyFormat from "react-currency-format";
import { useStateValue } from "../StateProvider";
import { getBasketTotal } from "../reducer";
import { useHistory } from "react-router-dom";
import { Backdrop } from "@material-ui/core";

function Subtotal() {
  const history = useHistory();
  const [{ basket }, dispatch] = useStateValue();
  return (
    <div className="subtotal">
      <CurrencyFormat
        renderText={(value) => (
          <>
            <p className="total__label">
           
              {/* Part of the homework */}
              Total ({basket.length}  {(() => {
        switch (basket.length) {
          case 1:   return "item";
          
          default:      return "items";
        }
      })()}): <strong className="total__value">{value}</strong>
            </p>
           
            <br></br>
            <br></br>
          </>
        )}
        decimalScale={2}
        value={getBasketTotal(basket)} // Part of the homework
        displayType={"text"}
        thousandSeparator={false}
        prefix={"R"}
      />
      <div className="payment__icon__div">
      <img 
            alt="payment gateway"
            src={require("assets/img/nl/payfast.png")}
            className="payment__icon"

             />
      </div>
       
            
    </div>
  );
}

export default Subtotal;
