import React from "react";

// reactstrap components
import {Row, Col} from "reactstrap";
import FooterNav from "../services/FooterNav.js";
import TransportHeader from "components/Headers/Custom/TransportHeader.js";
import { Link } from 'react-router-dom';

// core components
import ColorNavbar from "components/Navbars/ColorNavbar.js";
 
import ContactComponent from '../services/ContactComponent.js';

function Transport() {
  
  document.documentElement.classList.remove("nav-open");
  // function that is being called on scroll of the page
  const checkScroll = () => {
    // it takes all the elements that have the .add-animation class on them
    const componentPosition = document.getElementsByClassName("add-animation");
    const scrollPosition = window.pageYOffset;
    for (var i = 0; i < componentPosition.length; i++) {
      var rec =
        componentPosition[i].getBoundingClientRect().top + window.scrollY + 100;
      // when the element with the .add-animation is in the scroll view,
      // the .animated class gets added to it, so it creates a nice fade in animation
      if (scrollPosition + window.innerHeight >= rec) {
        componentPosition[i].classList.add("animated");
        // when the element with the .add-animation is not in the scroll view,
        // the .animated class gets removed from it, so it creates a nice fade out animation
      } else if (scrollPosition + window.innerHeight * 0.8 < rec) {
        componentPosition[i].classList.remove("animated");
      }
    }
  };
  

  React.useEffect(() => {
    document.body.classList.add("presentation-page");
    window.addEventListener("scroll", checkScroll);
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("presentation-page");
      window.removeEventListener("scroll", checkScroll);
    };
  });
  return (
    <>
      <ColorNavbar />
      <TransportHeader />
      <div className="wrapper">
       
     
     
      <div>
           
      <Row>
      
                    <Col md="6">
                      <div className="topcard">
                        <h2 id="titleblack3" className="align-left2">
                        For Transportation and Logistics, Traditional Routes Are Changing Fast
                        </h2>
                       
                      </div>
                    
                    </Col>
                    <Col md="6" className="padding-xr-30">
                      
                  <p className="top-description mx-20">
                  Like every modern industry, transportation and logistics are changing rapidly as they move into 
                  the digital era. In order to stay ahead of the curve, logistics companies must invest in cutting-edge 
                  software systems to automate process and optimize operations efficiency and scalability.<br></br><br></br>NEXTLEVEL offers 
                  a deep industry understanding and experience that we’ve gleaned helping African transportation companies take 
                  the leap into the digital future. We architect, create, and maintain state-of-the-art custom software 
                  systems for logistics and transportation industry companies nationwide.
                  </p>
                 
                    </Col> 
                </Row>

        </div>
        
          <Row className="">
         
         

          
          <Col md="6" className="">
          <img 
          alt="cape town"
          className="sectiontransimg"
          src={require("assets/img/nl/industries/transport/stock-taking.jpg")}
          />
          </Col>         
         
          

          <Col md="6">
          <div className="padding-y-50px">
          
          <h2 id="titleblack-trans" className="mr-20"><span>Signs it’s Time for a New, Custom TMS System</span></h2>
          
          <h5 className="whath4trans mx-20"><span>If you’re seeing any of these common friction points in your business operation, 
                      that means it’s time to analyze your systems and replace any ineffective links with the custom software components 
                      that will make your business run smoothly, now and in the future.</span></h5>
      </div>
          </Col>

          <Col md="6" className="shift-down-100pxmin">
          <div className="padding-y-50px">
          
          <h2 id="titleblack-trans" className="mx-10"><span>Why Choose Custom Transportation Management Software?</span></h2>
          
          <h5 className="whath4trans mx-20"><span>While there are several off-the-shelf TMS software options on the market, their out-of-the-box 
            features and clunky integration capabilities will make for a less-than-ideal experience. Custom logistics management software, 
            on the other hand, is built specifically to meet your business’s unique needs, taking into consideration every nuance.<br></br><br></br>What’s more, 
            you own the system — and the value it adds to your marketplace — and you can make adjustments as you grow and evolve, scaling to 
            support expanding teams and seamlessly integrating it with other third-party solutions. In short, with custom software, you’re always 
            in the driver’s seat.</span></h5>
      </div>
          </Col>
           
          <Col md="6" className="shift-down-100pxmin">
          <img 
          alt="cape town"
          className="sectiontransimg"
          src={require("assets/img/nl/industries/transport/truck-shipping.jpg")}
          />
          </Col> 


          <Col md="6" className="shift-down-100pxmin">
          <img 
          alt="cape town"
          className="sectiontransimg"
          src={require("assets/img/nl/industries/transport/auto-cart.jpg")}
          />
          </Col>         
         
          <Col md="6" className="shift-down-100pxmin">
          <div className="padding-y-50px">
          
          <h2 id="titleblack-trans" className="mr-20"><span>Benefits of Custom TMS Software for Transportation and Logistics</span></h2>
          
          <h5 className="whath4trans mx-20"><span>NEXTLEVEL's custom software solutions are game-changers for companies operating fleets 
            and managing multiple dispatchers and field employees. When your manual data processing efforts or obsolete systems 
            cause more traffic jams than they solve, our team is ready to work with yours to identify key inefficiencies and find 
            opportunities to automate processes to keep your organization on the right track — whether that means <Link className="custom-link2" to="/software-modernisation">updating and replacing legacy systems </Link>
            or starting from scratch.</span></h5>
      </div>
          </Col>

      </Row>



        
        <div className="padding-20px">
           
           


                     <Row>
           
                     <Col md="4" sm="12" xs="12" className="col1">
             <div className="topcard2">
             <img 
                         alt="maintenance"
                         className="customcardimg3"
                         src={require("assets/img/nlicons/transport/gear.svg")}
                         />
              
                         
               <h2 id="titleblacksmall" className="align-left2">
               Fleet
management
and operations 
               </h2>
              <p className="tile-description">
              Keep your fleet in top shape, identifying maintenance opportunities before they become problems.              </p>
             </div>
             
           
           </Col>
           <Col md="4" sm="12" xs="12" className="col1">
             <div className="topcard2">
             <img 
                         alt="maintenance"
                         className="customcardimg3"
                         src={require("assets/img/nlicons/transport/network.svg")}
                         />
                        
               <h2 id="titleblacksmall" className="align-left2">
               Field employees
reporting and
accountability                </h2>
              <p className="tile-description">
              From safe driving practices to condition of goods, custom TMS solutions and GPS tracking systems help you keep your eyes on the road.              </p>
              </div>
              
           
           </Col>
           <Col md="4" sm="12" xs="12" className="col1">
             <div className="topcard2">
             <img 
                         alt="maintenance"
                         className="customcardimg3"
                         src={require("assets/img/nlicons/transport/data-visualization.svg")}
                         />
                       
               <h2 id="titleblacksmall" className="align-left2">
               Warehouse
and shipment
operations                </h2>
              <p className="tile-description">
              Be assured every shipment arrives on time and every truck is carrying a full load.              </p>
             </div>
            
           
           </Col>
       
           
           <Col md="4" sm="12" xs="12" className="col1">
       
                         
             <div className="topcard2">
            
             <img 
                         alt="maintenance"
                         className="customcardimg3"
                         src={require("assets/img/nlicons/transport/dashboard.svg")}
                         />
                        
                         
                        
               <h2 id="titleblacksmall" className="align-left2">
               Comprehensive custom
reports
               </h2>
              <p className="tile-description">
              Improve business visibility with in-depth, data-driven insights in near-real-time.              </p>
             </div>
           
           </Col>
           <Col md="4" sm="12" xs="12" className="col1">
       
                         
       <div className="topcard2">
      
       <img 
                   alt="maintenance"
                   className="customcardimg3"
                   src={require("assets/img/nlicons/transport/networking.svg")}
                   />
                  
                   
                 
         <h2 id="titleblacksmall" className="align-left2">
         Vendors and contractor management          </h2>
        <p className="tile-description">
        From progress and inventory tracking to payment management, streamline your relationships with vendors and contractors.        </p>
       </div>
     
     </Col>
          
           
       </Row>
             </div>



             

        

        
        
             <div
          className="pricing-3 custom-software-div shift-down-50px"
          style={{
            backgroundImage:
              "url(" + require("assets/img/nl/industries/transport/logistics-bg.jpg") + ")",
          }}
        >

        <Row>
          <Col md="6">
         

          </Col>
          <Col md="6">
          <h1 className="whath2qa5"><span>Why Choose NEXTLEVEL</span></h1>
            <ul className="qalist">
              <li><img 
                        alt="website"
                        className="webcardimg mx-4"
                        src={require("assets/img/nlicons/check-circle-white.svg")}
                        /><h5 className="whychoose">Professional Team</h5>NEXTLEVEL provides a full professional development team with a customer-oriented approach.</li>
              <br></br>
              <li><img 
                        alt="website"
                        className="webcardimg mx-4"
                        src={require("assets/img/nlicons/check-circle-white.svg")}
                        /><h5 className="whychoose">Expertise</h5>Our developers’ diverse technology stack experience and our expertise in the transport and logistics sphere mean we can partner with clients to tailor effective solutions to any need. </li>
              <br></br>
              <li><img 
                        alt="website"
                        className="webcardimg mx-4"
                        src={require("assets/img/nlicons/check-circle-white.svg")}
                        /><h5 className="whychoose">Experience</h5>Our expert UI and UX developers and engineers can give your company a “digital makeover,” with visually appealing apps that promote a better patient experience. </li>
              <br></br>
              <li><img 
                        alt="website"
                        className="webcardimg mx-4"
                        src={require("assets/img/nlicons/check-circle-white.svg")}
                        /><h5 className="whychoose">Privacy & Security</h5>We understand the need for utmost data privacy, and we employ best practices to ensure your systems are secure and fully compliant to applicable regulations.  </li>

            </ul>
            
            </Col>
        </Row>
        </div>

    

             


       


       
        <ContactComponent />
      </div>
     
      <FooterNav />
    </>
  );
}

export default Transport;
