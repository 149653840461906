import React from "react";
// reactstrap components
import {
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
} from "reactstrap";

// core components
import CustomHeader from "components/Headers/Custom/CustomHeader.js";
import ColorNavbar from "components/Navbars/ColorNavbar"; 
import ContactComponent from './ContactComponent.js'

import FooterNav from "./FooterNav.js";
import Customfaq from "./CustomScript.js";


function CustomSoftware() {
  
 


  document.documentElement.classList.remove("nav-open");
  // function that is being called on scroll of the page
  const checkScroll = () => {
    // it takes all the elements that have the .add-animation class on them
    const componentPosition = document.getElementsByClassName("add-animation");
    const scrollPosition = window.pageYOffset;
    for (var i = 0; i < componentPosition.length; i++) {
      var rec =
        componentPosition[i].getBoundingClientRect().top + window.scrollY + 100;
      // when the element with the .add-animation is in the scroll view,
      // the .animated class gets added to it, so it creates a nice fade in animation
      if (scrollPosition + window.innerHeight >= rec) {
        componentPosition[i].classList.add("animated");
        // when the element with the .add-animation is not in the scroll view,
        // the .animated class gets removed from it, so it creates a nice fade out animation
      } else if (scrollPosition + window.innerHeight * 0.8 < rec) {
        componentPosition[i].classList.remove("animated");
      }
    }
  };
  React.useEffect(() => {
    document.title = "Custom Software Solutions"
 });

  React.useEffect(() => {
    document.body.classList.add("presentation-page");
    window.addEventListener("scroll", checkScroll);
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("presentation-page");
      window.removeEventListener("scroll", checkScroll);
    };
  });
  return (
    <>
    
      <ColorNavbar />
      <CustomHeader />
      <div className="wrapper">
       
     
     
      <div>
           
      <Row>
      
                    <Col md="7">
                      <div className="topcard">
                        <h2 id="titleblack" className="align-left2">
                        Building a better business with superior software
                        </h2>
                       
                      </div>
                    
                    </Col>
                    <Col md="4">
                      
                  <p className="top-description mx-20">
                  Companies looking for software to solve problems and power growth face a challenging dilemma: 
                  do they invest in a fully custom software solution or go with an off-the-shelf product? 
                  <br></br><br></br>At NEXTLEVEL, we have many years of experience helping businesses achieve their goals through custom software solutions 
                  based on a deep understanding of their unique processes, challenges and needs. 
                  </p>
                 
                    </Col> 
                </Row>

        </div>
         <div
          className="pricing-3 custom-software-div3"
          style={{
            backgroundImage:
              "url(" + require("assets/img/nl/sections/custom/neon.jpg") + ")",
          }}
        >
        
        <div className="my-20 padding-left-20px">
        <Row className="">
        <Col>
        <Row>
        <Col>
        <div className="custom-software-div">
<h2 id="custom-titlewhite-2" className="align-left2">
                        Benifits of Custom Software
                        </h2>
                        <h5 className="custom-whitetext-custom align-left mx-20">
                        There are clearly benefits both for custom software and "off-the-shelf" solutions. By choosing custom software development from NEXTLEVEL, you'll enjoy numerous advantages of a custom system, tailored for your business.

                        </h5>
        </div>
<img 
                        alt="website"
                        className="main-layout-img"
                        src={require("assets/img/nl/sections/custom/car-timelapse.jpg")}
                        />
        </Col>
                </Row>

        </Col>
        <Row>
        <Col>
         <div className="card-big-shadow">
                <Card
                  className="customcard1"
                >
                  <CardBody>
                  <hr className="h-line6"/>

                  <div className="customcardicon">

                  <img 
                        alt="website"
                        className="customcardimg"
                        src={require("assets/img/nlicons/custom/key.svg")}
                        />

                    </div>
                    <br></br>

                      
                    <CardTitle tag="h5">
                      
                        <span>
                        <h5 className="webcardtitle">
                        Full Ownership
                      </h5>
                        </span>
                  
                      
                    </CardTitle>
                    <br></br>
                    <p className="card-description">
                    NEXTLEVEL customers own 100% of their software, so you'll never pay subscription or royalty fees. 
                    Want to add another feature or more users? No problem; you have all the control.
                    <br></br>
                    <br></br>
                    </p>
                  </CardBody>
                </Card>
              </div>
               <div className="card-big-shadow">
               <Card
                  className="customcard1"
                >
                  <CardBody>
                    <hr className="h-line6"/>
                  <div className="customcardicon">
                  <img 
                        alt="website"
                        className="customcardimg"
                        src={require("assets/img/nlicons/custom/logistic.svg")}
                        />

                    </div>
                    <br></br>

                      
                    <CardTitle tag="h5">
                      
                        <span>
                        <h5 className="webcardtitle">
                        Competitive Advantages
                      </h5>
                        </span>
                  
                      
                    </CardTitle>
                    <br></br>
                    <p className="card-description">
                    Unlike "off-the-shelf" software, a custom-built NEXTLEVEL solution can boost your company's performance and be a valuable IP asset, two strong competitive advantages.
                    <br></br>
                    <br></br>
                    <br></br>
                    </p>
                  </CardBody>
                </Card>
              </div>
        </Col>
        <Col className="">
        <div className="card-big-shadow">
                <Card
                  className="customcard1"
                >
                  <CardBody>
                  <hr className="h-line6"/>
                  <div className="customcardicon">
                  <img 
                        alt="website"
                        className="customcardimg"
                        src={require("assets/img/nlicons/custom/device.svg")}
                        />

                    </div>
                    <br></br>

                      
                    <CardTitle tag="h5">
                      
                        <span>
                        <h5 className="webcardtitle">
                        Full Flexibility
                      </h5>
                        </span>
                  
                      
                    </CardTitle>
                    <br></br>
                    <p className="card-description">
                    With a custom-built NEXTLEVEL solution, you can do whatever you want whenever you want with your product. That means your software can grow and change with your business, not the other way around.
                    </p>
                  </CardBody>
                </Card>
              </div>
               <div className="card-big-shadow">
                <Card
                  className="customcard1"
                >
                  <CardBody>
                  <hr className="h-line6"/>
                  <div className="customcardicon">
                  <img 
                        alt="website"
                        className="customcardimg"
                        src={require("assets/img/nlicons/custom/tick.svg")}
                        />

                    </div>
                    <br></br>

                      
                    <CardTitle tag="h5">
                      
                        <span>
                        <h5 className="webcardtitle">
                        Technology Independent
                      </h5>
                        </span>
                  
                      
                    </CardTitle>
                    <br></br>
                    <p className="card-description">
                    NEXTLEVEL is a technology-agnostic company. In other words, we don't sell or promote specific technologies, 
                    so we're 100% focused on our customers. We pick the right stack for your unique business needs and goals.
                    </p>
                  </CardBody>
                </Card>
              </div>
        </Col>
        </Row>

        </Row>
        </div>
        
        </div>  

        <div>
        
      <Row>
      
                    <Col md="7">
                      <div className="topcard">
                        <h2 id="titleblack" className="align-left2">
                        Custom Software Services
                        </h2>
                       
                      </div>
                    
                    </Col>
                    <Col md="4">
                      
                  <p className="top-description mx-20">
                  NEXTLEVEL presents a unique service package to suit your needs! We've planned, 
                  built and implemented custom software solutions for many corporate 
                  clients - from transport & logistics to healthcare and entertainment. 
                  </p>
                 
                    </Col> 
                </Row>

        </div>

        
               
        <div className="custom-industries">

        <div className="smaller-border">
                      <div className="box">
            
            <Row className="mx-20">
              <Col md="3">
                <div className="info">
                  <div className="icon icon-danger">
                  <img 
                        alt="website"
                        className="customcardimg3"
                        src={require("assets/img/nlicons/custom/factory.svg")}
                        />                  
                        </div>
                  <div className="description">
                    <h4 className="info-title-black">Enterprise Applications</h4>
                    <p className="description-black">
                    Our elite team of software engineers creates dynamic, innovative ERP applications. 
                    We build solutions that optimize critical business processes and functions including 
                    inventory, CRM, accounting, HR, and more.
                    </p>
                    
                  </div>
                </div>
              </Col>
              <Col md="3">
              <div className="info">
                  <div className="icon icon-danger">
                  <img 
                        alt="website"
                        className="customcardimg3"
                        src={require("assets/img/nlicons/custom/cloud-network.svg")}
                        />                  </div>
                  <div className="description">
                    <h4 className="info-title-black">SaaS Applications</h4>
                    <p className="description-black">
                    NEXTLEVEL has extensive experience building a wide scope of commercial SaaS-based applications. 
                    We understand the specific challenges and opportunities of this process, and will leverage our 
                    knowledge to build your SaaS solution on-time and on-budget.
                    </p>
                    
                  </div>
                </div>
              </Col>
              <Col md="3">
              <div className="info">
                  <div className="icon icon-danger">
                  <img 
                        alt="website"
                        className="customcardimg3"
                        src={require("assets/img/nlicons/custom/compliant(1).svg")}
                        />                  </div>
                  <div className="description">
                    <h4 className="info-title-black">NHA & POPI compliant</h4>
                    <p className="description-black">
                    NEXTLEVEL specializes in creating advanced NHA and POPI Act-compliant applications with the most innovative 
                    and secure technologies. Our experts handle the software application from the architecture to 
                    delivery, ensuring it meets the strict government guidelines to protect personal data.
                    </p>
                    
                  </div>
                </div>
              </Col>
              <Col md="3">
              <div className="info">
                  <div className="icon icon-danger">
                  <img 
                        alt="website"
                        className="customcardimg3"
                        src={require("assets/img/nlicons/custom/ecommerce.svg")}
                        />                  </div>
                  <div className="description">
                    <h4 className="info-title-black">Ecommerce Applications</h4>
                    <p className="description-black">
                    If your business sells products or services online, a custom ecommerce application is an essential 
                    competitive advantage. NEXTLEVEL builds applications that streamline payment, inventory, reporting, 
                    and security to keep your business thriving.
                    </p>
                    
                  </div>
                </div>
              </Col>
           
              <Col md="3">
                <div className="info">
                  <div className="icon icon-danger">
                  <img 
                        alt="website"
                        className="customcardimg3"
                        src={require("assets/img/nlicons/custom/application.svg")}
                        />                  </div>
                  <div className="description">
                    <h4 className="info-title-black">Mobile Applications</h4>
                    <p className="description-black">
                    NEXTLEVEL's expert engineers build iOS, Android, and Hybrid mobile applications that can stand alone 
                    or be integrated with web applications. We ensure your application passes rigorous acceptance standards 
                    with flying colors and gets published quickly.
                    </p>
                    
                  </div>
                </div>
              </Col>
              <Col md="3">
              <div className="info">
                  <div className="icon icon-danger">
                  <img 
                        alt="website"
                        className="customcardimg3"
                        src={require("assets/img/nlicons/custom/tv.svg")}
                        />                  </div>
                  <div className="description">
                    <h4 className="info-title-black">Legacy Systems</h4>
                    <p className="description-black">
                    Businesses must innovate to stay relevant, and We can help take your older software to the next level. 
                    We rebuild legacy systems to be more dynamic, user-friendly, and efficient, based on cutting-edge technologies.
                    </p>
                    
                  </div>
                </div>
              </Col>
              <Col md="3">
              <div className="info">
                  <div className="icon icon-danger">
                  <img 
                        alt="website"
                        className="customcardimg3"
                        src={require("assets/img/nlicons/retail/integration.svg")}
                        />                  </div>
                  <div className="description">
                    <h4 className="info-title-black">Third-Party Applications</h4>
                    <p className="description-black">
                    In addition to building software from scratch, NEXTLEVEL can support or take over solutions 
                    from third-party vendors. We start by benchmarking the application's quality so you have a 
                    clear picture of what it needs and how to get there.
                    </p>
                    
                  </div>
                </div>
              </Col>
              <Col md="3">
              <div className="info">
                  <div className="icon icon-danger">
                  <img 
                        alt="website"
                        className="customcardimg3"
                        src={require("assets/img/nlicons/custom/new-product.svg")}
                        />                  </div>
                  <div className="description">
                    <h4 className="info-title-black">Product Development</h4>
                    <p className="description-black">
                    From idea to prototype to release, NEXTLEVEL can help your company through the entire life cycle 
                    of new product development. We've built many products at unbeatable values, and our expertise for building innovative solutions is unparalleled.
                    </p>
                    
                  </div>
                </div>
              </Col>
            </Row>
            </div>
            </div>
        </div>
        <br></br>
        <br></br>
        <br></br>
        

        <div>
      <Row>
      
                    <Col md="7">
                      <div className="topcard">
                        <h2 id="titleblack" className="align-left2">
                        NEXTLEVEL Development Process 
                        </h2>
                       
                      </div>
                    
                    </Col>
                    <Col md="4">
                      
                  <p className="top-description mx-20">
                  Software development, like any other manufacturing process stands on logic, clear structure, and 
                  accountability of each component. This is the only way to create quality software. We have all of 
                  those at NEXTLEVEL, we know what we do on each stage of creating a successful application. 
                  </p>
                 
                    </Col> 
                </Row>

        </div>
        <img 
                        alt="Design Process"
                        id="processhorizontal"
                        className="process-img-h"
                        src={require("assets/img/nlscreenshots/app-process-horizontal.png")}
                        />
         <img 
                        alt="Design Process"
                        id="processvertical"
                        className="process-img-v"
                        src={require("assets/img/nlscreenshots/app-process-vertical.png")}
                        />
                  
        

        <Customfaq />

       
        <ContactComponent />
      </div>
      
      <FooterNav />
    </>
    
  );
}

export default CustomSoftware;
