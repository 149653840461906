import React from "react";

// reactstrap components
import { Row, Col } from "reactstrap";

// core components
import ColorNavbar from "components/Navbars/ColorNavbar.js";
 
import FooterNav from "../services/FooterNav.js";
import { Link } from 'react-router-dom';
import DmHeader from "components/Headers/Custom/DmHeader.js";
import ContactComponent from './ContactComponent.js';


function DigitalMarketing() {
  document.documentElement.classList.remove("nav-open");
  // function that is being called on scroll of the page
  const checkScroll = () => {
    // it takes all the elements that have the .add-animation class on them
    const componentPosition = document.getElementsByClassName("add-animation");
    const scrollPosition = window.pageYOffset;
    for (var i = 0; i < componentPosition.length; i++) {
      var rec =
        componentPosition[i].getBoundingClientRect().top + window.scrollY + 100;
      // when the element with the .add-animation is in the scroll view,
      // the .animated class gets added to it, so it creates a nice fade in animation
      if (scrollPosition + window.innerHeight >= rec) {
        componentPosition[i].classList.add("animated");
        // when the element with the .add-animation is not in the scroll view,
        // the .animated class gets removed from it, so it creates a nice fade out animation
      } else if (scrollPosition + window.innerHeight * 0.8 < rec) {
        componentPosition[i].classList.remove("animated");
      }
    }
  };
  React.useEffect(() => {
    document.title = "Digital Marketing"
 });

  React.useEffect(() => {
    document.body.classList.add("presentation-page");
    window.addEventListener("scroll", checkScroll);
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("presentation-page");
      window.removeEventListener("scroll", checkScroll);
    };
  });
  return (
    <>
      <ColorNavbar />
      <DmHeader />
      <div className="wrapper">
       
     
     
      <div className="">
           
      <Row>
      
                    <Col md="6">
                      <div className="topcard">
                        <h2 id="titleblack" className="align-left2">
                        Why do I need digital marketing? So glad you asked!
                        </h2>
                       
                      </div>
                    
                    </Col>
                    <Col md="5">
                      
                  <p className="top-description">
                    <strong>The Scenario:</strong><br></br>
                    <br></br>
                    You have an incredible product.<br></br>
                    You provide the best services in the industry.<br></br>
                    You have a beautiful, intuitive website.<br></br>
                    <br></br>
                    You may believe that all of these factors above would translate directly into leads, sales and ultimately business sucess,
                    but with the crucial factor of web traffic missing from the equation, you will not possess the significant online presence to make your potential customers aware of your brand and online offerings.

                  </p>
                 
                    </Col> 
                    <Col md="12" classname="padding-x-20px">
                      
                  <p className="top-description-center">
                    <strong>This is where we can help!</strong><br></br>
                    </p>
                    <p className="top-description shift-up-30px padding-x-40px ml-20">
                    Through a digital marketing strategy, we can utilise a variety of digital tools and marketing techniques to get you noticed online, attract customers, convert customers & delight them.
                    </p>
                
                    </Col> 
                </Row>

              



                <div className="small-border">
                      <div className="box">
                      <Row className="shift-down-50px">
        <Col md="3" className="ml--20">
          <img 
                         alt="Payments"
                         className="customcardimg"
                         src={require("assets/img/nl/sections/dm/click.svg")}
                         />
          <h3 id="titleblack2">Pay Per Click</h3>
          <br></br>
          <p className="top-description2">
          <ul className="no-bullets">
                    <li>
   <img 
                        alt="dash"
                        className="webcardimg2"
                        src={require("assets/img/nl/sections/dm/minus.svg")}
                        /> 
                      Google AdWords
                    </li>
                    <li>
                    <img 
                        alt="dash"
                        className="webcardimg2"
                        src={require("assets/img/nl/sections/dm/minus.svg")}
                        /> 
Facebook Ads
                    </li>
                    <li>
                    <img 
                        alt="dash"
                        className="webcardimg2"
                        src={require("assets/img/nl/sections/dm/minus.svg")}
                        /> 
Keyword Research
                    </li>
                    <li>
                    <img 
                        alt="dash"
                        className="webcardimg2"
                        src={require("assets/img/nl/sections/dm/minus.svg")}
                        /> 
Campaign Management
                    </li>
                    <li>
                    <img 
                        alt="dash"
                        className="webcardimg2"
                        src={require("assets/img/nl/sections/dm/minus.svg")}
                        /> 
Conversion Tracking
                    </li>
                    </ul>
           </p>
          </Col>
        <Col md="3">
        <img 
                         alt="Inventory Management"
                         className="customcardimg"
                         src={require("assets/img/nl/sections/dm/marketing.svg")}
                         />
          <h3 id="titleblack2">Marketing Techniques</h3>
          <br></br>
          <p className="top-description2">
          <ul className="no-bullets">
                    <li>
   <img 
                        alt="dash"
                        className="webcardimg2"
                        src={require("assets/img/nl/sections/dm/minus.svg")}
                        /> 
Content Marketing
                    </li>
                    <li>
                    <img 
                        alt="dash"
                        className="webcardimg2"
                        src={require("assets/img/nl/sections/dm/minus.svg")}
                        /> 
Inbound Marketing
                    </li>
                    <li>
                    <img 
                        alt="dash"
                        className="webcardimg2"
                        src={require("assets/img/nl/sections/dm/minus.svg")}
                        /> 
SEO
                    </li>
                    <li>
                    <img 
                        alt="dash"
                        className="webcardimg2"
                        src={require("assets/img/nl/sections/dm/minus.svg")}
                        /> 
Affiliate Marketing
                    </li>
                    <li>
                    <img 
                        alt="dash"
                        className="webcardimg2"
                        src={require("assets/img/nl/sections/dm/minus.svg")}
                        /> 
Email Marketing
                    </li>
                    </ul>
          </p>
        </Col>
        <Col md="3">
        <img 
                         alt="Order Management"
                         className="customcardimg"
                         src={require("assets/img/nl/sections/dm/social-media.svg")}
                         />
          <h3 id="titleblack2">Social Media</h3>
          <br></br>
          <p className="top-description2">
          <ul className="no-bullets">
                    <li>
   <img 
                        alt="dash"
                        className="webcardimg2"
                        src={require("assets/img/nl/sections/dm/minus.svg")}
                        /> 
          PPC for Social Media
                    </li>
                    <li>
                    <img 
                        alt="dash"
                        className="webcardimg2"
                        src={require("assets/img/nl/sections/dm/minus.svg")}
                        /> 
Social Posting
                    </li>
                    <li>
                    <img 
                        alt="dash"
                        className="webcardimg2"
                        src={require("assets/img/nl/sections/dm/minus.svg")}
                        /> 
Content Creation
                    </li>
                    <li>
                    <img 
                        alt="dash"
                        className="webcardimg2"
                        src={require("assets/img/nl/sections/dm/minus.svg")}
                        /> 
Influencer Outreach
                    </li>
                    <li>
                    <img 
                        alt="dash"
                        className="webcardimg2"
                        src={require("assets/img/nl/sections/dm/minus.svg")}
                        /> 
Account Management
                    </li>
                    </ul>          </p>
        </Col>
        <Col md="3">
        <img 
                         alt="Fleet Management"
                         className="customcardimg"
                         src={require("assets/img/nl/sections/dm/marketing2.svg")}
                         />
          <h3 id="titleblack2">Additional Marketing</h3>
          <br></br>
          <p className="top-description2">
          <ul className="no-bullets">
                    <li>
   <img 
                        alt="dash"
                        className="webcardimg2"
                        src={require("assets/img/nl/sections/dm/minus.svg")}
                        /> 
          Online Surveys
                    </li>
                    <li>
                    <img 
                        alt="dash"
                        className="webcardimg2"
                        src={require("assets/img/nl/sections/dm/minus.svg")}
                        /> 
Marketing Material
                    </li>
                    <li>
                    <img 
                        alt="dash"
                        className="webcardimg2"
                        src={require("assets/img/nl/sections/dm/minus.svg")}
                        /> 
Brand Awareness
                    </li>
                    <li>
                    <img 
                        alt="dash"
                        className="webcardimg2"
                        src={require("assets/img/nl/sections/dm/minus.svg")}
                        /> 
Customer Outreach
                    </li>
                    <li>
                    <img 
                        alt="dash"
                        className="webcardimg2"
                        src={require("assets/img/nl/sections/dm/minus.svg")}
                        /> 
Customer Research
                    </li>
                    </ul>          </p>
        </Col>
       
       
        </Row>      
                      </div>
                  </div> 



                  <Row className="shift-down-100px">
         
         

          
         <Col md="6" className="">
         <img 
         alt="cape town"
         className="sectiondmimg ml--30 mobile-margins"
         src={require("assets/img/nl/sections/dm/adwords.jpg")}
         />
         </Col>         
        
         

         <Col md="6">
         <div className="pl-20 pr-20">
         
         <h2 id="titleblue-dm"><span>Google AdWords</span></h2>
         <h4 id="subtitle-dm"><span>Pay per click marketing</span></h4>
         
        
         <p className="top-description-space"><span><a className="custom-link5" href="https://ads.google.com/intl/en_za/home/">Google AdWords</a> allows you to be seen by customers at the very moment that they’re searching on Google for the things you offer. You only pay when they click to visit your website or call.
<br></br>
<br></br>
Accurately target the right customers who are looking for exactly the service or product you are offering. Target your ads to customers in certain countries, regions or cities – or within a set distance from your business or shop.
<br></br>
<br></br>
Our NEXTLEVEL Adword campaign managers are <a className="custom-link5" href="https://www.google.com/partners/overview/">Google AdWords Certified</a>. This means we’ve passed the AdWords certification exams and are officially recognised as a Google Partner. Let us develop a Google AdWords campaign to attract visitors to your website.</span></p>
     </div>
         </Col>

         <Col md="6" className="shift-down-100pxmin">
         <div className="ml-20 pr-30">
         
         <h2 id="titleblue-fb"><span>Facebook Marketing</span></h2>
         <h4 id="subtitle-dm"><span>Social Media PPC</span></h4>

         
         <p className="top-description-space"><span>Two billion people use Facebook every month. With that much access to consumers, you are able to target adverts to people based on their interests, demographics and location.
<br></br>
<br></br>

<a className="custom-link5" href="https://www.facebook.com/business/ads">Facebook Ads</a> are a powerful tool to increase awareness to your brand and get clicks to your website. Also you’re able to accurately track conversions with powerful reporting tools.
<br></br>
<br></br>

Facebook also has other paid promotional methods such as sponsored featured posts. Engage with your target audience and get likes, shares & clicks to your website.
</span></p>
     </div>
         </Col>
          
         <Col md="6" className="shift-down-100pxmin">
         <img 
         alt="cape town"
         className="sectiondmimg mobile-margins"
         src={require("assets/img/nl/sections/dm/facebook.jpg")}
         />
         </Col> 


         <Col md="6" className="shift-down-100pxmin">
         <img 
         alt="cape town"
         className="sectiondmimg ml--30 mobile-margins"
         src={require("assets/img/nl/sections/dm/influencer.jpg")}
         />
         </Col>         
        
         

         <Col md="6" className="shift-down-100pxmin">
         <div className="pr-30 pl-20">
         
         <h2 id="titleblue-dm"><span>Social Media Marketing</span></h2>
         <h4 id="subtitle-dm"><span>Influencer Marketing</span></h4>
         
        
         <p className="top-description-space"><span>
         While Facebook is the largest social media platform by users, it does have competition. Instagram, Youtube, Twitter, Pinterest and other social media platforms are highly valuable sources for generating brand awareness and attracting clicks. Many of them also offer paid promotional tools. 
<br></br>
<br></br>
Social influencers are people on social media platforms who have large fan bases. Youtubers with millions of subscribers, Instagrammers with viral  followings or bloggers with large reader bases. Many influencers will allow you to tap into consumer base and leverage their online outreach with paid promotions or even just promote your product or content because its of value.
          </span></p>
     </div>
         </Col>

         <Col md="6" className="shift-down-100pxmin">
         <div className="ml-20 pr-30">
         
         <h2 id="titleblue-fb"><span>
Email Marketing</span></h2>
         <h4 id="subtitle-dm"><span>Email Campaign Management with Mailchimp
</span></h4>

         
         <p className="top-description-space"><span><a className="custom-link5" href="https://en.wikipedia.org/wiki/Email_marketing">Email marketing</a> may still be considered old school, but it’s still a highly effective way of communicating with a customer database.
<br></br>
<br></br>

The best email marketing tool we have found for email campaign management, is without a doubt <a className="custom-link5" href="https://mailchimp.com/">Mailchimp</a>.
<br></br>
<br></br>

Because it’s packed with tons of <a className="custom-link5" href="https://mailchimp.com/marketing-platform/">features</a>, it’s the easiest way to grow an list of subscribers and engage with them on a regular basis.
</span></p>
     </div>
         </Col>
          
         <Col md="6" className="shift-down-100pxmin">
         <img 
         alt="cape town"
         className="sectiondmimg mobile-margins"
         src={require("assets/img/nl/sections/dm/email-comp.jpg")}
         />
         </Col> 


         <Col md="6" className="shift-down-100pxmin">
         <img 
         alt="cape town"
         className="sectiondmimg ml--30 mobile-margins"
         src={require("assets/img/nl/sections/dm/email-team.jpg")}
         />
         </Col>         
        
         

         <Col md="6" className="shift-down-100pxmin">
         <div className="pr-30 pl-20">
         
         <h2 id="titleblue-dm"><span>Digital Strategy</span></h2>
         <h4 id="subtitle-dm"><span>Custom Online Marketing Campaign</span></h4>
         
        
         <p className="top-description-space"><span>
         Depending on your online marketing goals, we can create a personalised digital strategy to grow your brand. 
<br></br>
<br></br>
NEXTLEVEL is leading, Cape Town based, South African digital agency that focuses on getting you results and return on investment for your marketing budget. Our team can employ any number of digital strategies to support your business.
<br></br>
<br></br>
Pick and choose from <Link className="custom-link5" to="/seo/info">SEO</Link>, <a className="custom-link5" href="https://www.hubspot.com/inbound-marketing">Inbound Marketing</a>, PPC, Social Media or plain old email marketing and go viral.
          </span></p>
     </div>
         </Col>


     </Row>


</div>



     

        <ContactComponent />
      </div>
      <div className="separator" />
      <FooterNav />
    </>
  );
}

export default DigitalMarketing;
