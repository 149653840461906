import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col,
} from "reactstrap";
import {Link} from 'react-router-dom';
// core components
import ColorNavbar from "components/Navbars/ColorNavbar"; 
import ContactComponent from '../services/ContactComponent.js';

import WebHeaderTemplate from "./webHeaders/WebHeaderTemplate.js";
import FooterNav from "../services/FooterNav.js";
import Product from "../Product";

function WebGraphicDesign() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
    };
  });
  return (
    <>
     <ColorNavbar />
      <WebHeaderTemplate navTitle="Graphic Design Services" Title="Graphic Design" />

      <div className="wrapper">
        {/* ********* PRICING 3 ********* */}
        <div
          className="pricing-3 section-bg-img"
          style={{
            backgroundImage:
            "url(" + require("assets/img/nl/sections/custom/red-blue.jpg") + ")",
          }}
        >
          

          <Container>
          <Row>
              <Col lg="12" md="12" sm="12" xs="12">
                <h1 id="titlewhite3" className="mt--30">
                        Graphic Creation
                        </h1>
                </Col>
              </Row>
          <Row>
          <Col xl="6" lg="6" md="12" sm="12" xs="12" className="col1">
              <Card className="card-pricing w-150">
              <h6 className="card-category">Budget</h6>
              
                <CardBody>
                <div className="webcardicon my-5">
                      <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/web/graphic/vector-design1.svg")}
                        />
                        <span>
                        <h5 className="webcardtitle">
                        Greyscale Graphic 
                      </h5>
                        </span>
                  
                      </div>
                  <CardTitle tag="h2">R1299</CardTitle>
                  <ul className="align-left">
                  
                    <li>
                    <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      Digitally Hand Drawn
                    </li>
                    
                    <li>
                    <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      Black & White - Including Shadow
                    </li>
                    
                    <br></br>

                  </ul>
                  
                  <br></br>
                  <br></br>
                  <Product 
                  id="GreyscaleGraphic"
                  title="Greyscale Graphic"
                  price={1299.0}
                  rating={5}
                  image={require("assets/img/nlicons/web/graphic/vector-design1.svg")}
                  />  
                 <Link to="/contact">
                  <Button
                    className="btn-round card-link web-card-btn"
                    color="success"
                    href="" 
                  >
                    Get Started
                  </Button>
                  </Link>
                </CardBody>
              </Card>
            </Col>
            <Col xl="6" lg="6" md="12" sm="12" xs="12" className="col1">
              <Card className="card-pricing w-150">
              <h6 className="card-category">Standard</h6>
              
                <CardBody>
                <div className="webcardicon my-5">
                      <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/web/graphic/vector-design.svg")}
                        />
                        <span>
                        <h5 className="webcardtitle">
                        3 Colour Graphic
                      </h5>
                        </span>
                  
                      </div>
                      <CardTitle tag="h2">R1499</CardTitle>
                 <ul className="align-left">
                  
                    <li>
                    <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      Digitally Hand Drawn
                    </li>
                    
                    <li>
                    <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      3 Colours - Including Black & White and Shadow
                    </li>
                    
                    <br></br>

                  </ul>
                  
                  <br></br>
                  <br></br>
                  <Product 
                  id="3ColourGraphic"
                  title="3 Colour Graphic"
                  price={1499.0}
                  rating={5}
                  image={require("assets/img/nlicons/web/graphic/vector-design.svg")}
                  />  
                 <Link to="/contact">
                  <Button
                    className="btn-round card-link web-card-btn"
                    color="success"
                    href="" 
                  >
                    Get Started
                  </Button>
                  </Link>
                </CardBody>
              </Card>
            </Col>
            </Row>
            <Row>
            <Col xl="6" lg="6" md="12" sm="12" xs="12" className="col1">
              <Card className="card-pricing w-150">
              <h6 className="card-category">Premium</h6>
                <CardBody>
                <div className="webcardicon my-5">
                      <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/web/graphic/computer.svg")}
                        />
                        <span>
                        <h5 className="webcardtitle">
                        5 Colour Graphic
                      </h5>
                        </span>
                  
                      </div>
                      <CardTitle tag="h2">R2699</CardTitle>
                  <ul className="align-left">
                  
                    <li>
                    <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      Digitally Hand Drawn
                    </li>
                    
                    <li>
                    <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      5 Colours - Including Black & White and Shadow
                    </li>
                    
                    <br></br>

                  </ul>
                  
                  <br></br>
                  <br></br>
                  <Product 
                  id="5ColourGraphic"
                  title="5 Colour Graphic"
                  price={2699.0}
                  rating={5}
                  image={require("assets/img/nlicons/web/graphic/computer.svg")}
                  />  
                 <Link to="/contact">
                  <Button
                    className="btn-round card-link web-card-btn"
                    color="success"
                    href="" 
                  >
                    Get Started
                  </Button>
                  </Link>
                </CardBody>
              </Card>
            </Col>
           
            <Col xl="6" lg="6" md="12" sm="12" xs="12" className="col1">
              <Card className="card-pricing w-150">
              <h6 className="card-category">Premium</h6>
                <CardBody>
                <div className="webcardicon my-5">
                      <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/web/graphic/graphic-designer.svg")}
                        />
                        <span>
                        <h5 className="webcardtitle">
                        Full Colour Graphic
                      </h5>
                        </span>
                  
                      </div>
                      <CardTitle tag="h2">R3499</CardTitle>
                    <ul className="align-left">
                  
                    <li>
                    <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      Digitally Hand Drawn
                    </li>
                    
                    <li>
                    <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      Full Colour - Including Shadow
                    </li>
                    
                    <br></br>

                  </ul>
                  
                  <br></br>
                  <br></br>
                  <Product 
                  id="FullColourGraphic"
                  title="Full Colour Graphic"
                  price={3499.0}
                  rating={5}
                  image={require("assets/img/nlicons/web/graphic/graphic-designer.svg")}
                  />  
                 <Link to="/contact">
                  <Button
                    className="btn-round card-link web-card-btn"
                    color="success"
                    href="" 
                  >
                    Get Started
                  </Button>
                  </Link>
                </CardBody>
              </Card>
            </Col>
            
          </Row>
          </Container>


           <Container>
          <Row>
              <Col lg="12" md="12" sm="12" xs="12">
                <h1 id="titlewhite2">
                        Infographics
                        </h1>
                </Col>
              </Row>
          <Row>
          <Col xl="6" lg="6" md="12" sm="12" xs="12" className="col1">
              <Card className="card-pricing w-150">
              <h6 className="card-category">Standard</h6>
              
                <CardBody>
                <div className="webcardicon my-5">
                      <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/web/graphic/web-design1.svg")}
                        />
                        <span>
                        <h5 className="webcardtitle">
                        Greyscale Infographic
                      </h5>
                        </span>
                  
                      </div>
                  <CardTitle tag="h2">R1299</CardTitle>
                  <ul className="align-left">
                  
                    <li>
                    <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      1-10 Website Infographics 
                    </li>
                    
                    <li>
                    <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      Digitally hand drawn
                    </li>
                   <li>
                    <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      Black & White
                    </li>
                    
                    <br></br>

                  </ul>
                  
                  <br></br>
                  <br></br>
                  <Product 
                  id="GreyscaleInfographic"
                  title="Greyscale Infographic"
                  price={1299.0}
                  rating={5}
                  image={require("assets/img/nlicons/web/graphic/web-design1.svg")}
                  />  
                 <Link to="/contact">
                  <Button
                    className="btn-round card-link web-card-btn"
                    color="success"
                    href="" 
                  >
                    Get Started
                  </Button>
                  </Link>
                </CardBody>
              </Card>
            </Col>
            <Col xl="6" lg="6" md="12" sm="12" xs="12" className="col1">
              <Card className="card-pricing w-150">
              <h6 className="card-category">Premium</h6>
              
                <CardBody>
                <div className="webcardicon my-5">
                      <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/web/graphic/web-design.svg")}
                        />
                        <span>
                        <h5 className="webcardtitle">
                        Colour Infographic
                      </h5>
                        </span>
                  
                      </div>
                      <CardTitle tag="h2">R1499</CardTitle>
                 <ul className="align-left">
                  
                    <li>
                    <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      1-10 Website Infographics 
                    </li>
                    
                    <li>
                    <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      Digitally hand drawn
                    </li>
                   <li>
                    <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      3 Colours
                    </li>
                    
                    <br></br>

                  </ul>
                  
                  <br></br>
                  <br></br>
                  <Product 
                  id="ColourInfographic"
                  title="Colour Infographic"
                  price={1499.0}
                  rating={5}
                  image={require("assets/img/nlicons/web/graphic/web-design.svg")}
                  />  
                 <Link to="/contact">
                  <Button
                    className="btn-round card-link web-card-btn"
                    color="success"
                    href="" 
                  >
                    Get Started
                  </Button>
                  </Link>
                </CardBody>
              </Card>
            </Col>
            </Row>
          
          </Container>

        </div>
        <ContactComponent />
      </div>
      <FooterNav />
    </>
  );
}

export default WebGraphicDesign;
