import React from "react";
// reactstrap components
import {
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
} from "reactstrap";

// core components
import EducationHeader from "components/Headers/Custom/EducationHeader.js";
import ColorNavbar from "components/Navbars/ColorNavbar"; 
import ContactComponent from '../services/ContactComponent.js';

import FooterNav from "../services/FooterNav.js";


function Education() {
  
 


  document.documentElement.classList.remove("nav-open");
  // function that is being called on scroll of the page
  const checkScroll = () => {
    // it takes all the elements that have the .add-animation class on them
    const componentPosition = document.getElementsByClassName("add-animation");
    const scrollPosition = window.pageYOffset;
    for (var i = 0; i < componentPosition.length; i++) {
      var rec =
        componentPosition[i].getBoundingClientRect().top + window.scrollY + 100;
      // when the element with the .add-animation is in the scroll view,
      // the .animated class gets added to it, so it creates a nice fade in animation
      if (scrollPosition + window.innerHeight >= rec) {
        componentPosition[i].classList.add("animated");
        // when the element with the .add-animation is not in the scroll view,
        // the .animated class gets removed from it, so it creates a nice fade out animation
      } else if (scrollPosition + window.innerHeight * 0.8 < rec) {
        componentPosition[i].classList.remove("animated");
      }
    }
  };
  

  React.useEffect(() => {
    document.body.classList.add("presentation-page");
    window.addEventListener("scroll", checkScroll);
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("presentation-page");
      window.removeEventListener("scroll", checkScroll);
    };
  });
  return (
    <>
    
      <ColorNavbar />
      <EducationHeader />
      <div className="wrapper">
       
     
     
      <div>
           
      <Row>
      
                    <Col md="7">
                      <div className="topcard">
                        <h2 id="titleblack" className="align-left2">
                        Why choose NEXTLEVEL? So glad you asked!
                        </h2>
                       
                      </div>
                    
                    </Col>
                    <Col md="4">
                      
                  <p className="top-description">
                  As educational institutions across the country embrace technology to improve student outcomes, traditional 
                  learning environments are becoming a thing of the past. At NEXTLEVEL, we partner with educators to develop 
                  the software solutions they need to help their students succeed.
                  </p>
                 
                    </Col> 
                </Row>

        </div>
         <div
          className="pricing-3 custom-software-div3"
          style={{
            backgroundImage:
              "url(" + require("assets/img/nl/industries/edu/edu-bg.jpg") + ")",
          }}
        >
        
        <div className="my-20 padding-left-20px">
        <Row>
        <Col>
        <Row>
        <Col>
        <div className="custom-software-div">
<h2 id="custom-titleblack" className="align-left2">
Your students’ success is our success
                        </h2>
                        
        </div>
<img 
                        alt="website"
                        className="main-layout-img"
                        src={require("assets/img/nl/industries/edu/students.jpg")}
                        />
        </Col>
                </Row>

        </Col>
        <Row>
        <Col className="padding-education">
         <div className="card-big-shadow">
                <Card
                  className="customcard1"
                >
                  <CardBody>
                  <div className="customcardicon">
                  <img 
                        alt="Remote Learning"
                        className="customcardimg"
                        src={require("assets/img/nlicons/education/electronics.svg")}
                        />

                    </div>
                    <br></br>

                      
                    <CardTitle tag="h5">
                      
                        <span>
                        <h5 className="webcardtitle">
                        Take Learning Off Campus
                      </h5>
                        </span>
                  
                      
                    </CardTitle>
                    <br></br>
                    <p className="card-description">
                    Education shouldn’t be limited to the classroom, so our applications allow students to access 
                    educational resources wherever they are, on any device.
                    <br></br>
                    </p>
                  </CardBody>
                </Card>
              </div>
               <div className="card-big-shadow">
               <Card
                  className="customcard1"
                >
                  <CardBody>
                  <div className="customcardicon">
                  <img 
                        alt="Streamlined Logistics"
                        className="customcardimg"
                        src={require("assets/img/nlicons/education/project-launch.svg")}
                        />

                    </div>
                    <br></br>

                      
                    <CardTitle tag="h5">
                      
                        <span>
                        <h5 className="webcardtitle">
                        Streamline Logistics
                      </h5>
                        </span>
                  
                      
                    </CardTitle>
                    <br></br>
                    <p className="card-description">
                    NEXTLEVEL works with educators to build learning management systems that take the headaches out of lesson planning and coordination.
                    <br></br>
                    <br></br>
                    </p>
                  </CardBody>
                </Card>
              </div>
        </Col>
        <Col className="padding-education">
        <div className="card-big-shadow">
                <Card
                  className="customcard1"
                >
                  <CardBody>
                  <div className="customcardicon">
                  <img 
                        alt="Data Security"
                        className="customcardimg"
                        src={require("assets/img/nlicons/education/data-security.svg")}
                        />

                    </div>
                    <br></br>

                      
                    <CardTitle tag="h5">
                      
                        <span>
                        <h5 className="webcardtitle">
                        Keep Your Data Safe
                      </h5>
                        </span>
                  
                      
                    </CardTitle>
                    <br></br>
                    <p className="card-description">
                    From information privacy to cybersecurity, NEXTLEVEL's custom software solutions are designed to protect your organization’s systems and the people who use them.                    
                    <br></br>
                    <br></br>
                    </p>
                  </CardBody>
                </Card>
              </div>
               <div className="card-big-shadow">
                <Card
                  className="customcard1"
                >
                  <CardBody>
                  <div className="customcardicon">
                  <img 
                        alt="Personalise Learning"
                        className="customcardimg"
                        src={require("assets/img/nlicons/education/report.svg")}
                        />

                    </div>
                    <br></br>

                      
                    <CardTitle tag="h5">
                      
                        <span>
                        <h5 className="webcardtitle">
                        Personalise Learning
                      </h5>
                        </span>
                  
                      
                    </CardTitle>
                    <br></br>
                    <p className="card-description">
                    When it comes to academic success, every student’s needs are unique. Our e-Learning applications empower students to learn at their own pace, in their own style.
                    </p>
                  </CardBody>
                </Card>
              </div>
        </Col>
        </Row>

        </Row>
        </div>
        
        </div>  

      

        <Row className="shiftup">
  <Col>
  <div className="custom-software-div">
<h2 className="align-left2" id="custom-titleblack">
Specific Applications
                        </h2>
                        <h5 className="custom-blacktext">
                        Bring your classroom into the digital age with a NEXTLEVEL custom software application.
                        </h5>
        </div>
  </Col>
  <Col>
  <img 
                        alt="Online Learning"
                        className="main-layout-img"
                        src={require("assets/img/nl/industries/edu/online-compress.jpg")}
                        />
  </Col>
</Row>







      
                    <div className="small-border">
                      <div className="box">
                      <Row className="shift-down-80px">
        <Col md="6">
          <img 
                         alt="maintenance"
                         className="customcardimg"
                         src={require("assets/img/nlicons/education/online-lesson.svg")}
                         />
          <h3 id="titleblack2">E-Learning</h3>
          <br></br>
          <p className="top-description2">
          From self-guided learning modules to secure, remote access to classroom resources, our e-learning solutions empower students to learn where they want, when they want, and how they want.
          </p>
          </Col>
        <Col md="6">
        <img 
                         alt="maintenance"
                         className="customcardimg"
                         src={require("assets/img/nlicons/education/customer-service.svg")}
                         />
          <h3 id="titleblack2">Learning Management System</h3>
          <br></br>
          <p className="top-description2">
          NEXTLEVEL's custom applications make it easy for faculty to stay organized and collaborate with students anytime, anywhere.
          </p>
        </Col>
       
        </Row>
                       
                      </div>
                    
                    
                  </div> 

                  <div
          className="pricing-3 custom-software-div shift-down-50px"
          style={{
            backgroundImage:
              "url(" + require("assets/img/nlbg/bg3.jpg") + ")",
          }}
        >

        <Row>
        <Col md="6" sm="12" xs="12">      

          </Col>
          <Col md="6" sm="12" xs="12">      
          <h1 className="whath2qa5"><span>Why Choose NEXTLEVEL</span></h1>

            <ul className="qalist">
              <li><img 
                        alt="website"
                        className="webcardimg mx-4"
                        src={require("assets/img/nlicons/check-circle-white.svg")}
                        /><h5 className="whychoose">Professional Team</h5>NEXTLEVEL provides a full professional development team with a customer-oriented approach.</li>
              <br></br>
              <li><img 
                        alt="website"
                        className="webcardimg mx-4"
                        src={require("assets/img/nlicons/check-circle-white.svg")}
                        /><h5 className="whychoose">Expertise</h5>Our diverse technology stack experience and expertise in education sphere mean we can partner with clients to tailor effective solutions to any need.  </li>
              <br></br>
              <li><img 
                        alt="website"
                        className="webcardimg mx-4"
                        src={require("assets/img/nlicons/check-circle-white.svg")}
                        /><h5 className="whychoose">Experience</h5>Our UI and UX experts can give educational institutions a “digital makeover,” making campuses as smart as their students.  </li>
              <br></br>
              <li><img 
                        alt="website"
                        className="webcardimg mx-4"
                        src={require("assets/img/nlicons/check-circle-white.svg")}
                        /><h5 className="whychoose">Privacy & Security</h5>We understand the need for flexible, personalized learning in a secure digital environment, and our custom software applications are designed to keep your students and their information safe.  </li>

            </ul>
            
            </Col>
        </Row>
        </div>
               
      

       
        <ContactComponent />
      </div>
     
      <FooterNav />
    </>
    
  );
}

export default Education;
