/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container } from "reactstrap";
import Typist from "react-typist";
import styled, { createGlobalStyle } from "styled-components";
import { Link } from 'react-router-dom'


// core comments
const StyledTypist = styled(Typist)`
  color: white;
  font-size: 2.5em;
  padding-top: 50px;
  text-align: left;
`;

const WeAre = styled.span`
color: rgb(249, 181, 23);
font-weight: bold;
`;

const Newspan = styled.span`
  font-weight: bold;
`;

const H2mb = styled.h1`
  margin-bottom: 30px;
  color: turquoise;
  font-weight: bold;
  font-size: 2em;
`;

const H2mb2 = styled.h1`
  margin-bottom: 30px;
  color: mediumorchid;
  font-weight: bold;
  font-size: 2em;

`;


const Newspan2 = styled.span`
vertical-align: text-top;
font-size: 3vw !important;
`;

function ContactHeader() {
  return (
    <>
      <div className="wrapper" id="header">
        <div
          className="page-header page-header-contact section-dark"
          style={{
            backgroundImage:
              "url(" + require("assets/img/nl/waterfront-slim.jpg") + ")",
          }}
        >
            <div className="top-nav-small">
            <p className="top-nav-text">
                    <Link className="custom-link-custom" to="/">Home</Link> <span>&nbsp; { '>' } &nbsp; Contact Us</span> 
                    </p>
            </div>
          <div className="content-center">
         
            <Container id="headercontainer">
                <h1 className="presentation-title-smaller-left3 pb-10">Contact Us</h1>
                
                
              <div className="title-brand">

             
              </div>
              <div>
              <StyledTypist loop={true}>
        <Newspan>Don't hesitate to ask us any questions</Newspan>
        <Typist.Backspace count={38} delay={800} />

        <Newspan>We are here to help</Newspan>
        <Typist.Backspace count={19} delay={800} />

        <Newspan>Get started now!</Newspan>
        <Typist.Backspace count={18} delay={800} />

        

        

        <Newspan>
        Whatever it may be, we are glad to hear from you! 
          </Newspan>
      </StyledTypist>
              </div>
            </Container>
          </div>
        </div>
        <div className="wrapper">
       
           
        </div>
       
      </div>
    </>
  );
}

export default ContactHeader;
