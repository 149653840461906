/*!

=========================================================
* Paper Kit PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-pro-react
* Copyright 2020 Creative Tim (http://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React, {useState, useEffect} from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";
import ReactGA from 'react-ga';
import { createBrowserHistory } from 'history';
// styles
import "assets/css/bootstrap.min.css";
import "assets/scss/paper-kit.scss";
import "assets/demo/demo.css";
import "assets/demo/buttons.css";
import "assets/demo/divider.css";
import "assets/demo/react-demo.css";
// pages

import Sections from "views/Sections.js";
import Basket from "views/Checkout.js";
import Payments from "views/Payment.js";

// others
import Agriculture from "views/industries/Agriculture.js";
import Healthcare from "views/industries/Healthcare.js";
import Retail from "views/industries/Retail.js";
import Education from "views/industries/Education.js";
import Finance from "views/industries/Finance.js";
import Transport from "views/industries/Transport.js";
import AppDevelopment from "views/services/AppDevelopment";
import BusinessAnalysis from "views/services/BusinessAnalysis";
import CustomSoftware from "views/services/CustomSoftware";
import DigitalMarketing from "views/services/DigitalMarketing";
import LegacySoftware from "views/services/LegacySoftware";
import LeveragingResources from "views/services/LeveragingResources";
import WebDevelopment from "views/services/WebDevelopment";
import QualityAssurance from "views/services/QualityAssurance";
import Ecommerce from "views/industries/Ecommerce.js";
import SMEs from "views/industries/SME.js";
import Entertainment from "views/industries/Entertainment.js";
import Mobile from "views/services/Mobile";
import Maintenance from "views/services/Maintenance.js";
import SeoInfo from "views/services/Seo.js";


import WebBasic from "views/web-services/WebBasic";
import WebAdWords from "views/web-services/WebAdWords";
import WebBusiness from "views/web-services/WebBusiness";
import WebContent from "views/web-services/WebContent";
import WebCorporate from "views/web-services/WebCorporate";
import WebCustom from "views/web-services/WebCustom";
import WebDevTraining from "views/web-services/WebDevTraining";
import WebEcommerce from "views/web-services/WebEcommerce";
import WebGraphicDesign from "views/web-services/WebGraphic";
import WebHosting from "views/web-services/WebHosting";
import WebMaintenance from "views/web-services/WebMaintenance";
import WebMaintenanceTraining from "views/web-services/WebMaintenancTraining";
import WebSecurity from "views/web-services/WebSecurity";
import WebSEO from "views/web-services/WebSEO";
import WebSocialMedia from "views/web-services/WebSocialMedia";
import WebSoftwareTraining from "views/web-services/WebSoftwareTraining";

import SuccessPage from "views/Success.js";
import ContactPage from 'views/services/ContactUs.js'
import AboutUs from 'views/services/AboutUs.js'
import Loader from './loader.js'
import usePageTracking from './Analytics';

import reducer, { initialState } from "./reducer";
import { StateProvider } from "./StateProvider";
import { auth } from "./firebase/firebaseConfig";
import { useStateValue } from "./StateProvider";

//import { getAnalytics } from "firebase/analytics";

  // Initialize google analytics page view tracking

//function initialize() {
  // [START analytics_initialize]
  //const analytics = firebase.analytics();
  // [END analytics_initialize]
//}
//export default initialize(); 

function Chat() {const [{}, dispatch] = useStateValue();

useEffect(() => {
  // will only run once when the app component loads...

  auth.onAuthStateChanged((authUser) => {
    console.log("THE USER IS >>> ", authUser);

    if (authUser) {
      // the user just logged in / the user was logged in

      dispatch({
        type: "SET_USER",
        user: authUser,
      });
    } else {
      // the user is logged out
      dispatch({
        type: "SET_USER",
        user: null,
      });
    }
  });
}, []);

  const [loaded, setLoaded] = useState(false)


  React.useEffect(() => {
    const scriptTag = document.createElement('script');
    scriptTag.src = "//code.tidio.co/u2az3dqswfokeu8ttapxr3maldf0z2jb.js";
    
    scriptTag.addEventListener('load', ()=>setLoaded(true))

    document.body.appendChild(scriptTag);
    
    
  }, []);
  
  React.useEffect(() => {
    if (!loaded) return;
  
  }, [loaded]);
  return (
    <div>
                  {loaded ? '' : ''}

    </div>
  )
}
export default Chat



ReactDOM.render(
  <React.StrictMode>
    <StateProvider initialState={initialState} reducer={reducer}>
  <BrowserRouter>
    <Loader />

  <Chat />
  <usePageTracking />

    <Switch>

   
    <Route path="/contact" 
    render={(props) => <ContactPage {...props} 
    title="Contact Us"/>}  
    />
     <Route path="/success" 
    render={(props) => <SuccessPage {...props} 
    title="Successful Payment"/>}  
    />
    <Route
        path="/shopping-basket"
        render={(props) => <Basket {...props} />}
      />
     <Route
        path="/payments"
        render={(props) => <Payments {...props} />}
      />
       <Route
        path="/about-us"
        render={(props) => <AboutUs {...props} />}
      />
      <Route
        path="/AdWords"
        render={(props) => <WebAdWords {...props} />}
      />
      <Route
        path="/basic-website-packages"
        render={(props) => <WebBasic {...props} />}
      />
      <Route
        path="/business-website-packages"
        render={(props) => <WebBusiness {...props} />}
      />
      <Route
        path="/custom-website-packages"
        render={(props) => <WebCustom {...props} />}
      />
      <Route
        path="/content-creation"
        render={(props) => <WebContent {...props} />}
      />
      <Route
        path="/corporate-identity"
        render={(props) => <WebCorporate {...props} />}
      />
      <Route
        path="/development-training"
        render={(props) => <WebDevTraining {...props} />}
      />
      <Route
        path="/ecommerce-packages"
        render={(props) => <WebEcommerce {...props} />}
      />
      <Route
        path="/graphic-design"
        render={(props) => <WebGraphicDesign {...props} />}
      />
      <Route
        path="/web-hosting"
        render={(props) => <WebHosting {...props} />}
      />
      <Route
        path="/website-maintenance"
        render={(props) => <WebMaintenance {...props} />}
      />
      <Route
        path="/website-maintenance-training"
        render={(props) => <WebMaintenanceTraining {...props} />}
      />
      <Route
        path="/web-security"
        render={(props) => <WebSecurity {...props} />}
      />
      <Route
        path="/seo"
        render={(props) => <WebSEO {...props} />}
      />
      <Route
        path="/social-media"
        render={(props) => <WebSocialMedia {...props} />}
      />
      <Route
        path="/software-training"
        render={(props) => <WebSoftwareTraining {...props} />}
      />
      <Route
        path="/product-development"
        render={(props) => <AppDevelopment {...props} />}
      />
      <Route
        path="/business-analysis"
        render={(props) => <BusinessAnalysis {...props} />}
      />
      <Route
        path="/custom-software"
        render={(props) => <CustomSoftware {...props} />}
      />
      <Route
        path="/digital-marketing"
        render={(props) => <DigitalMarketing {...props} />}
      />
      <Route
        path="/software-modernisation"
        render={(props) => <LegacySoftware {...props} />}
      />
      <Route
        path="/leveraging-resources"
        render={(props) => <LeveragingResources {...props} />}
      />
      <Route
        path="/quality-assurance"
        render={(props) => <QualityAssurance {...props} />}
      />
      <Route
        path="/web"
        render={(props) => <WebDevelopment {...props} />}
      />
      <Route
        path="/agriculture"
        render={(props) => <Agriculture {...props} />}
      />
      <Route
        path="/healthcare"
        render={(props) => <Healthcare {...props} />}
      />
      <Route
        path="/wholesale&retail"
        render={(props) => <Retail {...props} />}
      />
      <Route
        path="/ecommerce"
        render={(props) => <Ecommerce {...props} />}
      />
       <Route
        path="/smes"
        render={(props) => <SMEs {...props} />}
      />
       <Route
        path="/seo-info"
        render={(props) => <SeoInfo {...props} />}
      />
      <Route
        path="/media&entertainment"
        render={(props) => <Entertainment {...props} />}
      />
      <Route
        path="/education"
        render={(props) => <Education {...props} />}
      />
      <Route
        path="/finance&accounting"
        render={(props) => <Finance {...props} />}
      />
      <Route
        path="/transport&logistics"
        render={(props) => <Transport {...props} />}
      />
      <Route
        path="/mobile-software"
        render={(props) => <Mobile {...props} />}
      />
      <Route
        path="/software-maintenance"
        render={(props) => <Maintenance {...props} />}
      />
     <Route path="/" component={Sections} />
     <Redirect to="/" />
    </Switch>
  </BrowserRouter>
  </StateProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
