import React from "react";
import "./Checkout.css";
import Subtotal from "./Subtotal";
import { useStateValue } from "../StateProvider";
import CheckoutProduct from "./CheckoutProduct";
import ColorNavbar from "components/Navbars/ColorNavbar";
import {Row, Col} from 'reactstrap';
import FooterNav from "views/services/FooterNav.js";

function Basket() {
  
  const [{ basket, user }, dispatch] = useStateValue();

  
  

  document.documentElement.classList.remove("nav-open");
  // function that is being called on scroll of the page
  const checkScroll = () => {
    // it takes all the elements that have the .add-animation class on them
    const componentPosition = document.getElementsByClassName("add-animation");
    const scrollPosition = window.pageYOffset;
    for (var i = 0; i < componentPosition.length; i++) {
      var rec =
        componentPosition[i].getBoundingClientRect().top + window.scrollY + 100;
      // when the element with the .add-animation is in the scroll view,
      // the .animated class gets added to it, so it creates a nice fade in animation
      if (scrollPosition + window.innerHeight >= rec) {
        componentPosition[i].classList.add("animated");
        // when the element with the .add-animation is not in the scroll view,
        // the .animated class gets removed from it, so it creates a nice fade out animation
      } else if (scrollPosition + window.innerHeight * 0.8 < rec) {
        componentPosition[i].classList.remove("animated");
      }
    }
  };
  React.useEffect(() => {
    document.body.classList.add("presentation-page");
    window.addEventListener("scroll", checkScroll);
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("presentation-page");
      window.removeEventListener("scroll", checkScroll);
    };
  });

  return (
    <>
    <div className="checkout">
    <ColorNavbar/>

      <div className="checkout__div">
        
        <Row>
        <Col md="8">
        <div className="white__bg">
          <br></br>
          <h3 className="ck__greeting">Hello,  {!user ? 'Guest' : user.email}</h3>
          {(() => {
        switch (basket.length) {
          case 0:   return <h3 className="checkout__title2">Your Shopping Basket is currently empty</h3>;
          
          default:      return<h2 className="checkout__title">Your Shopping Basket</h2>;
        }
      })()}

          {basket.map(item => (
            <CheckoutProduct
              id={item.id}
              title={item.title}
              image={item.image}
              price={item.price}
              rating={item.rating}
            />
          ))}

        </div>
        </Col>
        <Col className="margin__subtotal" md="4">
        <div className="checkout__right">
        <Subtotal />
      </div>
        </Col>
        </Row>
        
      </div>

      
    </div>
    <FooterNav />
    </>
  );
}

export default Basket;
