import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col,
} from "reactstrap";
import {Link} from 'react-router-dom';
// core components
import ColorNavbar from "components/Navbars/ColorNavbar"; 
import ContactComponent from '../services/ContactComponent.js';

import WebHeaderTemplate from "./webHeaders/WebHeaderTemplate.js";
import FooterNav from "../services/FooterNav.js";

function WebCustom() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
    };
  });
  return (
    <>
      <ColorNavbar />
      <WebHeaderTemplate navTitle="Custom Website Packages" Title="Custom Websites" />
      <div className="wrapper">
        {/* ********* PRICING 3 ********* */}
        <div
          className="pricing-3 section-bg-img"
          style={{
            backgroundImage:
            "url(" + require("assets/img/nl/sections/custom/red-blue.jpg") + ")",
          }}
        >
           <Container>
          <Row>
            <Col lg="4" md="4" sm="4" xs="12" className="col1">
              <Card className="card-pricing mw-250">
              <h6 className="card-category">Budget</h6>
              
                <CardBody>
                <div className="webcardicon my-5">
                      <img 
                        alt="website"
                        className="webcardimg mx-1"
                        src={require("assets/img/nlicons/web/widget3.svg")}
                        />
                        <h6 className="webcardtitle">
                        Custom Starter Web Application
                      </h6>
                  
                      </div>
                  <CardTitle tag="h3">R14999</CardTitle>
                  <ul className="align-left">
                    <li>
   <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      Responsive (Mobile Friendly)
                    </li>
                    <li>
   <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      Content Management System
                    </li>
                    <li>
   <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      No-CMS Option Available
                    </li>
                    <li>
   <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      Core Business Functionality
                    </li>
                    <li>
   <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      Maximum 20 Pages
                    </li>
                    
                    <br></br>

                  </ul>
                  <br></br>
                  <br></br>
                  
                  
                 <Link to="/contact">
                  <Button
                    className="btn-round card-link web-card-btn"
                    color="success"
                    href="" 
                  >
                    Get Started
                  </Button>
                  </Link>
                </CardBody>
              </Card>
            </Col>
            <Col lg="4" md="4" sm="4" xs="12" className="col1">
              <Card className="card-pricing mw-250">
              <h6 className="card-category">Standard</h6>
              
                <CardBody>
                <div className="webcardicon my-5">
                      <img 
                        alt="website"
                        className="webcardimg mx-1"
                        src={require("assets/img/nlicons/web/widget3.svg")}
                        />
                        <span>
                        <h6 className="webcardtitle">
                        Custom Business Web Application
                      </h6>
                        </span>
                  
                      </div>
                  <CardTitle tag="h3">R29999</CardTitle>
                  <ul className="align-left">
                    <li>
   <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      Responsive (Mobile Friendly)
                    </li>
                    <li>
   <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      Content Management System
                    </li>
                    <li>
   <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      No-CMS Option Available
                    </li>
                    <li>
   <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      Core Business Functionality
                    </li>
                    <li>
   <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      Custom Business Functionality
                    </li>
                    
                    <li>
   <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      Maximum 25 Pages
                    </li>
                    
                    
                  </ul>
                  <br></br>
                  <br className="my-2"></br>
                 <Link to="/contact">
                  <Button
                    className="btn-round card-link web-card-btn"
                    color="success"
                    href="" 
                  >
                    Get Started
                  </Button>
                  </Link>
                </CardBody>
              </Card>
            </Col>
            <Col lg="4" md="4" sm="4" xs="12" className="col1">
              <Card className="card-pricing mw-250">
              <h6 className="card-category">Premium</h6>
                <CardBody>
                <div className="webcardicon my-5">
                      <img 
                        alt="website"
                        className="webcardimg mx-1"
                        src={require("assets/img/nlicons/web/widget3.svg")}
                        />
                        <span>
                        <h6 className="webcardtitle">
                        Custom Business Pro Web Application
                      </h6>
                        </span>
                  
                      </div>
                  <CardTitle tag="h3">R49999</CardTitle>
                  <ul className="align-left">
                    <li>
   <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      Responsive (Mobile Friendly)
                    </li>
                    <li>
   <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      Content Management System
                    </li>
                    <li>
   <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      No-CMS Option Available
                    </li>
                    <li>
   <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      <b>Unlimited</b> Business Functionality
                    </li>
                    
                    <li>
   <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      Maximum 40 Pages
                    </li>
                    
                  </ul>
                  <br></br>
                  <br></br>
                  
                 <Link to="/contact">
                  <Button
                    className="btn-round card-link web-card-btn"
                    color="success"
                    href="" 
                  >
                    Get Started
                  </Button>
                  </Link>
                </CardBody>
              </Card>
            </Col>
            
            
          </Row>
          </Container>
        </div>
       
        <ContactComponent />
      </div>
      <FooterNav />
    </>
  );
}

export default WebCustom;
