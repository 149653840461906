/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container } from "reactstrap";
import Typist from "react-typist";
import styled, { createGlobalStyle } from "styled-components";
// core comments
const StyledTypist = styled(Typist)`
  color: white;
  font-size: 2.5em;
  padding-top: 50px;
  text-align: left;
`;

const WeAre = styled.span`
  color: #ffdb3b;
  font-weight: bold;
`;

const Newspan = styled.span`
  font-weight: bold;
`;

const H2mb = styled.h1`
  margin-bottom: 30px;
  color: turquoise;
  font-weight: bold;
  font-size: 2em;
`;

const H2mb2 = styled.h1`
  margin-bottom: 30px;
  color: mediumorchid;
  font-weight: bold;
  font-size: 2em;

`;


const Newspan2 = styled.span`
vertical-align: text-top;
font-size: 3vw !important;
`;

function PresentationHeader() {
  return (
    <>
      <div className="mr--custom" id="header">
        <div
          className="page-header section-dark"
          style={{
            backgroundImage:
              "url(" + require("assets/img/nl/loopspace.gif") + ")",
          }}
        >
          <div className="content-center">
            <Container id="headercontainer">
                <h1 className="presentation-title">NEXTLEVEL<Newspan2 className="superscipt presentation-title-smaller"> &#174;</Newspan2></h1>
                
                
              <div className="title-brand">

              <div className="type">TECHNOLOGY</div>
              <br></br>
              <br></br>
              <br></br>
              </div>
              <div>
              <StyledTypist loop={true}>
        <WeAre>We are</WeAre>
        <Newspan> Software Engineers</Newspan>
        <Typist.Backspace count={18} delay={700} />

        <Newspan> Application & Website Developers</Newspan>
        <Typist.Backspace count={32} delay={700} />

        <Newspan> Businesss Solutions Architects</Newspan>
        <Typist.Backspace count={30} delay={700} />

        <Newspan> Creative</Newspan>
        <Typist.Backspace count={8} delay={600} />

        <Newspan> Innovative</Newspan>
        <Typist.Backspace count={10} delay={600} />

        <Newspan> Ambitious</Newspan>
        <Typist.Backspace count={43} delay={600} />


        <Newspan>
          Delivering fully tailored, innovative, customised business technology
          solutions
        </Newspan>
      </StyledTypist>
              </div>
            </Container>
          </div>
        </div>
        <div className="wrapper">
        <div className="divider__img__div">
        <img 
         alt="divider"
         className="divider__img2"
         src={require("assets/img/nlbg/triangleNegative3.svg")}
         />         
       
        <img 
         alt="divider"
         className="divider__img2"
         src={require("assets/img/nlbg/triangleNegative.svg")}
         />
           <img 
         alt="divider"
         className="divider__img2"
         src={require("assets/img/nlbg/triangleNegative2.svg")}
         />     
                           
        </div>
           
        </div>
       
      </div>
    </>
  );
}

export default PresentationHeader;
