import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";
import {Link} from 'react-router-dom';
// core components

function SectionResponsive() {
  return (
    <>
      <div className="section section-responsive section-dark cd-section mr--custom" id="AppDesign&Development">
        <Container fluid>
          <Row>
            <Col md="6">
              <div className="phone-container">
                <img
                  alt="Location Tracking"
                  src={require("assets/img/nl/devicesdata.png")}
                />
                </div>
                <div className="crossplatformicons">
                <img
                  alt="Cross Platform Icons"
                  src={require("assets/img/nlicons/crossplatformicons.png")}
                />
                </div>
            </Col>
            <Col lg="4">
              <div className="info info-horizontal">
              
                <div className="description">
                <Link to="/mobile-software">

                  <h3 className="title px-20" id="sectiontitle">Strategy, Design & Development</h3>
                  </Link>
                  <h6 className="category px-30" id="sectioncategory">Solutions & Custom App Development</h6>
                  <p id="sectiondescription" className="px-20"> 
                  We help startups, large organizations and firms in between create strategic software products that drive revenue, reduce cost, and transform their businesses and market performance.
                  Through custom software we empower entrepreneurs, growth companies, enterprises and visionary firms to achieve greater profitability and efficiency, valuation and ultimate success by constructing the perfect solutions and building the right tools for you from the ground up
. 

                  </p>

                </div>
                <br></br>
                <Link to="/mobile-software">
                <button href="" className="start-btn ml-4em" color="info">See More</button>
                </Link>
              </div>
          

            </Col>
          </Row>
          <Row>
            <Col>
            <div className="icon icon-info">
                  <br></br>
                <i className="nc-icon nc-laptop" />
                <i className="nc-icon nc-mobile" />
                <i className="nc-icon nc-tablet-2" />
                </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default SectionResponsive;
