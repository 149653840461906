import React from "react";

// reactstrap components
import {Row, Col, Button, Card, CardBody, CardTitle, CardFooter } from "reactstrap";
import { Link } from 'react-router-dom'


// core components

 


function SectionIndustries() {
    return (
        <div className="whitebg cd-section mr--custom" id="IndustriesWeServe">
                <Row className="rowspace">
                    <Col md="8">
                    <div className="">
                    <img 
                    alt="cape town"
                    className="sectionwhatimg3"
                    src={require("assets/img/nl/industries.jpg")}
                    />
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <h1 className="whath26 pl-20"><span>Industries<br></br>We Serve</span></h1>
                    
                </div>
                    </Col>
                    <Col md="4">
                    <div classname="carddiv"> 
                    <br></br>
                    <br></br>
                    <br></br>
                 <h5 className="card-description2 margin-30">
                  NEXTLEVEL serves businesses in all major sectors, providing exceptional 
                  software solutions for large corporations, mid-sized enterprises, and 
                  startups. We use industry-specific knowledge and custom-tailor our products 
                  to each customer's unique needs.
                 </h5> 
                  
              </div>
                    </Col> 
                </Row>
                <Row className="shift-up-50px">
                    <Col md="4">
                    <Link to="/agriculture">
                    <Card classname="card card-31">
                <CardBody>
                <div className="cardicon"> 
                    <img 
                        alt="Agriculture"
                        className="cardimg"
                        src={require("assets/img/nlicons/agritech.png")}
                        />
                    </div>
                    <CardTitle id="titleblack2" tag="h3">
                        Agriculture
                    </CardTitle>
                  <p className="card-description align-left">
                  NEXTLEVEL empowers established agribusinesses and AgriTech startups with its agriculture software 
                  development services to devise innovative ways for farmers to run their operations.
                  </p>
                  <CardFooter>
                  <Link to="/agriculture">
                  <Button
                      className="btn-link colorblack"
                      
                    >
                      See more
                    </Button>
                    </Link>
                  </CardFooter>
                </CardBody>
              </Card>
              </Link>
                    </Col>
                    <Col md="4">
                    <Link to="/healthcare">
                    <Card classname="card2 card-31">
                <CardBody>
                <div className="cardicon"> 
                    <img 
                        alt="Healthcare"
                        className="cardimg"
                        src={require("assets/img/nlicons/heartbeat.svg")}
                        />
                    </div>
                    <CardTitle id="titleblack2" tag="h3">
                        Healthcare
                    </CardTitle>
                  <p className="card-description align-left">
                  Our efficiency solutions for healthcare and life sciences organizations, 
                  including hospitals and pharmaceutical companies, meet rigorous
                  standards like the POPI Act, National Health Act and other compliance standards.                  </p>
                  <CardFooter>
                  <Link to="/healthcare">
                  <Button
                      className="btn-link colorblack"
                      
                    >
                      See more
                    </Button>
                    </Link>
                  </CardFooter>
                </CardBody>
              </Card>
              </Link>
                    </Col> 
                    <Col md="4">
                    <Link to="/wholesale&retail">
                    <Card classname="card card-31">
                <CardBody>
                <div className="cardicon"> 
                    <img 
                        alt="Retail & ECommerce"
                        className="cardimg"
                        src={require("assets/img/nlicons/retail.svg")}
                        />
                    </div>
                    <CardTitle id="titleblack2" tag="h3">
                        Wholesale & Retail
                    </CardTitle>
                  <p className="card-description align-left">
                  The CRM systems we have designed and supported handle comprehensive operations for ecommerce 
                  and retail providers' inventory, invoicing, tracking, shipping, payments and more. NEXTLEVEL consistent and 
                  customer-centric shopping experiences across all channels.

                  </p>
                  <CardFooter>
                  <Link to="/wholesale&retail">
                  <Button
                      className="btn-link colorblack"
                      
                    >
                      See more
                    </Button>
                    </Link>
                  </CardFooter>
                </CardBody>
              </Card>
              </Link>
                    </Col>
                </Row>

                <Row>
                    <Col md="4">
                    <Link to="/ecommerce">
                    <Card classname="card card-31">
                <CardBody>
                <div className="cardicon"> 
                    <img 
                        alt="Ecommerce"
                        className="cardimg"
                        src={require("assets/img/nl/industries/icons/online-shop.svg")}
                        />
                    </div>
                    <CardTitle id="titleblack2" tag="h3">
                        Ecommerce
                    </CardTitle>
                  <p className="card-description align-left">
                  Harness the power of technology to fuel your eCommerce growth. Transform your eCommerce platform to scale business quickly. 
                  Tap into NEXTLEVEL technical excellence and multi-year domain expertise in eCommerce development solutions to expand your consumer audience, drive brand engagement, improve retention rates, and maximize earnings.
                  </p>
                  <CardFooter>
                  <Link to="/ecommerce">
                  <Button
                      className="btn-link colorblack"
                      
                    >
                      See more
                    </Button>
                    </Link>
                  </CardFooter>
                </CardBody>
              </Card>
              </Link>
                    </Col>
                    <Col md="4">
                    <Link to="/smes">
                    <Card classname="card card-31">
                <CardBody>
                <div className="cardicon"> 
                    <img 
                        alt="SME"
                        className="cardimg"
                        src={require("assets/img/nl/industries/icons/enterprise.svg")}
                        />
                    </div>
                    <CardTitle id="titleblack2" tag="h3">
                        Small & Medium-Sized Enterprises
                    </CardTitle>
                  <p className="card-description align-left">
                  As businesses grow, so do their operations. NEXTLEVEL custom solutions and applications can automate processes, cut costs, and increase transparency. 
                  Our solutions are 100% tailored to your needs, so you can take your business to the next level.
                  </p>
                  <CardFooter>
                  <Link to="/smes">
                  <Button
                      className="btn-link colorblack"
                      
                    >
                      See more
                    </Button>
                    </Link>
                  </CardFooter>
                </CardBody>
              </Card>
              </Link>
                    </Col> 
                    <Col md="4">
                    <Link to="/media&entertainment">
                    <Card classname="card2 card-31">
                <CardBody>
                <div className="cardicon"> 
                    <img 
                        alt="Media and Entertainment"
                        className="cardimg"
                        src={require("assets/img/nl/industries/icons/media.svg")}
                        />
                    </div>
                    <CardTitle id="titleblack2" tag="h3">
                        Media & Entertainment
                    </CardTitle>
                  <p className="card-description align-left">
                  Our end-to-end solutions provide everything from content, distribution, to presentation, and growth. We optimize content value chains to reduce churn helping you succeed in this fast-paced industry.
                  </p>
                  <CardFooter>
                  <Link to="/media&entertainment">
                  <Button
                      className="btn-link colorblack"
                      
                    >
                      See more
                    </Button>
                    </Link>
                  </CardFooter>
                </CardBody>
              </Card>
              </Link>
                    </Col>
                </Row>


                <Row>
                    <Col md="4">
                    <Link to="/education">
                    <Card classname="card card-31">
                <CardBody>
                <div className="cardicon"> 
                    <img 
                        alt="Education"
                        className="cardimg"
                        src={require("assets/img/nlicons/mortarboard.svg")}
                        />
                    </div>
                    <CardTitle id="titleblack2" tag="h3">
                        Education
                    </CardTitle>
                  <p className="card-description align-left">
                  For educational innovators, seeking unique technologies, lean design 
                  and emphasis on learners. With solutions ranging from e-learning
                  and training portals to web conferecing and virtual classrooms, we aim to add value 
                  to students and organizations.
                  </p>
                  <CardFooter>
                  <Link to="/education">
                  <Button
                      className="btn-link colorblack"
                      
                    >
                      See more
                    </Button>
                    </Link>
                  </CardFooter>
                </CardBody>
              </Card>
              </Link>
                    </Col>
                    <Col md="4">
                    <Link to="/finance&accounting">
                    <Card classname="card card-31">
                <CardBody>
                <div className="cardicon"> 
                    <img 
                        alt="Finance & Accounting"
                        className="cardimg"
                        src={require("assets/img/nlicons/finance.svg")}
                        />
                    </div>
                    <CardTitle id="titleblack2" tag="h3">
                        Finance & Accounting
                    </CardTitle>
                  <p className="card-description align-left">
                  We've designed and deployed smart, custom built financial solutions -from automated 
                  financial trading systems, to integrated accounting systems, we deliver 
                  secure solutions in compliance with stringent standards, including FICA, FAIS, POPI 
                  and more.
                  </p>
                  <CardFooter>
                  <Link to="/finance&accounting">
                  <Button
                      className="btn-link colorblack"
                      
                    >
                      See more
                    </Button>
                    </Link>
                  </CardFooter>
                </CardBody>
              </Card>
              </Link>
                    </Col> 
                    <Col md="4">
                    <Link to="/transport&logistics">
                    <Card classname="card2 card-31">
                <CardBody>
                <div className="cardicon"> 
                    <img 
                        alt="Transport & Logistics"
                        className="cardimg"
                        src={require("assets/img/nl/industries/icons/truck.svg")}
                        />
                    </div>
                    <CardTitle id="titleblack2" tag="h3">
                        Transport & Logistics
                    </CardTitle>
                  <p className="card-description align-left">
                  From fleet management to routing and tracking solutions for transport and logistics companies to 
                  offer considerable value to customers and boost overall operating efficiency.
                  </p>
                  <CardFooter>
                  <Link to="/transport&logistics">
                  <Button
                      className="btn-link colorblack"
                      
                    >
                      See more
                    </Button>
                    </Link>
                  </CardFooter>
                </CardBody>
              </Card>
              </Link>
                    </Col>
                </Row>

               

        </div>


    )
}

export default SectionIndustries
