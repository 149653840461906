/*eslint-disable*/
import React from "react";
// react plugin used to create datetimepicker
import ReactDatetime from "react-datetime";
// react plugin used to create switch buttons
import Switch from "react-bootstrap-switch";
// react plugin used to create an image gallery
import { PhotoSwipeGallery } from "react-photoswipe";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Collapse,
  
  Container,
  Row,
  Col,
  
} from "reactstrap";


function SeoOffpage() {
 
  // collapse states and functions
  const [collapses, setCollapses] = React.useState([]);
  const changeCollapse = (collapse) => {
    if (collapses.includes(collapse)) {
      setCollapses(collapses.filter((prop) => prop !== collapse));
    } else {
      setCollapses([...collapses, collapse]);
    }
  };
  
  return (
    <>
      <div className="section section-blue javascript-components shift-up-150px bottom-up-150px background-tp">
        <Container>
    
          
          <Row className="justify-center">
            <Col md="12">
              <div id="acordeon">
                <div aria-multiselectable={true} id="accordion" role="tablist">
                  <Card className="no-transition">
                    <CardHeader
                      className="card-collapse"
                      id="headingOne"
                      role="tab"
                    >
                      <h2 className="mb-0 custom-title">
                        <a
                          aria-expanded={collapses.includes(1)}
                          className="collapsed"
                          data-parent="#accordion"
                          href=""
                          id="collapseOne"
                          onClick={(e) => {
                            e.preventDefault();
                            changeCollapse(1);
                          }}
                        >
                         Off Page SEO Breakdown{" "}
                          <i className="nc-icon nc-minimal-down" />
                        </a>
                      </h2>
                    </CardHeader>
                    <Collapse isOpen={collapses.includes(1)}>
                    <br></br>
                      <CardBody className="collapse-text align-left">
                      <a>
                        <a className="mb-20">
                        <strong>
                        THE NUMBER OF LINKING DOMAINS
                    </strong>
                        </a>
                        <br></br>
                      <br></br>
                      The number of domains linking to you is one of the most important ranking factors.
                      </a>
<br></br>
<br></br>
                      <a>
                      <strong>
                      THE NUMBER OF LINKING PAGES
                    </strong>
                      <br></br>
                      <br></br>
                      There might be some links from a particular domain to your site; their number is a ranking factor too. However, it is still better to have more links from individual domains rather than from a single domain.
                      </a>
                      <br></br>
<br></br>
                      <a>
                      <strong>
                      DOMAIN AUTHORITY OF LINKING PAGE
                    </strong>
                      <br></br>
                      <br></br>
                      Not all pages are equal. Links to pages with higher domain authority will be a bigger factor than those on low authority domains. Therefore, you should strive to build links from high domain authority websites.
                      </a>
                      <br></br>
<br></br>
                      <a>
                      <strong>
                      LINK RELEVANCY
                    </strong>
                      <br></br>
                      <br></br>
                      Some SEOs believe that links from pages related to your pages topic carry more relevancy for search engines.
                      </a>
                      <br></br>
<br></br>
                      <a>
                      <strong>
                      AUTHORITY OF LINKING DOMAIN
                    </strong>
                      <br></br>
                      <br></br>
                      The authority of a domain may be a ranking factor too. For that reason, a link from low authority page on a high authority site will be worth more that from a lower domain authority one.
</a>
<br></br>
<br></br>
                      <a>
                      <strong>
                      LINKS FROM A HOMEPAGE
                    </strong>
                      <br></br>
                      <br></br>
                      Similarly, some SEOs believe that links from a home page of a linking domain carry more strength than those on one of its pages.
                      </a>
                      <br></br>
                      <br></br>

                      <a>
                      <strong>
                      A NUMBER OF DO FOLLOW VS. NOFOLLOW LINKS
                    </strong>
                      <br></br>
                      <br></br>
                      Google officially stated that they don’t count nofollow links (link with rel=nofollow attribute attached). Therefore the number of your do follow links should affect your rankings too.
                      </a>
                      <br></br>
                      <br></br>
                       
                      <a>
                      <strong>
                      THE DIVERSITY OF LINK TYPES
                    </strong>
                      <br></br>
                      <br></br>
                      The types of links you build to your site matters too. Too many links of one type may be a spam indicator and impact your rankings negatively.
                      </a>
                      <br></br>
                      <br></br>
                       
                      <a>
                      <strong>
                      CONTEXTUAL LINKS
                    </strong>
                      <br></br>
                      <br></br>
                      It is said that links within the content of the page are worth more than links in a sidebar for instance.
                      </a>
                      <br></br>
                      <br></br>

                      <a>
                      <strong>
                      LINK ANCHOR
                    </strong>
                      <br></br>
                      <br></br>
                      Anchor text of a link used to be a strong ranking factor. Today it can be utilized as a web spam indicator, negatively impacting your rankings.
                      </a>
                      <br></br>
                      <br></br>

                     
                      </CardBody>
                    </Collapse>
                 
                  </Card>
                </div>
                {/* end acordeon */}
              </div>
            </Col>
          </Row>
         
        </Container>
      </div>
    </>
  );
}

export default SeoOffpage;
