import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components

import ColorNavbar from "components/Navbars/ColorNavbar"; 
import ContactComponent from '../services/ContactComponent.js';

import WebHeaderTemplate from "./webHeaders/WebHeaderTemplate.js";
import FooterNav from "../services/FooterNav.js";
import {Link} from 'react-router-dom';
import Product from "../Product";

function WebEcommerce() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
    };
  });
  return (
    <>
      <ColorNavbar />
      <WebHeaderTemplate navTitle="Advanced Ecommerce Packages" Title="Ecommerce Websites" />
      <div className="wrapper">
        {/* ********* PRICING 3 ********* */}
        <div
          className="pricing-3 section-bg-img"
          style={{
            backgroundImage:
            "url(" + require("assets/img/nl/sections/custom/red-blue.jpg") + ")",
          }}
        >
        
           <Container>
          <Row>
            
            <Col md="6" className="col1">
              <Card className="card-pricing w-150 w-150">
              <h6 className="card-category">Standard</h6>
              
                <CardBody>
                <div className="webcardicon my-5">
                      <img 
                        alt="website"
                        className="webcardimg mx-4"
                        src={require("assets/img/nlicons/web/cart-simple1.svg")}
                        />
                        <span>
                        <h5 className="webcardtitle">
                        Standard Ecommerce Website
                      </h5>
                        </span>
                  
                      </div>
                  <CardTitle tag="h2">R6999</CardTitle>
                  <ul className="align-left">
                    <li>
   <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      Responsive (Mobile Friendly)
                    </li>
                    <li>
   <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      Content Management System
                    </li>
                    
                    <li>
   <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      Online Shopping System
                    </li>
                    <li>
   <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      10 Products Pre-Loaded
                    </li>
                    <li>
   <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      Payment Gateway Integration
                    </li>
                    <li>
   <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      Full POS System
                    </li>
                    <li>
   <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      Additional Products At R120 each
                    </li>
                    <li>
   <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      Maximum 6 Pages
                    </li>
                    <li>
   <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      Additional Pages At R990 per page
                    </li>
                    <li>
   <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      Dynamic Pages At R1200 per page
                    </li>
                    <li>
 <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      <b>FREE</b> Domain Name
                    </li>
                    <li>
 <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      <b>FREE</b> SSL Certificate
                    </li>
                    <li>
 <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      <b>1 year FREE</b> Website Hosting
                    </li>
                    <li>
   <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      On-Page SEO
                    </li>
                  </ul>
                  <br></br>
                  <br></br>
                  <br></br>
                  <Product 
                  id="StandardEcommerceWebsite"
                  title="Standard Ecommerce Website"
                  price={6999.0}
                  rating={5}
                  image={require("assets/img/nlicons/web/cart-simple1.svg")}
                  />   
                 <Link to="/contact">
                  <Button
                    className="btn-round card-link web-card-btn"
                    color="success"
                    href="" 
                  >
                    Get Started
                  </Button>
                  </Link>
                </CardBody>
              </Card>
            </Col>
            <Col md="6" className="col1">
              <Card className="card-pricing w-150">
              <h6 className="card-category">Premium</h6>
                <CardBody>
                <div className="webcardicon my-5">
                      <img 
                        alt="website"
                        className="webcardimg mx-4"
                        src={require("assets/img/nlicons/web/cart-simple1.svg")}
                        />
                        <span>
                        <h5 className="webcardtitle">
                        Ecommerce Pro Website
                      </h5>
                        </span>
                  
                      </div>
                  <CardTitle tag="h2">R9999</CardTitle>
                  <ul className="align-left">
                    <li>
   <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      Responsive (Mobile Friendly)
                    </li>
                    <li>
   <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      Content Management System
                    </li>
                    <li>
   <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      Online Shopping System
                    </li>
                    <li>
   <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      30 Products Pre-Loaded
                    </li>
                    <li>
   <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      Payment Gateway Integration
                    </li>
                    <li>
   <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      Full POS System
                    </li>
                    <li>
   <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      Additional Products At R120 each
                    </li>
                    <li>
   <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      Maximum 14 Pages
                    </li>
                    <li>
   <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      Additional Pages At R990 per page
                    </li>
                    <li>
   <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      Dynamic Pages At R1200 per page
                    </li>
                    <li>
 <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      <b>FREE</b> Domain Name
                    </li>
                    <li>
   <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      <b>FREE</b> SSL Certificate
                    </li>
                    <li>
   <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      <b>1 YEAR FREE</b> Website Hosting
                    </li>
                    <li>
   <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      On-Page SEO
                    </li>
                  </ul>
                  <br></br>
                  <br></br>
                  <br></br>
                  <Product 
                  id="EcommerceProWebsite"
                  title="Ecommerce Pro Website"
                  price={9999.0}
                  rating={5}
                  image={require("assets/img/nlicons/web/cart-simple1.svg")}
                  />   
                 <Link to="/contact">
                  <Button
                    className="btn-round card-link web-card-btn"
                    color="success"
                    href="" 
                  >
                    Get Started
                  </Button>
                  </Link>
                </CardBody>
              </Card>
            </Col>
            
            
          </Row>
          </Container>
        
        </div>
        <ContactComponent />
      </div>
      <FooterNav />
    </>
  );
}

export default WebEcommerce;
