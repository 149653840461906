import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";
import {Link} from 'react-router-dom';
// core components

function SectionContent() {
  
  
 
 
  return (
    <>
      <div id="fadeInAnim">
        <div className="section section-content cd-section mr--custom" id="UX-UI-Design">
          <Container>
            <Row>
            <Col md="6">
                <div className="image-container">
                  <img
                    alt="..."
                    className="img123"
                    src={require("assets/img/nlmockup/devicesmockup.png")}
                  />
                  <img
                    alt="..."
                    className="area-img add-animation"
                    src={require("assets/img/nlmockup/ipad-right-img.jpg")}
                  />
                  <img
                    alt="..."
                    className="info-img add-animation"
                    src={require("assets/img/nlmockup/idea.png")}
                  />
                </div>
              </Col>  
              <Col md="4" className="mgl2">
                <div className="section-description">
                <Link to="/mobile-software">
                  <h3 className="title align-left">UX-UI Design</h3>
                  </Link>
                  <h6 className="category pr-20">Usable, Seamless User Experience</h6>
                  <p className="description px-20 align-left" id="sectiondescription">
                  We deliver remarkable fully responsive websites and applications that help you outdo your competition. 
                Our websites and apps boast beauty and brains, with gorgeous design, cutting-edge code, exceptional usability, 
                and conversion strategy built right into each and every one.
                  </p>
                </div>
                <Link to="/mobile-software">
                  <button class="glow-on-hover">VIEW MORE</button>
                  </Link>
              </Col>

            </Row>
            <Row>
            <Col>

            <div className="icon icon-info resicon minus-mt-800">
                  <br></br>
                  <br></br>
                  <br></br>
                  <i className="nc-icon nc-laptop"id="icon-info2" />
                <i className="nc-icon nc-mobile"id="icon-info4" />
                <i className="nc-icon nc-tablet-2"id="icon-info3" />
                </div>
            </Col>
          </Row>
          </Container>
        </div>
      </div>
    </>
  );
}

export default SectionContent;
