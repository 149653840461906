import React from "react";

// reactstrap components
import {Row, Col, Button, Card, CardBody, CardTitle, CardFooter } from "reactstrap";

import { Link } from 'react-router-dom'

// core components

 


function SectionWhat() {
    return (
        <div className="whitebg cd-section mr--custom" id="WhatWeDo">
                <Row>
                    <Col md="8">
                    <div className="">
                    <img 
                    alt="cape town"
                    className="sectionwhatimg"
                    src={require("assets/img/nl/capetown.jpg")}
                    />
                    <h1 className="whath211"><span>Our Services</span></h1>
                    <h5 className="whath4 pl-10 mx-20 pr-10"><span>To learn more about how we can help your business, check out our areas 
                        of expertise and don't hesitate to contact us with any questions!</span></h5>
                </div>
                    </Col>
                    <Col md="4">
                      <Link to="/custom-software">
                    <Card classname="card card-31">
                <CardBody>
                    <CardTitle className="align-left" id="titlepurple" tag="h2">
                    Custom Technology Solutions
                    </CardTitle>
                  <p className="card-description align-left">
                    We listen to your ideas and create custom technology solutions from the ground up, 
                    designed especially for your business. NEXTLEVEL customers agree that our engineers 
                    and executive team are some of the highest quality solutions architects. You can trust that we'll do it right the first time.
                  </p>
                  <CardFooter>
                  <Link to="/custom-software">
                  <Button
                      className="btn-link colorpurple"
                      
                    >
                      See more
                    </Button>
                    </Link>
                  </CardFooter>
                </CardBody>
              </Card>
              </Link>
                    </Col> 
                </Row>
                <Row>
                    <Col md="6">
                    <Link to="/web">
                    <Card classname="card card-31">
                <CardBody>
                    <CardTitle className="align-left" id="titleviolet" tag="h2">
                    Web Design & Development
                    </CardTitle>
                  <p className="card-description align-left">
                  Whether you are a start-up looking to establish an online business and grow your brand, or 
                        an enterprise looking to optimize your existing web frameworks and futher scale up your online operations, 
                        we've got you covered.
                  </p>
                  <CardFooter>
                  <Link to="/web">
                  <Button
                      className="btn-link colorviolet"
                      
                    >
                      See more
                    </Button>
                    </Link>
                  </CardFooter>
                </CardBody>
              </Card>
              </Link>
                    </Col>
                     <Col md="6">
                    <Link to="/mobile-software">
                    <Card classname="card card-31">
                <CardBody>
                    <CardTitle className="align-left" id="titleorange" tag="h2">
                    Mobile Software Solutions & Apps
                    </CardTitle>
                  <p className="card-description align-left">
                  Mobile software is the cornerstone of any modern business. NEXTLEVEL has extensive experience creating innovative mobile apps with different technology stacks. We develop the right mobile apps for our customers; our solutions are robust and reliable, and we always use the most up-to-date and efficient UI/UX.
                  </p>
                  <CardFooter>
                  <Link to="/mobile-software">
                  <Button
                      className="btn-link colororange"
                      
                    >
                      See more
                    </Button>
                    </Link>
                  </CardFooter>
                </CardBody>
              </Card>
              </Link>
                    </Col> 
                   
                </Row>
                <Row>
                    <Col md="6">
                    <Link to="/digital-marketing">
                    <Card classname="card card-31">
                <CardBody>
                    <CardTitle className="align-left" id="titleyellow" tag="h2">
                    Digital Marketing
                    </CardTitle>
                  <p className="card-description align-left">
                  We create and deliver digital marketing campaigns for businesses 
                  looking to increase leads, phone calls, transactions, qualified website traffic
                  and social exposure. 
                  </p>
                  <CardFooter>
                  <Link to="/digital-marketing">
                  <Button
                      className="btn-link coloryellow"
                      
                    >
                      See more
                    </Button>
                    </Link>
                  </CardFooter>
                </CardBody>
              </Card>
              </Link>
                    </Col>
                    <Col md="6">
                    <Link to="/quality-assurance">
                    <Card classname="card card-31">
                <CardBody>
                    <CardTitle className="align-left" id="titlegreen" tag="h2">
                    Quality Assurance
                    </CardTitle>
                  <p className="card-description align-left">
                  We believe in getting it right every time - that's why our Quality Assurance and 
                  Production teams work separately. We conduct thorough and rigorous QA checks on all of 
                  our projects. We can also check third-party products to ensure you're getting what you expect.
                  </p>
                  <CardFooter>
                  <Link to="/quality-assurance">
                  <Button
                      className="btn-link colorgreen"
                      
                    >
                      See more
                    </Button>
                    </Link>
                  </CardFooter>
                </CardBody>
              </Card>
              </Link>
                    </Col> 
                </Row>
                <Row>
                    <Col md="6">
                    <Link to="/business-analysis">
                    <Card classname="card card-31">
                <CardBody>
                    <CardTitle className="align-left" id="titleblue" tag="h2">
                    Business Analysis
                    </CardTitle>
                  <p className="card-description align-left">
                  Before NEXTLEVEL actually engineers a product, we always talk to our customers to understand their needs. 
                  Our team can analyze your project's functionality, business logic, software architecture, and future user 
                  scenarios. We'll sweat the small stuff so you can focus on the big picture.
                  </p>
                  <CardFooter>
                  <Link to="/business-analysis">
                  <Button
                      className="btn-link colorblue"
                      
                    >
                      See more
                    </Button>
                    </Link>
                  </CardFooter>
                </CardBody>
              </Card>
              </Link>
                    </Col>
                    <Col md="6">
                    <div
          className="pricing-3 custom-software-div3 height-100"
          style={{
            backgroundImage:
              "url(" + require("assets/img/nl/rocket2.jpg") + ")",
          }}
        >
                   
                    <h2 className="whath24 padding-xr-30"><span>Have a project or business idea? Push the button below to get started!</span></h2>
                    <Link to="/contact">
                    <Button className="whatbutton btn-outline-danger"><span>Get Started</span></Button>
                    </Link>
                    <div className="whatdiv">
                    </div>
                    </div>
                    </Col> 
                </Row>
                <Row>
                    <Col md="6">
                    <Link to="/software-modernisation">
                    <Card classname="card card-31">
                <CardBody>
                    <CardTitle className="align-left" id="titleteal" tag="h2">
                    Legacy Software Modernisation
                    </CardTitle>
                  <p className="card-description align-left">
                  NEXTLEVEL offers a service to update or replace your outdated legacy systems with 
                  cutting-edge new technologies and efficient custom software systems. We will bring 
                  back the efficiency of your business and get it to the next level to outrun your competition.
                  </p>
                  <CardFooter>
                  <Link to="/software-modernisation">
                  <Button
                      className="btn-link colorteal"
                      
                    >
                      See more
                    </Button>
                    </Link>
                  </CardFooter>
                </CardBody>
              </Card>
              </Link>
                    </Col>
                    <Col md="6">
                    <Link to="/leveraging-resources">
                    <Card classname="card card-31">
                <CardBody>
                    <CardTitle className="align-left" id="titlegreen2" tag="h2">
                    Leveraging Your Resources
                    </CardTitle>
                  <p className="card-description align-left">
                  Each NEXTLEVEL client receives a solution, completelely tailored to their individual needs. From idea to 
                  implementation, we can leverage your existing resources or become your new IT services provider.
                  </p>
                  <CardFooter>
                  <Link to="/leveraging-resources">
                  <Button
                      className="btn-link colorgreen2"
                      
                    >
                      See more
                    </Button>
                    </Link>
                  </CardFooter>
                </CardBody>
              </Card>
              </Link>
                    </Col> 
                </Row>
                <Row>
                <Col md="6">
                    <div
          className="pricing-3 custom-software-div3 height-100"
          style={{
            backgroundImage:
              "url(" + require("assets/img/nl/sections/marketing.jpeg") + ")",
          }}
        >
                   
                    <h2 className="whath241 padding-xr-30"><strong><span>Ideas</span><br></br><span>that</span><br></br><span>grow</span><br></br><span>brands</span></strong></h2>
                    <Link to="/contact">
                    <Button className="whatbutton-r btn-outline-danger"><span>Get Started</span></Button>
                    </Link>
                    <div className="whatdiv">
                    </div>
                    </div>
                    </Col> 

                    <Col md="6">
                    <Link to="/seo-info">
                    <Card classname="card card-31">
                <CardBody>
                    <CardTitle className="align-left" id="titlered" tag="h2">
                    Search Engine Optimisation
                    </CardTitle>
                  <p className="card-description align-left">
                  NEXTLEVEL is a performance marketing agency, structured for speed and efficiency in order to meet the everchanging needs of today’s consumers. Delivering the right content in the right context on a comprehensive scale requires the ability to pivot as fast as the digital marketplace.
                  </p>
                  <CardFooter>
                  <Link to="/seo-info">
                  <Button
                      className="btn-link colorred"
                      
                    >
                      See more
                    </Button>
                    </Link>
                  </CardFooter>
                </CardBody>
              </Card>
              </Link>
                    </Col> 
                </Row>
                <Row>
                <Col md="6">
                    <Link to="/product-development">
                    <Card classname="card2 card-31">
                <CardBody>
                    <CardTitle className="align-left" id="titlemaroon" tag="h2">
                    Product Development
                    </CardTitle>
                  <p className="card-description align-left">
                  We focus our architectural principles around constructing versatile and scalable solutions tailored to our 
                        clients' unique needs and constraints.
                  </p>
                  <CardFooter>
                  <Link to="/product-development">
                  <Button
                      className="btn-link colormaroon"
                      
                    >
                      See more
                    </Button>
                    </Link>
                  </CardFooter>
                </CardBody>
              </Card>
              </Link>
                    </Col> 
                    <Col md="6">
                    <Link to="/software-maintenance">
                    <Card classname="card card-31">
                <CardBody>
                    <CardTitle className="align-left" id="titlered2" tag="h2">
                    Software Maintenance 
                    </CardTitle>
                  <p className="card-description align-left">
                  Each NEXTLEVEL client receives a solution, completelely tailored to their individual needs. From idea to 
                  implementation, we can leverage your existing resources or become your new IT services provider.
                  </p>
                  <CardFooter>
                  <Link to="/software-maintenance">
                  <Button
                      className="btn-link colorred2"
                    >
                      See more
                    </Button>
                    </Link>
                  </CardFooter>
                </CardBody>
              </Card>
              </Link>
                    </Col> 
                </Row>

        </div>


    )
}

export default SectionWhat
