import React from "react";

// reactstrap components
import { Container, Row, Col} from "reactstrap";


// core components

function SectionSummary() {
  return (
    
    <>
    <div>
    </div>
      <div className="section section-summary mr--custom">
        <Container>
          <Row>
            <Col md="4">
              <div className="info">
                <div className="icon icon-dange align-center">
                  <img
                  alt="advisory"
                  src={require("assets/img/nlicons/adv.svg")}
                  />
                </div>
                <div className="blacktext">
                <br></br>
                  <h3 className="info-title" id="summaryheading"><strong>Advisory</strong></h3>
                  <br></br>
                  <h5 id="summarydes" className="pl-10">
                  Charting digital roadmaps through strategic solutions
                  </h5>
                </div>
              </div>
            </Col>
            <Col md="4">
              <div className="info">
                <div className="icon icon-dange align-center">
                <img
                  alt="design"
                  src={require("assets/img/nlicons/art.svg")}
                  />
                </div>
                <div className="blacktext">
                  <br></br>
                  <h3 className="info-title" id="summaryheading"><strong>Design</strong></h3>
                  <br></br>

                  <h5 id="summarydes" className="pl-10">
                  Redefined design thinking process, crafting products of tomorrow
                  </h5>
                </div>
              </div>
            </Col>
            <Col md="4">
              <div className="info">
                <div className="icon icon-danger align-center">
                <img
                  alt="engineering"
                  src={require("assets/img/nlicons/eng.svg")}
                  />
                </div>
                <div className="blacktext">
                <br></br>
                  <h3 className="info-title" id="summaryheading"><strong>Engineering</strong></h3>
                  <br></br>
                  <h5 id="summarydes" className="pl-10">
                    Utilising Industry leading standards to create holistic solutions
                  </h5>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      
    </>
  );
}

export default SectionSummary;
