import React from 'react';
import ContactComponent from './ContactComponent.js';
import ColorNavbar from "components/Navbars/ColorNavbar"; 

import FooterNav from "./FooterNav.js";
import { Row, Col } from 'reactstrap'
import AboutHeader from "components/Headers/Custom/AboutHeader.js";
import { Link } from 'react-router-dom';

function AboutUs() {
    const { hash } = window.location;
    if (hash !== '') {
      // Push onto callback queue so it runs after the DOM is updated,
      // this is required when navigating from a different page so that
      // the element is rendered on the page before trying to getElementById.
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) element.scrollIntoView();
      }, 0);
    }

    
    document.documentElement.classList.remove("nav-open");
  // function that is being called on scroll of the page
  const checkScroll = () => {
    
    // it takes all the elements that have the .add-animation class on them
    const componentPosition = document.getElementsByClassName("add-animation");
    const scrollPosition = window.pageYOffset;
    for (var i = 0; i < componentPosition.length; i++) {
      var rec =
        componentPosition[i].getBoundingClientRect().top + window.scrollY + 100;
      // when the element with the .add-animation is in the scroll view,
      // the .animated class gets added to it, so it creates a nice fade in animation
      if (scrollPosition + window.innerHeight >= rec) {
        componentPosition[i].classList.add("animated");
        // when the element with the .add-animation is not in the scroll view,
        // the .animated class gets removed from it, so it creates a nice fade out animation
      } else if (scrollPosition + window.innerHeight * 0.8 < rec) {
        componentPosition[i].classList.remove("animated");
      }
    }
  };
  React.useEffect(() => {
    document.title = "About Us"
 });

  React.useEffect(() => {
    document.body.classList.add("presentation-page");
    window.addEventListener("scroll", checkScroll);
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("presentation-page");
      window.removeEventListener("scroll", checkScroll);
    };
  });


  
    return (
        
        <div>
  
        <ColorNavbar />
        
        <AboutHeader />
        <Row>
      <Col md="4">
      <br></br>

        <div className="topcard">
          <h2 id="titleblack" className="align-left2 mb--20">
          Our Vision
          </h2>
         
        </div>
      
      </Col>
      <Col md="8">
        
    <p className="top-description mr-30">

    NEXTLEVEL Technology is a digital agency passionate about storytelling, visual design, and technology. We collaborate with companies small to large around the world to help them engage their audiences and build brand awareness.     
    <br></br>
    <br></br>

    Our team can create amazing applications and user experiences, beginning with deep market research, practical strategies, and professional execution.
    <br></br>
<br></br>


We are a digital experience agency fueled on talent, artistic flair and unconventional ideas.    
    Our agency strives to create meaningful digital content, which in turn become fantastic digital experiences.
<br></br>
<br></br>
</p>
   <br></br>
   <br></br>
      </Col> 
  </Row>
  <Row>
      <Col md="5" className="no-margins">
      <div className="topcard-blk">
          <h3 id="titlewhitesml" className="align-left2">
              Our Services
                </h3>    
                <br></br>
                    <ul className="servicelinks">
                    <Link to="/web">
                      <li>
                        <a href="" className="footer-link2">
                          Website Design & Development
                        </a>
                       </li>
                      </Link>
                      <Link to="/mobile-software">
                      <li>
                      <a href="" className="footer-link2">
                          Mobile Software Development
                        </a>
                       </li>
                      </Link>
                      
                      
                      <Link to="/custom-software">
                      <li>
                        <a href="" className="footer-link2">
                          Custom Software Development
                        </a>
                      </li>
                      </Link>
                      <Link to="/digital-marketing">
                      <li>
                        <a href="" className="footer-link2">
                          Digital Marketing
                        </a>
                       </li>
                      </Link>
                      
                      <Link to="/seo-info">
                      <li>
                        <a href="" className="footer-link2">
                        Search Engine Optimisation
                        </a>
                       </li>
                       </Link>
                       <Link to="/product-development">
                      <li>
                        <a href="" className="footer-link2">
                          Software Product Development
                        </a>
                       </li>
                      </Link>
                      <Link to="/business-analysis">
                      <li>
                        <a href="" className="footer-link2">
                          Business Analysis
                        </a>
                       </li>
                      </Link>
                      
                      <Link to="software-maintenance">
                      <li>
                        <a href="" className="footer-link2">
                        Software Maintenance 
                        </a>
                       </li>
                      </Link>
                     
                    
                       <Link to="/software-modernisation">
                      <li>
                        <a href="" className="footer-link2">
                        Legacy Software Replacement
                        </a>
                       </li>
                       </Link>
                       <Link to="/quality-assurance">
                      <li>
                        <a href="" className="footer-link2">
                          Quality Assurance
                        </a>
                       </li>
                      </Link>
                      

                      <Link to="/leveraging-resources">
                      <li>
                        <a href="" className="footer-link2">
                        Dedicated Development Team
                        </a>
                       </li>
                      </Link>
                      
                     
               
                     </ul>
                     </div>
      </Col>
     
      <Col md="7" className="no-margins">
      <div
          className="pricing-3 custom-software-div3"
          style={{
            backgroundImage:
              "url(" + require("assets/img/nlbg/bg4.jpg") + ")",
          }}
        >
                   <br></br>
                    <h2 className="whath2fin2 ml-20"><span>Our Mission</span></h2>
                    <br></br>
                    <h5 className="whath4ind pr-40"><span>
                    We create diverse, complex, web, mobile and desktop software as well as, cloud, server, network, IoT, cybersecurity and digital marketing solutions for any business need. Our knowledge and experience translate to added value and peace of mind for our customers. With NEXTLEVEL Technologies you get quality software and perfect service every time.
<br></br>
<br></br>

Websites, Applications, landing pages, interactive campaigns, or just about any other digital media needed, we can merge innovative ideas with beautiful designs to reach the hearts and minds of your core audiences.
                    </span></h5>
                    <br></br>
<br></br>
<br></br>

                </div>
      </Col>
  </Row>
  
           <Row className="shift-up-80px">
                    <Col md="7">
                    <div
          className="pricing-3 custom-software-div4 pr-30 pl-20"
          style={{
            backgroundImage:
            "url(" + require("assets/img/nlbg/bg5.jpg") + ")",
        }}
        >
                    <br></br>
                    <h2 className="whath2fin2"><span>Our Values</span></h2>
                    <br></br>
                    <h5 className="whath4ind"><span>To us, world-class digital products take more than intelligent code or creative design. It requires true partnership between the ideators and the innovators. Our collaborations are built on a bedrock of transparency, communication, expert project management, and attention to detail
                        </span></h5>
                <br></br>
                <h6 className="whath4ind"><span>Our values in a nutshell:
                        </span></h6>
                        <br></br>
                
                </div>
                    </Col>
                    <Col md="5" className="">
                    <img 
                    alt="online shopping"
                    className="sectionimg-sme3 shift-up-40px"
                    src={require("assets/img/nl/sections/seo/overhead.jpg")}
                    />
                    </Col> 
                </Row>
        <Row className="shift-up-50px px-20">
           
           <Col lg="4" md="4" sm="12" xs="12" className="col1">
   <div className="topcard2 z-index-2i">
   <hr className="h-line2"></hr>
   <img 
               alt="maintenance"
               className="customcardimg-cont"
               src={require("assets/img/nlicons/about/prototype.svg")}
               />
   
               
     <h2 id="titleblacksmall" className="align-left2">
     Creative Collaboration
     </h2>
    <p className="tile-description">


    


Communication creates clarity. At NEXTLEVEL Technology, our relationships are partnerships, with a common goal of engineering success through well-planned and well-executed strategies.

    </p>
   </div>
  
 
 </Col>
 <Col lg="4" md="4" sm="12" xs="12" className="col1">
 <div className="topcard2 z-index-2i">
 <hr className="h-line2"></hr>

   <img 
               alt="maintenance"
               className="customcardimg-cont"
               src={require("assets/img/nlicons/about/planning.svg")}
               />
              
     <h2 id="titleblacksmall" className="align-left2">
     Alignment & Execution
     </h2>
    <p className="tile-description">
    
Organization is the key to operating a deadline and results-driven environment. NEXTLEVEL utilizes a variety of tools to achieve goals and enhance transparency.

    </p>
    </div>
    
 
 </Col>
 <Col lg="4" md="4" sm="12" xs="12" className="col1">
 <div className="topcard2 z-index-2i">
 <hr className="h-line2"></hr>

   <img 
               alt="maintenance"
               className="customcardimg-cont"
               src={require("assets/img/nlicons/about/report.svg")}
               />
              
     <h2 id="titleblacksmall" className="align-left2">
     Financial Intelligence
     </h2>
    <p className="tile-description">

Everything we touch has financial implications— whether cost savings, improving margins, or generating new revenue streams. We’re not only aware of these considerations, we’re bound by them.

    </p>
    </div>
    
 
 </Col>
 </Row>
<br></br>
<br></br>

 <Row>
      
                    <Col md="7">
                      <div className="topcard" id="Compliance">
                        <h2 id="titleblack" className="align-left2">
                        Compliance Standards & Governance
                        </h2>
                       
                      </div>
                    
                    </Col>
                    <Col md="4">
                      
                  <p className="top-description">
                  NEXTLEVEL specializes in creating advanced applications with the most innovative and secure technologies, that meet industries standards and comply with rigorous legal acts. Our experts handle the software application from the architecture to delivery, ensuring it meets the strict government guidelines to protect personal data.
                  </p>
                 
                    </Col> 
                </Row>
                <div className="small-border">
                      <div className="box">
 <Row className="mx-20">
 <Col md="3">
             <div className="info">
             <div className="icon">
             <hr className="h-line"></hr>

                 </div>
                 <div className="description">
                   <h4 className="info-title-black">The Open Group Architecture Framework (TOGAF)</h4>
                   <p className="description-black">
                   TOGAF is the most used framework for enterprise architecture today that provides an approach for designing, planning, implementing, and governing an enterprise information technology architecture. 
                   TOGAF is a high-level approach to design. It is typically modeled at four levels: Business, Application, Data, and Technology                  
                   </p>
                   
                 </div>
               </div>
             </Col>
 <Col md="3">
               <div className="info">
               <div className="icon">
               <hr className="h-line"></hr>

                 </div>
                 <div className="description">
                   <h4 className="info-title-black">Information Technology Infrastructure Library (ITIL)</h4>
                   <p className="description-black">
                   The IT Infrastructure Library (ITIL) is a library of volumes describing a framework of best practices for delivering IT services. ITIL’s systematic approach to IT service management can help businesses manage risk, strengthen customer relations, establish cost-effective practices, and build a stable IT environment that allows for growth, scale and change.                   </p>
                   
                 </div>
               </div>
             </Col>
            
             <Col md="3">
               <div className="info">
               <div className="icon">
               <hr className="h-line"></hr>

                 </div>
                 <div className="description">
                   <h4 className="info-title-black">Protection of Personal Information Act (POPIA)</h4>
                   <p className="description-black">
                   The Protection of Personal Information Act (or POPI Act) is South Africa’s equivalent of the EU GDPR. It sets some conditions for responsible parties (called controllers in other jurisdictions) to lawfully process the personal information of data subjects (both natural and juristic persons).
                   </p>
                   
                 </div>
               </div>
             </Col>
             <Col md="3">
             <div className="info">
             <div className="icon">
             <hr className="h-line"></hr>

                 </div>
                 <div className="description">
                   <h4 className="info-title-black">Financial Intelligence Centre Act (FICA)</h4>
                   <p className="description-black">
                   FICA is a legal framework which exists to help identify the proceeds of unlawful activities and to combat activities related to money laundering, terrorist funding and tax evasion. Various control measures within FICA aim to ensure the detection and investigation of money laundering.

</p>
                   
                 </div>
               </div>
             </Col>
            
          
             <Col md="3">
               <div className="info">
               <div className="icon">
               <hr className="h-line"></hr>

                 </div>
                 <div className="description">
                   <h4 className="info-title-black">Broad-Based Black Economic Empowerment</h4>
                   <p className="description-black">
                   Broad-Based Black Economic Empowerment (B-BBEE or BEE) is an essential policy tool aimed at broadening the economic base of South Africa, promoting economic growth, mobilising investment, creating jobs and empowering communities.                    
                   </p>
                   
                 </div>
               </div>
             </Col>
            
            

             <Col md="3">
               <div className="info">
               <div className="icon">
               <hr className="h-line"></hr>

                 </div>
                 <div className="description">
                   <h4 className="info-title-black">Generally Accepted Accounting Principles (GAAP)</h4>
                   <p className="description-black">
                   GAAP is an international convention of good accounting practices. They are a set of rules that encompass the details, complexities, and legalities of business and corporate accounting. SAICA uses GAAP as the foundation for its comprehensive set of approved accounting methods and practices.                   </p>
                   
                 </div>
               </div>
             </Col>
             <Col md="3">
             <div className="info">
                 <div className="icon">
                 <hr className="h-line"></hr>

                 </div>
                 <div className="description">
                   <h4 className="info-title-black">National Health Act (NHA)</h4>
                   <p className="description-black">
                   Provides a framework for a structured uniform health system within the Republic, taking into account the obligations imposed by the Constitution and other laws on the national, provincial and local governments with regard to health services
                   </p>
                   
                 </div>
               </div>
             </Col>
             <Col md="3">
               <div className="info">
               <div className="icon">
               <hr className="h-line"></hr>

                 </div>
                 <div className="description">
                   <h4 className="info-title-black">KING IV™</h4>
                   <p className="description-black">
                   In supporting King IV™, the company ensures that directors:
                   <ul className="ml--10 mr--5 blacktext">
                    <li>lead ethically and effectively;</li>
                    <li>support an ethical culture;</li>
                    <li>set the strategic direction for the company for the year ahead;</li>
                    <li>approve policies and planning; and</li>
                    <li>administer and monitor the company's risk and opportunities, strategy, business model, performance and sustainable development.</li>

                    </ul>                   
                       </p>
                   </div>
               </div>
             </Col>
           </Row>
 </div>
 </div>
         <ContactComponent /> 
         <FooterNav />  
        </div>
    )
}
export default AboutUs