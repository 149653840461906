import React from "react";
import Product from "../Product";
// reactstrap components

import WebHeaderTemplate from "./webHeaders/WebHeaderTemplate.js";
// core components
import FooterNav from "../services/FooterNav.js";
import ColorNavbar from "components/Navbars/ColorNavbar.js";
 
import ContactComponent from '../services/ContactComponent.js';
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col,
} from "reactstrap";
import {Link} from 'react-router-dom';

function WebBasic() {
  
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
    };
  });
  return (
    <>
      <ColorNavbar />
      <WebHeaderTemplate navTitle="Basic Website Packages" Title="Basic Websites" />
      <div className="wrapper">

        
        
        <div
          className="pricing-3 section-bg-img"
          style={{
            backgroundImage:
              "url(" + require("assets/img/nl/sections/custom/red-blue.jpg") + ")",
          }}
        >
          <Container>
              
          <Row className="ml-10">
            <Col md="6" sm="12" xs="12" className="col1">
              <Card className="card-pricing">
              <h6 className="card-category">Budget</h6>
              
                <CardBody>
                <div className="webcardicon my-5 minus-starter-margins">
                      <img 
                        alt="website"
                        className="webcardimg mx-4"
                        src={require("assets/img/nlicons/web/widget1.svg")}
                        />
                        <span>
                        <h5 className="webcardtitle">
                        Starter Website
                      </h5>
                        </span>
                  
                      </div>
                  <CardTitle tag="h2">R2499</CardTitle>
                  <ul className="align-left">
                    <li>
   <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      Responsive (Mobile Friendly)
                    </li>
                    <li>
   <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      Content Management System
                    </li>
                    <li>
   <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      Maximum 3 Pages
                    </li>
                    <li>
   <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      Additional Pages At R990 per page
                    </li>
                    <li>
 <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      <b>FREE</b> Domain Name
                    </li>
                    <li>
   <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      <b>3</b> Custom Email Addresses
                    </li>
                    <li>
                      <i className="fa fa-times text-danger mr-1" />
                       Website Hosting
                    </li>
                    <li>
                      <i className="fa fa-times text-danger mr-1" />
                      SSL Certificate
                    </li>
                    
                  </ul>
                  <br></br>
                  <br className="my-1"></br>  
                  <Product 
                  id="StarterWebsite"
                  title="Starter Website"
                  price={2499.0}
                  rating={5}
                  image={require("assets/img/nlicons/web/widget1.svg")}
                  />                
                 <Link to="/contact">
                  <Button
                    className="btn-round card-link web-card-btn"
                    color="success"
                    href="" 
                  >
                    Get Started
                  </Button>
                  </Link>
                  
                </CardBody>
              </Card>
            </Col>
            <Col md="6" sm="12" xs="12" className="col1">
              <Card className="card-pricing">
              <h6 className="card-category">Premium</h6>
                <CardBody>
                <div className="webcardicon my-5 minus-starter-margins">
                      <img 
                        alt="website"
                        className="webcardimg mx-4"
                        src={require("assets/img/nlicons/web/widget1.svg")}
                        />
                        <span>
                        <h5 className="webcardtitle">
                        Basic Pro Website
                      </h5>
                        </span>
                  
                      </div>
                  <CardTitle tag="h2">R3999</CardTitle>
                  <ul className="align-left">
                    <li>
   <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      Responsive (Mobile Friendly)
                    </li>
                    <li>
   <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      Content Management System
                    </li>
                    <li>
   <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      No-CMS Option Available
                    </li>
                    <li>
   <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      Maximum 4 Pages
                    </li>
                    <li>
   <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      Additional Pages At R990 per page
                    </li>
                    <li>
 <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      <b>FREE</b> Domain Name
                    </li>
                    <li>
   <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      <b>1 YEAR FREE</b> Website Hosting
                    </li>
                    <li>
   <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      <b>FREE</b> SSL Certificate
                    </li>
                    <li>
   <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      <b>10</b> Custom Email Addresses
                    </li>
                    <li>
   <img 
                        alt="website"
                        className="webcardimg"
                        src={require("assets/img/nlicons/webservices/check-circle-07.svg")}
                        /> 
                      On-Page SEO
                    </li>
                  </ul>
                  <br></br>
                  <br></br>
                  <br></br>
                  <Product 
                  id="BasicProWebsite"
                  title="Basic Pro Website"
                  price={3999.0}
                  rating={5}
                  image={require("assets/img/nlicons/web/widget1.svg")}
                  />         
<Link to="/contact">
                  <Button
                    className="btn-round card-link web-card-btn"
                    color="success"
                    href="" 
                  >
                    Get Started
                  </Button>
                  </Link>
                </CardBody>
              </Card>
            </Col>
            
            
          </Row>
          </Container>
        </div>
       

        <ContactComponent />
        </div >

      <FooterNav />

    </>
  );
}

export default WebBasic;
