import React from 'react';
import * as emailjs from 'emailjs-com';
import { Form, FormGroup, Label, Input } from 'reactstrap'


class TopContact extends React.Component {
  state = {
    name: '',
    email: '',
    subject: '',
    message: '',
  };
  
    
  
  


  
  

handleSubmit(e) {
    e.preventDefault()
    const { name, email, subject, message } = this.state
    let templateParams = {
      email: email,
      name: name,
      subject: subject,
      message: message,
     }
     emailjs.send('service_wmh421i', 'template_if7tw59', templateParams, 'user_S43tLwP50AawjqOC9AJTY')
     alert("Thank you " + this.state.name + ", your message has been sent. We will get back to you as soon as possible."+
     "For immediate assistance, please use our live chat feature (blue widget in the bottom right corner of the screen)");
    this.resetForm()

     
 }
resetForm() {
    this.setState({
      name: '',
      email: '',
      subject: '',
      message: '',
    })
  }
handleChange = (param, e) => {
    this.setState({ [param]: e.target.value })
  }
  
render() {

  return (

      <div
      id="Contact"
  className="contactBg cd-section mr--custom"
style={{
  backgroundImage:
    "url(" + require("assets/img/nl/globepc.jpg") + ")",
}}
>

    
          <h1 className="p-heading1">Get in Touch</h1>
          <Form onSubmit={this.handleSubmit.bind(this)}>
           
<FormGroup controlId="formBasicName">
              <Label className="text-contact">Name</Label>
              <Input
                type="text"
                name="name"
                value={this.state.name}
                className="headcontact"
                onChange={this.handleChange.bind(this, 'name')}
                placeholder="Enter Name..."
              />
            </FormGroup>
            <FormGroup controlId="formBasicEmail">
              <Label className="text-contact">Email Address</Label>
              <Input
                type="email"
                name="email"
                value={this.state.email}
                className="headcontact"
                onChange={this.handleChange.bind(this, 'email')}
                placeholder="Email Address..."
              />
            </FormGroup>
<FormGroup controlId="formBasicSubject">
              <Label className="text-contact">Telephone Number</Label>
              <Input
                type="text"
                name="subject"
                className="headcontact"
                value={this.state.subject}
                onChange={this.handleChange.bind(this, 'subject')}
                placeholder="Contact Number..."
              />
            </FormGroup>
<FormGroup controlId="formBasicMessage">
              <Label className="text-contact">Message</Label>
              <Input
                type="textarea"
                name="message"
                className="headcontact"
                value={this.state.message}
                onChange={this.handleChange.bind(this, 'message')}
                placeholder="Message..."

              />
              <br></br>
            <br></br>
            <br></br>

            </FormGroup>
<button color="info" className="start-btn" type="submit">
              Send 
              <i className="nc-icon nc-send" />
            </button>
            



            <br></br>
            <br></br>
            <br></br>
           

          </Form>
          

          </div>
    )
  }
}
export default TopContact
  

  