import React from "react";

// reactstrap components
import { Row, Col } from "reactstrap";
import FooterNav from "../services/FooterNav.js";
import AgricultureHeader from "components/Headers/Custom/AgricultureHeader.js";

// core components
import ColorNavbar from "components/Navbars/ColorNavbar.js";
import ContactComponent from '../services/ContactComponent.js';


function Agriculture() {
  
  document.documentElement.classList.remove("nav-open");
  // function that is being called on scroll of the page
  const checkScroll = () => {
    // it takes all the elements that have the .add-animation class on them
    const componentPosition = document.getElementsByClassName("add-animation");
    const scrollPosition = window.pageYOffset;
    for (var i = 0; i < componentPosition.length; i++) {
      var rec =
        componentPosition[i].getBoundingClientRect().top + window.scrollY + 100;
      // when the element with the .add-animation is in the scroll view,
      // the .animated class gets added to it, so it creates a nice fade in animation
      if (scrollPosition + window.innerHeight >= rec) {
        componentPosition[i].classList.add("animated");
        // when the element with the .add-animation is not in the scroll view,
        // the .animated class gets removed from it, so it creates a nice fade out animation
      } else if (scrollPosition + window.innerHeight * 0.8 < rec) {
        componentPosition[i].classList.remove("animated");
      }
    }
  };
  

  React.useEffect(() => {
    document.body.classList.add("presentation-page");
    window.addEventListener("scroll", checkScroll);
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("presentation-page");
      window.removeEventListener("scroll", checkScroll);
    };
  });
  return (
    <>
      <ColorNavbar />
      <AgricultureHeader />
      <div className="wrapper">
       
     
     
      <div>
           
      <Row>
      
                    <Col md="7">
                      <div className="topcard">
                        <h2 id="titleblack2" className="align-left2">
                        Why choose NEXTLEVEL agriculture software solutions? So glad you asked!
                        </h2>
                       
                      </div>
                    
                    </Col>
                    <Col md="4">
                      
                  <p className="top-description mx-20">
                  NEXTLEVEL empowers established agribusinesses and AgriTech startups with its agriculture 
                  software development services to devise innovative ways for farmers to run their operations. 
                  We help our clients create agriculture software that let farmers make data-driven decisions 
                  resulting in higher profitability and sustainable business growth. 
                  </p>
                 
                    </Col> 
                </Row>

        </div>
        <Row className="margin-top-80px">
          
                    <Col md="6">
                    <div
          className="pricing-3 custom-software-div3 pr-20"
          style={{
            backgroundImage:
              "url(" + require("assets/img/nl/industries/agri/glow.jpg") + ")",
          }}
        >
                      
                     
                    
                    <h2 className="whath2fin2"><span>Our expertise in agriculture software development </span></h2>
                    
                </div>

                    </Col>
                    <Col md="6" className="minus-margin-left">
                    <img 
                    alt="cape town"
                    className="sectionimg-agri"
                    src={require("assets/img/nl/industries/agri/vertical-farming.jpg")}
                    />
                    </Col> 
                </Row>
        <div className="shift-down-50px small-border">
        <Row>
        <p className="top-description-center">
        We develop, validate, and 
                  scale agricultural software by providing technology expertise in 
                  precision farming, GPS guidance systems, remote IoT sensing, AI 
                  automation, cloud computing, location and mapping services.          </p>
        </Row>
        <div className="small-border">
                      <div className="box">
        <Row className="mx-20">
        <Col md="4">
          <img 
                         alt="farm management system"
                         className="customcardimg"
                         src={require("assets/img/nlicons/agri/smart-farm.svg")}
                         />
          <h3 id="titleblack2">Farm Management Systems</h3>
          <br></br>
          <p className="top-description2">
          Develop custom farm management systems to improve decision-making, optimize operations, and maximize farm production and profits
          </p>
          </Col>
          <Col md="4">
          <img 
                         alt="drone"
                         className="customcardimg"
                         src={require("assets/img/nlicons/agri/drone.svg")}
                         />
          <h3 id="titleblack2">Aerial imaging and analytics</h3>
          <br></br>
          <p className="top-description2">
          Capture field images using drones and satellites to build precision maps for monitoring crop yields, field conditions, and weather changes
          </p>
          </Col>
          <Col md="4">
          <img 
                         alt="indoor vertical farming"
                         className="customcardimg"
                         src={require("assets/img/nlicons/agri/hydroponic-gardening.svg")}
                         />
          <h3 id="titleblack2">Indoor and vertical farming</h3>
          <br></br>
          <p className="top-description2">
          Create software for vertical farms built within urban spaces to control climate conditions and track crop growth with image recognition
          </p>
          </Col>
          
          
       
        <Col md="4">
          <img 
                         alt="weather monitoring"
                         className="customcardimg"
                         src={require("assets/img/nlicons/agri/weather.svg")}
                         />
          <h3 id="titleblack2">Weather monitoring and predictions</h3>
          <br></br>
          <p className="top-description2">
          Monitor and analyze data collected from IoT sensors, satellites, and historical databases, train machine learning algorithms to predict weather 
          </p>
          </Col>
          <Col md="4">
          <img 
                         alt="distributed ledger"
                         className="customcardimg"
                         src={require("assets/img/nlicons/agri/distributed.svg")}
                         />
          <h3 id="titleblack2">Supply chain and food security</h3>
          <br></br>
          <p className="top-description2">
          Increase transparency using blockchain, eliminate counterfeit products by applying DLT, and empower small farms with smart contracts
          </p>
          </Col>
        <Col md="4">
        <img 
                         alt="IoT sensors"
                         className="customcardimg"
                         src={require("assets/img/nlicons/agri/monitoring.svg")}
                         />
          <h3 id="titleblack2">Smart IoT sensors</h3>
          <br></br>
          <p className="top-description2">
          Apply IoT connectivity to collect field data and remote sensing for monitoring irrigation, crop yields, soil moisture, and weather conditions
          </p>
        </Col>
        
        <Col md="4">
        <img 
                         alt="maintenance"
                         className="customcardimg"
                         src={require("assets/img/nlicons/agri/tractor.svg")}
                         />
          <h3 id="titleblack2">Robotics and automation</h3>
          <br></br>
          <p className="top-description2">
          Guide and steer tractors and harvesters by combining AI, ISOBUS, and GPS navigation to increase efficiency and minimize manual efforts
          </p>
        </Col>
        </Row>
        </div>
        </div>
        </div>

<br></br>
        <div className="shift-down-50px pl-10">
           
           <Row>
           
                         <Col md="7">
                           <div className="topcard">
                             <h2 id="titleblack" className="align-left2">
                             Agriculture R&D Process 
                             </h2>
                            
                           </div>
                         
                         </Col>
                         <Col md="4">
                           
                       <p className="top-description">
                       We’re a talented team of software designers, developers and engineers, specializing in making businesses more efficient 
                       with game-changing custom-made software solutions. 
                       </p>
                      
                         </Col> 
                     </Row>
                     <div className="small-border">


                     <Row>
           
                     <Col md="4" sm="12" xs="12" className="col1">
             <div className="topcard2">
             <img 
                         alt="maintenance"
                         className="customcardimg3"
                         src={require("assets/img/nlicons/agri/teamcopy.svg")}
                         />
              <img 
                         alt="maintenance"
                         className="customcardimg2"
                         id="flow-hori"
                         src={require("assets/img/nlicons/ic_arrow_forward_48px.svg")}
                         />
                         
               <h2 id="titleblacksmall" className="align-left2">
               Set up a scrum team
               </h2>
              <p className="tile-description">
              We set up a scrum team for your project to start as soon as you have an idea and high-level requirements for your solution
              </p>
             </div>
            
           
           </Col>
           <Col md="4" sm="12" xs="12" className="col1">
             <div className="topcard2">
             <img 
                         alt="maintenance"
                         className="customcardimg3"
                         src={require("assets/img/nlicons/agri/qualitative-research.svg")}
                         />
                         <img 
                         alt="maintenance"
                         className="customcardimg2"
                         id="flow-hori"
                         src={require("assets/img/nlicons/ic_arrow_forward_48px.svg")}
                         />
               <h2 id="titleblacksmall" className="align-left2">
               Conduct user research
               </h2>
              <p className="tile-description">
              Our team cooperates with your specialists to begin user research and come up with technical requirements to meet users’ needs
              </p>
              </div>
              
           
           </Col>
           <Col md="4" sm="12" xs="12" className="col1">
             <div className="topcard2">
             <img 
                         alt="maintenance"
                         className="customcardimg3"
                         src={require("assets/img/nlicons/agri/front-end.svg")}
                         />
                         <img 
                         alt="maintenance"
                         className="customcardimg2"
                         id="flow-hori"
                         src={require("assets/img/nlicons/ic_arrow_forward_48px.svg")}
                         />
               <h2 id="titleblacksmall" className="align-left2">
               Design the interface
               </h2>
              <p className="tile-description">
Our UX/UI team works with technical experts to sketch a user-centric interface and prepare a showcase for your main stakeholders
              </p>
             </div>
            
           
           </Col>
       
           
           <Col md="4" sm="12" xs="12"  className="col1">
       
                         
             <div className="topcard2">
            
             <img 
                         alt="maintenance"
                         className="customcardimg3"
                         src={require("assets/img/nlicons/agri/knowledge.svg")}
                         />
                        
                         
                         <img 
                         alt="maintenance"
                         className="customcardimg2"
                         id="flow-hori"
                         src={require("assets/img/nlicons/ic_arrow_forward_48px.svg")}
                         />
               <h2 id="titleblacksmall" className="align-left2">
               Develop your PoC
               </h2>
              <p className="tile-description">
We develop and show off a PoC with core features to receive fast feedback and test the solution on real users to make further improvements
              </p>
             </div>
           
             
           </Col>


           <Col md="4" sm="12" xs="12" className="col1">
       
                         
       <div className="topcard2">
      
       <img 
                   alt="maintenance"
                   className="customcardimg3"
                   src={require("assets/img/nlicons/agri/scrum.svg")}
                   />
                  
                   
                   <img 
                   alt="maintenance"
                   className="customcardimg2"
                   id="flow-hori"
                   src={require("assets/img/nlicons/ic_arrow_forward_48px.svg")}
                   />
         <h2 id="titleblacksmall" className="align-left2">
         Ramp up the team
         </h2>
        <p className="tile-description">
After your PoC has been tested and validated by end customers, we ramp up the team and build a fully working solution
        </p>
       </div>
       
       
     </Col>
           <Col md="4" sm="12" xs="12" className="col1">
             <div className="topcard2">
             <img 
                         alt="maintenance"
                         className="customcardimg3"
                         src={require("assets/img/nlicons/agri/product.svg")}
                         />
                          <img 
                         alt="maintenance"
                         className="customcardimg2"
                         src={require("assets/img/nlicons/ic_arrow_drop_down_circle_48px.svg")}
                         />
                         
                         
               <h2 id="titleblacksmall" className="align-left2">
               Deliver continuously
               </h2>
              <p className="tile-description">

After releasing the first version of your product, we continue to deliver improvements and updates according to your schedule
              </p>
             </div>
           
           </Col>
           
       </Row>
             </div>
</div>
        

        <div className="shift-down-50px">
      <Row>
      
                    <Col md="7">
                      <div className="topcard">
                        <h2 id="titleblack" className="align-left2">
                        Advanced technologies for agriculture
                        </h2>
                       
                      </div>
                    
                    </Col>
                    <Col md="4">
                      
                  <p className="top-description">
                  We provide our clients with full cycle development services for business solutions within the agricultural sphere. 
                  We start with a discovery phase, provide expert consultancy, figure out the most relevant 
                  data to collect, improve internal processes, and develop a visually appealing application within a scalable cloud infrastructure. 
                  </p>
                 
                    </Col> 
                </Row>

        </div>

        
               
        <div className="custom-industries">

        <div className="small-border">
                      <div className="box">
            
            <Row className="mx-20">
             
              <Col md="3">
              <div className="info">
                  <div className="icon">
                  <img 
                         alt="maintenance"
                         className="customcardimg3"
                         src={require("assets/img/nlicons/agri/gps.svg")}
                         />
                  </div>
                  <div className="description">
                    <h4 className="info-title-black">Location Based Service</h4>
                    <p className="description-black">
                    Introduce location intelligence to agriculture with data-saturated maps, automated steering for machinery, and navigation for drones

                    </p>
                    
                  </div>
                </div>
              </Col>
              <Col md="3">
                <div className="info">
                <div className="icon">
                  <img 
                         alt="maintenance"
                         className="customcardimg3"
                         src={require("assets/img/nlicons/agri/location.svg")}
                         />
                  </div>
                  <div className="description">
                    <h4 className="info-title-black">Geographic Information System</h4>
                    <p className="description-black">
                    Use geospatial and GPS data to create accurate field maps for crop monitoring, irrigation systems, and weather predictions

                    </p>
                    
                  </div>
                </div>
              </Col>
              <Col md="3">
              <div className="info">
              <div className="icon">
                  <img 
                         alt="maintenance"
                         className="customcardimg3"
                         src={require("assets/img/nlicons/agri/internet-of-things.svg")}
                         />
                  </div>
                  <div className="description">
                    <h4 className="info-title-black">Internet of Things</h4>
                    <p className="description-black">
                    Maximize the use of connected sensors installed across fields for data collection, deep insights, and improved crop management
                    </p>
                    
                  </div>
                </div>
              </Col>
              <Col md="3">
              <div className="info">
              <div className="icon">
                  <img 
                         alt="maintenance"
                         className="customcardimg3"
                         src={require("assets/img/nlicons/agri/artificial-intelligence.svg")}
                         />
                  </div>
                  <div className="description">
                    <h4 className="info-title-black">AI & Machine Learning</h4>
                    <p className="description-black">
                    Apply advanced machine learning algorithms to obtain quality recommendations, project crop yields and product demand
                    </p>
                    
                  </div>
                </div>
              </Col>
           
              <Col md="3">
                <div className="info">
                <div className="icon">
                  <img 
                         alt="maintenance"
                         className="customcardimg3"
                         src={require("assets/img/nlicons/agri/cloud-storage.svg")}
                         />
                  </div>
                  <div className="description">
                    <h4 className="info-title-black">Data Analytics</h4>
                    <p className="description-black">
                    Collect, process, and analyze real-time data from field sensors to turn raw numbers into strategic insights used for crop management
                    </p>
                    
                  </div>
                </div>
              </Col>
             
              
            </Row>
        </div>
        </div>
        </div>
        <br></br>


    

             


       


       
        <ContactComponent />
      </div>
      <FooterNav />
    </>
  );
}

export default Agriculture;
