import React from "react";
// reactstrap components

// core components
import ColorNavbar from "components/Navbars/ColorNavbar.js";
 
// sections for this page
import PresentationHeader from "components/Headers/PresentationHeader.js";
import SectionComponents from "./presentation-sections/SectionComponents.js";
import SectionSummary from "./presentation-sections/SectionSummary.js";
import SectionCards from "./presentation-sections/SectionCards.js";
import SectionContent from "./presentation-sections/SectionContent.js";
import SectionResponsive from "./presentation-sections/SectionResponsive.js";
import SectionWhat from "./sections-sections/SectionWhat.js";
import Carousel from "./sections-sections/SectionCarousel.js";
import SectionIndustries from "./sections-sections/SectionIndustries.js";
import DigitalMarketing from "./sections-sections/DigitalMarketing.js";
import FooterNav from "./services/FooterNav.js";
import TopContact from './presentation-sections/TopContact.js'
import ContactUs from './presentation-sections/contact.js'


function Sections() {
  
  document.documentElement.classList.remove("nav-open");
  // function that is being called on scroll of the page
  const checkScroll = () => {
    // it takes all the elements that have the .add-animation class on them
    const componentPosition = document.getElementsByClassName("add-animation");
    const scrollPosition = window.pageYOffset;
    for (var i = 0; i < componentPosition.length; i++) {
      var rec =
        componentPosition[i].getBoundingClientRect().top + window.scrollY + 100;
      // when the element with the .add-animation is in the scroll view,
      // the .animated class gets added to it, so it creates a nice fade in animation
      if (scrollPosition + window.innerHeight >= rec) {
        componentPosition[i].classList.add("animated");
        // when the element with the .add-animation is not in the scroll view,
        // the .animated class gets removed from it, so it creates a nice fade out animation
      } else if (scrollPosition + window.innerHeight * 0.8 < rec) {
        componentPosition[i].classList.remove("animated");
      }
    }
  };
  
  
  


  React.useEffect(() => {
    document.body.classList.add("section-page");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;

    document.body.classList.add("presentation-page");
    window.addEventListener("scroll", checkScroll);
   
    

    var href = window.location.href.substring(
      window.location.href.lastIndexOf("#/") + 2
    );
    var hrefId = href.substring(href.lastIndexOf("#") + 1);
    if (href.lastIndexOf("#") > 0) {
      document.getElementById(hrefId).scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }
    // this function is used to make the right bullets list
    // (the bellow <nav id="cd-vertical-nav">)
    // active when changeing the section on scroll
    const updateView = () => {
      var contentSections = document.getElementsByClassName("cd-section");
      var navigationItems = document
        .getElementById("cd-vertical-nav")
        .getElementsByTagName("a");

      for (let i = 0; i < contentSections.length; i++) {
        var activeSection =
          parseInt(navigationItems[i].getAttribute("data-number"), 10) - 1;
        if (
          contentSections[i].offsetTop - window.innerHeight / 2 <
            window.pageYOffset &&
          contentSections[i].offsetTop +
            contentSections[i].scrollHeight -
            window.innerHeight / 2 >
            window.pageYOffset
        ) {
          navigationItems[activeSection].classList.add("is-selected");
        } else {
          navigationItems[activeSection].classList.remove("is-selected");
        }
      }
    };

    window.addEventListener("scroll", updateView);
    return function cleanup() {
      document.body.classList.remove("section-page");
      window.removeEventListener("scroll", updateView);
    };
  });
  return (
    <>
    <div className="mr--custom">
      <ColorNavbar />
      <PresentationHeader />
      <SectionComponents />
      <SectionSummary />
      <TopContact />
      <SectionResponsive />
      <SectionCards />
      <SectionContent />
      <DigitalMarketing />
      <SectionWhat />
      <Carousel />
      <SectionIndustries />
      <ContactUs />
      <FooterNav />
</div>
      <nav id="cd-vertical-nav" className="mr--90">
        <ul>
          <li>
            <a
              data-number="1"
              href="#Introduction"
              onClick={(e) => {
                e.preventDefault();
                document.getElementById("Introduction").scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                  inline: "nearest",
                });
              }}
            >
              <span className="cd-dot" />
              <span className="cd-label">Introduction</span>
            </a>
          </li>
          <li>
            <a
              data-number="2"
              href="#Contact"
              onClick={(e) => {
                e.preventDefault();
                document.getElementById("Contact").scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                  inline: "nearest",
                });
              }}
            >
              <span className="cd-dot" />
              <span className="cd-label">Get In Contact</span>
            </a>
          </li>
          <li>
            <a
              data-number="3"
              href="#AppDesign&Development"
              onClick={(e) => {
                e.preventDefault();
                document.getElementById("AppDesign&Development").scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                  inline: "nearest",
                });
              }}
            >
              <span className="cd-dot" />
              <span className="cd-label">App Design & Development</span>
            </a>
          </li>
          <li>
            <a
              data-number="4"
              href="#WebDesign&Development"
              onClick={(e) => {
                e.preventDefault();
                document.getElementById("WebDesign&Development").scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                  inline: "nearest",
                });
              }}
            >
              <span className="cd-dot" />
              <span className="cd-label">Web Design & Development</span>
            </a>
          </li>
          <li>
            <a
              data-number="5"
              href="#UX-UI-Design"
              onClick={(e) => {
                e.preventDefault();
                document.getElementById("UX-UI-Design").scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                  inline: "nearest",
                });
              }}
            >
              <span className="cd-dot" />
              <span className="cd-label">UX-UI Design</span>
            </a>
          </li>
          <li>
            <a
              data-number="6"
              href="#DigitalMarketing"
              onClick={(e) => {
                e.preventDefault();
                document.getElementById("DigitalMarketing").scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                  inline: "nearest",
                });
              }}
            >
              <span className="cd-dot" />
              <span className="cd-label">Digital Marketing</span>
            </a>
          </li>
          <li>
            <a
              data-number="7"
              href="#WhatWeDo"
              onClick={(e) => {
                e.preventDefault();
                document.getElementById("WhatWeDo").scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                  inline: "nearest",
                });
              }}
            >
              <span className="cd-dot" />
              <span className="cd-label">Services</span>
            </a>
          </li>
          <li>
            <a
              data-number="8"
              href="#IndustriesWeServe"
              onClick={(e) => {
                e.preventDefault();
                document.getElementById("IndustriesWeServe").scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                  inline: "nearest",
                });
              }}
            >
              <span className="cd-dot" />
              <span className="cd-label">Industries</span>
            </a>
          </li>
          <li>
            <a
              data-number="9"
              href="#Contact-Us"
              onClick={(e) => {
                e.preventDefault();
                document.getElementById("Contact-Us").scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                  inline: "nearest",
                });
              }}
            >
              <span className="cd-dot" />
              <span className="cd-label">Contact Us</span>
            </a>
          </li>
        </ul>
      </nav>
</>    
  );
}

export default Sections;
