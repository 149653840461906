/*eslint-disable*/
import React from "react";
// react plugin used to create datetimepicker
import ReactDatetime from "react-datetime";
// react plugin used to create switch buttons
import Switch from "react-bootstrap-switch";
// react plugin used to create an image gallery
import { PhotoSwipeGallery } from "react-photoswipe";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Collapse,
  Container,
  Row,
  Col,
  
} from "reactstrap";


function MobileFAQ() {
 
  // collapse states and functions
  const [collapses, setCollapses] = React.useState([]);
  const changeCollapse = (collapse) => {
    if (collapses.includes(collapse)) {
      setCollapses(collapses.filter((prop) => prop !== collapse));
    } else {
      setCollapses([...collapses, collapse]);
    }
  };
  
  return (
    <>
      <div className="section section-blue javascript-components">
        <Container>
    
          <div>
            <h2 className="custom-title-main"><strong>Frequently Asked Questions</strong></h2>
          </div>
          <Row className="justify-center">
            <Col md="10">
              <div id="acordeon">
                <div aria-multiselectable={true} id="accordion" role="tablist">
                  <Card className="no-transition">
                    <CardHeader
                      className="card-collapse"
                      id="headingOne"
                      role="tab"
                    >
                      <h2 className="mb-0 custom-title">
                        <a
                          aria-expanded={collapses.includes(1)}
                          className="collapsed"
                          data-parent="#accordion"
                          href="#pablo"
                          id="collapseOne"
                          onClick={(e) => {
                            e.preventDefault();
                            changeCollapse(1);
                          }}
                        >
                          How much does a mobile application cost?{" "}
                          <i className="nc-icon nc-minimal-down" />
                        </a>
                      </h2>
                    </CardHeader>
                    <Collapse isOpen={collapses.includes(1)}>
                      <CardBody className="collapse-text">
                      Depending on the type and complexity of the application, about 90% of the cost falls within the 
                      range of R50 000 to R1 million. This number can vary considerably depending on the complexity of the 
                      project, so it is a good idea to work with a vendor to create a ballpark estimate for the job in advance to make an informed decision whether or not to move forward.
                      </CardBody>
                    </Collapse>
                    <CardHeader
                      className="card-collapse"
                      id="headingTwo"
                      role="tab"
                    >
                      <h2 className="mb-0 custom-title">
                        <a
                          aria-controls="collapseTwo"
                          aria-expanded={collapses.includes(2)}
                          className="collapsed"
                          data-parent="#accordion"
                          href="#pablo"
                          id="collapseTwo"
                          onClick={(e) => {
                            e.preventDefault();
                            changeCollapse(2);
                          }}
                        >
                          What is a native mobile application?{" "}
                          <i className="nc-icon nc-minimal-down" />
                        </a>
                      </h2>
                    </CardHeader>
                    <Collapse isOpen={collapses.includes(2)}>
                    <CardBody className="collapse-text">
                    Unlike hybrid or web applications that may function secondarily as mobile applications, a native mobile application is coded in Objective C for iPhones and Java for Android, and it has wider capabilities in terms of accessing the resources of a mobile device, working with graphics, functioning in offline mode, and improving overall reliability. Creating a native application usually costs more than making a hybrid or web app, but it provides the owner with a range of tools that generally enhance the end-user experience.
                      </CardBody>
                    </Collapse>
                    <CardHeader
                      className="card-collapse"
                      id="headingThree"
                      role="tab"
                    >
                      <h2 className="mb-0 custom-title">
                        <a
                          aria-controls="collapseThree"
                          aria-expanded={collapses.includes(3)}
                          className="collapsed"
                          data-parent="#accordion"
                          href="#pablo"
                          id="collapseThree"
                          onClick={(e) => {
                            e.preventDefault();
                            changeCollapse(3);
                          }}
                        >
                          When does a web-based project require the addition of mobile functionality?{" "}
                          <i className="nc-icon nc-minimal-down" />
                        </a>
                      </h2>
                    </CardHeader>
                    <Collapse isOpen={collapses.includes(3)}>
                    <CardBody className="collapse-text">
                    The short answer is always. The caveat is which type of mobile experience you want to provide for your users. A typical web project must be optimized for usage on standard mobile devices, as a high percentage of users will access the application from their smartphones. If the volume of mobile traffic is exceptionally high or functionality for a mobile experience is complex enough, sometimes it is necessary to create a native mobile application to support the best experience and reliability.
                      </CardBody>
                    </Collapse>
                  </Card>
                </div>
                {/* end acordeon */}
              </div>
            </Col>
          </Row>
         
        </Container>
      </div>
    </>
  );
}

export default MobileFAQ;
