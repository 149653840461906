import React from "react";
import "./Subtotal.css";
import CurrencyFormat from "react-currency-format";
import { useStateValue } from "../StateProvider";
import { getBasketTotal } from "../reducer";
import { useHistory } from "react-router-dom";
import { Backdrop } from "@material-ui/core";

function Subtotal() {
  const history = useHistory();
  const [{ basket }, dispatch] = useStateValue();
  return (
    <div className="subtotal">
      <CurrencyFormat
        renderText={(value) => (
          <>
            <p className="subtotal__label">
           
              {/* Part of the homework */}
              Subtotal ({basket.length}  {(() => {
        switch (basket.length) {
          case 1:   return "item";
          
          default:      return "items";
        }
      })()}): <strong className="total__value">{value}</strong>
            </p>
            <small className="subtotal__gift">
              <input type="checkbox" /> This order contains a gift
            </small>
            <br></br>
            <br></br>
           
          </>
          
        )}
        decimalScale={2}
        value={getBasketTotal(basket)} // Part of the homework
        displayType={"text"}
        thousandSeparator={false}
        prefix={"R"}
      />
            <div className="payment__icon__div2">

<img 
alt="payment gateway"
src={require("assets/img/nl/payfast.png")}
className="payment__icon"

/>

</div>  
      <button className="checkout-btn" onClick={e => history.push('/payments')}>
      <img 
            alt="secure_gateway"
            src={require("assets/img/nlicons/button/lock.png")}
            className="button__icon" />
            <img 
            alt="secure_payment"
            src={require("assets/img/nlicons/button/shield.png")} 
            className="button__icon" />Checkout</button>

    
    </div>
    
  );
}

export default Subtotal;
