import React from "react";

// reactstrap components
import { Row, Col} from "reactstrap";
import FooterNav from "../services/FooterNav.js";
import MobileHeader from "components/Headers/Custom/MobileHeader.js";
import MobileFAQ from "./FAQ/Mobilefaq.js";
import ContactComponent from './ContactComponent.js';

// core components
import ColorNavbar from "components/Navbars/ColorNavbar.js";
 

function Mobile() {
  
  document.documentElement.classList.remove("nav-open");
  // function that is being called on scroll of the page
  const checkScroll = () => {
    // it takes all the elements that have the .add-animation class on them
    const componentPosition = document.getElementsByClassName("add-animation");
    const scrollPosition = window.pageYOffset;
    for (var i = 0; i < componentPosition.length; i++) {
      var rec =
        componentPosition[i].getBoundingClientRect().top + window.scrollY + 100;
      // when the element with the .add-animation is in the scroll view,
      // the .animated class gets added to it, so it creates a nice fade in animation
      if (scrollPosition + window.innerHeight >= rec) {
        componentPosition[i].classList.add("animated");
        // when the element with the .add-animation is not in the scroll view,
        // the .animated class gets removed from it, so it creates a nice fade out animation
      } else if (scrollPosition + window.innerHeight * 0.8 < rec) {
        componentPosition[i].classList.remove("animated");
      }
    }
  };
  React.useEffect(() => {
    document.title = "Mobile Applications"
 });

  React.useEffect(() => {
    document.body.classList.add("presentation-page");
    window.addEventListener("scroll", checkScroll);
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("presentation-page");
      window.removeEventListener("scroll", checkScroll);
    };
  });
  return (
    <>
      <ColorNavbar />
      <MobileHeader />
      <div className="wrapper">
       
     
     
      <div>
           
      <Row>
      
                    <Col md="7">
                      <div className="topcard">
                        <h2 id="titleblack" className="align-left2">
                        Why choose NEXTLEVEL mobile software solutions? So glad you asked!
                        </h2>
                       
                      </div>
                    
                    </Col>
                    <Col md="4">
                      
                  <p className="top-description-left mx-20">
                  Mobile software is the cornerstone of any modern business. NEXTLEVEL has extensive experience creating innovative mobile apps with different technology stacks. We develop the right mobile apps for our customers; our solutions are robust and reliable, and we always use the most up-to-date and efficient UI/UX.
                  </p>
                 
                    </Col> 
                </Row>

        </div>
        


        <div className="shift-down-50px">
        
        
                    
                    <div
          className="pricing-3 custom-software-div3"
          style={{
            backgroundImage:
            "url(" + require("assets/img/nlbg/bg5.jpg") + ")",
        }}
        >
            <Row className="">
               <Col md="7">  
               <br></br>
               <br></br>
   
                    <h2 className="whath2fin2 ml-30"><span>Boost your business<br></br>with mobile software</span></h2>
                    <br></br>
                    <h5 className="whath4ind mx-20"><span>
                    Whatever you want to build, NEXTLEVEL will assist you. With the power of mobile software technologies, our engineers will transform your business, making it the utmost effective.
                    </span></h5>
                <br></br>
               
                
                
                    </Col>
                    <Col md="5" className="">
                    <img 
                    alt="team"
                    className="sectionimg-sme3"
                    src={require("assets/img/nl/sections/mobile/business-app.jpg")}
                    />
                    </Col> 
                    </Row>
                    
                
           
               
                     <div className="small-border">


                     <Row className="">
           
           <Col lg="6" md="6" sm="12" xs="12" className="col1">
   <div className="topcard2">
     <hr className="h-line5" />
   <img 
               alt="maintenance"
               className="customcardimg-mob"
               src={require("assets/img/nl/sections/mobile/responsive.svg")}
               />
   
               
     <h2 id="titleblacksmall" className="align-left2 mr-20">
     Integrate Mobile With Web
     </h2>
    <p className="tile-description2">
    Already have a great web-based application? See that your users access it via mobile devices? Our engineers can build a brilliant mobile counterpart.
    </p>
   </div>
  
 
 </Col>
 <Col md="6" sm="12" xs="12" className="col1">
   <div className="topcard2">
   <hr className="h-line5" />
   <img 
               alt="maintenance"
               className="customcardimg-mob"
               src={require("assets/img/nl/sections/mobile/andapp.jpg")}
               />
              
     <h2 id="titleblacksmall" className="align-left2 mr-20">
     Build for iOS and Android Platforms
     </h2>
    <p className="tile-description2">
    Building apps for both platforms allows greater flexibility, a better user experience, and more robust features. NEXTLEVEL engineers are experts in iOS and Android, and we'll get your app to market as quickly and efficiently as possible.
    </p>
    </div>
    
 
 </Col>
 </Row>
 <Row>
 <Col md="6" sm="12" xs="12" className="col1">
   <div className="topcard2">
   <hr className="h-line5" />
   <img 
               alt="maintenance"
               className="customcardimg-mob"
               src={require("assets/img/nl/sections/mobile/time.svg")}
               />
              
     <h2 id="titleblacksmall" className="align-left2 mr-20">
     Streamline Your Business Processes
     </h2>
    <p className="tile-description2">
    As your business gets more complicated, so do your businesses processes. By adding NEXTLEVEL mobile applications to your internal systems, you'll be able to work remotely, cut down on inefficiencies, and save valuable time and money.
    </p>
   </div>
  
 
 </Col>

 
 <Col md="6" sm="12" xs="12" className="col1">

               
   <div className="topcard2">
   <hr className="h-line5" />
   <img 
               alt="maintenance"
               className="customcardimg-mob"
               src={require("assets/img/nl/sections/mobile/shopping-online.svg")}
               />
              
               
              
     <h2 id="titleblacksmall" className="align-left2 mr-20">
     Create a Stand-Alone Mobile App
     </h2>
    <p className="tile-description2">
    If mobile is the core of your business, NEXTLEVEL can build an app that provides your customers with a superior user experience. Whether you're a start-up or an established business, we know exactly how to turn your valuable idea into reality.
    </p>
   </div>
 
   
 </Col>

  
</Row>
             </div>
             </div>
</div>
        

        <div className="shift-down-50px">
            
      <Row>
      
                    <Col md="7">
                      <div className="topcard">
                        <h2 id="titleblack" className="align-left2">
                        NEXTLEVEL builds mobile applications for your unique business goals
                        </h2>
                       
                      </div>
                    
                    </Col>
                    <Col md="4">
                      
                  <p className="top-description-left mx-20">
                    <br></br>
                  NEXTLEVEL presents a unique service package to suit your needs! We've planned, built and implemented custom software solutions for, small businesses, corporate clients and small to mid-sized enterprises - from transport to healthcare and entertainment.
                  </p>
                 
                    </Col> 
                </Row>

        </div>

        
               
        <div className="custom-industries">

        <div className="smaller-border">
                      <div className="box">
            
            <Row className="px-20">
             
              <Col md="3">
              <div className="info">
                  <div className="icon">
                  <img 
                         alt="maintenance"
                         className="customcardimg3"
                         src={require("assets/img/nl/sections/mobile/process.svg")}
                         />
                  </div>
                  <div className="description">
                    <h4 className="info-title-black">Process Automation Solutions</h4>
                    <p className="description-black">
                    We create applications that automate businesses processes so you can focus on your business instead of wasting valuable time and money on labor-intensive systems and administration.
                    </p>
                    
                  </div>
                </div>
              </Col>
              <Col md="3">
                <div className="info">
                <div className="icon">
                  <img 
                         alt="maintenance"
                         className="customcardimg3"
                         src={require("assets/img/nl/sections/mobile/play-button.svg")}
                         />
                  </div>
                  <div className="description">
                    <h4 className="info-title-black">Multimedia Tools</h4>
                    <p className="description-black">
                    A NEXTLEVEL mobile app can include video and audio streaming, image processing and management, social network integration, monetization tools, or all of the above for your business.
                    </p>
                    
                  </div>
                </div>
              </Col>
              <Col md="3">
              <div className="info">
              <div className="icon">
                  <img 
                         alt="maintenance"
                         className="customcardimg3"
                         src={require("assets/img/nl/sections/mobile/shopping.svg")}
                         />
                  </div>
                  <div className="description">
                    <h4 className="info-title-black">Ecommerce Apps</h4>
                    <p className="description-black"> 
Many custom NEXTLEVEL mobile apps are created to help our customers in the market. We help ecommerce companies provide secure and efficient user experiences, manage inventory and payments, and keep their businesses running smoothly.                    </p>
                    
                  </div>
                </div>
              </Col>
              <Col md="3">
              <div className="info">
              <div className="icon">
                  <img 
                         alt="maintenance"
                         className="customcardimg3"
                         src={require("assets/img/nl/sections/mobile/social-media.svg")}
                         />
                  </div>
                  <div className="description">
                    <h4 className="info-title-black">Social Networking Apps</h4>
                    <p className="description-black">
                    NEXTLEVEL mobile solutions can easily be integrated with existing social networking applications, adding a new layer of interactivity and fun experience for your users.
                    </p>
                    
                  </div>
                </div>
              </Col>
           
              <Col md="3">
                <div className="info">
                <div className="icon">
                  <img 
                         alt="maintenance"
                         className="customcardimg3"
                         src={require("assets/img/nl/sections/mobile/settings.svg")}
                         />
                  </div>
                  <div className="description">
                    <h4 className="info-title-black">Internal Corporate Solutions</h4>
                    <p className="description-black">
                    These NEXTLEVEL applications are focused on enhancing existing ERP systems. Our corporate customers love the mobile solutions we've built to increase efficiency and accountability in their organizations.
                    </p>
                    
                  </div>
                </div>
              </Col>

              <Col md="3">
                <div className="info">
                <div className="icon">
                  <img 
                         alt="maintenance"
                         className="customcardimg3"
                         src={require("assets/img/nl/sections/mobile/game-controller.svg")}
                         />
                  </div>
                  <div className="description">
                    <h4 className="info-title-black">Lifestyle & Leisure Apps</h4>
                    <p className="description-black">
                    Our engineers create mobile apps that enrich busy people's lives. Your customers will have amazing access to events, sports, food, travel, culture, and all of the good stuff life has to offer.
                    </p>
                    
                  </div>
                </div>
              </Col>


              <Col md="3">
                <div className="info">
                <div className="icon">
                  <img 
                         alt="maintenance"
                         className="customcardimg3"
                         src={require("assets/img/nl/sections/mobile/newspaper.svg")}
                         />
                  </div>
                  <div className="description">
                    <h4 className="info-title-black">News & Information Apps</h4>
                    <p className="description-black">
                    NEXTLEVEL applications create customized news and information experiences for your users, with advanced UI and superior graphic design, all within your control.
                    </p>
                    
                  </div>
                </div>
              </Col>

              <Col md="3">
                <div className="info">
                <div className="icon">
                  <img 
                         alt="maintenance"
                         className="customcardimg3"
                         src={require("assets/img/nl/sections/mobile/number-one.svg")}
                         />
                  </div>
                  <div className="description">
                    <h4 className="info-title-black">One-of-a-Kind Apps</h4>
                    <p className="description-black">
                    Sometimes your ideas just don't fit into a category. That's why NEXTLEVEL loves helping our customers engineer totally new mobile applications from the ground-up.
                    </p>
                    
                  </div>
                </div>
              </Col>
             
              
            </Row>
            </div>
            </div>
        </div>
        <br></br>


        <div
          className="pricing-3 custom-software-div3"
          style={{
            backgroundImage:
              "url(" + require("assets/img/nl/sections/mobile/mobile-why.jpeg") + ")",
          }}
        >

        <Row>
          <Col md="6" sm="12" xs="12">
          

          </Col>
          <Col md="6" sm="12" xs="12">      
          <h1 className="whath2qa5"><span>Why Choose NEXTLEVEL</span></h1>      
          <ul className="qalist">
              <li><img 
                        alt="website"
                        className="webcardimg mx-4"
                        src={require("assets/img/nlicons/check-circle-white.svg")}
                        /><h5 className="whychoose">Expertise</h5>Our team has diverse technology stack experience and our customers come from diverse industries. Whatever the problem, we can solve it efficiently. </li>
              <br></br>
              <li><img 
                        alt="website"
                        className="webcardimg mx-4"
                        src={require("assets/img/nlicons/check-circle-white.svg")}
                        /><h5 className="whychoose">Value</h5>By ensuring your application's superior quality, NEXTLEVEL ensures its superior value in the market. </li>
              <br></br>
              <li><img 
                        alt="website"
                        className="webcardimg mx-4"
                        src={require("assets/img/nlicons/check-circle-white.svg")}
                        /><h5 className="whychoose">Transparency</h5>We believe that strong business relationships are built on trust and reliability. That's why NEXTLEVEL offers fair, transparent pricing at every stage of your project. </li>
              <br></br>
              <li><img 
                        alt="website"
                        className="webcardimg mx-4"
                        src={require("assets/img/nlicons/check-circle-white.svg")}
                        /><h5 className="whychoose">Scalability</h5>NEXTLEVEL's team of expert engineers can scale any project. Save overheads, time, and stress with our QA services. </li>

            </ul>
            
            </Col>
        </Row>
        </div>




        <div>
      <Row>
      
                    <Col md="7">
                      <div className="topcard">
                        <h2 id="titleblack" className="align-left2">
                        Mobile App Development Process
                        </h2>
                       
                      </div>
                    
                    </Col>
                    <Col md="4">
                      
                  <p className="top-description-left mx-20">
                    <br></br>
                    <br></br>
                  The whole world goes mobile. To make the things right the first time, we follow the best coding practices to create software applications, and strict internal processes of delivery and quality control.
                  </p>
                 
                    </Col> 
                </Row>

        </div>
        <img 
                        alt="Design Process"
                        id="processhorizontal"
                        className="process-img-h"
                        src={require("assets/img/nlscreenshots/mobile-hori.png")}
                        />
         <img 
                        alt="Design Process"
                        id="processvertical"
                        className="process-img-v"
                        src={require("assets/img/nlscreenshots/mobile-vert.png")}
                        />


    

        <MobileFAQ />    


       


       
        <ContactComponent />
        
      </div>
      <FooterNav />
    </>
  );
}

export default Mobile;
