import React from "react";

// reactstrap components
import { Row, Col} from "reactstrap";

// core components
import ColorNavbar from "components/Navbars/ColorNavbar.js";
 
import SeoHeader from "components/Headers/Custom/SeoHeader.js";
import FooterNav from "../services/FooterNav.js";
import { Link } from 'react-router-dom';
import SeoAudit from './FAQ/SeoAudit.js';
import SeoContent from './FAQ/SeoContent.js';
import SeoOnpage from './FAQ/SeoOnpage.js';
import SeoOffpage from './FAQ/SeoOffpage.js';
import SeoLink from './FAQ/SeoLink.js';
import ContactComponent from './ContactComponent.js';


function SeoInfo() {
  document.documentElement.classList.remove("nav-open");
  // function that is being called on scroll of the page
  const checkScroll = () => {
    // it takes all the elements that have the .add-animation class on them
    const componentPosition = document.getElementsByClassName("add-animation");
    const scrollPosition = window.pageYOffset;
    for (var i = 0; i < componentPosition.length; i++) {
      var rec =
        componentPosition[i].getBoundingClientRect().top + window.scrollY + 100;
      // when the element with the .add-animation is in the scroll view,
      // the .animated class gets added to it, so it creates a nice fade in animation
      if (scrollPosition + window.innerHeight >= rec) {
        componentPosition[i].classList.add("animated");
        // when the element with the .add-animation is not in the scroll view,
        // the .animated class gets removed from it, so it creates a nice fade out animation
      } else if (scrollPosition + window.innerHeight * 0.8 < rec) {
        componentPosition[i].classList.remove("animated");
      }
    }
  };
  React.useEffect(() => {
    document.title = "Search Engine Optimisation"
 });

  React.useEffect(() => {
    document.body.classList.add("presentation-page");
    window.addEventListener("scroll", checkScroll);
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("presentation-page");
      window.removeEventListener("scroll", checkScroll);
    };
  });
  return (
    <>
      <ColorNavbar />
      <SeoHeader />
      <div className="wrapper">
       
     
     
      <div className="">
           
      <Row>
      
                    <Col md="6">
                      <div className="topcard">
                        <h2 id="titleblack" className="align-left2">
                        Do I need search engine optimisation? Yes, absolutely! Here's why:
                        </h2>
                       
                      </div>
                    
                    </Col>
                    <Col md="5">
                      
                  <p className="top-description">
                  NEXTLEVEL offers expert SEO consulting services which utilize organic search engine optimization tactics that can <strong>take a website from zero presence to ranking on the first page of Google</strong>. While no SEO guru can guarantee top search engine placement or overnight success, by following the best practices and a proven stratgey, 
                  a NEXTLEVEL SEO consultant will provide tangible results and true return on investment.





                  </p>
                 
                    </Col> 
                    <Col md="12" classname="padding-x-20px">
                      
                  <p className="top-description-center">
                    <strong>Together we’ll help you leverage content marketing and social media to create traffic from organic SEO. Our unique link building strategies will generate you a healthy and diverse array of high quality backlinks.</strong><br></br>
                    </p>
                    <p className="top-description shift-up-30px padding-x-40px ml-20">
                    Our SEO consulting services focus on producing quality content that is well written and focused on the needs of a specific target market. As Our SEO consultants will also assist in the other aspects of technical search engine optimization for your website in order to provide a good user experience and by proxy, increase page rank.
<br></br>
<br></br>
<strong>NEXTLEVEL offers SEO that actually works.</strong>               
</p>
                
                    </Col> 
                </Row>

              



                <div className="small-border">
                      <div className="box">
                      <Row className="shift-down-50px">
        <Col md="3" className="ml--20">
          <img 
                         alt="Payments"
                         className="customcardimg"
                         src={require("assets/img/nl/sections/seo/analysis.svg")}
                         />
          <h3 id="titleblack2">SEO Auditing</h3>
          <br></br>
          <p className="top-description2">
          <ul className="no-bullets">
                    <li>
   <img 
                        alt="dash"
                        className="webcardimg2"
                        src={require("assets/img/nl/sections/dm/minus.svg")}
                        /> 
Website Audits
                    </li>
                    <li>
                    <img 
                        alt="dash"
                        className="webcardimg2"
                        src={require("assets/img/nl/sections/dm/minus.svg")}
                        /> 
Competitor Analysis
                    </li>
                    <li>
                    <img 
                        alt="dash"
                        className="webcardimg2"
                        src={require("assets/img/nl/sections/dm/minus.svg")}
                        /> 
Keyword Research
                    </li>
                    <li>
                    <img 
                        alt="dash"
                        className="webcardimg2"
                        src={require("assets/img/nl/sections/dm/minus.svg")}
                        /> 
Strategic Analysis
                    </li>
                    <li>
                    <img 
                        alt="dash"
                        className="webcardimg2"
                        src={require("assets/img/nl/sections/dm/minus.svg")}
                        /> 
SEO Audit Report
                    </li>
                    </ul>
           </p>
          </Col>
        <Col md="3">
        <img 
                         alt="Inventory Management"
                         className="customcardimg"
                         src={require("assets/img/nl/sections/seo/blogger.svg")}
                         />
          <h3 id="titleblack2">Content Creation</h3>
          <br></br>
          <p className="top-description2">
          <ul className="no-bullets">
                    <li>
   <img 
                        alt="dash"
                        className="webcardimg2"
                        src={require("assets/img/nl/sections/dm/minus.svg")}
                        /> 
Content copy writing
</li>
                    <li>
                    <img 
                        alt="dash"
                        className="webcardimg2"
                        src={require("assets/img/nl/sections/dm/minus.svg")}
                        /> 
Content research
                    </li>
                    <li>
                    <img 
                        alt="dash"
                        className="webcardimg2"
                        src={require("assets/img/nl/sections/dm/minus.svg")}
                        /> 
Content Marketing
                    </li>
                    <li>
                    <img 
                        alt="dash"
                        className="webcardimg2"
                        src={require("assets/img/nl/sections/dm/minus.svg")}
                        /> 
Mixed Media Creation
                    </li>
                    <li>
                    <img 
                        alt="dash"
                        className="webcardimg2"
                        src={require("assets/img/nl/sections/dm/minus.svg")}
                        /> 
Inbound Marketing                    
                    </li>
                    </ul>
          </p>
        </Col>
        <Col md="3">
        <img 
                         alt="Order Management"
                         className="customcardimg"
                         src={require("assets/img/nl/sections/seo/website.svg")}
                         />
          <h3 id="titleblack2">On-Page SEO Factors</h3>
          <br></br>
          <p className="top-description2">
          <ul className="no-bullets">
                    <li>
   <img 
                        alt="dash"
                        className="webcardimg2"
                        src={require("assets/img/nl/sections/dm/minus.svg")}
                        /> 
          Website Technical SEO

                    </li>
                    <li>
                    <img 
                        alt="dash"
                        className="webcardimg2"
                        src={require("assets/img/nl/sections/dm/minus.svg")}
                        /> 
Website Speed
                    </li>
                    <li>
                    <img 
                        alt="dash"
                        className="webcardimg2"
                        src={require("assets/img/nl/sections/dm/minus.svg")}
                        /> 
HTML Markup
                    </li>
                    <li>
                    <img 
                        alt="dash"
                        className="webcardimg2"
                        src={require("assets/img/nl/sections/dm/minus.svg")}
                        /> 
Influencer Outreach
                    </li>
                    <li>
                    <img 
                        alt="dash"
                        className="webcardimg2"
                        src={require("assets/img/nl/sections/dm/minus.svg")}
                        /> 
Information Architecture
                    </li>
                    <li>
                    <img 
                        alt="dash"
                        className="webcardimg2"
                        src={require("assets/img/nl/sections/dm/minus.svg")}
                        /> 
Content
                    </li>
                    </ul>          </p>
        </Col>
        <Col md="3">
        <img 
                         alt="Fleet Management"
                         className="customcardimg"
                         src={require("assets/img/nl/sections/seo/social-media2.svg")}
                         />
          <h3 id="titleblack2">Off-Page SEO Factors</h3>
          <br></br>
          <p className="top-description2">
          <ul className="no-bullets">
                    <li>
   <img 
                        alt="dash"
                        className="webcardimg2"
                        src={require("assets/img/nl/sections/dm/minus.svg")}
                        /> 
Popularity and traffic
</li>
                    <li>
                    <img 
                        alt="dash"
                        className="webcardimg2"
                        src={require("assets/img/nl/sections/dm/minus.svg")}
                        /> 
Trustworthiness
                    </li>
                    <li>
                    <img 
                        alt="dash"
                        className="webcardimg2"
                        src={require("assets/img/nl/sections/dm/minus.svg")}
                        /> 
Backlink Profile
                    </li>
                    <li>
                    <img 
                        alt="dash"
                        className="webcardimg2"
                        src={require("assets/img/nl/sections/dm/minus.svg")}
                        /> 
Domain Authority
                    </li>
                    <li>
                    <img 
                        alt="dash"
                        className="webcardimg2"
                        src={require("assets/img/nl/sections/dm/minus.svg")}
                        /> 
Social Engagement                    
                    </li>
                    </ul>          </p>
        </Col>
       
       
        </Row>      
                      </div>
                  </div> 



                  <Row className="shift-down-100px">
         
         

          
         <Col md="6" className="">
         <img 
         alt="cape town"
         className="sectiondmimg ml--30 mobile-margins"
         src={require("assets/img/nl/sections/seo/audit.jpg")}
         />
         </Col>         
        
         

         <Col md="6">
         <div className="pl-20 pr-20">
         
         <h2 id="titlegreen-dm"><span>Website SEO Audit</span></h2>
         
        
         <p className="top-description-space"><span>Our thorough website audit will analyse your current website, identify any problems and provide recommendations for correcting known issues.
<br></br>
<br></br>
You’ll receive a detailed audit report and competitor analysis, after which a Woww WordPress developer can be hired to tackle the list of SEO conflicts and get your site on track to digital success.</span></p>
     </div>
     <SeoAudit />
         </Col>

         <Col md="6" className="shift-down-100pxmin">
         <div className="ml-20 pr-30">
         
         <h2 id="titlegreen-dm"><span>SEO Content Creation</span></h2>

         
         <p className="top-description-space"><span>Quality website content is always at the heart of any SEO strategy. We have a team of highly talented SEO copywriters who will produce quality content guaranteed to interest readers for your online target market.
<br></br>
<br></br>
However, as we have learnt, just publishing quality content is not enough for modern SEO. You also need to combine your newly generated search engine optimized content with high quality content marketing. Unfortunately addage of  “Publish it and they will come”, we now follow, the publish and promote SEO tactic.
</span></p>
     </div>
     <SeoContent />
         </Col>
          
         <Col md="6" className="shift-down-100pxmin">
         <img 
         alt="cape town"
         className="sectiondmimg mobile-margins"
         src={require("assets/img/nl/sections/seo/content-creation-c.jpg")}
         />
         </Col> 


         <Col md="6" className="shift-down-100pxmin">
         <img 
         alt="cape town"
         className="sectiondmimg ml--30 mobile-margins"
         src={require("assets/img/nl/sections/seo/onpage.jpg")}
         />
         </Col>         
        
         

         <Col md="6" className="shift-down-100pxmin">
         <div className="pr-30 pl-20">
         
         <h2 id="titlegreen-dm"><span>On Page SEO Ranking Factors</span></h2>
         
        
         <p className="top-description-space"><span>
         Our thorough website audit will analyse your current website, identify any problems and provide recommendations for correcting known issues.
<br></br>
<br></br>
You’ll receive a detailed audit report and competitive analysis, after which one of our skilled NEXTLEVEL developers can be hired to tackle the list of SEO conflicts and get your site on track to digital success.
          </span></p>
     </div>
     <SeoOnpage />
         </Col>

         <Col md="6" className="shift-down-100pxmin">
         <div className="ml-20 pr-30">
         
         <h2 id="titlegreen-dm"><span>
         Off Page SEO Ranking Factors</span></h2>
         
         
         <p className="top-description-space"><span>
         When ranking your pages, Google looks at factors outside of your site as well. Here are some of the key ones:

</span></p>
     </div>
     <SeoOffpage />
         </Col>
          
         <Col md="6" className="shift-down-100pxmin">
         <img 
         alt="cape town"
         className="sectiondmimg2 mobile-margins"
         src={require("assets/img/nl/sections/seo/puzzle.jpg")}
         />
         </Col> 


         <Col md="6" className="shift-down-100pxmin">
         <img 
         alt="cape town"
         className="sectiondmimg ml--30 mobile-margins"
         src={require("assets/img/nl/sections/seo/pushpins.jpg")}
         />
         </Col>         
        
         

         <Col md="6" className="shift-down-100pxmin">
         <div className="pr-30 pl-20">
         
         <h2 id="titlegreen-dm"><span>Link Building For SEO
</span></h2>
         
        
         <p className="top-description-space"><span>
         Link building is an art. It’s almost always the most challenging part of an SEO’s job, but also the one most critical to success. 
         Link building requires creativity, hustle, and often, a budget. No two link building campaigns are the same, and the way you choose to build links depends as much upon your website as it does your personality. Below are three basic types of link acquisition.  You can also check out Backlinko's guide to building backlinks here.
<br></br>
<br></br>
However, for the effective implementation and execution of link building, our specialised SEO experts will implement a backlink development campaign using various other specialised skills and techniques acquired through prestigious courses.

          </span></p>
     </div>
     <SeoLink />
         </Col>

         <Col md="6" className="shift-down-100pxmin">
         <div className="ml-20 pr-30">
         
         <h2 id="titlegreen-dm"><span>
         SEO Retainer</span></h2>
         
         
         <p className="top-description-space"><span>
         While your SEO can be done once, its best done over time. This way we can track the results and analyse which SEO strategies work best. We’ll also be able to use the latest SEO tips and tricks and stay ahead of the competition. See our  <Link className="custom-link6" to="/seo" target="_blank">SEO pricing plans</Link>.
</span></p>
     </div>
         </Col>
          
         <Col md="6" className="shift-down-100pxmin">
         <img 
         alt="cape town"
         className="sectiondmimg mobile-margins"
         src={require("assets/img/nl/sections/seo/overhead.jpg")}
         />
         </Col> 

         


     </Row>


</div>


<div className="separator"></div>
     

        <ContactComponent />
      </div>
      <div className="separator" />
      <FooterNav />
    </>
  );
}

export default SeoInfo;
