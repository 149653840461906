/*eslint-disable*/
import React from "react";
// react plugin used to create datetimepicker
import ReactDatetime from "react-datetime";
// react plugin used to create switch buttons
import Switch from "react-bootstrap-switch";
// react plugin used to create an image gallery
import { PhotoSwipeGallery } from "react-photoswipe";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Collapse,
  
  Container,
  Row,
  Col,
  
} from "reactstrap";


function SeoAudit() {
 
  // collapse states and functions
  const [collapses, setCollapses] = React.useState([]);
  const changeCollapse = (collapse) => {
    if (collapses.includes(collapse)) {
      setCollapses(collapses.filter((prop) => prop !== collapse));
    } else {
      setCollapses([...collapses, collapse]);
    }
  };
  
  return (
    <>
      <div className="section section-blue javascript-components shift-up-120px bottom-up-150px background-tp">
        <Container>
    
          
          <Row className="justify-center">
            <Col md="12">
              <div id="acordeon">
                <div aria-multiselectable={true} id="accordion" role="tablist">
                  <Card className="no-transition">
                    <CardHeader
                      className="card-collapse"
                      id="headingOne"
                      role="tab"
                    >
                      <h2 className="mb-0 custom-title">
                        <a
                          aria-expanded={collapses.includes(1)}
                          className="collapsed"
                          data-parent="#accordion"
                          href="#pablo"
                          id="collapseOne"
                          onClick={(e) => {
                            e.preventDefault();
                            changeCollapse(1);
                          }}
                        >
                         SEO Audit Breakdown{" "}
                          <i className="nc-icon nc-minimal-down" />
                        </a>
                      </h2>
                    </CardHeader>
                    <Collapse isOpen={collapses.includes(1)}>
                    <h4>SEO Audit Analysis:</h4>
                    <br></br>
                      <CardBody className="collapse-text">
                      <ol>
                          <li className="align-center">
                              <strong>
                              Accessibility  
                            </strong>

                          <ol className="align-left3">
                            <li>
                            Robots.txt
                            </li>
                            <li>
                            Robots Meta tags
                            </li>
                            <li>
                            HTTP Status Codes
                            </li>
                            <li>
                            XML Sitemap
                            </li>
                            <li>
                            Site Architecture
                            </li>
                            <li>
                            Site Performance
                            </li>

                            </ol>
                          </li>
<br></br>
                          <li>
                              <strong>
                              Indexability  
                            </strong>
                            
                            <ol className="align-left3">
                            <li>
                            Site Command
                            </li>
                            <li>
                            Sanity Checks
                            </li>
                            <li>
                            Page Searches
                            </li>
                            <li>
                            Brand Searches
                            </li>
                            <li>
                            Search Engine Penalities
                            </li> 

                            </ol>
                          </li>
                          
                          <br></br>
                          <li>
                              <strong>
                              On-Page Ranking Factors  
                            </strong>
                            
                            <ol className="align-left3">
                            <li>
                            URLs
                            </li>
                            <li>
                            URL based Duplicate Content
                            </li>
                            <li>
                            Content, keywords, HTML Markup, Images, Outlinks                            
                            </li>

                            </ol>
                          </li>

                          <br></br>
                          <li>
                              <strong>
                              Off-Page Ranking Factors 
                            </strong>
                            
                            <ol className="align-left3">
                            <li>
                            Popularity
                            </li>
                            <li>
                            Trustworthiness
                            </li>
                            <li>
                            Backlink Profile
                            </li>
                            <li>
                            Authority
                            </li>
                            <li>
                            Social Engagement
                            </li> 

                            </ol>
                          </li>

                          <br></br>
                          <li>
                              <strong>
                              Competitive Analysis 
                            </strong>
                            
                            <ol className="align-left3">
                            <li>
                            We’ll also provide a detailed SEO competitor analysis so you know who and how to beat them.
                            </li>
                            

                            </ol>
                          </li>
                          
                      </ol>
                      <br></br>
                      <a className="custom-link6" href="https://moz.com/blog/how-to-perform-the-worlds-greatest-seo-audit" target="_blank">Based on the industry leader: Moz</a>
                      </CardBody>
                    </Collapse>
                 
                  </Card>
                </div>
                {/* end acordeon */}
              </div>
            </Col>
          </Row>
         
        </Container>
      </div>
    </>
  );
}

export default SeoAudit;
